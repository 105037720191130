import React from 'react'
import { Helmet } from 'react-helmet'

import { CssBaseline, ThemeProvider } from '@mui/material'
import { styled } from '@mui/material/styles'

import { usePlayerSelectedTheme } from '@tyto/themes'

import AppBackground from './components/AppBackground'

interface AppLayoutProps {
	children: React.ReactNode
}

const Layout = styled('div', { name: 'AppLayout', slot: 'Layout' })({
	display: 'flex',
	flexDirection: 'column',
	height: '100%',
})

export const AppLayout = ({ children }: AppLayoutProps) => {
	const { muiTheme: theme } = usePlayerSelectedTheme()

	return (
		<ThemeProvider theme={theme}>
			<Helmet>
				<title>Tyto - Work done fun</title>

				<link
					href={
						'https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap'
					}
					rel={'stylesheet'}
				/>
			</Helmet>
			<Layout>
				<CssBaseline />
				<AppBackground>{children}</AppBackground>
			</Layout>
		</ThemeProvider>
	)
}

export default AppLayout
