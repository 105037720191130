import { UserActivityListParams, UserListParams } from '../../api'

export type UserKeysAll = ['users']
export type UserKeysLists = ['users', 'list']
export type UserKeysList = ['users', 'list', UserListParams]
export type UserKeysDetails = ['users', 'detail']
export type UserKeysDetail = ['users', 'detail', string]
export type UserKeysOrganisation = ['users', 'me', 'organisation']
export type UserKeysActivity = [
	'users',
	'detail',
	string,
	'activity',
	'list',
	UserActivityListParams,
]

type UserKeys = {
	all: UserKeysAll
	lists: () => UserKeysLists
	list: (params: UserListParams) => UserKeysList
	details: () => UserKeysDetails
	detail: (userId: string) => UserKeysDetail
	organisation: () => UserKeysOrganisation
	activity: (
		userId: string,
		params: UserActivityListParams
	) => UserKeysActivity
}

export const userKeys: UserKeys = {
	all: ['users'],
	lists: () => [...userKeys.all, 'list'],
	list: (params: UserListParams) => [...userKeys.lists(), params],
	details: () => [...userKeys.all, 'detail'],
	detail: (userId: string) => [...userKeys.details(), userId],
	organisation: () => [...userKeys.all, 'me', 'organisation'],
	activity: (userId: string, params: UserActivityListParams) => [
		...userKeys.detail(userId),
		'activity',
		'list',
		params,
	],
}
