export const FETCH_NOTIFICATIONS_FAILURE = 'FETCH_NOTIFICATIONS_FAILURE'
export const FETCH_NOTIFICATIONS_REQUEST = 'FETCH_NOTIFICATIONS_REQUEST'
export const FETCH_NOTIFICATIONS_SUCCESS = 'FETCH_NOTIFICATIONS_SUCCESS'
export const SET_LAST_READ_NOTIFICATION = 'SET_LAST_READ_NOTIFICATION'
export const USER_NOTIFICATION_CREATED = 'USER_NOTIFICATION_CREATED'

export const fetchNotificationsFailure = (reason) => ({
	type: FETCH_NOTIFICATIONS_FAILURE,
	payload: reason,
})
export const fetchNotificationsRequest = (payload) => ({
	type: FETCH_NOTIFICATIONS_REQUEST,
	payload,
})
export const fetchNotificationsSuccess = (payload) => ({
	type: FETCH_NOTIFICATIONS_SUCCESS,
	payload,
})

export const setLastReadNotification = (payload) => ({
	type: SET_LAST_READ_NOTIFICATION,
	payload,
})

export const userNotificationCreated = (payload) => ({
	type: USER_NOTIFICATION_CREATED,
	payload,
})
