import { del, get, set } from 'idb-keyval'

import { AsyncStorage } from './asyncStorageTypes'

export const asyncStorage: AsyncStorage = {
	getItem: (key: string) =>
		get(key).then((value) => (value === undefined ? null : value)),
	setItem: (key: string, value: string) => set(key, value),
	removeItem: (key: string) => del(key),
}
