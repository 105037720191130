import { AssigneeTreeTask, BoardStrategy } from '../projectsTypes'
import { buildTaskTreeItemsById } from './utils'

type Strategy = BoardStrategy<AssigneeTreeTask>

const buildAssigneeTree: Strategy['buildTree'] = (_, tasks = []) => {
	const assignedTasks = tasks.filter((task) => task.assigneeId)

	const tree = buildTaskTreeItemsById(
		'assignee',
		'assigneeRoot',
		assignedTasks,
		(task) => task.assigneeId
	)

	return tree
}
export const assigneeStrategy: Strategy = {
	buildTree: buildAssigneeTree,
	name: 'assigneeStrategy',
}
