import {
	TaskActivity,
	TaskActivityDescr,
	TaskActivityDescrSchema,
} from '../task-activity-schemas'

export const parseTaskDescrToString = (activity: TaskActivityDescr): string => {
	const meta = activity.meta

	return `${meta.user.nickname} updated the description of this task.`
}

export const validateTaskDescr = (taskActivity: TaskActivity) =>
	TaskActivityDescrSchema.safeParse(taskActivity)
