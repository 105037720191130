import { z } from 'zod'

export type UserAvatarTypes = 'generated' | 'image' | 'letters'
export const UserAvatarGeneratedSchema = z.object({
	type: z.literal('generated'),
	data: z.object({}),
})
export type UserAvatarGenerated = z.infer<typeof UserAvatarGeneratedSchema>

export const UserAvatarImageSchema = z.object({
	type: z.literal('image'),
	data: z.object({
		url: z.string(),
	}),
})
export type UserAvatarImage = z.infer<typeof UserAvatarImageSchema>

export const UserAvatarLettersSchema = z.object({
	type: z.literal('letters'),
	data: z.object({
		userId: z.string(),
		name: z.string(),
		nickname: z.string(),
	}),
})
export type UserAvatarLetters = z.infer<typeof UserAvatarLettersSchema>

export const UserAvatarSchema = z.discriminatedUnion('type', [
	UserAvatarGeneratedSchema,
	UserAvatarImageSchema,
	UserAvatarLettersSchema,
])
export type UserAvatar = z.infer<typeof UserAvatarSchema>
