import forEach from 'lodash/forEach'
import isFunction from 'lodash/isFunction'

import { SET_PLAYER, socketOff, socketOn, startSocket } from './actions'
import { getPlayer } from './reducers/player-reducer'

export * from './socket'

let socketResolve
export const socketReady = new Promise((resolve) => (socketResolve = resolve))

const isNewPlayer = (state, action) => {
	const player = getPlayer(state)
	const newId = action.payload ? action.payload.id : null
	return newId !== player.get('id')
}

const subscribedChannels = {}
const isSubscribedTo = (eventName) => subscribedChannels[eventName]

export const createSocketMiddleware = (url, actionMap = {}) => {
	return (store) => (next) => (action) => {
		switch (action.type) {
			/* case FOCUS_PROJECT_TASK:
				if (socket) socket.emit('viewingProject', action.payload.taskId)
				break */

			case SET_PLAYER:
				// Only continue if the player changed
				if (!isNewPlayer(store.getState(), action)) break

				store.dispatch(startSocket())
				break

			case 'START_SOCKET': {
				if (socket.connected) {
					socketResolve(socket)
				}
				socket.on('connect_error', (err) =>
					console.debug('socket connect_error', err)
				)

				socket.on('error', (err) => console.debug('socket error', err))

				socket.on('disconnect', () =>
					console.debug('socket disconnected')
				)

				socket.on('reconnect', () =>
					console.debug('socket reconnected')
				)

				socket.on('connect', () => {
					console.debug('socket connected')
					socketResolve(socket)
					store.dispatch(socketOn())
				})
				socket.on('disconnect', () => store.dispatch(socketOff()))

				forEach(actionMap, (action, eventName) => {
					subscribedChannels[eventName] = true
					socket.on(eventName, (data) => {
						if (isFunction(action)) {
							store.dispatch(action(data))
						}
					})
				})
				break
			}

			case 'START_CHANNEL':
				if (!isSubscribedTo(action.payload)) {
					socket.on(action.payload)
				}
				break

			case 'STOP_CHANNEL':
				// We probably don't need to check if we're subscribed in the stop case
				socket.off(action.payload)
				subscribedChannels[action.payload] = false
				break

			default:
				break
		}
		return next(action)
	}
}
