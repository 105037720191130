export const fuzzySearch = (textToSearch = '', query = '') => {
	let n = -1
	const haystack = textToSearch.toLowerCase()
	const needles = query.toLowerCase()
	for (let i = 0; i < needles.length; i++) {
		const needle = needles[i]
		n = haystack.indexOf(needle, n + 1)
		if (n === -1) return false
	}
	return true
}
