import { constant, isNullish, when } from 'remeda'

import { regexMatchAll } from '../string'

const findTags = (str: string) => {
	const regex = /(?:^|\s|>|&nbsp;)(?:#)([^\s#<>'"]+)/gm
	return regexMatchAll(regex, str).map((matches) => matches[1])
}

const defaultToEmptyString: (data?: string) => string = when(
	isNullish,
	constant('')
)

export const extractTags = (
	task: Partial<{ title: string; descr: string }> | undefined
) => {
	if (!task || !('title' in task || 'descr' in task)) {
		return { tagString: undefined, tags: undefined }
	}
	const title = defaultToEmptyString(task.title).replace(/&nbsp;/g, ' ')
	const descr = defaultToEmptyString(task.descr).replace(/&nbsp;/g, ' ')

	// Remove hash character from the front
	const titleTags = findTags(title)
	const descrTags = findTags(descr)

	return {
		tagString: [...titleTags, ...descrTags].join(' '),
		tags: [
			...titleTags.map((t) => ({ descr: t, source: 'task.title' })),
			...descrTags.map((t) => ({ descr: t, source: 'task.descr' })),
		],
	}
}
