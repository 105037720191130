import { isValid, parseISO } from 'date-fns'

import { getTimeInfo, TimeInfo } from '../../helpers/time/getTimeInfo'
import { Task } from '../../types'

// 👻 Deprecated: use durationFormat instead
export const formatHours = (rawHours: number) => {
	const data = parseHours(rawHours)
	let result = ''

	result += data.hours ? data.hours + 'h' : ''
	result += data.hours && data.minutes ? ' ' : ''
	result += data.minutes ? data.minutes + 'm' : ''

	return result
}

export const getProgress = (
	task:
		| Pick<
				Task,
				'currentTimer' | 'hoursAllocated' | 'hoursTaken' | 'startDate'
		  >
		| null
		| undefined,
	{ isRounded = true } = {}
) => {
	if (!task) {
		return 0
	}

	const info = getTimeInfoFromTask(task)

	const progress = info.duration ? (info.elapsed / info.duration) * 100 : 0

	return isRounded ? Math.round(progress) : progress
}

const blankTimeInfo: TimeInfo = {
	daysToSubtract: 0,
	diff: 0,
	duration: 0,
	elapsed: 0,
	exceeded: 0,
	isOvertime: false,
	isNearOvertime: false,
	remaining: 0,
	taken: 0,
}
export const getTimeInfoFromTask = (
	task:
		| Pick<Task, 'currentTimer' | 'hoursAllocated' | 'hoursTaken'>
		| undefined
): TimeInfo => {
	const startDate =
		task?.currentTimer?.date && isValid(parseISO(task.currentTimer.date))
			? parseISO(task.currentTimer.date)
			: null
	if (!task || !task.currentTimer) {
		return blankTimeInfo
	}

	const hoursAllocated = task.hoursAllocated || 0
	const hoursTaken = task.hoursTaken || 0
	return getTimeInfo(startDate, Math.round(hoursAllocated * 3600), {
		timeTaken: Math.round(hoursTaken * 3600),
		status: task.currentTimer.status,
	})
}

export const getTimeStatus = (task: Pick<Task, 'currentTimer'> | undefined) => {
	if (!task) {
		return 'stopped'
	}
	return task.currentTimer?.status || 'stopped'
}

export const parseHours = (rawHours: number) => {
	const rawSeconds = Math.round(rawHours * 3600)
	const hours = Math.floor(rawHours)
	// Note: Doing subtraction before converting to minutes creates
	// floating point errors.
	const minutes = Math.floor(rawSeconds / 60 - hours * 60)

	return {
		hours: hours,
		minutes: minutes,
	}
}

export const secondsToTimeTakenString = (seconds: number) =>
	formatHours(seconds / 3600) || '0m'
