export const SET_INVITE_TYPE = 'SET_INVITE_TYPE'
export const SOCKET_INVITE_ACCEPTED = 'SOCKET_INVITE_ACCEPTED'
export const SOCKET_INVITE_DECLINED = 'SOCKET_INVITE_DECLINED'
export const TOGGLE_INVITE = 'TOGGLE_INVITE'

export const setInviteData = data => ({ type: SET_INVITE_TYPE, data })
export const socketInviteAccepted = user => ({
	type: SOCKET_INVITE_ACCEPTED,
	payload: user,
})
export const socketInviteDeclined = user => ({
	type: SOCKET_INVITE_DECLINED,
	payload: user,
})
export const toggleInvite = isVisible => ({ type: TOGGLE_INVITE, isVisible })
