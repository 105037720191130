import { QueryClient, QueryFunctionContext } from '@tanstack/react-query'

import { optionIds } from '../../constants'
import { PlayerOptions } from '../../types'
import { ApiAdapter } from '../api'
import { playerKeys } from '../queries'

export const fetchRecentTags =
	(apiAdapter: ApiAdapter) =>
	async ({ signal }: QueryFunctionContext): Promise<Partial<PlayerOptions>> =>
		apiAdapter.users.getOptions('me', [optionIds.RECENT_TAGS], { signal })

export const getRecentTagsFromQueryCache = (queryClient: QueryClient) => {
	const playerOptions = queryClient.getQueryData<PlayerOptions>(
		playerKeys.options()
	)

	if (playerOptions && Array.isArray(playerOptions[optionIds.RECENT_TAGS])) {
		return playerOptions[optionIds.RECENT_TAGS] as string[]
	} else {
		return []
	}
}
