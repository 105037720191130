type DurationFormatOptions = {
	resolution?: 'days' | 'hours' | 'minutes' | 'seconds'
	upperLimit?: 'days' | 'hours' | 'minutes' | 'seconds'
}

const defaultOptions = {
	resolution: 'minutes' as const,
	upperLimit: 'hours' as const,
}
const level = { days: 0, hours: 1, minutes: 2, seconds: 3 }

const MINUTE = 60
const HOUR = 60 * MINUTE
const DAY = 24 * HOUR

/**
 * Format a duration, given in seconds, into a more readable string like 1h 30m.
 */
export const durationFormat = (
	seconds: number,
	options: DurationFormatOptions = {}
): string => {
	if (!seconds) return ''

	const resolution = options.resolution || defaultOptions.resolution
	const upperLimit = options.upperLimit || defaultOptions.upperLimit
	const segments: string[] = []
	let rem = seconds
	const currentLevel = level[resolution] ?? level[defaultOptions.resolution]
	const upperLevel = level[upperLimit] ?? level[defaultOptions.upperLimit]

	const days = Math.floor(rem / DAY)
	if (
		days > 0 &&
		currentLevel >= level['days'] &&
		segments.length < 2 &&
		upperLevel <= level['days']
	) {
		rem = rem % DAY
		segments.push(`${days}d`)
	}

	const hours = Math.floor(rem / HOUR)
	if (
		hours > 0 &&
		currentLevel >= level['hours'] &&
		segments.length < 2 &&
		upperLevel <= level['hours']
	) {
		rem = rem % HOUR
		segments.push(`${hours}h`)
	}

	const minutes = Math.floor(rem / MINUTE)
	if (
		minutes > 0 &&
		currentLevel >= level['minutes'] &&
		segments.length < 2 &&
		upperLevel <= level['minutes']
	) {
		rem = rem % MINUTE
		segments.push(`${minutes}m`)
	}

	const secondsOffset = Math.floor(rem)
	if (
		secondsOffset > 0 &&
		currentLevel >= level['seconds'] &&
		segments.length < 2 &&
		upperLevel <= level['seconds']
	) {
		segments.push(`${secondsOffset}s`)
	}

	return segments.join(' ')
}

export default durationFormat
