import {
	ActiveTaskStatusCode,
	InactiveTaskStatusCode,
	TaskStatusCode,
} from '../types'

export interface TaskStatus {
	id: TaskStatusCode
	label: string
	bgColor: string
	fgColor: string
}

export const defaultTaskStatusCode: TaskStatusCode = 'new'

export const STATUS: Record<TaskStatusCode, TaskStatus> = {
	new: { id: 'new', label: 'New', bgColor: '#d5d5d5', fgColor: '#fff' },
	planning: {
		id: 'planning',
		label: 'Planning',
		bgColor: '#95a5a6',
		fgColor: '#fff',
	},
	ready: { id: 'ready', label: 'Ready', bgColor: '#323231', fgColor: '#fff' },
	started: {
		id: 'started',
		label: 'Started',
		bgColor: '#2980b9',
		fgColor: '#fff',
	},
	testing: {
		id: 'testing',
		label: 'Testing',
		bgColor: '#f17288',
		fgColor: '#fff',
	},
	waiting: {
		id: 'waiting',
		label: 'Waiting',
		bgColor: '#f39c12',
		fgColor: '#fff',
	},
	done: { id: 'done', label: 'Done', bgColor: '#49c666', fgColor: '#fff' },
	archived: {
		id: 'archived',
		label: 'Archived',
		bgColor: '#16a085',
		fgColor: '#fff',
	},
	deleted: {
		id: 'deleted',
		label: 'Deleted',
		bgColor: '#2c3e50',
		fgColor: '#fff',
	},
}

export const STATUS_ORDER: TaskStatusCode[] = [
	'new',
	'planning',
	'ready',
	'started',
	'testing',
	'waiting',
	'done',
	'archived',
	'deleted',
]

// Get a status given a statusId
export const getTaskStatusById = (id: TaskStatusCode) =>
	STATUS[id] || STATUS[STATUS_ORDER[0]]

/** Get a list of task status objects for select components */
export const getTaskStatusList = () => STATUS_ORDER.map((id) => STATUS[id])

// Get a list of the active task status codes.
export const getActiveTaskStatuses = (): ActiveTaskStatusCode[] => [
	'new',
	'waiting',
	'ready',
	'planning',
	'started',
	'testing',
]

export const isActive = (
	status: TaskStatusCode | undefined
): status is ActiveTaskStatusCode =>
	getActiveTaskStatuses().includes(status as ActiveTaskStatusCode)

//Get a list of the inactive task status codes.
export const getInactiveTaskStatuses = (): InactiveTaskStatusCode[] => [
	'done',
	'deleted',
	'archived',
]

export const isInactive = (
	status: TaskStatusCode | undefined
): status is InactiveTaskStatusCode =>
	getInactiveTaskStatuses().includes(status as InactiveTaskStatusCode)
