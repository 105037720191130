import { formatISO } from 'date-fns'
import produce from 'immer'
import moment from 'moment'

import { DATE_FORMAT } from '../constants'
import mergeTask from '../tasks/mergeTask'
import { Task, TaskParent } from '../types'
import { createTaskId } from './createId'

export const getDefaultTask = (ownerId: string): Task => ({
	assigneeId: null,
	childCount: {
		active: 0,
		inactive: 0,
	},
	childSortOrder: [],
	currentTimer: {
		date: null,
		status: 'stopped',
	},
	dateCreated: formatISO(Date.now()),
	deletedDate: null,
	doneDate: null,
	dueDate: null,
	dueTime: null,
	followers: [],
	hasPermission: true,
	hasUserLink: true,
	hoursAllocated: 0,
	hoursTaken: 0,
	id: createTaskId(ownerId),
	importance: 0,
	isActive: true,
	isMinimised: false,
	isParent: false,
	isProject: false,
	isPinned: false,
	isStarred: false,
	isUnread: false,
	lastUpdated: formatISO(Date.now()),
	notificationLevel: 'mute',
	ownerId,
	parentId: null,
	parents: [],
	startDate: null,
	startTime: null,
	statusCode: 'new',
	tags: [],
	title: '',
	urgency: 0,
	workflowData: null,
})

export const createBaseTask = (
	ownerId: string,
	title: string,
	initialData: Omit<Partial<Task>, 'ownerId' | 'title'> = {}
): Task => mergeTask(getDefaultTask(ownerId), { ...initialData, title })

// Create an item for the parents field on a task
export const createParentsItem = (
	task: Pick<Task, 'id' | 'title'>
): TaskParent => ({
	id: task.id,
	title: task.title,
})

export const isInboxTitle = (title: string) => /Inbox:?/.test(title)
// Change task title to include the owner's nickname if it's an inbox task.
export const formatInboxTitle = (task: Task, ownerNickname: string) => {
	if (isInboxTitle(task.title)) {
		const nickname = task.ownerId === '0' ? 'Tyto' : ownerNickname
		return produce(task, (draft) => {
			if (nickname) {
				draft.title = `${nickname}'s inbox`
			}
		})
	}

	return task
}

export const preTaskUpdate = (oldTask: any, updateData: Partial<Task>) => {
	console.warn('preTaskUpdate deprecated: use `makePreTaskUpdate` instead')
	const now = moment()
	// Assignee changed and startDate is in the past
	if (
		updateData.assigneeId &&
		updateData.assigneeId !== oldTask.get('assigneeId') &&
		(!oldTask.get('startDate') ||
			moment(oldTask.get('startDate')).isBefore(now, 'day'))
	) {
		updateData.startDate = now.startOf('day').format(DATE_FORMAT)
	}

	return updateData
}
