import { QueryClient, QueryFunction } from '@tanstack/react-query'
import { produce } from 'immer'
import { map, pipe, prop, unique } from 'remeda'

import { isApiTaskActivityV2 } from '../../../task-activity/task-activity-schemas'
import { Task } from '../../../types'
import {
	ApiAdapter,
	ApiListResult,
	ApiTaskListResult,
	TaskListParams,
} from '../../api'
import { convertTaskActivityFromApiToV2 } from '../../task-activity/taskActivityQueries'
import updateTaskQueryCacheWithList from '../../utils/updateTaskQueryCacheWithList'
import { getQueryLastUpdated, setQueryLastUpdated } from '../utils'

const mergeLastUpdatedTaskListResults = (
	listA: ApiTaskListResult,
	listB: ApiTaskListResult
): ApiTaskListResult => {
	const items = unique([...listA.items, ...listB.items])

	return {
		...listA,
		count: items.length,
		items,
	}
}

const mapLastActivity = produce((draft) => {
	if (draft.lastActivity && isApiTaskActivityV2(draft.lastActivity)) {
		draft.lastActivity = convertTaskActivityFromApiToV2(draft.lastActivity)
	}
})

const mapRawResultToQueryResult = (
	result: ApiListResult<Task> & { privateTaskCount: number }
): ApiTaskListResult => ({
	...result,
	items: pipe(result.items, map(prop('id')), unique()),
})

type TaskListQueryOptions = {
	filter?: (item: Task) => boolean
}

export function createFetchTaskListQueryFn(
	apiAdapter: ApiAdapter,
	queryClient: QueryClient,
	{ filter }: TaskListQueryOptions = {}
): QueryFunction<ApiTaskListResult<string>> {
	return async ({ queryKey, signal }) => {
		const params: TaskListParams = queryKey[2] || {}
		const prevResult =
			queryClient.getQueryData<ApiTaskListResult<string>>(queryKey)

		const paramsWithMeta = Object.assign({}, params)

		const lastUpdated = await getQueryLastUpdated(queryKey)
		if (lastUpdated && prevResult) {
			paramsWithMeta.updatedAfter = lastUpdated
		}

		const result = await apiAdapter.tasks.getList(paramsWithMeta, {
			signal,
		})
		if (result) {
			const filteredResult = filter
				? { ...result, items: result.items.filter(filter) }
				: result

			let mappedResult = mapRawResultToQueryResult(filteredResult)

			// Merge with previous data
			if (lastUpdated && prevResult) {
				mappedResult = mergeLastUpdatedTaskListResults(
					prevResult,
					mappedResult
				)
			}

			updateTaskQueryCacheWithList(
				queryClient,
				result.items.map(mapLastActivity)
			)
			setQueryLastUpdated(queryKey)

			return mappedResult
		} else {
			if (prevResult) {
				return prevResult
			} else {
				return {
					items: [],
					count: 0,
					privateTaskCount: 0,
					page: 1,
					pageSize: 30,
					hasMore: false,
				} satisfies ApiTaskListResult<string>
			}
		}
	}
}

export const createFetchFolderListQueryFn =
	(
		apiAdapter: ApiAdapter,
		queryClient: QueryClient
	): QueryFunction<ApiTaskListResult<Task>> =>
	async ({ queryKey, signal }) => {
		const params: TaskListParams = queryKey[2] || {}

		const paramsWithMeta = Object.assign({}, params)

		const result = await apiAdapter.tasks.getList(paramsWithMeta, {
			signal,
		})

		updateTaskQueryCacheWithList(queryClient, result.items)

		return result
	}
