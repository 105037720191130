import * as Sentry from '@sentry/react'

import Api from './api'

/**
 * In future this needs to handle any type of auth to easily adapt to provider changes eg: switching to firebase
 * or auth0 or perhaps something like passport.
 */

const TOKEN_STORAGE_ID = 'ty.auth'

let playerId = null

export const clearToken = () => {
	localStorage.removeItem(TOKEN_STORAGE_ID)
}

// deprecated
export const fetchPlayer = async () => {
	try {
		const player = await Api.fetchPlayer()
		playerId = player.id
		return player
	} catch (err) {
		if (!(err instanceof Error)) {
			if (err.error) {
				Sentry.captureException(err.error)
			}
		} else {
			Sentry.captureException(err)
		}
		return null
	}
}

export const getToken = () => {
	const searchParams = new URLSearchParams(window.location.search)
	return searchParams.has('auth_token')
		? searchParams.get('auth_token')
		: localStorage.getItem(TOKEN_STORAGE_ID)
}

export const getPlayerId = () => playerId
export const setPlayerId = (id) => {
	playerId = id
}

export const isAuthenticated = () => Boolean(getToken()) && Boolean(playerId)

export const setToken = (newToken) => {
	localStorage.setItem(TOKEN_STORAGE_ID, newToken)
}

export const loginWithToken = (token) => {
	if (token) {
		setToken(token)
		return fetchPlayer()
	}
	return null
}

export const login = async (username, password) => {
	const response = await Api.login(username, password)
	if (response?.token) {
		return loginWithToken(response.token)
	}

	return null
}

export const logout = () => {
	playerId = null
	clearToken()
}
