import { QueryClient } from '@tanstack/react-query'
import { formatISO, startOfDay } from 'date-fns'

import { ApiAdapter } from '../../api'
import { createFetchTaskListQueryFn } from './fetchTaskList'
import { taskKeys } from './taskKeys'

export const createCompletedUserTasksQuery =
	(apiAdapter: ApiAdapter, queryClient: QueryClient) => (userId: string) => {
		const params = {
			assigneeId: userId,
			statusCodes: 'done',
			showDoneFrom: formatISO(startOfDay(Date.now())),
		}
		return {
			queryKey: taskKeys.list(params),
			queryFn: createFetchTaskListQueryFn(apiAdapter, queryClient),
		}
	}
