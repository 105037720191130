import { QueryFunctionContext } from '@tanstack/react-query'

import { StoreContext, User } from '../../../types'
import { ApiListResult } from '../../api'
import updateUserQueryCacheWithList from '../../utils/updateUserQueryCacheWithList'
import { UserKeysList } from './userKeys'

export const createFetchUserListQueryFn =
	({
		apiAdapter,
		queryClient,
	}: Pick<StoreContext, 'apiAdapter' | 'queryClient'>) =>
	async ({
		queryKey,
		signal,
	}: QueryFunctionContext<UserKeysList>): Promise<ApiListResult<User>> => {
		const result = await apiAdapter.users.getList(queryKey[2], { signal })

		if (Array.isArray(result.items)) {
			updateUserQueryCacheWithList(queryClient, result.items)
		}

		return result
	}
