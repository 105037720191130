import {
	TaskActivity,
	TaskActivityTitle,
	TaskActivityTitleSchema,
} from '../task-activity-schemas'

export const parseTaskTitleToString = (activity: TaskActivityTitle): string => {
	const meta = activity.meta

	const formatTitle = (title: string) => `<strong>${title}</strong>`
	return `${meta.user.nickname} renamed this task from ${formatTitle(
		activity.oldTaskTitle
	)} to ${formatTitle(activity.newTaskTitle)}.`
}

export const validateTaskTitle = (taskActivity: TaskActivity) =>
	TaskActivityTitleSchema.safeParse(taskActivity)
