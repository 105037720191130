import { z } from 'zod'

import { getUuid } from './get-uuid'

export const taskIdSchema = z
	.string()
	.refine(
		(id) =>
			id.startsWith('a') ||
			(id.startsWith('f') && /^[0-9a-zA-Z]+$/.test(id)),
		{
			message:
				'Task ID must start with "a" or "f" and contain only alphanumeric characters',
		}
	)

export const workflowIdSchema = z.string().length(20)
export const workflowStepIdSchema = z.string().startsWith('step').length(20)

export const validateTaskId = (taskId: string) => taskIdSchema.safeParse(taskId)
export const validateWorkflowId = (workflowId: string) =>
	workflowIdSchema.safeParse(workflowId)
export const validateWorkflowStepId = (workflowStepId: string) =>
	workflowStepIdSchema.safeParse(workflowStepId)

export const randomString = (
	length: number,
	chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
) => {
	let result = ''
	for (let i = length; i > 0; --i) {
		result += chars[Math.floor(Math.random() * chars.length)]
	}
	return result
}

export const createActivityId = () => getUuid()

export const createChatMessageId = () => getUuid()
export const createChatRoomId = (playerId?: string | null, userId?: string) =>
	playerId && userId
		? [playerId, userId].sort((a, b) => (a < b ? -1 : 1)).join('')
		: getUuid()

export const createFileId = () => getUuid()

export const createChatFileS3Key = (
	organisationId: string,
	chatMessageId: string,
	fileId: string,
	isThumbnail = false
) =>
	`organisation/${organisationId}/chat-message/${chatMessageId}/files/${fileId}/${
		isThumbnail ? 'thumbnail' : 'main'
	}`

export const createTaskFileS3Key = (
	organisationId: string,
	taskId: string,
	fileId: string,
	isThumbnail = false
) =>
	`organisation/${organisationId}/task/${taskId}/files/${fileId}/${
		isThumbnail ? 'thumbnail' : 'main'
	}`

export const createTaskId = (userId: string) => `a${userId}${randomString(8)}`

export const createUserId = () => randomString(4)

export const createWorkflowId = () => randomString(20)
export const createWorkflowStepId = () => `step${randomString(16)}`
