import { durationStringFromSeconds } from '../../helpers/dates'
import {
	TaskActivity,
	TaskActivityPause,
	TaskActivityPauseSchema,
} from '../task-activity-schemas'

export const parseTaskPauseToString = (activity: TaskActivityPause): string => {
	const meta = activity.meta

	if (activity.assigneeId && activity.assigneeId !== activity.userId) {
		return `${meta.user.nickname} paused this task for ${meta.assignee.nickname}.`
	} else {
		return `${
			meta.user.nickname
		} paused this task after working on it for ${durationStringFromSeconds(
			activity.duration
		)}.`
	}
}

export const validateTaskPause = (taskActivity: TaskActivity) =>
	TaskActivityPauseSchema.safeParse(taskActivity)
