import type { QueryFunctionContext } from '@tanstack/react-query'

import type { User } from '../../../types'
import type { ApiAdapter, ApiResult } from '../../api'
import { userKeys } from './userKeys'

export const fetchUser =
	(apiAdapter: ApiAdapter, userId: string) =>
	async ({
		signal,
	}: QueryFunctionContext): Promise<ApiResult<User> | null> => {
		if (!userId) {
			return null
		}

		return apiAdapter.users.getDetail(userId, { signal })
	}

export const createUserDetailQuery =
	(apiAdapter: ApiAdapter) => (userId: string) => ({
		queryKey: userKeys.detail(userId),
		queryFn: fetchUser(apiAdapter, userId),
	})
