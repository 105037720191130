import {
	TaskActivity,
	TaskActivityStart,
	TaskActivityStartSchema,
} from '../task-activity-schemas'

export const parseTaskStartToString = (activity: TaskActivityStart): string => {
	const meta = activity.meta

	if (activity.assigneeId && activity.assigneeId !== activity.userId) {
		return `${meta.user.nickname} started this task for ${meta.assignee.nickname}.`
	} else {
		return `${meta.user.nickname} started this task.`
	}
}

export const validateTaskStart = (taskActivity: TaskActivity) =>
	TaskActivityStartSchema.safeParse(taskActivity)
