import { durationStringFromSeconds } from '../../helpers/dates'
import {
	TaskActivity,
	TaskActivityStop,
	TaskActivityStopSchema,
} from '../task-activity-schemas'

export const parseTaskStopToString = (activity: TaskActivityStop): string => {
	const meta = activity.meta

	if (activity.assigneeId && activity.assigneeId !== activity.userId) {
		return `${meta.user.nickname} stopped this task for ${meta.assignee.nickname}.`
	} else {
		return `${
			meta.user.nickname
		} stopped this task after working on it for ${durationStringFromSeconds(
			activity.duration
		)}.`
	}
}

export const validateTaskStop = (taskActivity: TaskActivity) =>
	TaskActivityStopSchema.safeParse(taskActivity)
