import { useMemo } from 'react'
import {
	useInfiniteQuery,
	useQuery,
	UseQueryOptions,
} from '@tanstack/react-query'
import {
	filter,
	flatMap,
	isNot,
	isTruthy,
	map,
	pick,
	pipe,
	prop,
	sortBy,
} from 'remeda'

import { getUserName } from '../../helpers/user'
import { User } from '../../types'
import { ApiListResult, ApiResult, UserListParams } from '../api'
import {
	createFetchUserListQueryFn,
	fetchUser,
	userKeys,
	UserKeysDetail,
	UserKeysList,
} from '../queries'
import useStore from '../useStore'
import { usePlayerId, usePlayerOptions } from './player'

//import { WatchableOptions } from './types'

type UseUsersOptions = UserListParams & {
	withGuests?: boolean
}

type UseUsersConfig = UseQueryOptions<
	ApiListResult<User>,
	unknown,
	ApiListResult<User>,
	UserKeysList
>

type UseUserListIdsProps = UseUsersOptions & {
	filterFn?: (user: User) => boolean
}

const sortByName = sortBy((user: User) => getUserName(user))

const filterOutGuests = (users: User[], organisationId: string) =>
	users.filter(
		(user) =>
			!user.userGroups.includes('guest') &&
			String(user.organisationId) === String(organisationId)
	)

export const useAllUsers = (params: UserListParams = {}, options = {}) => {
	const { apiAdapter, queryClient } = useStore.getState()

	return useQuery(
		userKeys.list(params),
		createFetchUserListQueryFn({ apiAdapter, queryClient }),
		{ ...options }
	)
}

export const useIsOnline = (userId: string) => {
	const { data: onlineUsers = [] } = useOnlineUsers({
		// TODO: check that this only triggers a rerender when the provided
		// userId is online, so other users going online/offline doesn't
		// trigger a rerender
		select: (data) => ({
			...data,
			items: data.items.filter((user) => user.id === userId),
			count: data.items.length,
		}),
	})
	return onlineUsers.length > 0
}

export const useOnlineUsers = (config?: UseUsersConfig) =>
	useUsers({ isOnline: true }, { staleTime: 1000 * 60, ...config })

export const usePlayer = (config?: WatchableOptions<User>) => {
	const playerId = usePlayerId()
	return useUser(playerId, config)
}

export interface WatchableOptions<T, U extends (keyof T)[] = (keyof T)[]>
	extends Omit<
		UseQueryOptions<
			T,
			unknown,
			U extends (keyof T)[] ? Pick<T, U[number]> : T,
			UserKeysDetail
		>,
		'queryKey' | 'queryFn'
	> {
	watchFields?: U extends (keyof T)[] ? U[number][] : (keyof T)[]
}
// export function useUser<K extends (keyof ApiResult<User>)[]>(
// 	userId: string | null,
// 	options: WatchableOptions<ApiResult<User>, K> & { watchFields: K }
// ): QueryObserverResult<Pick<ApiResult<User>, K[number]>, unknown>
//
// export function useUser(
// 	userId?: string | null,
// 	options?: WatchableOptions<ApiResult<User>>
// ): QueryObserverResult<ApiResult<User>, unknown>

export function useUser<K extends (keyof User)[] = (keyof User)[]>(
	userId?: string | null,
	{ watchFields, ...restOptions }: WatchableOptions<User, K> = {}
) {
	const { apiAdapter } = useStore.getState()
	return useQuery<
		ApiResult<User>,
		Error,
		User extends (keyof User)[] ? Pick<User, User[number]> : User,
		UserKeysDetail
	>(userKeys.detail(userId || ''), fetchUser(apiAdapter, userId || ''), {
		enabled: Boolean(userId),
		select: watchFields
			? (data) => (data ? pick(data, watchFields) : data)
			: undefined,
		...restOptions,
	})
}

export const useUserListIds = (
	{ filterFn = () => true, isOnline }: UseUserListIdsProps = {},
	config = {}
) => {
	const { apiAdapter, queryClient } = useStore.getState()
	const { data: player } = usePlayer({ watchFields: ['organisationId'] })
	const { data: optionsData = {} } = usePlayerOptions([
		'recents.managedUsers',
		'usersIsStarred',
	])

	const organisationId = String(player?.organisationId)
	const {
		'recents.managedUsers': recentUsers = [],
		usersIsStarred: starredUsers = [],
	} = optionsData

	const queryKey = useMemo(() => userKeys.list({ isOnline }), [isOnline])
	const { data, ...other } = useInfiniteQuery(
		queryKey,
		createFetchUserListQueryFn({ apiAdapter, queryClient }),
		{
			getNextPageParam: (lastPage) =>
				lastPage?.hasMore ? lastPage.page + 1 : undefined,
			getPreviousPageParam: (firstPage) =>
				!firstPage || firstPage?.page === 1
					? undefined
					: firstPage.page - 1,
			...config,
		}
	)

	const filteredUsers = useMemo(() => {
		if (data && Array.isArray(data.pages)) {
			return pipe(
				data.pages,
				filter(isTruthy),
				flatMap(prop('items')),
				map((user) =>
					starredUsers.includes(user.id)
						? { ...user, isStarred: true }
						: user
				),
				filter(filterFn)
			)
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
		} else if (data && Array.isArray(data.items)) {
			// TODO: this should be an InfiniteData object, why is it returning
			// data.items?
			return pipe(
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				data.items,
				map((user) =>
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					starredUsers.includes(user.id)
						? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
							// @ts-ignore
							{ ...user, isStarred: true }
						: user
				),
				filter(filterFn)
			)
		} else {
			return []
		}
	}, [data, filterFn, starredUsers])

	const isGuest = (organisationId: string) => (user: User) =>
		user.userGroups.includes('guest') ||
		String(user.organisationId) !== String(organisationId)
	const guestIds: string[] = pipe(
		filteredUsers,
		filter(isGuest(organisationId)),
		sortByName,
		map(prop('id'))
	)
	const recentUserIds: string[] = pipe(
		filteredUsers,
		filter((user) => recentUsers.includes(user.id)),
		map(prop('id'))
	)
	const starredUserIds: string[] = pipe(
		filteredUsers,
		filter(prop('isStarred')),
		sortByName,
		map(prop('id'))
	)
	const unStarredUserIds: string[] = pipe(
		filteredUsers,
		filter(isNot(prop('isStarred'))),
		filter(isNot(isGuest(organisationId))),
		sortByName,
		map(prop('id'))
	)
	const sections = {
		guestIds,
		recentUserIds,
		starredUserIds,
		unStarredUserIds,
	}

	return {
		data: data
			? { users: filteredUsers.map(prop('id')), sections }
			: undefined,
		...other,
	}
}

export const useUsers = (
	{ withGuests = false, ...options }: UseUsersOptions = {},
	config?: UseQueryOptions<ApiListResult<User>, unknown, User[], UserKeysList>
) => {
	const { apiAdapter, queryClient } = useStore.getState()
	const { data: player } = usePlayer({ watchFields: ['organisationId'] })
	const { data: optionsData = {} } = usePlayerOptions(['usersIsStarred'])

	const organisationId = String(player?.organisationId)
	const { usersIsStarred: starredUserIds = [] } = optionsData

	return useQuery<ApiListResult<User>, unknown, User[], UserKeysList>({
		queryKey: userKeys.list(options),
		queryFn: createFetchUserListQueryFn({ apiAdapter, queryClient }),
		select: (apiResult) => {
			const items = apiResult?.items || []
			const users = items.map((user) =>
				starredUserIds.includes(user.id)
					? { ...user, isStarred: true }
					: user
			)
			return withGuests ? users : filterOutGuests(users, organisationId)
		},
		...config,
	})
}

export const useActiveUsers = (
	config?: UseQueryOptions<ApiListResult<User>, unknown, User[], UserKeysList>
) => useUsers({ isActive: true }, config)
