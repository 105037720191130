import { getDefaultTask } from '../helpers/task'
import { Task } from '../types'
import mergeTask from './mergeTask'

export const createTask = (
	ownerId: string,
	initialData: { title: string } & Partial<Task>
): Task => mergeTask(getDefaultTask(ownerId), { ...initialData })

export default createTask
