import Color from 'color'

import { alpha, Shadows, Theme } from '@mui/material/styles'

export const createContrastColor = (
	color: string,
	{ darkContrast = '#fff', lightContrast = '#1a1a1a' } = {}
) => {
	return Color(color).contrast(Color('#fff')) < 3
		? darkContrast
		: lightContrast
}

const getContrastText = (color: string) =>
	createContrastColor(color, {
		darkContrast: Color('#000').alpha(0.82).toString(),
		lightContrast: '#fff',
	})

export const blendColor = (color: string, color2: string, blendAmount = 0.2) =>
	mixColors(color, color2, blendAmount)

export const createBlendedPaletteColor = (
	baseTheme: Theme,
	color: string,
	{
		blendAmount,
		contrastText,
		tonalOffset = 0.2,
	}: { blendAmount?: number; contrastText?: string; tonalOffset?: number } = {
		tonalOffset: 0.2,
	}
) => {
	const contrastTextValue = contrastText || getContrastText(color)

	const blendedColor = blendColor(
		color,
		baseTheme.palette.background.paper,
		blendAmount
	)

	return {
		light: blendedColor.lighten(tonalOffset).toString(),
		main: blendedColor.toString(),
		dark: blendedColor.darken(tonalOffset).toString(),
		contrastText: contrastTextValue,
	}
}

export const createPaletteColor = (
	color: string,
	{
		contrastText,
		tonalOffset = 0.2,
	}: { contrastText?: string; tonalOffset?: number } = {
		tonalOffset: 0.2,
	}
) => {
	const contrastTextValue = contrastText || getContrastText(color)
	return {
		light: Color(color).lighten(tonalOffset).toString(),
		main: color,
		dark: Color(color).darken(tonalOffset).toString(),
		contrastText: contrastTextValue,
	}
}

/*	// Create a function to generate a sequence of elevated shadows:
	[
		"none",
		"0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
		"0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
		"0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)",
		"0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
		"0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)",
		"0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)",
		"0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)",
		"0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)",
		"0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)",
		"0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)",
		"0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)",
		"0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)",
		"0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)",
		"0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)",
		"0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)",
		"0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)",
		"0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)",
		"0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)",
		"0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)",
		"0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)",
		"0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)",
		"0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)",
		"0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)",
		"0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)"
	]
*/
export const createThemeShadows = (color: string): Shadows => {
	// Create an array to hold the shadows
	const shadows = ['none']
	// Create a loop to generate the shadows
	for (let i = 1; i < 25; i++) {
		const current = [
			`0px ${i / 2}px ${i / 2}px -${Math.round(i / 3)}px ${alpha(
				color,
				0.15
			)}`,
			`0px ${i}px ${i * 1.5}px ${Math.round(i / 8)}px ${alpha(
				color,
				0.12
			)}`,
			`0px ${i / 2.5}px ${i * 4}px ${Math.round(i / 3)}px ${alpha(
				color,
				0.1
			)}`,
		]
		// Join the current array into a string and add it to the shadows array
		shadows.push(current.join(','))
	}
	// Return the shadows array
	return shadows as Shadows
}

export const createThemeGlow = (color: string): Shadows => {
	// Create an array to hold the shadows
	const shadows = ['none']
	// Create a loop to generate the shadows
	for (let i = 1; i < 25; i++) {
		const current = [
			`0px 0px ${i}px 0px ${alpha(color, 0.15)}`,
			`0px 0px ${i * 1.5}px ${Math.round(i / 8)}px ${alpha(color, 0.12)}`,
			`0px 0px ${i * 4}px ${Math.round(i / 3)}px ${alpha(color, 0.1)}`,
		]
		// Join the current array into a string and add it to the shadows array
		shadows.push(current.join(','))
	}
	// Return the shadows array
	return shadows as Shadows
}

export const getOverlayAlpha = (elevation: number) => {
	let alphaValue
	if (elevation < 1) {
		alphaValue = 5.11916 * elevation ** 2
	} else {
		alphaValue = 4.5 * Math.log(elevation + 1) + 2
	}
	return (alphaValue / 100).toFixed(2)
}

export const getAlpha = (color: string, value: number) =>
	Color(color).alpha(value).toString()

export const getShade = (color: string, tone: number) =>
	Color(color).mix(Color('#000'), tone).toString()

export const getTint = (color: string, tone: number) =>
	Color(color).mix(Color('#fff'), tone).toString()

export const getTone = (color: string, tone: number) =>
	Color(color)
		.lightness(tone * 100)
		.toString()

export const getHueRotate = (color: string, degrees: number) =>
	Color(color).rotate(degrees).toString()

export const mixColors = (color1: string, color2: string, weight: number) =>
	Color(color1).mix(Color(color2), weight)
