import { durationFormat } from '../../helpers/formatters/duration-format'
import {
	TaskActivity,
	TaskActivityDuration,
	TaskActivityDurationSchema,
} from '../task-activity-schemas'

export const parseTaskDurationToString = (
	activity: TaskActivityDuration
): string => {
	const meta = activity.meta

	const oldDuration = durationFormat(activity.oldDuration)
	const newDuration = durationFormat(activity.newDuration)

	if (activity.oldDuration === 0) {
		return `${meta.user.nickname} set the duration to ${newDuration}.`
	} else {
		return `${meta.user.nickname} changed the duration from ${oldDuration} to ${newDuration}.`
	}
}

export const validateTaskDuration = (taskActivity: TaskActivity) =>
	TaskActivityDurationSchema.safeParse(taskActivity)
