import { createTaskTreeItem } from '../../store/utils'
import {
	AssigneeTreeTask,
	OwnerTreeTask,
	ParentTreeTask,
	StatusTreeTask,
	Tree,
	TreeTask,
	WorkflowStepTreeTask,
	WorkflowTreeTask,
} from '../projectsTypes'

export const buildEmptyTreeTask = <T extends TreeTask = TreeTask>(
	props: Pick<T, 'id' | 'type'> & Partial<Omit<T, 'id' | 'type'>>
) => {
	switch (props.type) {
		case 'assignee':
			return {
				assigneeId: '',
				...props,
			} as AssigneeTreeTask
		case 'owner':
			return {
				ownerId: '',
				...props,
			} as OwnerTreeTask
		case 'parent':
		default:
			return {
				childSortOrder: [],
				isMinimised: false,
				isParent: false,
				parentId: null,
				parents: [],
				...props,
			} as ParentTreeTask
		case 'status':
			return {
				statusCode: 'new',
				...props,
			} as StatusTreeTask
		case 'workflow':
			return {
				parentId: null,
				workflowData: null,
				...props,
			} as WorkflowTreeTask
		case 'workflowStep':
			return {
				...props,
			} as WorkflowStepTreeTask
	}
}

type GetGroupIdFn<T> = (task: T) => string

export const buildTaskTreeItemsById = <T extends TreeTask = TreeTask>(
	type: T['type'],
	rootId: T['id'],
	tasks: T[],
	getGroupId: GetGroupIdFn<T>
) => {
	const rootData = {
		id: rootId,
		type,
	} as Pick<T, 'id' | 'type'> & Partial<T>
	const rootItem = createTaskTreeItem(buildEmptyTreeTask<T>(rootData), [])
	const items = {
		[rootItem.id]: rootItem,
	}

	tasks.forEach((task) => {
		items[task.id] = createTaskTreeItem(task)
	})

	const tasksByGroup = tasks.reduce<Record<string, typeof tasks>>(
		(acc, task) => {
			const groupId = getGroupId(task)
			acc[groupId] = acc[groupId] || []
			acc[groupId].push(task)
			return acc
		},
		{}
	)

	Object.entries(tasksByGroup).forEach(([id, tasks]) => {
		const taskIds = tasks
			.map((task) => String(task.id))
			.filter((id): id is T['id'] => Boolean(id))
		items[id] = createTaskTreeItem(
			buildEmptyTreeTask({ id, type } as Pick<T, 'id' | 'type'> &
				Partial<T>),
			taskIds
		)
	})

	rootItem.children = Object.keys(tasksByGroup)

	return { items, rootId: rootItem.id } as Tree<T>
}
