import {
	TaskActivity,
	TaskActivityStatus,
	TaskActivityStatusSchema,
} from '../task-activity-schemas'

export const parseTaskStatusToString = (
	activity: TaskActivityStatus
): string => {
	const meta = activity.meta

	let message = ''
	if (activity.message) {
		message = ` with comment:<br/>${activity.message}`
	}
	if (activity.status) {
		return `${meta.user.nickname} changed the status to ${
			activity.status
		}${message ? message : '.'}`
	} else {
		return `${meta.user.nickname} cleared the status${
			message ? message : '.'
		}`
	}
}

export const validateTaskStatus = (taskActivity: TaskActivity) =>
	TaskActivityStatusSchema.safeParse(taskActivity)
