import { InfiniteData } from '@tanstack/react-query'

import { isObject } from '../type-guards'
import { StoreError } from './store-types'

export const isInfiniteData = <T>(data: unknown): data is InfiniteData<T> => {
	return (
		isObject(data) &&
		'pages' in data &&
		Array.isArray((data as InfiniteData<T>).pages) &&
		'pageParams' in data &&
		Array.isArray((data as InfiniteData<T>).pageParams)
	)
}

export const isStoreError = (storeError: unknown): storeError is StoreError => {
	return (
		isObject(storeError) && 'error' in storeError && 'message' in storeError
	)
}
