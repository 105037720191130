import { combineReducers } from 'redux-immutable'

import { TOGGLE_SIDEBAR } from '../actions'

const isSidebarVisible = localStorage.getItem('ty.sidebar.isVisible')
const initialState =
	isSidebarVisible !== null ? isSidebarVisible === 'true' : true

const isVisible = (state = initialState, { payload, type }) => {
	switch (type) {
		case TOGGLE_SIDEBAR:
			return typeof payload === 'boolean' ? payload : !state

		default:
			return state
	}
}

export const sidebar = combineReducers({
	isVisible,
})

export const getIsVisible = state => state.getIn(['sidebar', 'isVisible'])
