import * as yup from 'yup'

import { TaskFollower, TaskParent, TaskTagUpdate } from '../../../types'

export const taskFollowerSchema: yup.SchemaOf<TaskFollower> = yup.object({
	id: yup.string().required(),
	isVirtual: yup.boolean().required(),
	roles: yup
		.mixed()
		.oneOf(['owner,assignee', 'owner', 'assignee', 'follower'])
		.required(),
})

export const taskParentSchema: yup.SchemaOf<TaskParent> = yup.object({
	id: yup.string().required(),
	title: yup.string().required(),
})

export const taskTagUpdateSchema: yup.SchemaOf<TaskTagUpdate> = yup.object({
	add: yup.array().of(yup.string().defined()),
	remove: yup.array().of(yup.string().defined()),
})

export const taskSchema = yup.object({
	assigneeId: yup.string().defined().nullable().default(null),
	childSortOrder: yup.array(yup.string().defined()).ensure().defined(),
	currentTimer: yup
		.object({
			date: yup.string().nullable().required().default(null),
			status: yup
				.mixed()
				.oneOf(['paused', 'started', 'stopped'])
				.required()
				.default('stopped'),
		})
		.required(),
	deletedDate: yup.string().defined().nullable().default(null),
	doneDate: yup.string().defined().nullable().default(null),
	dueDate: yup.string().defined().nullable().default(null),
	dueTime: yup.string().defined().nullable().default(null),
	followers: yup.array().of(taskFollowerSchema).default([]),
	hoursAllocated: yup.number().min(0).default(0),
	id: yup.string().required(),
	importance: yup.number().min(0).max(10).default(0),
	isActive: yup.bool().required().default(true),
	isMinimised: yup.bool().required().default(false),
	isPinned: yup.bool().required().default(false),
	isStarred: yup.bool().required().default(false),
	isUnread: yup.bool().required().default(false),
	notificationLevel: yup
		.mixed()
		.oneOf(['digest', 'instant', 'mute'])
		.required()
		.default('mute'),
	ownerId: yup.string().required(),
	parentId: yup.string().defined().nullable().default(null),
	parents: yup.array().of(taskParentSchema).default([]),
	startDate: yup.string().defined().nullable().default(null),
	startTime: yup.string().defined().nullable().default(null),
	statusCode: yup
		.mixed()
		.oneOf([
			'new',
			'ready',
			'testing',
			'waiting',
			'done',
			'deleted',
			'archived',
		])
		.required()
		.default('new'),
	tags: yup
		.mixed()
		.oneOf([yup.array().of(yup.string()), taskTagUpdateSchema])
		.defined()
		.default([]),
	title: yup.string().required(),
	urgency: yup.number().min(0).max(10).default(0),
	workflowData: yup.object().nullable().default(null),
})
