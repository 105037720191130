import { indexOf, reduce, sortBy } from 'ramda'

import { ObjectIndex, Task } from '../../types'

export const buildSubtasksByParentId = (
	tasks: Task[] = []
): ObjectIndex<string[]> => {
	const childSortOrderIndex: ObjectIndex<string[]> = {}

	const objectIndex = reduce<Task, ObjectIndex<string[]>>(
		(acc, task) => {
			childSortOrderIndex[task.id] = task.childSortOrder || []

			if (!task.parentId) {
				return acc
			}

			if (!acc[task.parentId]) {
				acc[task.parentId] = []
			}

			acc[task.parentId].push(task.id)

			return acc
		},
		{},
		tasks
	)

	Object.keys(objectIndex).forEach((parentId) => {
		const childSortOrder = childSortOrderIndex[parentId]
		const subtasks = objectIndex[parentId]

		if (
			!childSortOrderIndex[parentId] ||
			childSortOrderIndex[parentId]?.length === 0
		) {
			return
		}

		objectIndex[parentId] = sortBy(
			(subtaskId) => indexOf(subtaskId, childSortOrder),
			subtasks
		)
	})

	return objectIndex
}
