import { RawDraftContentState } from 'draft-js'

import { Task, User, Workflow } from '@tyto/types'

import { createActivityId } from '../helpers/createId'
import {
	FileData,
	MetaUserSchema,
	MetaWorkflowSchema,
	TaskActivity,
	TaskActivityComment,
	TaskActivityCreate,
	TaskActivityDone,
	TaskActivityFiles,
	TaskActivityMove,
	TaskActivityRenameFile,
	TaskActivityWorkflow,
	WrappedTaskActivityV2WithType,
} from './task-activity-schemas'

type TaskActivityMetaUser = Pick<User, 'id' | 'name' | 'nickname' | 'gravatar'>
type TaskActivityMetaWorkflow = Pick<Workflow, 'id' | 'title'>

export const createWrappedActivity = <T = TaskActivity>(
	userId: string,
	taskId: string,
	descr = '',
	data: WrappedTaskActivityV2WithType<T>['data']
): WrappedTaskActivityV2WithType<T> => ({
	id: createActivityId(),
	dateCreated: new Date(),
	data,
	descr,
	hasPermission: true,
	reactions: {},
	taskId,
	userId,
	version: 2,
})

export const createCommentActivity = (
	taskId: string,
	userId: string,
	comment: TaskActivityComment['comment'],
	draftModel: RawDraftContentState,
	replyTo: TaskActivityComment['replyTo']
): WrappedTaskActivityV2WithType<TaskActivityComment> =>
	createWrappedActivity<TaskActivityComment>(userId, taskId, comment, {
		type: 'task.comment',
		comment,
		draftModel,
		replyTo,
		userId,
		meta: { user: { id: userId, name: '', nickname: '', gravatar: '' } },
	})

export const createTaskActivityCreate = (
	user: Pick<User, 'id' | 'name' | 'nickname' | 'gravatar'>,
	source: 'mail' | 'mobile' | 'web' | 'chrome'
): TaskActivityCreate => {
	return {
		type: 'task.create',
		source,
		userId: user.id,
		meta: { user },
	}
}

export const createTaskActivityComment = (
	user: Pick<User, 'id' | 'name' | 'nickname' | 'gravatar'>,
	comment: TaskActivityComment['comment'],
	draftModel: TaskActivityComment['draftModel'],
	replyTo: TaskActivityComment['replyTo'] = null
): TaskActivityComment => {
	return {
		type: 'task.comment',
		comment,
		draftModel,
		replyTo,
		userId: user.id,
		meta: { user },
	}
}

export const createTaskActivityDone = (
	user: Pick<User, 'id' | 'name' | 'nickname' | 'gravatar'>,
	message: TaskActivityDone['message'],
	draftModel: RawDraftContentState
): TaskActivityDone => {
	return {
		type: 'task.finish',
		userId: user.id,
		message,
		draftModel,
		meta: { user },
	}
}

export const createTaskActivityRemoveFile = (
	user: Pick<User, 'id' | 'name' | 'nickname' | 'gravatar'>,
	file: FileData
): TaskActivityFiles => {
	return {
		type: 'task.files',
		userId: user.id,
		files: null,
		removedFile: file,
		meta: { user },
	}
}

export const createTaskActivityRenameFile = (
	user: Pick<User, 'id' | 'name' | 'nickname' | 'gravatar'>,
	oldFileName: string,
	newFileName: string
): TaskActivityRenameFile => {
	return {
		type: 'task.files.rename',
		userId: user.id,
		oldFileName,
		newFileName,
		meta: { user },
	}
}

export const createTaskActivityMove = (
	user: TaskActivityMetaUser,
	oldTask: Pick<Task, 'id' | 'parentId' | 'parents'>,
	newTask: Pick<Task, 'id' | 'parentId' | 'parents'>
): TaskActivityMove => {
	const getParentTitle = (task: Pick<Task, 'parents'>) => {
		const parent =
			task.parents?.length > 0 && task.parents[task.parents?.length - 1]
		return parent ? parent.title : 'Root'
	}
	const oldParentTitle = getParentTitle(oldTask)
	const newParentTitle = getParentTitle(newTask)
	return {
		type: 'task.move',
		userId: user.id,
		oldParentId: oldTask.parentId,
		newParentId: newTask.parentId,
		oldParentTitle,
		newParentTitle,
		meta: {
			oldTask: {
				id: oldTask.id,
				title: oldParentTitle,
			},
			newTask: {
				id: oldTask.id,
				title: newParentTitle,
			},
			user,
		},
	}
}

export const createWorkflowActivity = (
	user: TaskActivityMetaUser,
	workflow: TaskActivityMetaWorkflow
): TaskActivityWorkflow => {
	const metaUser = MetaUserSchema.parse(user)
	const metaWorkflow = MetaWorkflowSchema.parse(workflow)
	return {
		type: 'task.workflow',
		userId: metaUser.id,
		workflowId: metaWorkflow.id,
		workflowTitle: metaWorkflow.title,
		//newStepId: z.string().nullable(),
		//newStepIndex: z.number().nullable(),
		//newStepTitle: z.string().nullable(),
		//oldStepId: z.string().nullable(),
		//oldStepIndex: z.number().nullable(),
		//oldStepTitle: z.string().nullable(),
		//removedWorkflowId: z.string().nullable(),
		//removedWorkflowTitle: z.string().nullable(),
		//hasRequirementsChanged: z.boolean(),
		//requirementsChange: z.object({}).nullable(), // TODO: finish off this schema
		//completedSteps: z
		//	.array(
		//		z.object({
		//			id: z.string(),
		//			userId: z.string(),
		//			date: z.string(),
		//			comment: z.string().optional(),
		//		})
		//	)
		//	.nullable(),
		//comment: z.string().nullable(),
		meta: { user: metaUser, workflow: metaWorkflow },
	}
}
