import { Task } from '../types'

export interface TaskPriority {
	importance: number
	urgency: number
}

export const PRIORITY_THRESHOLD = 7

type PriorityClass =
	| 'importance-1'
	| 'importance-2'
	| 'importance-3'
	| 'urgency-1'
	| 'urgency-2'
	| 'urgency-3'

export const getClassFromTask = (
	task: Pick<Task, 'importance' | 'urgency'>
): PriorityClass | '' => {
	if (!task) return ''

	const maxField = getMaxField(task.importance, task.urgency)
	const range = getRange(Math.max(task.importance, task.urgency))

	return maxField && range ? maxField + '-' + range : ''
}

export const getClassName = (
	type: keyof TaskPriority,
	value: number
): PriorityClass | '' => {
	if (!type) return ''

	if (value >= 1 && value <= 4) {
		return `${type}-1`
	} else if (value >= 5 && value < PRIORITY_THRESHOLD) {
		return `${type}-2`
	} else if (value >= PRIORITY_THRESHOLD && value <= 10) {
		return `${type}-3`
	} else {
		return ''
	}
}

export const getColorName = (task: Pick<Task, 'importance' | 'urgency'>) =>
	getClassFromTask(task).replace('-', '') as
		| 'importance1'
		| 'importance2'
		| 'importance3'
		| 'urgency1'
		| 'urgency2'
		| 'urgency3'
		| ''

export const getAvailability = (task: Pick<Task, 'importance' | 'urgency'>) => {
	if (!task) return 'green'

	const range = getRange(Math.max(task.importance, task.urgency))
	const mapping = { 1: 'green', 2: 'amber', 3: 'red' }

	return range ? mapping[range] : 'green'
}

export const getShortText = (task: Pick<Task, 'importance' | 'urgency'>) => {
	if (!task) return ''

	const range = getRange(Math.max(task.importance, task.urgency))
	const mapping = { 1: 'L', 2: 'M', 3: 'H' }

	return range ? mapping[range] : ''
}

export const getMediumText = (task: Pick<Task, 'importance' | 'urgency'>) => {
	if (!task) return ''

	const range = getRange(Math.max(task.importance, task.urgency))
	const mapping = { 1: 'low', 2: 'medium', 3: 'high' }

	return range && mapping[range] ? mapping[range] : 'low'
}

export const getLongText = (task: Pick<Task, 'importance' | 'urgency'>) => {
	if (!task) return ''

	const maxField = getMaxField(task.importance, task.urgency)
	const range = getRange(Math.max(task.importance, task.urgency))
	const mapping = { 1: 'low', 2: 'medium', 3: 'high' }

	return range && mapping[range] ? `${mapping[range]} ${maxField}` : ''
}

function getMaxField(importance: number, urgency: number) {
	if (urgency >= importance) {
		return 'urgency'
	} else if (importance > urgency) {
		return 'importance'
	} else {
		return null
	}
}

function getRange(value: number) {
	if (value >= 1 && value <= 4) {
		return 1
	} else if (value >= 5 && value < PRIORITY_THRESHOLD) {
		return 2
	} else if (value >= PRIORITY_THRESHOLD && value <= 10) {
		return 3
	} else {
		return 0
	}
}
