import { call, put, select, takeEvery } from 'redux-saga/effects'

import {
	FETCH_NOTIFICATIONS_REQUEST,
	fetchNotificationsFailure,
	fetchNotificationsSuccess,
	SET_LAST_READ_NOTIFICATION,
} from '../actions'
import Api from '../api'
import { getNotifications } from '../reducers/notifications-reducer'

export function* notifications() {
	yield takeEvery(FETCH_NOTIFICATIONS_REQUEST, handleNotificationsRequest)
	yield takeEvery(SET_LAST_READ_NOTIFICATION, handleSetLastReadNotification)
}

function* handleNotificationsRequest({ payload }) {
	const state = yield select()
	try {
		const result = yield call(Api.fetchNotifications, payload)
		const notifications = getNotifications(state)
		result.hasMore = notifications.size < result.total
		result.page = payload.page
		yield put(fetchNotificationsSuccess(result))
	} catch (e) {
		yield put(fetchNotificationsFailure(e))
	}
}

function* handleSetLastReadNotification({ payload }) {
	yield call(Api.setLastReadNotification, payload)
}
