import { createSnackbarKey } from '../reducers/snackbar-reducer'

export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR'
export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR'

export const enqueueSnackbar = notification => {
	const key =
		(notification.options && notification.options.key) ||
		createSnackbarKey()
	return {
		type: ENQUEUE_SNACKBAR,
		payload: {
			...notification,
			key,
			options: {
				...notification.options,
				key,
			},
		},
	}
}

export const removeSnackbar = key => ({
	type: REMOVE_SNACKBAR,
	payload: key,
})
