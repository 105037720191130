import { styled } from '@mui/material/styles'

const AppBackground = styled('div', { name: 'AppBackground' })(({ theme }) => ({
	backgroundColor: theme.palette.background.default,
	backgroundImage: theme.appBackgroundImage,
	backgroundPosition: 'center center',
	backgroundSize: 'cover',
	flex: '1 1 0',
	height: '100vh',
	minWidth: '0',
}))

export default AppBackground
