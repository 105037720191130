import { createBrowserHistory } from 'history'

export const history = createBrowserHistory({ window })

const cachedPush = history.push
// Override history.push method to allow exit animations and delayed FLIP.
history.push = (args) => {
	if (typeof args === 'string') {
		return cachedPush(args)
	}
	if (args && args.state && args.state.animate) {
		args.state.animate().then(() => {
			delete args.state.animate
			cachedPush(args)
		})
	} else {
		cachedPush(args)
	}
}
