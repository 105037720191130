import {
	TaskActivity,
	TaskActivityDone,
	TaskActivityDoneSchema,
} from '../task-activity-schemas'

export const parseTaskDoneToString = (activity: TaskActivityDone): string => {
	const meta = activity.meta

	if (activity.message) {
		return `${meta.user.nickname} marked this task as done with comment:<br/>${activity.message}`
	} else {
		return `${meta.user.nickname} marked this task as done.`
	}
}

export const validateTaskDone = (taskActivity: TaskActivity) =>
	TaskActivityDoneSchema.safeParse(taskActivity)
