/*
 * Task factory functions for mutating state
 */

import { QueryClient } from '@tanstack/react-query'
import debug from 'debug'
import { isDeepEqual } from 'remeda'

import { noop } from '@tyto/utils'

import { Task } from '../../../types'
import { ApiAdapter } from '../../api/baseApiAdapter'
import { taskKeys } from '../../queries/tasks'
import { AppState } from '../../store-types'
import { deepMergeTask } from '../../utils/deepMergeTask'

// TODO: refactor to use a react-query mutation, so it benefits from retries and other mutation features
export const updateTaskMutation = async (
	state: AppState,
	taskId: string,
	changes: Partial<Task>
) => {
	const { apiAdapter, queryClient } = state
	createUpdateTaskOnQueryCache(queryClient)(taskId, changes)
	return createUpdateTaskOnApi(apiAdapter)(taskId, changes)
}

export const createUpdateTaskOnApi =
	(apiAdapter: ApiAdapter) =>
	async (taskId: string, changes: Partial<Task>) =>
		apiAdapter.tasks.update(taskId, changes)

export const createUpdateBulkTasksOnApi =
	(apiAdapter: ApiAdapter) =>
	async (taskIds: string[], changes: Partial<Task>) => {
		await apiAdapter.tasks.updateBulk(taskIds, changes)
		return noop
	}

export const createUpdateTaskOnQueryCache =
	(queryClient: QueryClient) =>
	async (taskId: string, changes: Partial<Task>) => {
		const taskQueryKey = taskKeys.detail(taskId)
		let result: Task | undefined

		await queryClient.cancelQueries(taskQueryKey, { exact: true })
		const previousValue = queryClient.getQueryData<Task>(taskQueryKey)
		if (previousValue) {
			const mergedTask = deepMergeTask(previousValue, changes)
			result = mergedTask
			debug('mutations:updateTaskOnQueryCache')({
				currentTask: previousValue,
				changes,
				merged: mergedTask,
			})
			if (!isDeepEqual(previousValue, mergedTask)) {
				queryClient.setQueryData<Task>(taskQueryKey, mergedTask)
			}
		}

		// Update the parents field of all tasks that contain this title
		if ('title' in changes && typeof changes.title === 'string') {
			const title = changes.title
			// Performance optimization: queryClient.setQueriesData method is very slow for big operations like this
			const queries = queryClient.getQueriesData<Task>(taskKeys.details())
			const filteredQueries = queries.filter(([, task]) => {
				if (!task || !changes.title || !Array.isArray(task.parents)) {
					return false
				}
				const index = task.parents.findIndex(
					(parent) => parent.id === taskId
				)
				if (index > -1) {
					return true
				}
				return false
			})
			if (filteredQueries.length > 0) {
				queryClient.setQueriesData<Task | undefined>(
					filteredQueries.map(([key]) => key),
					(task) => {
						if (!task || !Array.isArray(task.parents)) {
							return task
						}
						const index = task.parents.findIndex(
							(parent) => parent.id === taskId
						)
						if (task.parents[index]) {
							task.parents[index].title = title
						}
						return task
					}
				)
			}
		}

		return result
	}
