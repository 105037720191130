import { taskRoles } from '@tyto/dna'

export const ADD_FOLLOWER = 'ADD_FOLLOWER'
export const ADD_TASK = 'ADD_TASK'
export const BULK_FETCH_TASKS = 'BULK_FETCH_TASKS'
export const BULK_UPDATE_TASKS = 'BULK_UPDATE_TASKS'
export const CLEAR_USER_VIEW_TASKS = 'CLEAR_USER_VIEW_TASKS'
export const FETCH_TASKS_FAILURE = 'FETCH_TASKS_FAILURE'
export const FETCH_TASKS_REQUEST = 'FETCH_TASKS_REQUEST'
export const FETCH_TASKS_SUCCESS = 'FETCH_TASKS_SUCCESS'
export const FETCH_USER_VIEW_TASKS_FAILURE = 'FETCH_USER_VIEW_TASKS_FAILURE'
export const FETCH_USER_VIEW_TASKS_REQUEST = 'FETCH_USER_VIEW_TASKS_REQUEST'
export const FETCH_USER_VIEW_TASKS_SUCCESS = 'FETCH_USER_VIEW_TASKS_SUCCESS'
export const FETCH_TASK = 'FETCH_TASK'
export const MERGE_TASK = 'MERGE_TASK'
export const MERGE_TASKS = 'MERGE_TASKS'
export const MOVE_TASK = 'MOVE_TASK'
export const PAUSE_TASK = 'PAUSE_TASK'
export const PUSH_TASK_TO_USER_VIEW = 'PUSH_TASK_TO_USER_VIEW'
export const SET_ASSIGNEE = 'SET_ASSIGNEE'
export const SET_TASK = 'SET_TASK'
export const SET_TASK_FILES = 'SET_TASK_FILES'
export const SHALLOW_MERGE_TASK = 'SHALLOW_MERGE_TASK'
export const SOCKET_ADD_TASK = 'SOCKET_ADD_TASK'
export const SOCKET_MOVE_TASK = 'SOCKET_MOVE_TASK'
export const SOCKET_TASK_TIMER = 'SOCKET_TASK_TIMER'
export const SOCKET_UPDATE_TASK = 'SOCKET_UPDATE_TASK'
export const SOCKET_UPDATE_TASKS = 'SOCKET_UPDATE_TASKS'
export const START_TASK = 'START_TASK'
export const STOP_TASK = 'STOP_TASK'
export const REMOVE_FOLLOWER = 'REMOVE_FOLLOWER'
export const REMOVE_TASK = 'REMOVE_TASK'
export const UPDATE_TASK = 'UPDATE_TASK'
export const TOGGLE_TASK_TIME_MODAL = 'TOGGLE_TASK_TIME_MODAL'

export const addFollower = (
	taskId,
	followerId,
	roles = taskRoles.FOLLOWER
) => ({
	type: ADD_FOLLOWER,
	payload: { taskId, followerId, roles },
})
export const addTask = (task, files, index = null) => ({
	type: ADD_TASK,
	payload: { task, files, index },
})
export const bulkFetchTasks = (taskIds) => ({
	type: BULK_FETCH_TASKS,
	payload: taskIds,
})
export const bulkUpdateTasks = (taskIds, changes) => ({
	type: BULK_UPDATE_TASKS,
	payload: { taskIds, changes },
})
export const clearUserViewTasks = () => ({ type: CLEAR_USER_VIEW_TASKS })
export const fetchTask = (taskId) => ({ type: FETCH_TASK, taskId })
export const fetchTasksFailure = (reason) => ({
	type: FETCH_TASKS_FAILURE,
	payload: reason,
})
export const fetchTasksRequest = (payload) => ({
	type: FETCH_TASKS_REQUEST,
	payload,
})
export const fetchTasksSuccess = (tasks) => ({
	type: FETCH_TASKS_SUCCESS,
	payload: tasks,
})
export const pushTaskToUserView = (taskId) => ({
	type: PUSH_TASK_TO_USER_VIEW,
	taskId,
})

export const mergeTask = (task) => ({ type: MERGE_TASK, task })
export const mergeTasks = (tasks) => ({ type: MERGE_TASKS, payload: tasks })
export const shallowMergeTask = (task) => ({
	type: SHALLOW_MERGE_TASK,
	payload: task,
})
export const moveTask = (taskId, source, destination) => ({
	type: MOVE_TASK,
	payload: { destination, source, taskId },
})
export const setTask = (task) => ({ type: SET_TASK, task })
export const setTaskFiles = (files) => ({
	type: SET_TASK_FILES,
	payload: files,
})
export const removeFollower = (taskId, followerId) => ({
	type: REMOVE_FOLLOWER,
	payload: { taskId, followerId },
})
export const removeTask = (taskId) => ({ type: REMOVE_TASK, payload: taskId })

export const socketAddTask = (payload) => ({ type: SOCKET_ADD_TASK, payload })
export const socketMoveTask = (payload) => ({
	type: SOCKET_MOVE_TASK,
	payload,
})
export const socketTaskTimer = (payload) => ({
	type: SOCKET_TASK_TIMER,
	payload,
})
export const socketUpdateTask = (payload) => ({
	type: SOCKET_UPDATE_TASK,
	payload: payload.task || payload,
})
export const socketUpdateTasks = (payload) => ({
	type: SOCKET_UPDATE_TASKS,
	payload,
})
export const updateTask = (payload) => ({
	type: UPDATE_TASK,
	payload,
})

export const toggleTaskTimeEdit = (timerId, taskId) => ({
	type: TOGGLE_TASK_TIME_MODAL,
	timerId,
	taskId,
})
