import {
	INIT_QUICK_ADD_DRAFTS,
	PUSH_QUICK_ADD_DRAFT,
	REMOVE_QUICK_ADD_DRAFT,
	setQuickAddDrafts,
} from '../actions'
import { takeLatest, put, select, takeEvery } from 'redux-saga/effects'
import { getDrafts } from '../reducers/quick-add-reducer'
import { toJS } from '../utils'

const DRAFT_LS_KEY = 'quickAddDrafts'

export function* quickAddSaga() {
	yield takeEvery(PUSH_QUICK_ADD_DRAFT, commitDraftState)
	yield takeEvery(REMOVE_QUICK_ADD_DRAFT, commitDraftState)
	yield takeLatest(INIT_QUICK_ADD_DRAFTS, init)
}

function* commitDraftState() {
	const drafts = yield select(getDrafts)
	localStorage.setItem(DRAFT_LS_KEY, JSON.stringify(toJS(drafts)))
}

function* init() {
	try {
		const draftString = localStorage.getItem(DRAFT_LS_KEY)
		if (draftString) {
			const drafts = JSON.parse(draftString)
			yield put(setQuickAddDrafts(drafts))
		}
	} catch (e) {
		console.log(e)
		// error parsing
	}
}
