import { QueryClient } from '@tanstack/react-query'

import { ApiAdapter } from '../../api'
import { createFetchTaskListQueryFn } from './fetchTaskList'
import { taskKeys } from './taskKeys'

export const createFollowUpTasksQuery =
	(apiAdapter: ApiAdapter, queryClient: QueryClient) => () => {
		const params = { assigneeId: '!me', ownerId: 'me' }
		return {
			queryKey: taskKeys.list(params),
			queryFn: createFetchTaskListQueryFn(apiAdapter, queryClient, {
				filter: (item) => item.assigneeId != null,
			}),
		}
	}
