import { TreeItem, TreeTask } from '../../../projects/projectsTypes'

export const createTreeItem = <T>(
	data: T & { id: string },
	children: string[] = []
): TreeItem<T> => ({
	id: data.id,
	children,
	data: JSON.parse(JSON.stringify(data)),
	hasChildren: children.length > 0,
	isExpanded: true,
	isParent: children.length > 0,
	isChildrenLoading: false,
	path: [],
})

export const createTaskTreeItem = <T extends TreeTask = TreeTask>(
	task: T,
	children: string[] = []
) => createTreeItem<T>(task, children)
