import { z } from 'zod'

export const PagePaginationSchema = z.object({
	page: z.coerce.number().optional(),
	pageSize: z.coerce.number().optional(),
})
export type PagePagination = z.infer<typeof PagePaginationSchema>
export const PagePaginationWithDefaultsSchema = z.object({
	page: z.coerce.number().default(1),
	pageSize: z.coerce.number().default(30),
})
export type PagePaginationWithDefaults = z.infer<
	typeof PagePaginationWithDefaultsSchema
>

export const DatePaginationSchema = z.object({
	after: z.string().optional(),
	before: z.string().optional(),
	limit: z.coerce.number().optional(),
})
export type DatePagination = z.infer<typeof DatePaginationSchema>
