import { difference, uniq, without } from 'ramda'
import { prop } from 'remeda'

import { extractTags } from '@tyto/utils/tags/tags-utils'

import { Task } from '../../types'

const getTagArrayFromPartialTask = (
	partialTask: Partial<Pick<Task, 'title' | 'descr'>> | undefined
) => {
	const { tags } = extractTags(partialTask)
	if (!tags) {
		return undefined
	}
	return tags.map(prop('descr'))
}

export const getAddedTags = (
	oldTask: Partial<Task> | undefined,
	newTask: Partial<Task>
) => {
	const oldTags = (oldTask?.tags as string[]) || []
	const newTags = getTagArrayFromPartialTask(newTask)

	if (!newTags) {
		return []
	}

	return difference(newTags, oldTags)
}

export const getRemovedTags = (
	oldTask: Partial<Task> | undefined,
	newTask: Partial<Task>
) => {
	const oldTags = (oldTask?.tags as string[]) || []
	const newTags = getTagArrayFromPartialTask(newTask)

	if (!newTags) {
		return []
	}

	return difference(oldTags, newTags)
}

export const getTagChanges = (
	oldTask: Partial<Task> | undefined,
	newTask: Partial<Task>
) => {
	const oldTags = (oldTask?.tags as string[]) || []
	const added = getAddedTags(oldTask, newTask)
	const removed = getRemovedTags(oldTask, newTask)

	const nextValue = uniq(without(removed, oldTags.concat(added)))

	return { added, removed, nextValue }
}
