import { QueryClient } from '@tanstack/react-query'
import { filter, map, Observable, shareReplay } from 'rxjs'
import { Socket } from 'socket.io-client'
import { Mutate, StoreApi } from 'zustand'

import { ApiAdapter } from '../api'
import { AppState, MutatorMiddleware } from '../store-types'
import { createZustandObservable } from './createZustandObservable'

export function getServiceFromState(
	api: Mutate<StoreApi<AppState>, MutatorMiddleware>,
	service: 'apiAdapter'
): Observable<ApiAdapter>
export function getServiceFromState(
	api: Mutate<StoreApi<AppState>, MutatorMiddleware>,
	service: 'queryClient'
): Observable<QueryClient>
export function getServiceFromState(
	api: Mutate<StoreApi<AppState>, MutatorMiddleware>,
	service: 'socket'
): Observable<Socket>
export function getServiceFromState(
	api: Mutate<StoreApi<AppState>, MutatorMiddleware>,
	service: 'apiAdapter' | 'queryClient' | 'socket'
): Observable<unknown> {
	return createZustandObservable(api, (state) => state[service], {
		fireImmediately: true,
	}).pipe(
		map(({ state }) => state),
		filter((service) => Boolean(service)),
		map((service) => service),
		shareReplay(1)
	)
}
