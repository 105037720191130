import { isMatch } from 'date-fns'
import { z } from 'zod'

import { RawDraftContentStateSchema } from '../draft-js-content-state-schema'
import { DatePaginationSchema } from '../schemas'

export const SourceSchema = z.enum(['mail', 'mobile', 'web', 'chrome'])
export type Source = z.infer<typeof SourceSchema>

export const TaskActivityTypeSchema = z.enum([
	'task.assign',
	'task.comment',
	'task.create',
	'task.delete',
	'task.descr',
	'task.dueDate',
	'task.duration',
	'task.files',
	'task.files.rename',
	'task.finish',
	'task.follower',
	'task.move',
	'task.multiple',
	'task.owner',
	'task.pause',
	'task.priority',
	'task.schedule',
	'task.start',
	'task.status',
	'task.stop',
	'task.title',
	'task.update',
	'task.workflow',
])
export type TaskActivityType = z.infer<typeof TaskActivityTypeSchema>

export const isTaskActivityType = (type: string): type is TaskActivityType => {
	const result = TaskActivityTypeSchema.safeParse(type)
	return result.success
}

export const TaskActivityReactionsSchema = z.record(
	z.string(),
	z.record(z.string(), z.boolean())
)

export const ApiTaskActivityV1Schema = z.object({
	id: z.string(),
	dateCreated: z.string(),
	descr: z.string(),
	descrJson: z.null(),
	hasPermission: z.boolean(),
	reactions: TaskActivityReactionsSchema,
	taskId: z.string(),
	type: TaskActivityTypeSchema,
	userId: z.string(),
	version: z.literal(1),
})
export type ApiTaskActivityV1 = z.infer<typeof ApiTaskActivityV1Schema>

export const ApiTaskActivityV2Schema = z.object({
	id: z.string(),
	dateCreated: z.string(),
	descr: z.string(),
	descrJson: z.record(z.any()),
	hasPermission: z.boolean(),
	reactions: TaskActivityReactionsSchema,
	taskId: z.string(),
	type: TaskActivityTypeSchema,
	userId: z.string(),
	version: z.literal(2),
})
export type ApiTaskActivityV2 = z.infer<typeof ApiTaskActivityV2Schema>

export const ApiTaskActivitySchema = z.discriminatedUnion('version', [
	ApiTaskActivityV1Schema,
	ApiTaskActivityV2Schema,
])
export type ApiTaskActivity = z.infer<typeof ApiTaskActivitySchema>

export const isApiTaskActivityV1 = (
	activity: ApiTaskActivity
): activity is ApiTaskActivityV1 => activity.version === 1
export const isApiTaskActivityV2 = (
	activity: ApiTaskActivity
): activity is ApiTaskActivityV2 => activity.version === 2

export const taskActivityTypes = {
	ASSIGN: 'task.assign',
	COMMENT: 'task.comment',
	CREATE: 'task.create',
	DELETE: 'task.delete',
	DESCR: 'task.descr',
	DUE_DATE: 'task.dueDate',
	DURATION: 'task.duration',
	FILES: 'task.files',
	FILES_RENAME: 'task.files.rename',
	FINISH: 'task.finish',
	FOLLOWER: 'task.follower',
	MOVE: 'task.move',
	MULTIPLE: 'task.multiple',
	OWNER: 'task.owner',
	PAUSE: 'task.pause',
	PRIORITY: 'task.priority',
	SCHEDULE: 'task.schedule',
	START: 'task.start',
	STATUS: 'task.status',
	STOP: 'task.stop',
	TITLE: 'task.title',
	UPDATE: 'task.update',
	WORKFLOW: 'task.workflow',
	// This is a temporary value for client-side permissions and not meant to be
	// saved in the database.
	PRIVATE: 'task.private',

	// 👻 These are old activity names. Better ones were chosen but
	// the db may still have some of the older names stored.
	OVERDUE: 'task_overdue',
	WORKED_FOR: 'task.worked_for',
	UPDATE_IMPORTANCE: 'task.update.importance',
	UPDATE_URGENCY: 'task.update.urgency',
	SCHEDULE_START_DATE: 'task.schedule.startDate',
	DIARIZED: 'task.diarized',
	DONE: 'task_done',
	DONE_WITH_COMMENT: 'task_done_comment',
	CREATE_WITH_MAIL: 'task_create_with_mail',
}

const DraftModelSchema = RawDraftContentStateSchema.nullable()
const DateStringSchema = z
	.union([
		z.string().refine((val) => isMatch(val, 'yyyy-MM-dd'), {
			message: 'Date string must be in yyyy-MM-dd format',
		}),
		z.literal('someday'),
	])
	.nullable()
	// TODO: remove defaults because they could lead to ellusive bugs
	.default(null)
const TimeStringSchema = z
	.string()
	.refine((val) => isMatch(val, 'HH:mm:ss'), {
		message: 'Time string must be in HH:mm:ss format',
	})
	.nullable()
	// TODO: remove defaults because they could lead to ellusive bugs
	.default(null)

const ChatFileDataSchema = z.object({
	chatMessageId: z.string(),
	contentType: z.string(),
	filename: z.string(),
	lastUpdated: z.coerce.date().default(new Date()),
	id: z.string(),
	totalBytes: z.coerce.number(),
	type: z.literal('chat'),
})
export type ChatFileData = z.infer<typeof ChatFileDataSchema>
const TaskFileDataSchema = z.object({
	contentType: z.string(),
	filename: z.string(),
	lastUpdated: z.coerce.date().default(new Date()),
	id: z.string(),
	taskId: z.string(),
	totalBytes: z.coerce.number(),
	type: z.literal('task'),
})
export type TaskFileData = z.infer<typeof TaskFileDataSchema>
const FileDataSchema = z.discriminatedUnion('type', [
	ChatFileDataSchema,
	TaskFileDataSchema,
])
export type FileData = z.infer<typeof FileDataSchema>

export const MetaTaskSchema = z.object({
	id: z.string(),
	title: z.string(),
})
export type MetaTask = z.infer<typeof MetaTaskSchema>
export const MetaUserSchema = z.object({
	id: z.string(),
	name: z.string(),
	nickname: z.string(),
	gravatar: z.string(),
})
export type MetaUser = z.infer<typeof MetaUserSchema>
export const MetaWorkflowSchema = z.object({
	id: z.string(),
	title: z.string(),
})
export type MetaWorkflow = z.infer<typeof MetaWorkflowSchema>

export const TaskActivityAssignSchema = z.object({
	type: TaskActivityTypeSchema.extract(['task.assign']),
	userId: z.string(),
	assigneeId: z.string().nullable(),
	meta: z.object({
		user: MetaUserSchema,
		assignee: z.union([MetaUserSchema, z.null()]),
	}),
})
export type TaskActivityAssign = z.infer<typeof TaskActivityAssignSchema>

export const TaskActivityCommentSchema = z.object({
	type: TaskActivityTypeSchema.extract(['task.comment']),
	userId: z.string(),
	comment: z.string().trim(),
	replyTo: z.string().nullable(),
	draftModel: DraftModelSchema,
	meta: z.object({ user: MetaUserSchema }),
})
export type TaskActivityComment = z.infer<typeof TaskActivityCommentSchema>

export const TaskActivityCreateSchema = z.object({
	type: TaskActivityTypeSchema.extract(['task.create']),
	userId: z.string(),
	source: SourceSchema,
	meta: z.object({ user: MetaUserSchema }),
})
export type TaskActivityCreate = z.infer<typeof TaskActivityCreateSchema>

export const TaskActivityDeleteSchema = z.object({
	type: z.literal('task.delete'),
	userId: z.string(),
	meta: z.object({ user: MetaUserSchema }),
})
export type TaskActivityDelete = z.infer<typeof TaskActivityDeleteSchema>

export const TaskActivityDescrSchema = z.object({
	type: z.literal('task.descr'),
	userId: z.string(),
	meta: z.object({ user: MetaUserSchema }),
})
export type TaskActivityDescr = z.infer<typeof TaskActivityDescrSchema>

export const TaskActivityDoneSchema = z.object({
	type: z.literal('task.finish'),
	userId: z.string(),
	message: z.string().trim().nullable().optional(),
	draftModel: DraftModelSchema.optional(),
	meta: z.object({ user: MetaUserSchema }),
})
export type TaskActivityDone = z.infer<typeof TaskActivityDoneSchema>

export const TaskActivityDueDateSchema = z.object({
	type: z.literal('task.dueDate'),
	userId: z.string(),
	date: DateStringSchema,
	time: TimeStringSchema,
	// TODO: remove defaults because they could lead to ellusive bugs
	isRecurring: z.boolean().default(false),
	meta: z.object({ user: MetaUserSchema }),
})
export type TaskActivityDueDate = z.infer<typeof TaskActivityDueDateSchema>

export const TaskActivityDurationSchema = z.object({
	type: z.literal('task.duration'),
	userId: z.string(),
	// Note: Durations should be in seconds. Using hours as the resolution has
	// proven to be problematic with floating point and rounding errors.
	oldDuration: z.number().min(0),
	newDuration: z.number().min(0),
	meta: z.object({ user: MetaUserSchema }),
})
export type TaskActivityDuration = z.infer<typeof TaskActivityDurationSchema>

/**
 * Task activity files schemas
 */
export const TaskActivityFilesSchema = z.object({
	type: z.literal('task.files'),
	userId: z.string(),
	// files: z.array(FileDataSchema).nonempty(),
	// @deprecated still here just for backwards compatibility, nullable should be removed
	files: z.array(FileDataSchema).nonempty().nullable(),
	removedFile: FileDataSchema.nullable().default(null),
	meta: z.object({ user: MetaUserSchema }),
})
export type TaskActivityFiles = z.infer<typeof TaskActivityFilesSchema>

export const TaskActivityRemoveFileSchema = z.object({
	type: z.literal('task.removeFile'),
	userId: z.string(),
	removedFile: FileDataSchema,
	meta: z.object({ user: MetaUserSchema }),
})
export type TaskActivityRemoveFile = z.infer<
	typeof TaskActivityRemoveFileSchema
>

export const TaskActivityRenameFileSchema = z.object({
	type: z.literal('task.files.rename'),
	userId: z.string(),
	oldFileName: z.string(),
	newFileName: z.string(),
	meta: z.object({ user: MetaUserSchema }),
})
export type TaskActivityRenameFile = z.infer<
	typeof TaskActivityRenameFileSchema
>

export const TaskActivityFollowerSchema = z.object({
	type: z.literal('task.follower'),
	userId: z.string(),
	// followerId: z.string(),
	// role: z.enum(['assignee', 'follower', 'guest']),
	// @deprecated still here just for backwards compatibility
	followerId: z.string().nullable().default(null),
	role: z.enum(['assignee', 'follower', 'guest']).nullable().default(null),
	removeFollowerId: z.string().nullable().default(null),
	meta: z.object({
		user: MetaUserSchema,
		follower: MetaUserSchema.nullable(),
	}),
})
export type TaskActivityFollower = z.infer<typeof TaskActivityFollowerSchema>

export const TaskActivityRemoveFollowerSchema = z.object({
	type: z.literal('task.follower.remove'),
	userId: z.string(),
	removeFollowerId: z.string(),
	meta: z.object({
		user: MetaUserSchema,
		follower: MetaUserSchema.nullable(),
	}),
})
export type TaskActivityRemoveFollower = z.infer<
	typeof TaskActivityRemoveFollowerSchema
>

export const TaskActivityMoveSchema = z.object({
	type: z.literal('task.move'),
	userId: z.string(),
	oldParentId: z.string().nullable(),
	newParentId: z.string().nullable(),
	// Note: It might seem unnecessary to save titles because we can reference
	// the task. But saving them allows us to make a later edit if the user perhaps
	// changes the new title shortly after. Therefor preventing extra unneeded task logs.
	oldParentTitle: z.string(),
	newParentTitle: z.string(),
	meta: z.object({
		oldTask: MetaTaskSchema,
		newTask: MetaTaskSchema,
		user: MetaUserSchema,
	}),
})
export type TaskActivityMove = z.infer<typeof TaskActivityMoveSchema>

export const TaskActivityOwnerSchema = z.object({
	type: z.literal('task.owner'),
	userId: z.string(),
	ownerId: z.string(),
	meta: z.object({
		user: MetaUserSchema,
		owner: MetaUserSchema,
	}),
})
export type TaskActivityOwner = z.infer<typeof TaskActivityOwnerSchema>

export const TaskActivityPauseSchema = z.object({
	type: z.literal('task.pause'),
	userId: z.string(),
	assigneeId: z.string().nullable(),
	duration: z.number(),
	meta: z.object({
		user: MetaUserSchema,
		assignee: MetaUserSchema,
	}),
})
export type TaskActivityPause = z.infer<typeof TaskActivityPauseSchema>

export const TaskActivityPrioritySchema = z.object({
	type: z.literal('task.priority'),
	userId: z.string(),
	oldImportance: z.number().min(0).max(10).nullable().default(null),
	newImportance: z.number().min(0).max(10).nullable().default(null),
	oldUrgency: z.number().min(0).max(10).nullable().default(null),
	newUrgency: z.number().min(0).max(10).nullable().default(null),
	meta: z.object({ user: MetaUserSchema }),
})
// .refine(
// 	(val) => {
// 		const hasImportance =
// 			'newImportance' in val && 'oldImportance' in val
// 		const hasUrgency = 'newUrgency' in val && 'oldUrgency' in val
// 		return hasImportance || hasUrgency
// 	},
// 	{
// 		message:
// 			'Must have values for either newImportance and oldImportance, or newUrgency and oldUrgency',
// 	}
// )
export type TaskActivityPriority = z.infer<typeof TaskActivityPrioritySchema>

export const TaskActivityScheduleSchema = z.object({
	type: z.literal('task.schedule'),
	userId: z.string(),
	date: DateStringSchema,
	time: TimeStringSchema,
	// TODO: remove defaults because they could lead to ellusive bugs
	isRecurring: z.boolean().default(false),
	meta: z.object({ user: MetaUserSchema }),
})
export type TaskActivitySchedule = z.infer<typeof TaskActivityScheduleSchema>

export const TaskActivityStartSchema = z.object({
	type: z.literal('task.start'),
	userId: z.string(),
	assigneeId: z.string().nullable(),
	meta: z.object({
		user: MetaUserSchema,
		assignee: MetaUserSchema,
	}),
})
export type TaskActivityStart = z.infer<typeof TaskActivityStartSchema>

export const TaskActivityStatusSchema = z.object({
	type: z.literal('task.status'),
	userId: z.string(),
	status: z.string().nullable(),
	message: z.string().trim().nullable().default(null),
	meta: z.object({ user: MetaUserSchema }),
})
export type TaskActivityStatus = z.infer<typeof TaskActivityStatusSchema>

export const TaskActivityStopSchema = z.object({
	type: z.literal('task.stop'),
	userId: z.string(),
	assigneeId: z.string().nullable(),
	duration: z.number(),
	meta: z.object({
		user: MetaUserSchema,
		assignee: MetaUserSchema,
	}),
})
export type TaskActivityStop = z.infer<typeof TaskActivityStopSchema>

export const TaskActivityTitleSchema = z.object({
	type: z.literal('task.title'),
	userId: z.string(),
	// Note: It might seem unnecessary to save titles because it may seem
	// simpler to put them straight into a string. But saving them allows us to
	// make a later edit if the user perhaps changes the new title shortly
	// after. Therefore preventing extra unneeded task logs.
	oldTaskTitle: z.string(),
	newTaskTitle: z.string(),
	meta: z.object({ user: MetaUserSchema }),
})
export type TaskActivityTitle = z.infer<typeof TaskActivityTitleSchema>

export const TaskActivityUnknownSchema = z.object({
	type: z.literal('unknown'),
	original: z.record(z.any()),
})
export type TaskActivityUnknown = z.infer<typeof TaskActivityUnknownSchema>

export const TaskActivityUpdateSchema = z.object({
	type: z.literal('task.update'),
	userId: z.string(),
	meta: z.object({ user: MetaUserSchema }),
})
export type TaskActivityUpdate = z.infer<typeof TaskActivityUpdateSchema>

export const TaskActivityWorkflowSchema = z.object({
	type: z.literal('task.workflow'),
	userId: z.string(),
	workflowId: z.string().nullable(),
	workflowTitle: z.string(),
	newStepId: z.string().nullable(),
	newStepIndex: z.number().nullable(),
	newStepTitle: z.string().nullable(),
	oldStepId: z.string().nullable(),
	oldStepIndex: z.number().nullable(),
	oldStepTitle: z.string().nullable(),
	removedWorkflowId: z.string().nullable(),
	removedWorkflowTitle: z.string().nullable(),
	hasRequirementsChanged: z.boolean(),
	requirementsChange: z
		.object({
			newSteps: z.any(),
			new: z.any(),
			old: z.any(),
		})
		.nullable(), // TODO: finish off this schema
	completedSteps: z
		.array(
			z.object({
				id: z.string(),
				userId: z.string(),
				date: z.string(),
				comment: z.string().nullable().optional(),
			})
		)
		.nullable(),
	comment: z.string().nullable(),
	meta: z.object({ user: MetaUserSchema, workflow: MetaWorkflowSchema }),
})
export type TaskActivityWorkflow = z.infer<typeof TaskActivityWorkflowSchema>

const BaseActivitySchema = z.discriminatedUnion('type', [
	TaskActivityAssignSchema,
	TaskActivityCommentSchema,
	TaskActivityCreateSchema,
	TaskActivityDeleteSchema,
	TaskActivityDescrSchema,
	TaskActivityDoneSchema,
	TaskActivityDueDateSchema,
	TaskActivityDurationSchema,
	TaskActivityFilesSchema,
	TaskActivityRenameFileSchema,
	//TaskActivityRemoveFileSchema,
	TaskActivityFollowerSchema,
	//TaskActivityRemoveFollowerSchema,
	TaskActivityMoveSchema,
	TaskActivityOwnerSchema,
	TaskActivityPrioritySchema,
	TaskActivityPauseSchema,
	TaskActivityScheduleSchema,
	TaskActivityStartSchema,
	TaskActivityStatusSchema,
	TaskActivityStopSchema,
	TaskActivityTitleSchema,
	TaskActivityUpdateSchema,
	TaskActivityWorkflowSchema,
])

export const TaskActivityMultipleSchema = z.object({
	type: z.literal('task.multiple'),
	userId: z.string(),
	taskLogs: z.array(BaseActivitySchema),
	meta: z.object({
		user: MetaUserSchema,
	}),
})
export type TaskActivityMultiple = z.infer<typeof TaskActivityMultipleSchema>

export const TaskActivitySchema = z.discriminatedUnion('type', [
	...BaseActivitySchema.options,
	TaskActivityMultipleSchema,
])
export const TaskActivitySchemaWithUnknown = z.discriminatedUnion('type', [
	...BaseActivitySchema.options,
	TaskActivityMultipleSchema,
	TaskActivityUnknownSchema,
])
export type TaskActivityWithUnknown = z.infer<
	typeof TaskActivitySchemaWithUnknown
>
export type TaskActivity =
	| TaskActivityAssign
	| TaskActivityComment
	| TaskActivityCreate
	| TaskActivityDelete
	| TaskActivityDescr
	| TaskActivityDone
	| TaskActivityDueDate
	| TaskActivityDuration
	| TaskActivityFiles
	| TaskActivityRenameFile
	// | TaskActivityRemoveFile
	| TaskActivityFollower
	// | TaskActivityRemoveFollower
	| TaskActivityMove
	| TaskActivityOwner
	| TaskActivityPriority
	| TaskActivityPause
	| TaskActivitySchedule
	| TaskActivityStart
	| TaskActivityStatus
	| TaskActivityStop
	| TaskActivityTitle
	| TaskActivityUpdate
	| TaskActivityWorkflow
	| TaskActivityMultiple

export const WrappedTaskActivityV2Schema = z.object({
	dateCreated: z.coerce.date(),
	descr: z.string(),
	data: TaskActivitySchemaWithUnknown,
	hasPermission: z.boolean(),
	id: z.string(),
	reactions: TaskActivityReactionsSchema,
	taskId: z.string(),
	userId: z.string(),
	version: z.literal(2),
})

export type WrappedTaskActivityV2 = z.infer<typeof WrappedTaskActivityV2Schema>
export type WrappedTaskActivityV2WithType<T> = Omit<
	WrappedTaskActivityV2,
	'data'
> & {
	data: T
}
export const WrappedTaskActivityV2SchemaWithType = <
	T extends z.ZodSchema<TaskActivityWithUnknown>,
>(
	schema: T
): z.ZodSchema<WrappedTaskActivityV2WithType<z.infer<T>>> =>
	WrappedTaskActivityV2Schema.extend({
		data: schema,
	}) as z.ZodSchema<WrappedTaskActivityV2WithType<z.infer<T>>>

/**
 * Task activity api schemas
 */
export const TaskActivityRequestGetBodySchema = z.object({})
export type TaskActivityRequestGetBody = z.infer<
	typeof TaskActivityRequestGetBodySchema
>
export const TaskActivityRequestGetQuerySchema = DatePaginationSchema.extend({
	type: TaskActivityTypeSchema.optional(),
})
export type TaskActivityRequestGetQuery = z.infer<
	typeof TaskActivityRequestGetQuerySchema
>
export const TaskActivityRequestGetParamsSchema = z.object({
	taskId: z
		.string()
		.min(1, 'String cannot be empty')
		.refine((val) => val !== 'null' && val !== 'undefined', {
			message: 'String cannot be "null" or "undefined"',
		}),
})
export type TaskActivityRequestGetParams = z.infer<
	typeof TaskActivityRequestGetParamsSchema
>
export const TaskActivityRequestGetSchema = z.object({
	body: TaskActivityRequestGetBodySchema,
	query: TaskActivityRequestGetQuerySchema,
	params: TaskActivityRequestGetParamsSchema,
})
export type TaskActivityRequestGet = z.infer<
	typeof TaskActivityRequestGetSchema
>
