import { Observable } from 'rxjs'
import { Mutate, StateSelector, StoreApi } from 'zustand'

import { AppState, MutatorMiddleware } from '../store-types'

export const createZustandObservable = <T = AppState>(
	api: Mutate<StoreApi<AppState>, MutatorMiddleware>,
	selector: StateSelector<AppState, T> = (state) => state as any,
	options?: {
		equalityFn?: (a: T, b: T) => boolean
		fireImmediately?: boolean
	}
) =>
	new Observable<{ state: T; prevState: T }>((subscriber) => {
		const handleSubscribe = (state: T, prevState: T) => {
			subscriber.next({ state, prevState })
		}

		if (options && options.fireImmediately) {
			const state = selector(api.getState())
			const prevState = state
			handleSubscribe(state, prevState)
		}

		return api.subscribe(selector, handleSubscribe, options)
	})
