import {
	Task,
	Workflow,
	WorkflowData,
	WorkflowDataStep,
	WorkflowStep,
} from '../types'
import { WorkflowDataSummary } from './workflow-types'

export const buildWorkflowStrings = (
	workflow?: Workflow,
	workflowData?: WorkflowData,
	activeStep?: WorkflowStep
) => {
	if (!workflow || !workflowData) {
		return {}
	}

	const activeStepId = activeStep?.id
	const activeStepNumber = activeStep
		? workflow.steps.findIndex((s) => s.id === activeStepId) + 1
		: 1

	const title = workflow?.title
	const stepPosition = `${activeStepNumber}/${workflow.steps.length}`
	const description = activeStep
		? `${stepPosition} ${activeStep.title}`
		: null

	return {
		title,
		description,
		activeStep,
		activeStepDescription: activeStep?.description,
		fullString: `${title}${description ? ':' : ''} ${description}`,
		stepPosition,
	}
}

export const calculateWorkflowProgress = (
	workflow: Workflow,
	activeStepId: string
) => {
	const activeStepNumber =
		workflow?.steps.findIndex((step) => step.id === activeStepId) + 1
	const totalSteps = workflow?.steps.length

	return Math.round((activeStepNumber / totalSteps) * 100)
}

export const getActiveStepId = (
	workflow?: Workflow,
	taskWorkflowData?: WorkflowData
) => {
	// If all steps are completed, there is no active step and ignore any
	// specified activeStepId.
	const matchedSteps = getStepsBelongingToWorkflow(workflow, taskWorkflowData)
	if (
		taskWorkflowData?.activeStepId === null ||
		(matchedSteps.every((step) => step.completed) &&
			matchedSteps.length === workflow?.steps.length)
	) {
		return null
	}

	// If some steps are completed, use the following non completed step as the active step
	const completedSteps = getCompletedSteps(workflow, taskWorkflowData)
	const lastCompletedStep = completedSteps[completedSteps.length - 1]
	const lastCompletedStepIndex =
		workflow?.steps.findIndex(
			(step) => step.id === lastCompletedStep?.id
		) || -1
	const nextNonCompletedStep = workflow?.steps[lastCompletedStepIndex + 1]

	const workflowSteps =
		workflow && Array.isArray(workflow.steps) ? workflow.steps : []
	const step = workflowSteps.find(
		(step) => step.id === taskWorkflowData?.activeStepId
	) ||
		nextNonCompletedStep ||
		workflowSteps[0] || { id: null }
	return step.id
}

export const getCheckableWorkflowRequirements = (
	requirements: Required<WorkflowDataStep>['requirements']
) =>
	requirements.blocks
		.filter((block) => block.type === 'checkable')
		.map((block) => ({
			label: block.text,
			isChecked: Boolean(block.data?.['checked']),
		}))

export const getCompletedSteps = (
	workflow?: Workflow,
	taskWorkflowData?: WorkflowData
) => {
	const matchedSteps = getStepsBelongingToWorkflow(workflow, taskWorkflowData)
	return matchedSteps.filter((step) => step.completed)
}

export const getFinalStepId = (workflow: Workflow) => {
	const steps =
		workflow && Array.isArray(workflow.steps) ? workflow.steps : []
	const finalStep = steps[steps.length - 1] || { id: null }
	return finalStep.id
}

export const getNextActiveStepId = (
	workflow?: Workflow,
	activeStepId?: string
) => {
	const steps =
		workflow && Array.isArray(workflow.steps) ? workflow.steps : []
	const activeStepIndex = steps.findIndex((step) => step.id === activeStepId)
	const nextStep = steps[activeStepIndex + 1]
	return nextStep ? nextStep.id : null
}

export const getPrevStepId = (workflow?: Workflow, activeStepId?: string) => {
	const steps =
		workflow && Array.isArray(workflow.steps) ? workflow.steps : []
	const activeStepIndex = steps.findIndex((step) => step.id === activeStepId)
	const prevStep = steps[activeStepIndex - 1]
	return prevStep ? prevStep.id : null
}

// Some steps could be from other workflows
export const getStepsBelongingToWorkflow = (
	workflow?: Workflow,
	taskWorkflowData?: WorkflowData
) => {
	if (!workflow?.steps || !Array.isArray(workflow.steps)) {
		return []
	}

	return workflow.steps.reduce((acc, step) => {
		if (taskWorkflowData?.stepData?.[step.id]) {
			acc.push(taskWorkflowData.stepData[step.id])
		}
		return acc
	}, [] as WorkflowDataStep[])
}

export const getStepById = (workflow: Workflow, id: string) => {
	if (!workflow || !workflow.steps || !workflow.steps[0]) {
		return null
	}

	const step = workflow.steps.find((step) => step.id === id)
	return step || workflow.steps[0]
}

export const getStepRequirements = (
	stepId?: string,
	workflowData?: WorkflowData,
	workflow?: Workflow
) => {
	if (!stepId || !workflowData || !workflow) {
		return null
	}
	const dataStep = workflowData?.stepData?.[stepId]
	if (dataStep?.requirements) {
		return dataStep.requirements
	}
	const workflowStep = workflow.steps?.find((step) => step.id === stepId)
	if (workflowStep?.requirements) {
		return workflowStep.requirements
	}
	return null
}

export const getWorkflowData = (
	task?: Pick<Task, 'workflowData'>,
	parent?: Pick<Task, 'workflowData'>
) => {
	// Collect all workflow data and let UI handle visibility logic.
	const result: WorkflowDataSummary = {
		activeWorkflow: null,
		childWorkflow: null,
		hasChildWorkflow: false,
		hasParentWorkflow: false,
		hasOverriddenWorkflow: false,
		hasTaskWorkflow: false,
		hasWorkflow: false,
		parentWorkflow: null,
		taskWorkflow: null,
	}

	if (task?.workflowData) {
		result.taskWorkflow = {
			...task.workflowData,
		}
		result.hasTaskWorkflow = true
	}

	if (parent?.workflowData?.childId) {
		result.parentWorkflow = { id: parent.workflowData.childId }
		result.hasParentWorkflow = true

		if (task?.workflowData?.id) {
			result.hasOverriddenWorkflow = true
		}
	} else if (task?.workflowData?.type === 'inherit') {
		result.parentWorkflow = { id: task.workflowData.id }
		result.hasParentWorkflow = true

		if (task?.workflowData?.id) {
			result.hasOverriddenWorkflow = true
		}
	}

	if (task?.workflowData?.childId) {
		result.childWorkflow = { id: task.workflowData.childId }
		result.hasChildWorkflow = true
	}

	result.activeWorkflow = result.parentWorkflow || result.taskWorkflow
	result.hasWorkflow = result.hasParentWorkflow || result.hasTaskWorkflow

	return result
}

export const getWorkflowId = (
	task: Pick<Task, 'workflowData'>,
	parent: Pick<Task, 'workflowData'>
) => {
	const { activeWorkflow } = getWorkflowData(task, parent)
	return activeWorkflow?.id || null
}

// Check if requirements changed for each step
export const reduceToStepsWithRequirements = (
	workflow: Workflow,
	stepData: WorkflowData['stepData']
): Record<string, WorkflowDataStep> => {
	if (!stepData) {
		return {}
	}
	return Object.entries(stepData).reduce(
		(acc, [stepId, step]) => {
			const workflowStep = workflow.steps.find(
				(step) => step.id === stepId
			)
			const requirements = step?.requirements
			if (requirements && workflowStep) {
				acc[stepId] = {
					id: workflowStep.id,
					requirements: stepData[stepId].requirements,
				}
			}
			return acc
		},
		{} as Record<string, WorkflowDataStep>
	)
}
