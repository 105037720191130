import * as Sentry from '@sentry/react'

export const logError = (...args) => {
	if (process.env.NODE_ENV === 'production') {
		Sentry.withScope(scope => {
			scope.setLevel('error')
			scope.setExtra('args', args.slice(1))
			Sentry.captureException(args[0])
		})
	} else {
		console.error.call(null, ...args)
	}
}

export const logWarning = (...args) => {
	if (process.env.NODE_ENV === 'production') {
		Sentry.withScope(scope => {
			scope.setLevel('error')
			scope.setExtra('args', args.slice(1))
			Sentry.captureMessage(args[0])
		})
	} else {
		console.error.call(null, ...args)
	}
}
