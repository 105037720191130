import { lazy, Suspense, useEffect } from 'react'
import * as Sentry from '@sentry/react'
import { isAxiosError } from 'axios'
import { Provider } from 'react-redux'
import {
	unstable_HistoryRouter as HistoryRouter,
	Route,
	Routes,
} from 'react-router-dom'

import { useStore } from '@tyto/dna/store'

import AppLayout from './AppLayout'
import { clearToken, getToken } from './auth-provider'
import { history } from './history'
import { handleError } from './sentry'
import store from './store'

const AuthenticatedApp = lazy(() => import('./AuthenticatedApp'))
const UnauthenticatedApp = lazy(() => import('./UnauthenticatedApp'))

const EmailSubscriptionSettings = lazy(
	() => import('./email-subscriptions-settings/EmailSubscriptionSettings')
)
const MergeUsersPage = lazy(() => import('./components/MergeUsersPage'))
const MergeGuestOrSet = lazy(() =>
	import('./reset-password/MergeOrResetPassword').then((module) => ({
		default: module.MergeGuestOrSet,
	}))
)
const MergeUserOrSet = lazy(() =>
	import('./reset-password/MergeOrResetPassword').then((module) => ({
		default: module.MergeUserOrSet,
	}))
)
const ResetPassword = lazy(() => import('./reset-password/ResetPassword'))
const VerifyEmail = lazy(() => import('./verify-email/VerifyEmail'))

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

const Root = () => {
	const authToken = useStore((state) => state.player.authToken)

	useEffect(() => {
		return history.listen((location, action) => {
			// Handle Google analytics pageview event.
			if (action === 'PUSH') {
				window.dataLayer = window.dataLayer || []
				window.dataLayer.push({
					event: 'pageview',
					pagePath: location.pathname,
				})
			}
		})
	}, [])

	useEffect(() => {
		const token = getToken()
		if (token && !authToken) {
			useStore
				.getState()
				.player.provideToken(token)
				.then((player) => {
					if (player) {
						Sentry.setUser({
							id: player.id,
							username: player.name,
							email: player.email,
						})
					}
					return player
				})
				.catch((err) => {
					if (isAxiosError(err) && err.response?.status === 401) {
						console.debug('token is invalid: logging out')
						clearToken()
						useStore.getState().player.logout()
					}
					handleError(err)
				})
		}
	}, [authToken])

	return (
		<AppLayout>
			<Suspense>
				<Provider store={store}>
					<HistoryRouter history={history}>
						<SentryRoutes>
							<Route
								path={'/email-settings/:token'}
								element={<EmailSubscriptionSettings />}
							/>
							<Route
								path={'/user-emails/:token'}
								element={<VerifyEmail />}
							/>
							<Route
								path={'/resetpassword/:token'}
								element={<ResetPassword />}
							/>
							<Route
								path={'/mergeuser/:token'}
								element={<MergeUsersPage />}
							/>
							<Route
								path={'/setpassword/:token'}
								element={<MergeUserOrSet />}
							/>
							<Route
								path={'/guest-access/:token/:taskId'}
								element={<MergeGuestOrSet />}
							/>
							<Route
								path={'/setpassword/:token/:taskId'}
								element={<MergeUserOrSet />}
							/>
							<Route
								path={'*'}
								element={
									authToken ? (
										<AuthenticatedApp />
									) : (
										<UnauthenticatedApp />
									)
								}
							/>
						</SentryRoutes>
					</HistoryRouter>
				</Provider>
			</Suspense>
		</AppLayout>
	)
}

export default Sentry.withProfiler(Root)
