import * as Sentry from '@sentry/react'
import { fromJS } from 'immutable'
import has from 'lodash/fp/has'
import map from 'lodash/fp/map'
import size from 'lodash/size'
import moment from 'moment'
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'

import { applyWorkflowActionsToTask, DATE_FORMAT } from '@tyto/dna'
import { preTaskUpdate } from '@tyto/helpers'
import { dateFormats } from '@tyto/helpers/formatters/date-formats'

import {
	ADD_FOLLOWER,
	BULK_FETCH_TASKS,
	BULK_UPDATE_TASKS,
	enqueueSnackbar,
	FETCH_TASK,
	FETCH_TASKS_REQUEST,
	fetchTasksFailure,
	fetchTasksSuccess,
	mergeTask,
	mergeTasks,
	pushTaskToUserView,
	REMOVE_FOLLOWER,
	setPlayer,
	setTask,
	SOCKET_OFF,
	SOCKET_ON,
	SOCKET_TASK_TIMER,
	SOCKET_UPDATE_TASK,
	SOCKET_UPDATE_TASKS,
	UPDATE_TASK,
} from '../actions'
import Api from '../api'
import { doOnceAcrossTabs } from '../doOnceAcrossTabs'
import {
	getFetchingUserViewTasksFor,
	getTask,
	getTask2,
} from '../reducers/tasks-reducer'
import { getUser } from '../reducers/users-reducer'
import { playPopSound } from '../soundsData'
import { toJS } from '../utils'

export function* tasksSaga() {
	yield takeEvery(SOCKET_ON, handleSocketReconnect)
	yield takeEvery(SOCKET_OFF, handleSocketDisconnect)
	yield takeEvery(SOCKET_TASK_TIMER, taskTimerSocket)
	yield takeEvery(SOCKET_UPDATE_TASK, taskUpdateSocket)
	yield takeEvery(SOCKET_UPDATE_TASKS, tasksUpdateSocket)
	yield takeEvery(ADD_FOLLOWER, addFollower)
	yield takeEvery(BULK_UPDATE_TASKS, handleBulkUpdateTasks)
	yield takeEvery(UPDATE_TASK, handleUpdateTask)
	yield takeEvery(FETCH_TASK, fetchTask)
	yield takeEvery(REMOVE_FOLLOWER, removeFollower)
	yield takeLatest(BULK_FETCH_TASKS, handleBulkFetchTasks)
	yield takeLatest(FETCH_TASKS_REQUEST, handleFetchAllTasks)
}

function* addFollower({ payload }) {
	const { followerId, taskId, roles } = payload
	try {
		yield call(Api.updateTask, {
			id: taskId,
			followers: [{ id: followerId, roles }],
		})
	} catch (e) {
		Sentry.withScope((scope) => {
			scope.setExtra('taskId', taskId)
			scope.setExtra('followers', [followerId])
			scope.setExtra('request', `PUT /tasks/${taskId}`)
			Sentry.captureException(e)
		})
	}
}

function* fetchTask({ taskId }) {
	Sentry.captureMessage(
		'FETCH_TASK action is still being called. Needs to be removed.'
	)
	console.log('FETCH_TASK action is still being called. Needs to be removed.')

	try {
		const task = yield call(Api.fetchTask, taskId)
		yield put(mergeTask(task))
	} catch (e) {
		if (e.response.status === 403) {
			// User doesn't have permission to view this task, so create a
			// private entry.
			yield put(
				setTask({
					id: taskId,
					title: 'Private task',
					hasPermission: false,
				})
			)
		} else if (e.response && e.response.status === 404) {
			// Do nothing
		} else {
			Sentry.withScope((scope) => {
				scope.setExtra('taskId', taskId)
				scope.setExtra('request', `GET /tasks/${taskId}`)
				Sentry.captureException(e)
			})
		}
	}
}

function* handleFetchAllTasks() {
	try {
		const response = yield call(Api.fetchTaskList, {
			pageSize: 0,
		})
		yield put(fetchTasksSuccess(response.items))
	} catch (err) {
		if (err.request && err.request.status === 401) {
			yield put(setPlayer(null))
		} else {
			Sentry.captureException(err)
		}
		yield put(fetchTasksFailure(err.message))
	}
}

let lastUserId = null
let isDisconnected = false
function* handleSocketReconnect() {
	if (!lastUserId || !isDisconnected) return
	isDisconnected = false
	yield null
}
function* handleSocketDisconnect() {
	if (isDisconnected) return
	isDisconnected = true
	yield null
}

function* handleBulkFetchTasks({ payload: taskIds }) {
	for (let i = 0; i < size(taskIds); i++) {
		const taskId = taskIds[i]
		const task = yield call(Api.fetchTask, taskId)
		yield put(setTask(task))
	}
}

function* handleBulkUpdateTasks({ payload }) {
	const state = yield select()
	const { changes, taskIds } = payload
	try {
		const tasks = []
		for (let i = 0; i < size(taskIds); i++) {
			const taskId = taskIds[i]
			tasks.push({ id: taskId, ...changes })
		}
		yield put(mergeTasks(tasks))
		yield call(Api.updateMultipleTasks, taskIds, changes)

		// Create toast for schedule actions
		if (changes.startDate !== undefined) {
			const date = changes.startDate
				? dateFormats.smartDate(
						moment(changes.startDate, changes.startTime)
					)
				: 'Never'
			const message = `Rescheduled ${size(taskIds)} tasks for ${date}`
			yield put(enqueueSnackbar({ message }))
		}

		// Create toast for deleting tasks
		if (changes.statusCode === 'deleted') {
			yield put(
				enqueueSnackbar({
					message: `Deleted ${size(taskIds)} tasks`,
				})
			)
		}

		// Create toast for assigning tasks
		if (changes.assigneeId) {
			const user = getUser(state, changes.assigneeId)
			yield put(
				enqueueSnackbar({
					message: `Assigned ${size(taskIds)} tasks to ${user.get(
						'nickname'
					)}`,
				})
			)
		}
	} catch (err) {
		Sentry.withScope((scope) => {
			scope.setExtra('payload', payload)
			Sentry.captureException(err)
		})
	}
}

function* handleUpdateTask({ payload }) {
	const state = yield select()
	const now = moment()

	Sentry.captureMessage(
		'UPDATE_TASK action is still being called. Needs to be removed.'
	)
	console.log(
		'UPDATE_TASK action is still being called. Needs to be removed.'
	)

	const oldTask = toJS(getTask2(state, { taskId: payload.id }))

	// Assignee changed and startDate is in the past
	if (
		payload?.assigneeId !== oldTask?.assigneeId &&
		(!oldTask?.startDate || moment(oldTask.startDate).isBefore(now, 'day'))
	) {
		payload.startDate = moment().startOf('day').format(DATE_FORMAT)
	}

	try {
		payload = preTaskUpdate(fromJS(oldTask), payload)
	} catch (err) {
		console.error(err)
		Sentry.withScope((scope) => {
			scope.setExtra('payload', payload)
			Sentry.captureException(err)
		})
	}

	// If there are workflow actions, apply them before committing the update.
	// NOTE: This mutates the update data and the API will be doing the same
	// action on it's side. But it's mutation will result in no extra change
	// because it should resolve to the same value.
	if (has('workflowData')(payload)) {
		const workflow = state.getIn([
			'workflow',
			'byId',
			payload.workflowData?.id,
		])
		payload = applyWorkflowActionsToTask(payload, toJS(workflow), oldTask)
	}

	yield put(mergeTask(payload))

	try {
		yield call(Api.updateTask, payload)
	} catch (err) {
		Sentry.withScope((scope) => {
			scope.setExtra('payload', payload)
			scope.setExtra('request', `PUT /tasks/${payload.id}`)
			scope.setExtra('errorRequest', JSON.stringify(err.request))
			Sentry.captureException(err)
		})
		console.error(err)
	}
}

function* removeFollower({ payload }) {
	const state = yield select()
	const { followerId, taskId } = payload
	let task = getTask(state, taskId, null)

	if (!task) return

	try {
		yield call(Api.removeFollower, taskId, followerId)
	} catch (e) {
		Sentry.withScope((scope) => {
			scope.setExtra('taskId', taskId)
			scope.setExtra('followerId', followerId)
			scope.setExtra(
				'request',
				`DELETE /tasks/${taskId}/followers/${followerId}`
			)
			Sentry.captureException(e)
		})
	}
}

function* taskTimerSocket({ payload }) {
	yield put(
		mergeTask({
			id: payload.taskId,
			currentTimer: payload.currentTimer,
			...(payload.hoursTaken ? { hoursTaken: payload.hoursTaken } : {}),
		})
	)
}

function* tasksUpdateSocket({ payload }) {
	yield put(
		mergeTasks(map((id) => ({ id, ...payload.changes }))(payload.taskIds))
	)
}

function* taskUpdateSocket({ payload }) {
	const state = yield select()

	// If there is no task in memory, then fetch it.
	let oldTask = getTask(state, payload.id, null)
	if (!oldTask) {
		yield call(Api.fetchTask, payload.id)
	}

	yield put(mergeTask(payload))

	if (payload.assigneeId === getFetchingUserViewTasksFor(state)) {
		yield put(pushTaskToUserView(payload.id))
		doOnceAcrossTabs(playPopSound, 'ADD_TASK')
	}
}
