import { captureException, withScope } from '@sentry/react'
import { createAsyncStoragePersister } from '@tanstack/query-async-storage-persister'
import { MutationCache, QueryCache } from '@tanstack/react-query'
import { persistQueryClient } from '@tanstack/react-query-persist-client'

import {
	asyncStorage,
	createQueryClient,
	setStoragePersister,
	useStore,
} from '@tyto/dna/store'

import { createDebugFn } from './debug/debug-utils'

const cacheTime = 1000 * 60 * 60 * 24

export const configureQueryClient = () => {
	const queryClient = createQueryClient({
		defaultOptions: {
			queries: { cacheTime, networkMode: 'offlineFirst' },
			mutations: {
				networkMode: 'offlineFirst',
			},
		},
		// Use custom fingerprinting to get better information out of sentry errors
		// https://aronschueler.de/blog/2022/12/16/generating-meaningful-issues-in-sentry-with-react-query-+-axios/
		mutationCache: new MutationCache({
			onError: (err, _variables, _context, mutation) => {
				withScope((scope) => {
					scope.setContext('mutation', {
						mutationId: mutation.mutationId,
						variables: mutation.state.variables,
					})
					if (mutation.options.mutationKey) {
						scope.setFingerprint(
							// Duplicate to prevent modification
							Array.from(mutation.options.mutationKey) as string[]
						)
					}
					captureException(err)
				})
			},
		}),
		queryCache: new QueryCache({
			onError: (err, query) => {
				withScope((scope) => {
					scope.setContext('query', { queryHash: query.queryHash })
					scope.setFingerprint([
						query.queryHash.replaceAll(/[0-9]/g, '0'),
					])
					captureException(err)
				})
			},
		}),
	})
	const asyncStoragePersister = createAsyncStoragePersister({
		storage: asyncStorage,
	})
	persistQueryClient({
		buster: window.version || '',
		persister: asyncStoragePersister,
		queryClient,
	})

	setStoragePersister(asyncStoragePersister)
	useStore.setState({ queryClient })

	createDebugFn('clearQueryCache', () => {
		queryClient.clear()
	})
}
