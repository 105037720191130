import { call, put, takeLatest } from 'redux-saga/effects'
import {
	FETCH_TEAM_REQUEST,
	fetchTeamFailure,
	fetchTeamSuccess,
} from '../actions'

import Api from '../api'
import { logError } from '../log-error'

export function* teamSaga() {
	yield takeLatest(FETCH_TEAM_REQUEST, fetchTeam)
}

function* fetchTeam() {
	try {
		const team = yield call(Api.fetchTeam)
		yield put(fetchTeamSuccess(team))
	} catch (e) {
		logError("Couldn't fetch team", e)
		yield put(fetchTeamFailure(e.message))
	}
}
