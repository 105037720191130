import { QueryFunctionContext } from '@tanstack/react-query'

import { VideoCallStatusUser } from '../../types'
import { ApiAdapter } from '../api'

export const videoCallKeys = {
	all: ['videoCall'] as const,
	usersStatus: () => [...videoCallKeys.all, 'usersStatus'] as const,
}

export const fetchVideoCallStatus =
	(apiAdapter: ApiAdapter) =>
	async ({ signal }: QueryFunctionContext): Promise<VideoCallStatusUser[]> =>
		apiAdapter.users.getVideoCallStatus({ signal })

export default {}
