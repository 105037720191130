import { Howl, HowlOptions } from 'howler'

import gameStartSoundMp3 from './assets/sounds/game-start.mp3'
import gameStartSoundOgg from './assets/sounds/game-start.ogg'
import mentionSoundMp3 from './assets/sounds/mention.mp3'
import mentionSoundOgg from './assets/sounds/mention.ogg'
import newMessageSoundMp3 from './assets/sounds/new-message.mp3'
import newMessageSoundWav from './assets/sounds/new-message.wav'
import newVersionSoundMp3 from './assets/sounds/new-version.mp3'
import newVersionSoundWav from './assets/sounds/new-version.wav'
import notificationSoundMp3 from './assets/sounds/notification.mp3'
import notificationSoundWav from './assets/sounds/notification.wav'
import phoneSoundMp3 from './assets/sounds/phone.mp3'
import phoneSoundWav from './assets/sounds/phone.wav'
import popSoundMp3 from './assets/sounds/pop.mp3'
import popSoundWav from './assets/sounds/pop.wav'
import scoreChangeSoundMp3 from './assets/sounds/score-change.mp3'
import scoreChangeSoundWav from './assets/sounds/score-change.wav'
import startStopSoundMp3 from './assets/sounds/start-stop.mp3'
import startStopSoundWav from './assets/sounds/start-stop.wav'
import { createDebugFn } from './debug/debug-utils'

// Set global volume to 50%
const globalVolume = 0.5

// Register sounds to be used in redux actions.
const soundsData: Record<string, HowlOptions> = {
	gameStart: {
		src: [gameStartSoundMp3, gameStartSoundOgg],
		volume: globalVolume,
	},
	mention: {
		src: [mentionSoundMp3, mentionSoundOgg],
		volume: globalVolume,
	},
	newMessage: {
		src: [newMessageSoundMp3, newMessageSoundWav],
		volume: globalVolume,
	},
	newVersion: {
		src: [newVersionSoundMp3, newVersionSoundWav],
		volume: globalVolume,
	},
	notification: {
		src: [notificationSoundMp3, notificationSoundWav],
		volume: globalVolume,
	},
	phone: {
		src: [phoneSoundMp3, phoneSoundWav],
		// looks like loop is bugged with redux-sounds so using howler for this one
		loop: true,
		volume: globalVolume,
	},
	pop: {
		src: [popSoundMp3, popSoundWav],
		volume: globalVolume,
	},
	scoreChange: {
		src: [scoreChangeSoundMp3, scoreChangeSoundWav],
		volume: globalVolume,
	},
	startStop: {
		src: [startStopSoundMp3, startStopSoundWav],
		volume: globalVolume,
	},
}

type SoundKey = keyof typeof soundsData

const soundCache: Partial<Record<SoundKey, Howl>> = {}

const getSound = (soundId: SoundKey): Howl => {
	let sound: Howl
	const cachedSound = soundCache[soundId]
	if (typeof cachedSound !== 'undefined') {
		sound = cachedSound
	} else {
		sound = new Howl(soundsData[soundId])
		soundCache[soundId] = sound
	}
	return sound
}

const makePlaySound = (soundId: SoundKey) => () => playSound(soundId)

export const playSound = (soundId: SoundKey) => {
	const sound = getSound(soundId)
	if (sound.playing()) {
		sound.stop()
	}
	sound.play()
	return sound
}

export const stopSound = (soundId: SoundKey) => {
	const sound = soundCache[soundId]
	if (typeof sound !== 'undefined') {
		sound.stop()
	}
}

createDebugFn('playSound', (soundId: SoundKey) => {
	if (!soundId) {
		return `Provide the name of sound you'd like to play from this list: ${Object.keys(soundsData).join(', ')}`
	}
	return playSound(soundId)
})
createDebugFn('stopSound', (soundId: SoundKey) => {
	if (!soundId) {
		return `Provide the name of sound you'd like to stop from this list: ${Object.keys(soundsData).join(', ')}`
	}
	return stopSound(soundId)
})

export const playGameStartSound = makePlaySound('gameStart')
export const playMentionSound = makePlaySound('mention')
export const playNewMessageSound = makePlaySound('newMessage')
export const playNewVersionSound = makePlaySound('newVersion')
export const playNotificationSound = makePlaySound('notification')
export const playPhoneSound = makePlaySound('phone')
export const playPopSound = makePlaySound('pop')
export const playScoreChangeSound = makePlaySound('scoreChange')
export const playStartStopSound = makePlaySound('startStop')

export default soundsData
