import produce from 'immer'
import { fromEvent, merge, of, timer } from 'rxjs'
import { concatMap, switchMap, take, tap } from 'rxjs/operators'
import { Socket } from 'socket.io-client'

import { env } from '@tyto/utils'

import { events } from '../constants'
import { createSocketObservable } from '../socketUtils'
import { syncStore } from '../sync/syncStore'
import {
	PlatformNotification,
	PlayerOptions,
	ScoreSocketValue,
	Task,
	TaskMoveSocketValue,
	TaskPosition,
	TeardownFn,
	User,
	UserStatus,
} from '../types'
import { ApiListResult, TaskTimeResult } from './api'
import {
	ChatMessageFileSocket,
	ChatReactionsSocket,
	SendChatMessageResult,
} from './chat'
import { createUpdatePlayerOptionsOnQueryCache } from './mutations/player'
import { createAddTaskToQueryCache } from './mutations/tasks/create'
import { createUpdateTaskOnQueryCache } from './mutations/tasks/update'
import {
	createStartTaskOnQueryCache,
	createStopTaskOnQueryCache,
} from './mutations/timer'
import { createUpdateUserOnQueryCache } from './mutations/users'
import {
	addTaskSubject,
	chatMessageFileSubject,
	chatMessageSubject,
	chatReactionsSubject,
	moveTaskSubject,
	onlineUsersSubject,
	platformNotificationsSubject,
	typingInChatSubject,
	updateTaskSubject,
	updateUserSubject,
	userStatusesSubject,
} from './observables'
import { userKeys } from './queries'
import { AppState } from './store-types'
import useStore from './useStore'

const createChatMessageSocket = (socket: Socket) =>
	createSocketObservable<SendChatMessageResult>(
		socket,
		events.NEW_CHAT_MESSAGE
	)
const createChatMessageFileSocket = (socket: Socket) =>
	createSocketObservable<ChatMessageFileSocket>(
		socket,
		events.CHAT_FILE_ADDED
	)
const createChatReactionsSocket = (socket: Socket) =>
	createSocketObservable<ChatReactionsSocket>(socket, events.CHAT_REACTIONS)
const createLatestVersionSocket = (socket: Socket) => {
	const eventName = env('APP_ENV')
		? `${events.LATEST_VERSION}:${env('APP_ENV')}`
		: events.LATEST_VERSION
	return createSocketObservable<string>(socket, eventName)
}
const createOnlineUsersSocket = (socket: Socket) =>
	createSocketObservable<string[]>(socket, events.ACTIVE_USERS)
const createPlatformNotificationSocket = (socket: Socket) =>
	createSocketObservable<PlatformNotification>(
		socket,
		events.BROWSER_NOTIFICATION
	)
const createScoreUpdateSocket = (socket: Socket) =>
	createSocketObservable<ScoreSocketValue>(socket, events.TASK_SCORE_UPDATE)
const createTaskAddSocket = (socket: Socket) =>
	createSocketObservable<Task>(socket, events.TASK_ADDED)
const createTaskMoveSocket = (socket: Socket) =>
	createSocketObservable<TaskMoveSocketValue>(socket, events.TASK_MOVE)
const createTaskStatusSocket = (socket: Socket) =>
	createSocketObservable<{ task: Task; oldStatusCode: Task['statusCode'] }>(
		socket,
		events.TASK_STATUS_UPDATE
	)
const createTaskTimerSocket = (socket: Socket) =>
	createSocketObservable<TaskTimeResult>(socket, events.TASK_TIMER)
const createTaskUpdateSocket = (socket: Socket) =>
	createSocketObservable<{ id: Task['id'] } & Partial<Task>>(
		socket,
		events.TASK_UPDATE
	)
const createTypingInChatSocket = (socket: Socket) =>
	createSocketObservable<{ chatRoomId: string; typingUserId: string }>(
		socket,
		events.TYPING_IN_CHAT
	)

const createUserOptionsChangedSocket = (socket: Socket) =>
	createSocketObservable<{ userId: string; options: PlayerOptions }>(
		socket,
		events.OPTIONS_CHANGED
	)

const createUserStatusesSocket = (socket: Socket) =>
	createSocketObservable<Record<string, UserStatus>>(socket, 'userStatuses')

const createUserUpdateSocket = (socket: Socket) =>
	createSocketObservable<{ id: string } & Partial<User>>(
		socket,
		events.USER_UPDATE
	)

const onlineUsersPoll = timer(60 * 1000, 60 * 1000)

export const setupGeneralSocketListeners = (appState: AppState): TeardownFn => {
	// Actions triggered by the API
	const { apiAdapter, queryClient, socket } = appState

	console.debug('setting up socket listeners')

	if (!socket) {
		throw new Error(
			'Expected a socket to be available on the application state'
		)
	}

	if (!queryClient) {
		throw new Error(
			'Expected a queryClient to be available on the application state'
		)
	}

	const addTaskToQueryCache = createAddTaskToQueryCache(queryClient)
	const startTaskOnQueryCache = createStartTaskOnQueryCache(queryClient)
	const stopTaskOnQueryCache = createStopTaskOnQueryCache(queryClient)
	const updateTaskOnQueryCache = createUpdateTaskOnQueryCache(queryClient)
	const updateUserOnQueryCache = createUpdateUserOnQueryCache(queryClient)
	const updatePlayerOptions =
		createUpdatePlayerOptionsOnQueryCache(queryClient)

	const chatMessage = createChatMessageSocket(socket)
	const chatMessageSubscription = chatMessage.subscribe((data) => {
		chatMessageSubject.next(data.message)
	})
	const chatMessageFile = createChatMessageFileSocket(socket)
	const chatMessageFileSubscription = chatMessageFile.subscribe((data) => {
		chatMessageFileSubject.next(data.file)
	})
	const chatReactions = createChatReactionsSocket(socket)
	const chatReactionsSubscription = chatReactions.subscribe((data) => {
		chatReactionsSubject.next(data)
	})

	const latestVersion = createLatestVersionSocket(socket)
	const latestVersionSubscription = latestVersion.subscribe((data) => {
		useStore.getState().setVersion(data)
	})

	const onlineUsers = createOnlineUsersSocket(socket)
	const onlineUsersSubscription = onlineUsers.subscribe((data) => {
		onlineUsersSubject.next(data)
		queryClient.setQueryData<ApiListResult<User>>(
			userKeys.list({ isOnline: true }),
			produce((draft) => {
				if (!draft) {
					return
				}

				draft.items = draft.items.filter((user) =>
					data.includes(user.id)
				)
				const diff = data.filter(
					(id) => !draft.items.find((user) => user.id === id)
				)
				diff.forEach((id) => {
					const user = queryClient.getQueryData<User>(
						userKeys.detail(id)
					)
					if (user) {
						draft.items.push(user)
					}
				})
			})
		)
	})
	const onlineUsersPollSubscription = onlineUsersPoll.subscribe(() => {
		socket.emit('getActiveUsers')
	})

	const platformNotification = createPlatformNotificationSocket(socket)
	const platformNotificationSocketSubscription =
		platformNotification.subscribe((data) => {
			platformNotificationsSubject.next(data)
		})

	const scoreUpdate = createScoreUpdateSocket(socket)
	const scoreUpdateSubscription = scoreUpdate.subscribe((data) => {
		if (!data?.userId) {
			return
		}
		updateUserSubject.next({
			userId: data.userId,
			changes: { currentScore: data.currentScore },
		})
		updateUserOnQueryCache(data.userId, { currentScore: data.currentScore })
	})

	const taskAdd = createTaskAddSocket(socket)
	const taskAddSocketSubscription = taskAdd.subscribe((newTask) => {
		addTaskToQueryCache(newTask)
		// Send to lists in the next cycle
		setTimeout(() => {
			addTaskSubject.next({ task: newTask })
		})
	})

	const taskMove = createTaskMoveSocket(socket)
	const taskMoveSocketSubscription = taskMove.subscribe(
		({ destParent, srcParent, task }) => {
			updateTaskOnQueryCache(task.id, task)
			// If moving to root, then destParent will be null
			if (destParent) {
				updateTaskOnQueryCache(destParent.id, destParent)
			}
			if (srcParent) {
				updateTaskOnQueryCache(srcParent.id, srcParent)
			}

			setTimeout(() => {
				const createPosition = (parent: {
					id: string
					childSortOrder: string[]
				}): TaskPosition =>
					parent
						? {
								parentId: parent.id,
								childSortOrder: parent.childSortOrder,
							}
						: { parentId: null }
				moveTaskSubject.next({
					destination: createPosition(destParent),
					source: createPosition(srcParent),
					taskId: task.id,
				})
			})
		}
	)

	const taskStatus = createTaskStatusSocket(socket)
	const taskStatusSocketSubscription = taskStatus.subscribe(({ task }) => {
		updateTaskOnQueryCache(task.id, task)
	})

	const taskTimer = createTaskTimerSocket(socket)
	const taskTimerSocketSubscription = taskTimer.subscribe(
		(taskTimeResult: TaskTimeResult) => {
			const { currentTimer, taskId } = taskTimeResult

			if (currentTimer.status === 'started') {
				startTaskOnQueryCache(taskId, currentTimer)
			} else {
				stopTaskOnQueryCache(taskId, currentTimer)
			}
		}
	)

	const taskUpdate = createTaskUpdateSocket(socket)
	const taskUpdateSocketSubscription = taskUpdate.subscribe(
		(task: { id: Task['id'] } & Partial<Task>) => {
			updateTaskOnQueryCache(task.id, task)
			// Send to lists in the next cycle
			setTimeout(() => {
				updateTaskSubject.next({ taskId: task.id, changes: task })
			})
		}
	)

	const typingInChat = createTypingInChatSocket(socket)
	const typingInChatSocketSubscription = typingInChat.subscribe(
		({ chatRoomId, typingUserId }) => {
			typingInChatSubject.next({ chatRoomId, typingUserId })
		}
	)

	const userOptionsChanged = createUserOptionsChangedSocket(socket)
	const userOptionsChangedSocketSubscription = userOptionsChanged.subscribe(
		({ userId, options }) => {
			if (userId === appState.player.id) {
				updatePlayerOptions(options)
			}
		}
	)

	const userStatuses = createUserStatusesSocket(socket)
	const userStatusesSubscription = userStatuses.subscribe((data) => {
		userStatusesSubject.next(data)
	})

	const userUpdate = createUserUpdateSocket(socket)
	const userUpdateSocketSubscription = userUpdate.subscribe(
		(user: { id: User['id'] } & Partial<User>) => {
			updateUserOnQueryCache(user.id, user)
		}
	)

	const socket$ = of(socket)
	const connect$ = socket$.pipe(
		switchMap((socket) =>
			merge(fromEvent(socket, 'connect'), fromEvent(socket, 'reconnect'))
		)
	)
	const disconnect$ = socket$.pipe(
		switchMap((socket) => fromEvent(socket, 'disconnect'))
	)
	const syncOnConnect$ = connect$.pipe(
		tap(() =>
			console.debug(
				'socket connected/reconnected, refetching any active tasks and task lists'
			)
		),
		tap(syncStore(queryClient)),
		tap(async () => {
			const result = await apiAdapter.apiInstance
				.get('/versions/web-app')
				.then((response) => response.data)
			console.debug('getting new version on socket disconnect', result)
			if (result.version) {
				console.debug('setting version to', result.version)
				useStore.getState().setVersion(result.version)
			}
		})
	)

	// We only want to sync on reconnect after a disconnect has happened
	const syncSubscription = disconnect$
		.pipe(concatMap(() => syncOnConnect$.pipe(take(1))))
		.subscribe()

	return () => {
		syncSubscription.unsubscribe()

		chatMessageSubscription.unsubscribe()
		chatMessageFileSubscription.unsubscribe()
		chatReactionsSubscription.unsubscribe()
		latestVersionSubscription.unsubscribe()
		onlineUsersSubscription.unsubscribe()
		onlineUsersPollSubscription.unsubscribe()
		platformNotificationSocketSubscription.unsubscribe()
		scoreUpdateSubscription.unsubscribe()
		taskAddSocketSubscription.unsubscribe()
		taskMoveSocketSubscription.unsubscribe()
		taskStatusSocketSubscription.unsubscribe()
		taskTimerSocketSubscription.unsubscribe()
		taskUpdateSocketSubscription.unsubscribe()
		typingInChatSocketSubscription.unsubscribe()
		userOptionsChangedSocketSubscription.unsubscribe()
		userStatusesSubscription.unsubscribe()
		userUpdateSocketSubscription.unsubscribe()
	}
}
