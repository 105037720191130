import { from, merge, of, startWith, switchMap } from 'rxjs'
import { Socket } from 'socket.io-client'
import { Mutate, StoreApi } from 'zustand'

import { events } from '../../constants'
import { createSocketObservable } from '../../socketUtils'
import { UserFocus } from '../../types'
import { AppState, MutatorMiddleware } from '../store-types'
import { getServiceFromState } from '../utils'

const createUserFocusSocket = (socket: Socket) =>
	createSocketObservable<Record<string, UserFocus>>(socket, events.USER_FOCUS)

export const createUsersFocusObservable = (
	api: Mutate<StoreApi<AppState>, MutatorMiddleware>
) => {
	const apiAdapter$ = getServiceFromState(api, 'apiAdapter')
	const socket$ = getServiceFromState(api, 'socket')

	const fetchUsersFocus$ = apiAdapter$.pipe(
		switchMap((apiAdapter) => from(apiAdapter.users.getUsersFocus()))
	)
	const usersFocusSocket$ = socket$.pipe(
		switchMap((socket) => createUserFocusSocket(socket))
	)
	const usersFocus$ = merge(
		usersFocusSocket$.pipe(
			startWith(null),
			switchMap((initial) =>
				initial === null ? fetchUsersFocus$ : of(initial)
			)
		),
		usersFocusSocket$
	)

	return usersFocus$
}
