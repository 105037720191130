import { fromJS, List, Map, OrderedMap } from 'immutable'
import { combineReducers } from 'redux-immutable'
import { createSelector } from 'reselect'

import {
	FETCH_NOTIFICATIONS_FAILURE,
	FETCH_NOTIFICATIONS_REQUEST,
	FETCH_NOTIFICATIONS_SUCCESS,
	SET_LAST_READ_NOTIFICATION,
	SET_PLAYER,
	USER_NOTIFICATION_CREATED,
} from '../actions'

const hasMore = (state = true, action) => {
	switch (action.type) {
		case FETCH_NOTIFICATIONS_SUCCESS:
			return action.payload.hasMore

		default:
			return state
	}
}

const isFetching = (state = false, action) => {
	switch (action.type) {
		case FETCH_NOTIFICATIONS_REQUEST:
			return true

		case FETCH_NOTIFICATIONS_FAILURE:
		case FETCH_NOTIFICATIONS_SUCCESS:
			return false

		default:
			return state
	}
}

// This reduce function tries to remove duplicates by building a map
const filterDuplicates = (a, v) => {
	if (!a.get(v.get('control'))) a = a.set(v.get('control'), v)
	return a
}
const items = (state = List(), action) => {
	switch (action.type) {
		case 'clearNotifications':
			return state.clear()

		case FETCH_NOTIFICATIONS_SUCCESS: {
			return state
				.concat(fromJS(action.payload.items))
				.reduce(filterDuplicates, OrderedMap())
				.toList()
		}
		case USER_NOTIFICATION_CREATED:
			return state
				.unshift(Map(action.payload))
				.reduce(filterDuplicates, OrderedMap())
				.toList()
		default:
			return state
	}
}

const lastRead = (state = null, action) => {
	switch (action.type) {
		case SET_PLAYER:
			return action.payload ? action.payload.taskLogNotifyControl : state

		case SET_LAST_READ_NOTIFICATION:
			return action.payload

		default:
			return state
	}
}

const page = (state = 1, action) => {
	switch (action.type) {
		case FETCH_NOTIFICATIONS_SUCCESS:
			return action.payload.page

		default:
			return state
	}
}

export const notifications = combineReducers({
	hasMore,
	isFetching,
	items,
	lastRead,
	page,
})

export const getHasMoreNotifications = (state) =>
	state.getIn(['notifications', 'hasMore'])
export const getIsFetchingNotifications = (state) =>
	state.getIn(['notifications', 'isFetching'])
export const getNotifications = (state) =>
	state.getIn(['notifications', 'items'])
export const getLastReadNotification = (state) =>
	state.getIn(['notifications', 'lastRead'])

export const unreadNotificationsCount = createSelector(
	[getNotifications, getLastReadNotification],
	(notifications, lastRead) => {
		let index = notifications.findIndex(
			(n) => n.get('control') === lastRead
		)
		if (index === -1) {
			index = notifications.size
		}
		return Math.max(0, index)
	}
)
