import {
	TaskActivity,
	TaskActivityCreate,
	TaskActivityCreateSchema,
} from '../task-activity-schemas'

export const parseTaskCreateToString = (
	activity: TaskActivityCreate
): string => {
	const meta = activity.meta

	switch (activity.source) {
		case 'mail':
			return `${meta.user.nickname} mailed in this task.`

		case 'web':
		default:
			return `${meta.user.nickname} added this task.`
	}
}

export const validateTaskCreate = (taskActivity: TaskActivity) =>
	TaskActivityCreateSchema.safeParse(taskActivity)
