import moment from 'moment'

import { dateFormats } from '../../helpers/formatters/date-formats'
import { Task, User } from '../../types'
import { TaskTimeResult } from '../api'
import { userKeys } from '../queries'
import { AppState, StoreNotification } from '../store-types'
import { secondsToTimeTakenString } from '../utils'

type UpdateBulkTaskProps = {
	changes: Partial<Task>
	taskIds: string[]
}

export const createUpdateBulkTaskSnackbar =
	(state: AppState) =>
	({
		changes,
		taskIds,
	}: UpdateBulkTaskProps): StoreNotification | undefined => {
		const { assigneeId, startDate, startTime, statusCode } = changes

		const taskCount = taskIds.length

		// Create snackbar for schedule actions
		if (startDate !== undefined) {
			const date = startDate
				? dateFormats.smartDate(moment(startDate), startTime)
				: 'Never'
			return {
				type: 'snackbar',
				message: `Rescheduled ${taskCount} tasks for ${date}`,
			}
		}

		// Create snackbar for marking tasks as done
		if (statusCode === 'done') {
			return {
				type: 'snackbar',
				message: `Marked ${taskCount} tasks as done`,
			}
		}
		// Create snackbar for deleting tasks
		else if (statusCode === 'deleted') {
			return {
				type: 'snackbar',
				message: `Deleted ${taskCount} tasks`,
			}
		}

		// Create snackbar for assigning tasks
		if ('assigneeId' in changes) {
			if (assigneeId) {
				const user = state.queryClient.getQueryData<User>(
					userKeys.detail(assigneeId)
				)
				if (user) {
					return {
						type: 'snackbar',
						message: `Assigned ${taskCount} tasks to ${user.nickname}`,
					}
				}
			}

			return {
				type: 'snackbar',
				message: `Reassigned ${taskCount} tasks`,
			}
		}

		return undefined
	}

export const createNoPermissionSnackbar = ():
	| StoreNotification
	| undefined => ({
	type: 'snackbar',
	message: `You do not have permission to edit this task`,
})

export const createAddTaskSnackbar =
	(/* state: AppState */) =>
	(taskId: string): StoreNotification => ({
		type: 'snackbar',
		message: `Task created!`,
		action: {
			type: 'taskCreate',
			payload: { taskId },
		},
	})

export const createAddTaskFailedSnackbar = () => (): StoreNotification => ({
	type: 'snackbar',
	message: `Failed to create task`,
})

export const createTaskStoppedSnackbar =
	(/* state: AppState */) =>
	({
		stoppedTimerId,
		stoppedTimerSecondsTaken,
		stoppedTaskId,
	}: TaskTimeResult): StoreNotification | undefined => ({
		type: 'snackbar',
		message: `Task stopped after ${secondsToTimeTakenString(
			stoppedTimerSecondsTaken
		)}`,
		action: {
			type: 'taskTimerStop',
			payload: {
				timerId: stoppedTimerId,
				taskId: stoppedTaskId,
			},
		},
	})

export const createUpdateTaskSnackbar =
	(/* state: AppState */) =>
	(task: Partial<Task>): StoreNotification | undefined => {
		if (task.notificationLevel) {
			return {
				type: 'snackbar',
				message: `Notification level set to ${task.notificationLevel}`,
			}
		}

		if (task.startDate !== undefined) {
			const date =
				task.startDate === 'someday'
					? task.startDate
					: task.startDate
						? dateFormats.smartDate(
								moment(task.startDate),
								task.startTime
							)
						: 'Never'
			return {
				type: 'snackbar',
				message: `Rescheduled for ${date}`,
			}
		}

		if (task.statusCode === 'done') {
			return {
				type: 'snackbar',
				message: 'Marked as done',
			}
		}

		return undefined
	}
