import { formatISO } from 'date-fns'
import { RRule } from 'rrule'

import { Task, TaskRepeat } from '../types'

export const getTaskRepeat = (task: Task): TaskRepeat | null => {
	if (!task || !task.repeatType || !task.repeatTime) {
		return null
	}

	const repeat: TaskRepeat = {
		intervals: task.repeatInterval,
		recurFreqType: task.repeatType,
		time: task.repeatTime,
	}

	if (task.repeatRrule !== null) {
		repeat.rrule = task.repeatRrule
	}

	if (task.repeatSchedule !== null) {
		repeat.repeatSchedule = task.repeatSchedule
	}

	return repeat
}

const rruleFreqToRecurFreqType = {
	[RRule.YEARLY]: 'year',
	[RRule.MONTHLY]: 'month',
	[RRule.WEEKLY]: 'week',
	[RRule.DAILY]: 'day',
	[RRule.HOURLY]: 'hour',
	[RRule.MINUTELY]: 'minute',
	[RRule.SECONDLY]: 'second',
}

export const rruleToTaskRepeat = (rrule: RRule): TaskRepeat => {
	const { interval, bymonth, bymonthday, freq, dtstart } = rrule.options
	const result: TaskRepeat = {
		intervals: String(interval),
		recurFreqType: rruleFreqToRecurFreqType[freq],
		//repeatSchedule: rrule.options.byweekday,
		rrule: rrule.toString(),
		time: formatISO(dtstart, { representation: 'time' }),
	}

	if (bymonth) {
		result.month = String(bymonth[0])
	}

	if (bymonthday) {
		result.monthDate = bymonthday[0]
	}

	return result
}
