import {
	TaskActivity,
	TaskActivityAssign,
	TaskActivityAssignSchema,
} from '../task-activity-schemas'

export const parseTaskAssignToString = (
	activity: TaskActivityAssign
): string => {
	const meta = activity.meta

	if (activity.assigneeId === null) {
		return `${meta.user.nickname} set the assignee to nobody.`
	}

	if (activity.userId === activity.assigneeId) {
		return `${meta.user.nickname} assigned this task to themselves.`
	} else {
		return `${meta.user.nickname} assigned this task to ${meta.assignee.nickname}.`
	}
}

export const validateTaskAssign = (taskActivity: TaskActivity) =>
	TaskActivityAssignSchema.safeParse(taskActivity)
