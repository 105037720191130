export const CHAT_OPENED = 'CHAT_OPENED'
export const PUSH_ROOM_UNREAD_MESSAGE = 'PUSH_ROOM_UNREAD_MESSAGE'
export const REMOVE_OLD_UNREAD_MESSAGES = 'REMOVE_OLD_UNREAD_MESSAGES'
export const SEND_MESSAGE = 'SEND_MESSAGE'
export const SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS'
export const SEND_MESSAGE_FAILURE = 'SEND_MESSAGE_FAILURE'
export const SET_LAST_READ_DATE = 'SET_LAST_READ_DATE'
export const CHAT_MESSAGE_UPDATE = 'CHAT_MESSAGE_UPDATE'
export const CHAT_MESSAGE_DELETED = 'CHAT_MESSAGE_DELETED'
export const START_CHAT = 'START_CHAT'
export const STOP_CHAT = 'STOP_CHAT'
export const UPDATE_LAST_READ_DATE = 'UPDATE_LAST_READ_DATE'
export const CHAT_REPLY = 'CHAT_REPLY'

export const TYPING_IN_CHAT = 'TYPING_IN_CHAT'
export const TYPING_IN_CHAT_RECEIVED = 'TYPING_IN_CHAT_RECEIVED'
export const TYPING_IN_CHAT_REMOVE = 'TYPING_IN_CHAT_REMOVE'

export const NOTIFY_CHAT_NOW = 'NOTIFY_CHAT_NOW'

export const CREATE_ROOM_REQUEST = 'CREATE_ROOM_REQUEST'
export const CREATE_ROOM_SUCCESS = 'CREATE_ROOM_SUCCESS'
export const CREATE_ROOM_FAILURE = 'CREATE_ROOM_FAILURE'

export const UPDATE_ROOM_REQUEST = 'UPDATE_ROOM_REQUEST'
export const UPDATE_ROOM_SUCCESS = 'UPDATE_ROOM_SUCCESS'
export const UPDATE_ROOM_FAILURE = 'UPDATE_ROOM_FAILURE'
export const CHAT_ROOM_CREATED = 'CHAT_ROOM_CREATED'
export const CHAT_ROOM_REMOVED = 'CHAT_ROOM_REMOVED'
export const CHAT_ROOM_UPDATED = 'CHAT_ROOM_UPDATED'

export const TOGGLE_NEW_CONVERSATION = 'TOGGLE_NEW_CONVERSATION'

export const ARCHIVE_ROOM = 'ARCHIVE_ROOM'
export const CHAT_LAST_READ_DATE_UPDATED = 'CHAT_LAST_READ_DATE_UPDATED'

export const pushRoomUnreadMessage = (payload) => ({
	type: PUSH_ROOM_UNREAD_MESSAGE,
	payload,
})
export const removeOldUnreadMessages = (payload) => ({
	type: REMOVE_OLD_UNREAD_MESSAGES,
	payload,
})
export const sendMessage = (payload) => ({ type: SEND_MESSAGE, payload })
export const sendMessageSuccess = (messageId) => ({
	type: SEND_MESSAGE_SUCCESS,
	payload: messageId,
})
export const sendMessageFailure = (messageId) => ({
	type: SEND_MESSAGE_FAILURE,
	payload: messageId,
})
export const setLastReadDate = (date, roomId) => ({
	type: SET_LAST_READ_DATE,
	payload: { date, roomId },
})

export const chatMessageUpdate = (payload) => ({
	type: CHAT_MESSAGE_UPDATE,
	payload,
})
export const chatMessageDeleted = (payload) => ({
	type: CHAT_MESSAGE_DELETED,
	payload,
})
export const startChat = (userId) => ({ type: START_CHAT, payload: userId })
export const stopChat = (payload) => ({ type: STOP_CHAT, payload })
export const updateLastReadDate = (roomId, date) => ({
	type: UPDATE_LAST_READ_DATE,
	payload: { roomId, date },
})

export const chatOpened = (roomId) => ({
	type: CHAT_OPENED,
	payload: { roomId },
})

export const notifyChatNow = (roomId) => ({
	type: NOTIFY_CHAT_NOW,
	payload: roomId,
})
export const chatReply = (messageId) => ({
	type: CHAT_REPLY,
	payload: messageId,
})

export const typingInChat = (roomId) => ({
	type: TYPING_IN_CHAT,
	payload: roomId,
})
export const typingInChatReceived = (payload) => ({
	type: TYPING_IN_CHAT_RECEIVED,
	payload,
})
export const typingInChatRemove = (roomId, userId) => ({
	type: TYPING_IN_CHAT_REMOVE,
	payload: { roomId, userId },
})

export const createChatRoom = (userIds) => ({
	type: CREATE_ROOM_REQUEST,
	payload: userIds,
})
export const createChatRoomSuccess = (room) => ({
	type: CREATE_ROOM_SUCCESS,
	payload: room,
})
export const createChatRoomFailure = (payload) => ({
	type: CREATE_ROOM_FAILURE,
	payload,
})
export const chatRoomCreated = (payload) => ({
	type: CHAT_ROOM_CREATED,
	payload,
})
export const chatRoomRemoved = (payload) => ({
	type: CHAT_ROOM_REMOVED,
	payload,
})

export const updateChatRoom = (room) => ({
	type: UPDATE_ROOM_REQUEST,
	payload: room,
})
export const updateChatRoomSuccess = (room) => ({
	type: UPDATE_ROOM_SUCCESS,
	payload: room,
})
export const updateChatRoomFailure = (payload) => ({
	type: UPDATE_ROOM_FAILURE,
	payload,
})
export const chatRoomUpdated = (room) => ({
	type: CHAT_ROOM_UPDATED,
	payload: room,
})
export const chatLastReadDateUpdated = (payload) => ({
	type: CHAT_LAST_READ_DATE_UPDATED,
	payload,
})

export const toggleNewConversation = (isToggled) => ({
	type: TOGGLE_NEW_CONVERSATION,
	payload: { isToggled },
})

export const archiveRoom = (roomId) => ({ type: ARCHIVE_ROOM, payload: roomId })
