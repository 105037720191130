import {
	TaskActivity,
	TaskActivityMove,
	TaskActivityMoveSchema,
} from '../task-activity-schemas'

export const parseTaskMoveToString = (activity: TaskActivityMove): string => {
	const meta = activity.meta

	const formatTitle = (title: string) => `<strong>${title}</strong>`
	return `${meta.user.nickname} moved this task from ${formatTitle(
		activity.oldParentTitle
	)} to ${formatTitle(activity.newParentTitle)}.`
}

export const validateTaskMove = (taskActivity: TaskActivity) =>
	TaskActivityMoveSchema.safeParse(taskActivity)
