import { fromJS, Map, List } from 'immutable'
import { createSelector } from 'reselect'

import {
	MERGE_USER,
	RECEIVE_USER_OPTIONS,
	SET_PLAYER,
	SET_PLAYER_SETTINGS,
	UPDATE_PLAYER_SCORE,
} from '../actions'
import { validateUser } from '../utils'
import { getById as getTaskById } from './tasks-reducer'

export const defaultOnboarding = {
	sidebarInviteVisible: true,
	sidebarProjectsVisible: true,
}
const initialState = fromJS({
	id: null,
	settings: {},
	options: {
		minimiseProjects: false,
		minimiseTasks: false,
		minimiseUsers: false,
		onboarding: {
			sidebarInviteVisible: false,
			sidebarProjectsVisible: false,
		},
	},
})

export const player = (state = initialState, { payload, type }) => {
	switch (type) {
		case SET_PLAYER:
			return payload
				? state.mergeDeep(fromJS(validateUser(payload)))
				: initialState

		case MERGE_USER:
			return payload.id === state.get('id')
				? state.mergeDeep(fromJS(validateUser(payload)))
				: state
		case RECEIVE_USER_OPTIONS:
			return payload.userId === state.get('id')
				? state.update('options', Map(), options =>
						options.merge(fromJS(payload.options))
				  )
				: state

		case SET_PLAYER_SETTINGS:
			return state.update('settings', Map(), settings =>
				settings.merge(fromJS(payload))
			)

		case UPDATE_PLAYER_SCORE:
			return state.merge({ currentScore: payload })

		default:
			return state
	}
}

export const getPlayer = state => state.get('player')
export const getPlayerCurrentTaskId = state =>
	state.getIn(['player', 'currentTaskId'])
export const getPlayerCurrentTaskStartDate = state =>
	state.getIn(['player', 'currentTaskStartDate'])
export const getPlayerId = state => state.getIn(['player', 'id'])
export const getPlayerSettings = state => state.getIn(['player', 'settings'])

export const getUserGroups = state =>
	state.getIn(['player', 'userGroups'], List())
export const isPlayer = state => Boolean(state.getIn(['player', 'id']))

export const getPlayerCurrentTask = createSelector(
	[getPlayerCurrentTaskId, getTaskById],
	(currentTaskId, byTaskId) => byTaskId.get(currentTaskId)
)

export const getIsGuest = createSelector([getUserGroups], userGroups =>
	userGroups.includes('guest')
)
// Deprecated
export const isGuest = getIsGuest
