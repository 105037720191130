import { call, put, takeLatest } from 'redux-saga/effects'

import { SET_MAKE_A_COPY_TASK, setMakeACopyDescendants } from '../actions/'
import Api from '../api'
import { logWarning } from '../log-error'

export function* makeACopySaga() {
	yield takeLatest(SET_MAKE_A_COPY_TASK, fetchTaskDescendants)
}

function* fetchTaskDescendants({ id }) {
	try {
		const { items: tasks } = yield call(Api.fetchTaskList, {
			ancestorId: id,
			filters: 'archived,deleted,done',
			pageSize: 0,
		})
		yield put(setMakeACopyDescendants(tasks))
	} catch (err) {
		return logWarning(
			'makeACopySaga.fetchTaskDescendants - failed to get descendants',
			err
		)
	}
}
