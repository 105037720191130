import moment from 'moment'

import { fuzzySearch } from '../helpers/fuzzySearch'
import { getShortText, PRIORITY_THRESHOLD } from '../helpers/priority'
import { isActive, isInactive } from '../helpers/taskStatus'
import { Task } from '../types'
import { Criteria } from './types'

class TaskCriteria {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	check(task: Task) {
		throw new Error(
			`check() method not implemented for class '${this.constructor.name}'`
		)
	}
}

export class AndCriteria extends TaskCriteria implements Criteria {
	criteria: Criteria[]

	constructor(criteria: Criteria[] = []) {
		super()
		this.criteria = criteria
		this.check = this.check.bind(this)
	}

	override check(task: Partial<Task>) {
		return this.criteria.every((criteria) => criteria.check(task))
	}
}

export class OrCriteria extends TaskCriteria implements Criteria {
	criteria: Criteria[]

	constructor(criteria: Criteria[] = []) {
		super()
		this.criteria = criteria
		this.check = this.check.bind(this)
	}

	override check(task: Task) {
		if (this.criteria.length === 0) {
			return true
		}
		return this.criteria.some((criteria) => criteria.check(task))
	}
}

export class AncestorCriteria extends TaskCriteria implements Criteria {
	ancestorIds: string[] = []

	constructor(ancestorIds: string | string[]) {
		super()

		if (Array.isArray(ancestorIds)) {
			this.ancestorIds = ancestorIds
		} else if (typeof ancestorIds === 'string') {
			this.ancestorIds = [ancestorIds]
		}

		this.check = this.check.bind(this)
	}

	override check(task: Task) {
		return task.parents.some((parent) =>
			this.ancestorIds.includes(parent.id)
		)
	}
}

export class AssigneeCriteria extends TaskCriteria implements Criteria {
	assigneeIds: (string | null)[] = []

	constructor(assigneeIds: null | string | (string | null)[]) {
		super()

		if (assigneeIds === null) {
			this.assigneeIds = [null]
		} else if (Array.isArray(assigneeIds)) {
			this.assigneeIds = assigneeIds
		} else if (typeof assigneeIds === 'string' || assigneeIds === null) {
			this.assigneeIds = [assigneeIds]
		}

		this.check = this.check.bind(this)
	}

	override check(task: Task) {
		return this.assigneeIds.includes(task.assigneeId)
	}
}

export class IdentityCriteria extends TaskCriteria implements Criteria {
	constructor() {
		super()
		this.check = this.check.bind(this)
	}

	override check() {
		return true
	}
}

export class ImportanceCriteria extends TaskCriteria implements Criteria {
	constructor() {
		super()
		this.check = this.check.bind(this)
	}

	override check(task: Task) {
		return task.importance > PRIORITY_THRESHOLD
	}
}

export class IsActiveCriteria extends TaskCriteria implements Criteria {
	constructor() {
		super()
		this.check = this.check.bind(this)
	}

	override check(task: Task) {
		return isActive(task.statusCode)
	}
}

export class IsInactiveCriteria extends TaskCriteria implements Criteria {
	constructor() {
		super()
		this.check = this.check.bind(this)
	}

	override check(task: Task) {
		return isInactive(task.statusCode)
	}
}
export class OverdueCriteria extends TaskCriteria implements Criteria {
	constructor() {
		super()
		this.check = this.check.bind(this)
	}

	override check(task: Task) {
		return moment(task.dueDate).isBefore(moment(), 'day')
	}
}

export class OwnerCriteria extends TaskCriteria implements Criteria {
	ownerIds: string[] = []

	constructor(ownerIds: string | string[]) {
		super()

		if (Array.isArray(ownerIds)) {
			this.ownerIds = ownerIds
		} else if (typeof ownerIds === 'string') {
			this.ownerIds = [ownerIds]
		}

		this.check = this.check.bind(this)
	}

	override check(task: Task) {
		return this.ownerIds.includes(task.ownerId)
	}
}

export class ParentCriteria extends TaskCriteria implements Criteria {
	parentIds: string[] = []

	constructor(parentIds: string | string[]) {
		super()

		if (Array.isArray(parentIds)) {
			this.parentIds = parentIds
		} else if (typeof parentIds === 'string') {
			this.parentIds = [parentIds]
		}

		this.check = this.check.bind(this)
	}

	override check(task: Task) {
		return this.parentIds.includes(task.parentId || '')
	}
}

export class PriorityCriteria extends TaskCriteria implements Criteria {
	priorityKeys: string[] = []

	constructor(priorityKeys: string | string[]) {
		super()

		if (Array.isArray(priorityKeys)) {
			this.priorityKeys = priorityKeys
		} else if (typeof priorityKeys === 'string') {
			this.priorityKeys = [priorityKeys]
		}

		this.check = this.check.bind(this)
	}

	override check(task: Task) {
		return this.priorityKeys.includes(getShortText(task))
	}
}

export class SearchCriteria extends TaskCriteria implements Criteria {
	query: string
	fields: string[]

	constructor(query = '', { fields = [] }: { fields?: string[] } = {}) {
		super()

		this.query = query.toLowerCase()
		// The fields to include in the search. Default them if nothing is provided
		this.fields = fields.length > 0 ? fields : ['title', 'tags']
		this.check = this.check.bind(this)
	}

	override check(task: Task) {
		if (!this.query) {
			return true
		}

		const fieldConditions: Record<string, (task: Task) => boolean> = {
			control: (task: Task) =>
				task?.control
					? task.control.toString().includes(this.query)
					: false,
			id: (task: Task) => task.id === this.query,
			title: (task: Task) => fuzzySearch(task.title, this.query),
			descr: (task: Task) => fuzzySearch(task.descr || '', this.query),
			parents: (task: Task) =>
				task.parents.some((t) =>
					fuzzySearch(t.title.toLowerCase(), this.query)
				),
			tags: (task: Task) => {
				const MATCH_TAG = /#([^\s]+)/g
				const matches = Array.from(this.query.matchAll(MATCH_TAG))

				const tagQueries = matches.map((match) =>
					match[1].toLowerCase()
				)

				if (Array.isArray(task.tags)) {
					return task.tags.some((tag) =>
						tagQueries.some((query) => tag.startsWith(query))
					)
				} else {
					return false
				}
			},
		}

		return this.fields
			.map((field) => fieldConditions[field](task))
			.some(Boolean)
	}
}

export class StatusCriteria extends TaskCriteria implements Criteria {
	statuses: string[] = []

	constructor(statuses: string | string[]) {
		super()

		if (Array.isArray(statuses)) {
			this.statuses = statuses
		} else if (typeof statuses === 'string') {
			this.statuses = [statuses]
		}

		this.check = this.check.bind(this)
	}

	override check(task: Task) {
		return this.statuses.includes(task.statusCode)
	}
}

export class TagsCriteria extends TaskCriteria implements Criteria {
	tags: string[] = []

	constructor(tags: string | string[]) {
		super()

		if (Array.isArray(tags)) {
			this.tags = tags
		} else if (typeof tags === 'string') {
			this.tags = [tags]
		}

		this.check = this.check.bind(this)
	}

	override check(task: Task) {
		// We want the tag filter to be case insensitive
		const lowerCaseTags = this.tags.map((tag) => tag.toLowerCase())
		if (Array.isArray(task.tags)) {
			return task.tags.some((tag) =>
				lowerCaseTags.includes(tag.toLowerCase())
			)
		} else {
			return false
		}
	}
}

export class UrgencyCriteria extends TaskCriteria implements Criteria {
	constructor() {
		super()
		this.check = this.check.bind(this)
	}

	override check(task: Task) {
		return task.urgency > PRIORITY_THRESHOLD
	}
}

export class WorkflowCriteria extends TaskCriteria implements Criteria {
	workflowId: string

	constructor(workflowId: string) {
		super()
		this.workflowId = workflowId
		this.check = this.check.bind(this)
	}

	override check(task: Task) {
		if (this.workflowId === 'noWorkflow') {
			return !task.workflowData || !task.workflowData.id
		} else {
			return task.workflowData
				? task.workflowData.id === this.workflowId
				: false
		}
	}
}
