export const FETCH_WHATS_NEW_FAILURE = 'FETCH_WHATS_NEW_FAILURE'
export const FETCH_WHATS_NEW_REQUEST = 'FETCH_WHATS_NEW_REQUEST'
export const FETCH_WHATS_NEW_SUCCESS = 'FETCH_WHATS_NEW_SUCCESS'

export const fetchWhatsNewFailure = reason => ({
	type: FETCH_WHATS_NEW_FAILURE,
	payload: reason,
})
export const fetchWhatsNewRequest = () => ({ type: FETCH_WHATS_NEW_REQUEST })
export const fetchWhatsNewSuccess = payload => ({
	type: FETCH_WHATS_NEW_SUCCESS,
	payload,
})
