import { Map } from 'immutable'

import {
	NEXT_STEP_INDEX,
	PREV_STEP_INDEX,
	SET_TOUR_STEP_INDEX,
	RESET_TOUR,
	START_TOUR,
	STOP_TOUR,
	FETCH_SETTINGS_SUCCESS,
} from '../actions'

const initialState = Map({
	run: false,
	stepIndex: 0,
})

export const tour = (state = initialState, action) => {
	switch (action.type) {
		// Get the `showWalkthrough` setting and if true, show the tour on app
		// start
		case FETCH_SETTINGS_SUCCESS:
			return state.set('run', Boolean(action.response.showWalkthrough))

		case NEXT_STEP_INDEX:
			// TODO: this value needs to be clamped at the length of the steps
			return state.set('stepIndex', state.getStepIndex + 1)

		case PREV_STEP_INDEX:
			return state.set('stepIndex', Math.max(state.getStepIndex - 1, 0))

		case RESET_TOUR:
			return state.set('stepIndex', 0).set('run', false)

		case SET_TOUR_STEP_INDEX:
			return state.set('stepIndex', action.payload)

		case START_TOUR:
			return state.set('run', true)

		case STOP_TOUR:
			return state.set('run', false)

		default:
			return state
	}
}

export const getTourRun = state => state.getIn(['tour', 'run'])
export const getTourStepIndex = state => state.getIn(['tour', 'stepIndex'])
