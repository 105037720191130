import {
	addUser,
	chatLastReadDateUpdated,
	chatMessageDeleted,
	chatMessageUpdate,
	chatRoomCreated,
	chatRoomRemoved,
	chatRoomUpdated,
	setIsOnline,
	setTaskFiles,
	socketAddTask,
	socketInviteAccepted,
	socketInviteDeclined,
	socketMoveTask,
	socketTaskTimer,
	socketUpdateTask,
	socketUpdateTasks,
	socketUserFocus,
	typingInChatReceived,
	userNotificationCreated,
} from './actions'

const socketEventToActionMap = {
	activeUsers: setIsOnline,
	inviteAccepted: socketInviteAccepted,
	inviteDeclined: socketInviteDeclined,
	taskAdded: socketAddTask,
	taskMove: socketMoveTask,
	taskTimer: socketTaskTimer,
	taskFileUpdate: setTaskFiles,
	taskUpdate: socketUpdateTask,
	tasksUpdate: socketUpdateTasks,
	taskDone: socketUpdateTask,
	userAdd: addUser,
	userFocus: socketUserFocus,
	chatMessageUpdate,
	chatMessageDeleted,
	typingInChat: typingInChatReceived,
	chatRoomCreated,
	chatRoomRemoved,
	chatRoomUpdated,
	userNotificationCreated,
	chatLastReadDateUpdated,
}

export default socketEventToActionMap
