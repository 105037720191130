import { includes, map, prop } from 'ramda'

import { Tree, TreeTask } from '../../../projects/projectsTypes'
import { Task } from '../../../types'

export * from './createTreeItem'

export const isDescendantOf = (projectId: string, task: Task) =>
	includes(projectId, map(prop('id'), task.parents || []))

export const isParentInTree = (
	tree: Tree<TreeTask>,
	task: Pick<Task, 'parentId'>
) => Boolean(task.parentId && tree.items[task.parentId])

// TODO: fill in with top-level business logic and move to more global place.
export const isTopLevelProject = (task: Task) => {
	return task?.parentId === null || task?.parentId === 'root'
}
