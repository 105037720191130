import { Entity, Task, Workflow } from '../types'

export interface Tree<T> {
	rootId: string | null
	items: { [taskId: Task['id']]: TreeItem<T> }
}

export interface TreeItem<T> extends Entity {
	children: string[]
	data: T
	hasChildren: boolean
	isChildrenLoading: boolean
	isExpanded: boolean
	isParent: boolean
	path: number[]
}

export interface BoardStrategy<T extends TreeTask = TreeTask> {
	buildTree: (
		rootTask: T | null,
		tasks?: T[],
		rootWorkflow?: Workflow | null
	) => Tree<T>
	/**
	 * Filtering will orphan a lot of tasks. The resolver decides how the parent structure is maintained.
	 */
	filterResolver?: (taskMap: Record<string, T>, filteredTasks: T[]) => T[]
	name: string
}

export type AssigneeTreeTask = Entity & { type: 'assignee'; assigneeId: string }
export type OwnerTreeTask = Entity & { type: 'owner'; ownerId: string }
export type ParentTreeTask = Entity & {
	type: 'parent'
	childSortOrder: Task['childSortOrder']
	isMinimised: Task['isMinimised']
	isParent: Task['isParent']
	parentId: Task['parentId']
	parents: Task['parents']
}
export type StatusTreeTask = Entity & {
	type: 'status'
	statusCode: Task['statusCode']
}
export type WorkflowTreeTask = Entity & {
	type: 'workflow'
	parentId: Task['parentId']
	workflowData: Task['workflowData']
}
export type WorkflowStepTreeTask = Entity & {
	type: 'workflowStep'
	title: string
}
export type TreeTask =
	| AssigneeTreeTask
	| OwnerTreeTask
	| ParentTreeTask
	| StatusTreeTask
	| WorkflowTreeTask
	| WorkflowStepTreeTask

export const isParentTreeTask = (
	treeTask: TreeTask
): treeTask is ParentTreeTask => treeTask.type === 'parent'
