export class SelectorStore {
	constructor(factory) {
		this.map = {}
		this.factory = factory
	}

	get(id) {
		if (!this.map[id]) {
			this.map[id] = this.factory(id)
		}
		return this.map[id]
	}
}
