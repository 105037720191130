export const ADD_TO_BULK_EDIT = 'ADD_TO_BULK_EDIT'
export const ADD_MULTIPLE_TO_BULK_EDIT = 'ADD_MULTIPLE_TO_BULK_EDIT'
export const CLEAR_BULK_EDIT = 'CLEAR_BULK_EDIT'
export const REMOVE_FROM_BULK_EDIT = 'REMOVE_FROM_BULK_EDIT'
export const REMOVE_MULTIPLE_FROM_BULK_EDIT = 'REMOVE_MULTIPLE_FROM_BULK_EDIT'

export const addToBulkEdit = id => ({ type: ADD_TO_BULK_EDIT, payload: id })
export const addMultipleToBulkEdit = ids => ({
	type: ADD_MULTIPLE_TO_BULK_EDIT,
	payload: ids,
})
export const clearBulkEdit = () => ({ type: CLEAR_BULK_EDIT })
export const removeFromBulkEdit = id => ({
	type: REMOVE_FROM_BULK_EDIT,
	payload: id,
})
export const removeMultipleFromBulkEdit = ids => ({
	type: REMOVE_MULTIPLE_FROM_BULK_EDIT,
	payload: ids,
})
