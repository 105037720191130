import { QueryClient, QueryKey } from '@tanstack/react-query'

import { TeardownFn } from '../../types'

/**
 * Update the query cache but don't update the API. That should be done
 * separately and composed into a higher order function.
 *
 * @param {QueryClient} queryClient
 * @returns Returns previous value from before update. Useful if needing to
 * 			rollback a failed update.
 */
export const createBaseQueryCacheMutation =
	(queryClient: QueryClient) =>
	async <TData>(
		queryKey: QueryKey,
		updater: TData | ((oldData: TData | undefined) => TData | undefined)
	): Promise<TeardownFn> => {
		// Cancel any outgoing refetches (so they don't overwrite our optimistic
		// update).
		await queryClient.cancelQueries(queryKey, { exact: true })

		// Snapshot the previous value.
		const previousValue = queryClient.getQueryData<TData>(queryKey)

		if (previousValue) {
			// Optimistically update to the new value.
			// NOT using setQueriesData anymore because it updates the task activity
			// when it shouldn't.
			queryClient.setQueryData<TData | undefined>(queryKey, updater)
		}

		// Return a rollback function.
		return () => {
			queryClient.setQueryData<TData | undefined>(queryKey, previousValue)
		}
	}
