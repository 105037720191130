import { QueryClient } from '@tanstack/react-query'
import { differenceInSeconds, formatISO, parseISO } from 'date-fns'
import produce from 'immer'
import { isDeepEqual } from 'remeda'

import { createStopTaskActivity } from '@tyto/task-activity'

import { TraceableError } from '../../../helpers/error-handling'
import { Task, TaskTimer, User } from '../../../types'
import { TaskTimeResult } from '../../api'
import { ApiAdapter } from '../../api/baseApiAdapter'
import { StoreActions } from '../../createActions'
import { taskKeys, userKeys } from '../../queries'
import { createTaskStoppedSnackbar } from '../../snackbars'
import { AppState } from '../../store-types'
import { createAddTaskActivityToQueryCache } from '../../task-activity/taskActivityMutations'
import { getTimeInfoFromTask } from '../../utils'

export const createStopTask =
	(queryClient: QueryClient, updateTask: StoreActions['updateTask']) =>
	(taskId: string) => {
		const task = queryClient.getQueryData<Task>(taskKeys.detail(taskId))
		const timeInfo = getTimeInfoFromTask(task)
		const patch: Partial<Task> = {
			currentTimer: { status: 'stopped', date: formatISO(Date.now()) },
			hoursTaken: timeInfo.elapsed / 3600,
		}
		const patchedTask = { ...task, ...patch }

		// Only update if there are valid changes
		if (!isDeepEqual(patchedTask, task)) {
			updateTask(taskId, patch)
		}
	}

export const sendTaskStoppedSnackbar = (
	appState: AppState,
	taskTimeResult: TaskTimeResult
) => {
	const notifications$ = appState.notifications
	const taskStoppedSnackbar = createTaskStoppedSnackbar()

	const snackbar = taskStoppedSnackbar(taskTimeResult)
	if (snackbar) {
		notifications$.next(snackbar)
	}
}

export const stopTaskMutation = (
	state: AppState,
	taskId: string,
	actions: Pick<StoreActions, 'updateTask' | 'updateUser'>
) => {
	const { apiAdapter, queryClient } = state
	const { updateTask, updateUser } = actions
	createStopTaskOnApi(apiAdapter)(taskId)
		.then((data) => {
			if (data && data.stoppedTaskId) {
				createStopTask(queryClient, updateTask)(data.stoppedTaskId)

				if (data.stoppedTimerId) {
					sendTaskStoppedSnackbar(state, data)
				}
			}
			return
		})
		.catch((err) => {
			throw new TraceableError('Failed to stop task', err)
		})

	const task = queryClient.getQueryData<Task>(taskKeys.detail(taskId))
	const userId = task?.assigneeId || task?.ownerId

	if (userId) {
		const user = queryClient.getQueryData<User>(userKeys.detail(userId))

		if (user && user.currentTaskId === taskId) {
			updateUser(userId, {
				currentTaskId: null,
				currentTaskStartDate: null,
			})
		}
	}

	createStopTask(queryClient, updateTask)(taskId)

	/* Disabled optimistic updates for activity since there are some issues with ids not matching up
	// Add task activity
	const playerId = state.player.id
	if (!playerId) {
		throw new Error('playerId is falsey in AppState')
	}
	const newTask = queryClient.getQueryData<Task>(taskKeys.detail(taskId))
	if (!newTask) {
		throw new Error(
			'Cannot create optimistic task activity for stopTask action without a task in cache'
		)
	}
	if (!newTask.currentTimer.date) {
		throw new Error(`Cannot stop task. Task is not running.`)
	}
	const duration = differenceInSeconds(
		new Date(),
		parseISO(newTask.currentTimer.date)
	)
	const activity = createStopTaskActivity(
		playerId,
		newTask.assigneeId || playerId,
		duration,
		taskId
	)
	if (activity) {
		createAddTaskActivityToQueryCache(apiAdapter, queryClient)(activity)
	}
	*/
}

export const createStopTaskOnApi =
	(apiAdapter: ApiAdapter) => (taskId: string) =>
		apiAdapter.tasks.updateTimer(taskId, {
			date: formatISO(Date.now()),
			status: 'stopped',
		})

export const createStopTaskOnQueryCache =
	(queryClient: QueryClient) => (taskId: string, currentTimer: TaskTimer) => {
		const task = queryClient.getQueryData<Task>(taskKeys.detail(taskId))

		if (!task) {
			return
		}

		const userId = task?.assigneeId || task?.ownerId

		// Update user timer details
		queryClient.setQueryData<User | undefined>(
			userKeys.detail(userId),
			(prevUser) =>
				prevUser &&
				produce(prevUser, (draft) => {
					draft.currentTaskId = null
					draft.currentTaskStartDate = null
				})
		)

		// Update task timer details
		queryClient.setQueryData<Task | undefined>(
			taskKeys.detail(taskId),
			(prevTask) =>
				prevTask &&
				produce(prevTask, (draft) => {
					draft.currentTimer = currentTimer
				})
		)
	}
