import xxhash from 'xxhashjs'

const SEED = 0

export const deterministic = (obj: unknown): Record<string, unknown> =>
	obj === null || typeof obj !== 'object'
		? obj
		: Array.isArray(obj)
		? obj.map(deterministic)
		: Object.assign(
				{},
				...Object.entries(obj)
					.sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
					.map(
						([k, v]): Record<string, unknown> => ({
							[k]: deterministic(v),
						})
					)
		  )

// This function is slower than it's wasm counterpart by about 10 times but will
// work outside of browser environments.
export const getHash = (object: Record<string, unknown> | null) => {
	const input = JSON.stringify(deterministic(object))
	return xxhash.h32(input, SEED).toString()
}
