import { fromJS, List, Map } from 'immutable'
import moment from 'moment'

import {
	CHAT_MESSAGE_DELETED,
	CHAT_MESSAGE_UPDATE,
	CHAT_OPENED,
	CHAT_REPLY,
	PUSH_ROOM_UNREAD_MESSAGE,
	REMOVE_OLD_UNREAD_MESSAGES,
	SEND_MESSAGE,
	SEND_MESSAGE_FAILURE,
	SEND_MESSAGE_SUCCESS,
	SET_LAST_READ_DATE,
	START_CHAT,
	STOP_CHAT,
	CREATE_ROOM_SUCCESS,
	CHAT_ROOM_CREATED,
	UPDATE_ROOM_SUCCESS,
	CHAT_ROOM_REMOVED,
	CHAT_ROOM_UPDATED,
	TOGGLE_NEW_CONVERSATION,
	ARCHIVE_ROOM,
} from '../actions'

const initialState = Map({
	lastReadDate: null,
	messages: List(),
	references: Map(),
	roomId: null,
	isNewConversationView: false,
	chatOpen: false,
	hasMore: true,
	replyToId: null,
	roomDrafts: Map(),
})

export const chat = (state = initialState, { payload, type }) => {
	switch (type) {
		case CHAT_MESSAGE_DELETED: {
			const lastMessageState = state.getIn(['rooms', payload.chatRoomId])
				? state.updateIn(['rooms', payload.chatRoomId], (room) =>
						room
							.set('lastMessage', payload.lastMessage)
							.update('unreadMessages', (unreadMessages) =>
								unreadMessages.filter(
									(message) =>
										message.get('id') !== payload.deletedId
								)
							)
				  )
				: state
			if (payload.chatRoomId !== state.get('roomId')) {
				return lastMessageState
			}
			return lastMessageState.update('messages', (messages) =>
				messages.filter(
					(message) => message.get('id') !== payload.deletedId
				)
			)
		}
		case CHAT_MESSAGE_UPDATE: {
			const lastMessageState = state.getIn(['rooms', payload.chatRoomId])
				? state.updateIn(['rooms', payload.chatRoomId], (room) =>
						room.update('lastMessage', (lastMessage) =>
							lastMessage.get('id') === payload.id
								? lastMessage.merge(payload)
								: lastMessage
						)
				  )
				: state
			if (payload.chatRoomId !== state.get('roomId')) {
				return lastMessageState
			}
			return lastMessageState.update('messages', (messages) =>
				messages.map((message) =>
					message.get('id') === payload.id
						? message.merge(payload)
						: message
				)
			)
		}
		case PUSH_ROOM_UNREAD_MESSAGE: {
			if (
				!state.getIn(['rooms', payload.chatRoomId]) ||
				state
					.getIn(
						['rooms', payload.chatRoomId, 'unreadMessages'],
						List()
					)
					.find((msg) => msg.get('id') === payload.id)
			) {
				return state
			}
			const message = Map(payload)
			return state
				.updateIn(
					['rooms', payload.chatRoomId, 'unreadMessages'],
					List(),
					(messages) => messages.push(message)
				)
				.setIn(['rooms', payload.chatRoomId, 'lastMessage'], message)
		}
		case REMOVE_OLD_UNREAD_MESSAGES: {
			const unreadMessages = state.getIn(
				['rooms', payload.roomId, 'unreadMessages'],
				List()
			)
			const filterDate = (msg) =>
				moment(msg.get('dateCreated')).isAfter(payload.date)

			if (
				unreadMessages.filter(filterDate).size !== unreadMessages.size
			) {
				return state.updateIn(
					['rooms', payload.roomId, 'unreadMessages'],
					List(),
					(messages) => messages.filter(filterDate)
				)
			}

			return state
		}
		case SET_LAST_READ_DATE: {
			const currentRoomId = state.get('roomId')
			let updatedState = state
			if (!payload.roomId || payload.roomId === currentRoomId) {
				updatedState = state.set('lastReadDate', payload.date)
			}
			if (
				updatedState.getIn(['rooms', payload.roomId || currentRoomId])
			) {
				return updatedState.setIn(
					['rooms', payload.roomId || currentRoomId, 'lastReadDate'],
					payload.date
				)
			}
			return updatedState
		}
		case STOP_CHAT:
			return state.merge({
				messages: List(),
				lastReadDate: null,
				chatOpen: false,
				hasMore: true,
				replyToId: null,
			})
		case CHAT_OPENED:
			return state.merge({
				roomId: payload.roomId,
				isNewConversationView: false,
				chatOpen: true,
				messages: List(),
				hasMore: true,
				replyToId: null,
			})
		case START_CHAT:
			return state.merge({ chatOpen: true })
		case SEND_MESSAGE_SUCCESS:
			return state.setIn(['messages', payload, 'state'], 'sent')
		case SEND_MESSAGE_FAILURE:
			return state.setIn(['messages', payload, 'state'], 'failed')
		case CHAT_REPLY:
			return state.merge({ replyToId: payload }).setIn(
				['references', payload],
				state
					.get('messages')
					.find((message) => message.get('id') === payload)
			)
		case SEND_MESSAGE:
			return state.merge({ replyToId: null })

		case CREATE_ROOM_SUCCESS:
		case CHAT_ROOM_CREATED:
			return state.setIn(
				['rooms', payload.id],
				fromJS({ unreadMessages: [], ...payload })
			)
		case UPDATE_ROOM_SUCCESS:
		case CHAT_ROOM_UPDATED:
			return state.updateIn(
				['rooms', payload.id],
				Map({
					unreadMessages: List(),
				}),
				(room) => room.merge(payload)
			)
		case CHAT_ROOM_REMOVED:
			return state.removeIn(['rooms', payload])
		case TOGGLE_NEW_CONVERSATION:
			return state.set('isNewConversationView', payload.isToggled)
		case ARCHIVE_ROOM: {
			const newState = state.update('rooms', (rooms) =>
				rooms.filter((room) => room.get('id') !== payload)
			)
			if (newState.get('roomId') === payload) {
				return newState.merge({
					roomId: null,
					hasMore: false,
					references: Map(),
					messages: List(),
					replyToId: null,
				})
			}
			return newState
		}
		default:
			return state
	}
}

export const getMessages = (state) => state.get('chat').get('messages')
export const getIsVisible = (state) => state.get('chat').get('chatOpen')
export const getLastReadDate = (state, roomId) =>
	state.getIn(['chat', 'rooms', roomId, 'lastReadDate'], null)
export const getCurrentRoomId = (state) => state.get('chat').get('roomId')
export const getChatHasMoreMessages = (state) =>
	state.get('chat').get('hasMore')
export const getChatReferences = (state) => state.get('chat').get('references')
export const getChatReplyToId = (state) => state.get('chat').get('replyToId')

export const getMessage = (state, messageId) => {
	const message = getMessages(state).find(
		(message) => message.get('id') === messageId
	)
	// if (!message) {
	// 	return getRooms(state)
	// 		.map((room) => room.get('lastMessage'))
	// 		.find((message) => message.get('id') === messageId)
	// }
	return message
}

export const isNewConversationView = (state) =>
	state.getIn(['chat', 'isNewConversationView'], false)
