import { optionIds } from '../../constants'
import { PlayerOptions } from '../../types'

export const userOptionIds: (keyof PlayerOptions)[] = [
	'isTaskEditorDetached',
	'minimiseProjects',
	'minimiseTasks',
	'minimiseUsers',
	'projects.defaultView',
	'projects.boardView',
	'recents.taskEdit.viewedTasks',
	'recents.search.queries',
	'taskEdit.activityTab',
	'tasksTableSort',
	'usersIsStarred',
	optionIds.INACTIVE_TASKS_RANGE_KEY,
	optionIds.STARRED_PROJECTS,
	optionIds.GOOGLE_CALENDAR_SYNC_ENABLED,
	optionIds.BROWSER_NOTIFICATIONS_STATE,
	optionIds.NOTIFICATIONS_STATE,
	optionIds.ONBOARDING,
	optionIds.OUTLOOK_CALENDAR_SYNC_ENABLED,
	optionIds.RECENT_CALLS,
	optionIds.RECENT_TAGS,
	optionIds.THEME,
	optionIds.THEME_NIGHT,
	optionIds.USER_VIEW_SELECTED_VIEW,
	optionIds.USER_VIEW_TASK_LAYOUT,
]
