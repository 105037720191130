import {
	TaskActivity,
	TaskActivityOwner,
	TaskActivityOwnerSchema,
} from '../task-activity-schemas'

export const parseTaskOwnerToString = (activity: TaskActivityOwner): string => {
	const meta = activity.meta

	return `${meta.user.nickname} changed this owner to ${meta.owner.nickname}.`
}

export const validateTaskOwner = (taskActivity: TaskActivity) =>
	TaskActivityOwnerSchema.safeParse(taskActivity)
