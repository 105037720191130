import { fromJS, Map } from 'immutable'
import pick from 'lodash/pick'
import { combineReducers } from 'redux-immutable'

import {
	FETCH_SETTINGS_FAILURE,
	FETCH_SETTINGS_REQUEST,
	FETCH_SETTINGS_SUCCESS,
	SET_GOOGLE_SETTINGS,
	SET_SHOW_WALKTHROUGH,
} from '../actions'
import { validateDailyTaskMail } from '../utils'

const isFetching = (state = false, action) => {
	switch (action.type) {
		case FETCH_SETTINGS_REQUEST:
			return true
		case FETCH_SETTINGS_SUCCESS:
		case FETCH_SETTINGS_FAILURE:
			return false
		default:
			return state
	}
}

const initialGoogleState = Map({
	//googleCalendarId: null,
	//googleCalendarResourceId: null,
	googleCalendarSyncToken: null,
	googleRefreshToken: null,
	syncFromGoogleCalendar: false,
	syncToGoogleCalendar: false,
})

const google = (state = initialGoogleState, action) => {
	const fields = [
		'googleCalendarSyncToken',
		'googleRefreshToken',
		'syncFromGoogleCalendar',
		'syncToGoogleCalendar',
	]

	switch (action.type) {
		case FETCH_SETTINGS_SUCCESS:
			return state.merge(fromJS(pick(action.response, fields)))

		case SET_GOOGLE_SETTINGS:
			return state.merge(fromJS(pick(action.settings, fields)))

		default:
			return state
	}
}

const initialDailyTaskMailState = Map({
	wantsDailyTaskMail: false,
	wantsDailyTaskMailDays: null,
	wantsDailyTaskMailOnWeekends: false,
	wantsDailyTaskMailTime: '08:00:00',
	wantsDailyTaskMailWhenTasksAssignedForDay: false,
})

const dailyTaskMail = (state = initialDailyTaskMailState, action) => {
	const fields = [
		'wantsDailyTaskMail',
		'wantsDailyTaskMailDays',
		'wantsDailyTaskMailOnWeekends',
		'wantsDailyTaskMailTime',
		'wantsDailyTaskMailWhenTasksAssignedForDay',
	]

	switch (action.type) {
		case FETCH_SETTINGS_SUCCESS:
			return state.merge(
				fromJS(pick(validateDailyTaskMail(action.response), fields))
			)

		default:
			return state
	}
}

const showWalkthrough = (state = false, action) => {
	switch (action.type) {
		case FETCH_SETTINGS_SUCCESS:
			return Boolean(action.response.showWalkthrough)

		case SET_SHOW_WALKTHROUGH:
			return action.payload

		default:
			return state
	}
}

export const settings = combineReducers({
	dailyTaskMail,
	google,
	isFetching,
	showWalkthrough,
})

export const getDailyTaskMail = (state) =>
	state.get('settings').get('dailyTaskMail')
