import Color from 'color'

import './themeOverrides'

import {
	alpha,
	createTheme,
	emphasize,
	Theme,
	ThemeOptions,
} from '@mui/material/styles'

import { deepMerge } from '@tyto/utils'

import { defaultPalette } from './palettes'
import { PRIORITY_COLORS } from './priority-colors'
import { createBlendedPaletteColor, getAlpha } from './theme-utils'

const defaultFontFamily =
	'Poppins, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif'

export const defaultBorderRadius = 8
export const defaultBorderRadiusDialog = 20

export const createBaseTheme = (options?: ThemeOptions) => {
	const baseTheme = createTheme(options)
	const defaultOptions = defaultStyles(baseTheme)
	const merged = deepMerge<ThemeOptions>(defaultOptions, options || {})
	return createTheme(merged)
}

export const createWallpaperTheme = (
	options: ThemeOptions,
	wallpaperOptions: ThemeOptions
) => {
	const baseTheme = createBaseTheme(options)
	const defaultOptions = defaultStyles(baseTheme)
	const wallpaperTheme = createBaseTheme(deepMerge(options, wallpaperOptions))

	const baseWallpaperMerged = deepMerge(
		{
			palette: {
				background: {
					contextPanel: 'transparent',
					sidebar: 'transparent',
				},
				wallpaper: deepMerge(wallpaperTheme.palette, {
					background: {
						paper: baseTheme.palette.background.paper,
					},
				}),
			},
			components: {
				MuiAppBar: {
					styleOverrides: {
						colorDefault: { backgroundColor: 'transparent' },
						root: { boxShadow: 'none' },
					},
				},
			},
		},
		options
	)
	return createTheme(deepMerge(defaultOptions, baseWallpaperMerged))
}

const createNeutralColor = (theme: Theme) => {
	const textColor = theme.palette.text.primary

	const tonalOffset =
		typeof theme.palette.tonalOffset === 'object'
			? theme.palette.tonalOffset[theme.palette.mode]
			: theme.palette.tonalOffset

	const mixedColor = Color(theme.palette.background.paper)
		.mix(Color(textColor), 0.1)
		.toString()

	return {
		light: Color(mixedColor).lighten(tonalOffset).toString(),
		main: mixedColor,
		dark: Color(mixedColor).darken(tonalOffset).toString(),
		contrastText: textColor,
	}
}

export const createTranslucentColor = (theme: Theme) => {
	const paperColor = theme.palette.background.paper
	const contrastText = theme.palette.text.primary

	return theme.palette.mode === 'light'
		? {
				main: alpha(emphasize(paperColor, 0.1), 0.3),
				dark: alpha(emphasize(paperColor, 0.2), 0.3),
				contrastText,
			}
		: {
				main: alpha(emphasize(paperColor, 0.1), 0.3),
				dark: alpha(emphasize(paperColor, 0.2), 0.3),
				contrastText,
			}
}

export const defaultStyles = (baseTheme: Theme): ThemeOptions => {
	const buttonSizes = {
		small: baseTheme.spacing(4),
		medium: baseTheme.spacing(4.5),
		large: baseTheme.spacing(6.5),
	}

	const neutralColor = baseTheme.palette.neutral
		? baseTheme.palette.neutral
		: createNeutralColor(baseTheme)

	const blendedColor = (color: string, contrast?: string) =>
		createBlendedPaletteColor(baseTheme, color, {
			blendAmount: 0.2,
			contrastText: contrast,
		})

	return {
		typography: {
			fontFamily: defaultFontFamily,
			h1: { fontWeight: 500 },
			h2: { fontWeight: 500 },
			h3: { fontWeight: 700 },
			h4: { fontWeight: 700 },
			h5: { fontWeight: 700 },
			h6: { fontWeight: 700 },
			//subtitle1: { fontSize: '1.125rem', fontWeight: 700 },
			caption: { lineHeight: 1.7 },
		},

		palette: {
			mode: baseTheme.palette.mode,
			primary: blendedColor(defaultPalette.primary, '#fff'),
			secondary: blendedColor(defaultPalette.secondary, '#fff'),
			neutral: neutralColor,
			translucent: createTranslucentColor(baseTheme),

			brand: blendedColor(defaultPalette.brand, '#fff'),
			busy: blendedColor(defaultPalette.busy, '#fff'),
			bulk: blendedColor(defaultPalette.bulk, '#fff'),
			archived: blendedColor(defaultPalette.archived, '#fff'),
			deleted: blendedColor(defaultPalette.deleted, '#fff'),
			done: blendedColor(defaultPalette.done, '#fff'),
			duration: blendedColor(defaultPalette.duration),
			overtime: blendedColor(baseTheme.palette.error.main),
			starred: blendedColor(defaultPalette.starred),
			userFocus: blendedColor(defaultPalette.userFocus),
			workflow: blendedColor(defaultPalette.workflow, '#fff'),

			link: defaultPalette.link,

			background: {
				contextPanel: 'transparent',
				lightEmphasis: alpha('#F6F7F9', 0.8),
				sidebar: baseTheme.palette.background.paper,
				secondary: baseTheme.palette.grey[200],
				tertiary: baseTheme.palette.background.paper,
			},

			text: {
				primary:
					baseTheme.palette.mode === 'light'
						? 'rgba(0, 0, 0, 0.82)'
						: 'rgba(255, 255, 255, 1)',
				secondary:
					baseTheme.palette.mode === 'light'
						? 'rgba(0, 0, 0, 0.5)'
						: 'rgba(255, 255, 255, 0.7)',
				disabled:
					baseTheme.palette.mode === 'light'
						? 'rgba(0, 0, 0, 0.35)'
						: 'rgba(255, 255, 255, 0.35)',
			},
		},

		mixins: {
			busyProgress: (theme: Theme) => {
				const mode = theme.palette.mode

				const busyColor = Color(theme.palette.busy.main)
				const bgColor =
					mode === 'light'
						? busyColor.lighten(0.2)
						: busyColor.darken(0.8).desaturate(0.6)

				return {
					bgColor: alpha(bgColor.hex(), 0.8),
					fgColor: busyColor.hex(),
				}
			},
			busyBackground: (theme: Theme) => {
				return getAlpha(
					theme.palette.busy.main,
					theme.palette.action.hoverOpacity * 2
				)
			},
			buttonHover: (theme: Theme) => ({
				transition: theme.transitions.create('background-color'),

				'&:hover': {
					backgroundColor: theme.palette.action.hover,
				},
			}),
		},

		components: {
			MuiAppBar: {
				styleOverrides: {
					colorDefault: {
						backgroundColor: baseTheme.palette.background.default,
						color: baseTheme.palette.text.primary,
					},
				},
			},
			MuiAvatar: {
				styleOverrides: {
					colorDefault: {
						color: 'white',
					},
				},
			},
			MuiButton: {
				defaultProps: {
					disableElevation: true,
					color: 'neutral',
					variant: 'contained',
				},
				styleOverrides: {
					root: {
						borderRadius: defaultBorderRadius * 10,
						fontWeight: 700,
						padding: baseTheme.spacing(0.5, 1.5),
						textTransform: 'capitalize',

						// Fix for possible material-ui bug
						flexShrink: 0,
						whiteSpace: 'nowrap',
					},
				},
				variants: [
					// {
					// 	props: { color: 'neutral' },
					// 	style: { color: baseTheme.palette.text.primary },
					// },
					{
						props: {
							variant: 'outlined',
							color: 'neutral',
						},
						style: {
							borderColor:
								baseTheme.palette.mode === 'light'
									? 'rgba(0, 0, 0, 0.23)'
									: 'rgba(255, 255, 255, 0.23)',
							color: baseTheme.palette.text.primary,

							'&.Mui-disabled': {
								border: `1px solid ${baseTheme.palette.action.disabledBackground}`,
							},
							'&:hover': {
								borderColor:
									baseTheme.palette.mode === 'light'
										? 'rgba(0, 0, 0, 0.23)'
										: 'rgba(255, 255, 255, 0.23)',
								backgroundColor: alpha(
									baseTheme.palette.text.primary,
									baseTheme.palette.action.hoverOpacity
								),
							},
						},
					},
					{
						props: {
							variant: 'text',
							color: 'neutral',
						},
						style: {
							'&:hover': {
								backgroundColor: alpha(
									baseTheme.palette.text.primary,
									baseTheme.palette.action.hoverOpacity
								),
							},
						},
					},
					{
						props: { size: 'large' },
						style: {
							fontSize: '1.0625rem',
							lineHeight: baseTheme.spacing(4),
							height: buttonSizes.large,
							padding: baseTheme.spacing(1.25, 3),
						},
					},
					{
						props: { size: 'medium' },
						style: {
							fontSize: '0.9375rem',
							lineHeight: baseTheme.spacing(3.5),
							height: buttonSizes.medium,
							padding: baseTheme.spacing(0.5, 2),
						},
					},
					{
						props: { size: 'small' },
						style: {
							fontSize: '0.875rem',
							lineHeight: baseTheme.spacing(3.5),
							height: buttonSizes.small,
							padding: baseTheme.spacing(0.125, 1.5),
						},
					},
				],
			},
			MuiLoadingButton: {
				defaultProps: {
					disableElevation: true,
					color: 'neutral',
					variant: 'contained',
				},
			},
			MuiChip: {
				defaultProps: {
					// @ts-expect-error - missing in MuiButtonProps
					color: 'neutral',
				},
				variants: [
					{
						props: {
							variant: 'outlined',
							// @ts-expect-error - missing in MuiButtonProps
							color: 'neutral',
						},
						style: {
							color: neutralColor.contrastText,
							borderColor: alpha(neutralColor.contrastText, 0.12),
							'&:hover': {
								borderColor:
									baseTheme.palette.mode === 'light'
										? neutralColor.dark
										: alpha(
												neutralColor.contrastText,
												0.12 +
													baseTheme.palette.action
														.hoverOpacity
											),
								backgroundColor: alpha(
									neutralColor.contrastText,
									baseTheme.palette.action.hoverOpacity
								),
							},
							'& .MuiChip-deleteIcon': {
								color: alpha(neutralColor.contrastText, 0.64),
								'&:hover': {
									color: neutralColor.contrastText,
								},
							},
						},
					},
				],
			},
			MuiCssBaseline: {
				defaultProps: {
					enableColorScheme: true,
				},
				styleOverrides: `
					html {
						-webkit-font-smoothing: auto;
					}
					#root {
						height: 100vh;
					}
					a {
						color: ${defaultPalette.link};
					}
				`,
			},
			MuiDialog: {
				styleOverrides: {
					paper: {
						borderRadius: defaultBorderRadiusDialog,
					},
				},
			},
			MuiDialogActions: {
				styleOverrides: {
					root: {
						padding: baseTheme.spacing(2),
					},
				},
			},
			MuiDrawer: {
				styleOverrides: {
					paper: {
						borderLeftColor: 'transparent',
					},
				},
			},
			MuiFilledInput: {
				styleOverrides: {
					root: {
						borderRadius: defaultBorderRadius,
						overflow: 'hidden',

						'&::after': {
							borderWidth: 3,
						},
					},
					underline: {
						'&::before, &:hover::before': {
							borderBottomColor: 'transparent',
						},
					},
				},
			},
			MuiIconButton: {
				defaultProps: {
					color: 'neutral',
				},
				styleOverrides: {
					root: {
						'&:hover': {
							backgroundColor: baseTheme.palette.action.hover,
						},
					},
				},
				variants: [
					{
						props: {
							color: 'primary',
							variant: 'contained',
						},
						style: {
							backgroundColor: alpha(
								defaultPalette.primary,
								0.07
							),

							'&:hover': {
								backgroundColor: alpha(
									defaultPalette.primary,
									0.11
								),
							},
						},
					},
					{
						props: { color: 'neutral' },
						style: {
							color: neutralColor.contrastText,
						},
					},
					{
						props: { variant: 'contained' },
						style: ({ ownerState, theme }) => {
							const color =
								theme.palette[ownerState.color] || neutralColor
							return {
								backgroundColor: color.main,
								color: color.contrastText,

								'&:hover': {
									backgroundColor: color.dark,
								},
							}
						},
					},
					{
						props: { variant: 'outlined' },
						style: {
							border: `1px solid ${neutralColor.main}`,

							'&:hover': {
								borderColor: neutralColor.dark,
							},
						},
					},
					{
						props: {
							color: 'neutral',
							variant: 'outlined',
						},
						style: {
							color: baseTheme.palette.text.primary,
							borderColor:
								baseTheme.palette.mode === 'light'
									? 'rgba(0, 0, 0, 0.23)'
									: 'rgba(255, 255, 255, 0.23)',
							'&.Mui-disabled': {
								border: `1px solid ${baseTheme.palette.action.disabledBackground}`,
							},
							'&:hover': {
								borderColor:
									baseTheme.palette.mode === 'light'
										? 'rgba(0, 0, 0, 0.23)'
										: 'rgba(255, 255, 255, 0.23)',
								backgroundColor: alpha(
									baseTheme.palette.text.primary,
									baseTheme.palette.action.hoverOpacity
								),
							},
						},
					},
					{
						props: { size: 'large' },
						style: {
							height: buttonSizes.large,
							padding: baseTheme.spacing(1.25),
							width: buttonSizes.large,
						},
					},
					{
						props: { size: 'medium' },
						style: {
							height: buttonSizes.medium,
							padding: baseTheme.spacing(0.75),
							width: buttonSizes.medium,
						},
					},
					{
						props: { size: 'small' },
						style: {
							height: buttonSizes.small,
							padding: baseTheme.spacing(0.125),
							width: buttonSizes.small,
						},
					},
				],
			},
			MuiInputBase: {
				styleOverrides: {
					root: {
						textDecoration: 'inherit',
					},
				},
			},
			MuiInputLabel: {
				styleOverrides: {
					root: {
						fontWeight: baseTheme.typography.fontWeightMedium,
					},
				},
			},
			MuiLink: {
				defaultProps: {
					color: 'link',
					underline: 'hover',
				},
				styleOverrides: {
					root: {
						cursor: 'pointer',
					},
				},
			},
			MuiListItem: {
				styleOverrides: {
					root: {
						'&.Mui-selected': {
							backgroundColor: baseTheme.palette.action.selected,

							'&:hover': {
								backgroundColor: baseTheme.palette.action.hover,
							},
						},
					},
				},
			},
			MuiListSubheader: {
				defaultProps: {
					disableSticky: true,
				},
				styleOverrides: {
					root: {
						lineHeight: baseTheme.spacing(4),
					},
				},
			},
			MuiMenuItem: {
				styleOverrides: {
					root: {
						'&.Mui-selected': {
							backgroundColor: baseTheme.palette.action.selected,

							'&:hover': {
								backgroundColor: baseTheme.palette.action.hover,
							},
						},
					},
				},
			},
			MuiPaper: {
				styleOverrides: {
					root: {
						backgroundImage: 'none',
					},
				},
				variants: [
					{
						props: { variant: 'outlined' },
						style: {
							backgroundColor: 'transparent',
							backdropImage: 'none',
							backdropFilter: 'none',
						},
					},
				],
			},
			MuiSkeleton: {
				defaultProps: {
					animation: 'wave',
				},
				styleOverrides: {
					text: {
						borderRadius: 4,
					},
				},
			},
			MuiTab: {
				styleOverrides: {
					root: {
						borderTopLeftRadius: baseTheme.shape.borderRadius,
						borderTopRightRadius: baseTheme.shape.borderRadius,
						color: baseTheme.palette.text.secondary,
						fontWeight: baseTheme.typography.fontWeightMedium,
						margin: 0,
						minHeight: baseTheme.spacing(4),
						minWidth: 0,
						padding: baseTheme.spacing(0.5, 1.5),
						textTransform: 'initial',
						transition: baseTheme.transitions.create('all'),

						[baseTheme.breakpoints.up('md')]: {
							minWidth: 0,
						},

						'&:hover': {
							backgroundColor: baseTheme.palette.action.hover,
						},

						'&.Mui-selected': {
							color: baseTheme.palette.text.primary,
						},

						'&.Mui-wrapper': {
							fontSize: baseTheme.typography.body2.fontSize,
							fontWeight: baseTheme.typography.fontWeightBold,
							// Fix wrapping content
							whiteSpace: 'nowrap',
						},
					},
				},
			},
			MuiTabs: {
				styleOverrides: {
					root: {
						display: 'inline-flex',
						minHeight: baseTheme.spacing(4),
					},
					indicator: {
						backgroundColor: baseTheme.palette.text.primary,
						borderTopLeftRadius: 3,
						borderTopRightRadius: 3,
						height: 3,
					},
				},
			},
			MuiTextField: {
				defaultProps: {
					color: 'secondary',
					variant: 'filled',
				},
			},
			MuiTooltip: {
				defaultProps: {
					disableInteractive: true,
					enterDelay: 400,
				},
				styleOverrides: {
					tooltip: {
						backgroundColor: '#383838',
						fontSize: 12,
					},
				},
			},
			MuiTypography: {
				defaultProps: {
					color: 'text.primary',
				},
			},
		},

		shape: {
			borderRadius: defaultBorderRadius,
			borderRadiusDialog: defaultBorderRadiusDialog,
		},

		// Custom values
		appBackgroundImage: 'none',
		featureColor: {
			archived: '#f89500', // deprecated, use palette value
			busy: defaultPalette.primary, // deprecated, use palette value
			danger: '#eb4652',
			done: '#49c666', // deprecated, use palette value
			deleted: '#eb4652', // deprecated, use palette value
			success: '#59c878',
			warning: '#f89500',
			starred: '#f0c30e', // deprecated, use palette value
			pin: '#3C3744', //picked from https://coolors.co/55a0f1-5d5cf1-3c3744-fbfff1-e4dfda
			today: '#40acf9',
			schedule: '#4fb09c',
			timer: '#eb4652',
		},
		availabilityColor: {
			green: '#59c878',
			amber: '#f89500',
			red: '#eb4652',
		},
		priorityColor: PRIORITY_COLORS,
		rightPaneWidth: {
			xs: '100%',
			sm: 64 * 5.5,
			md: 64 * 6,
			lg: 64 * 6,
			xl: 64 * 8,
		},
		contentMinWidth: 600,
		contentGutter: 8,
		contextPanelWidth: 208,
		sidebarWidth: 256,
	}
}

export default defaultStyles
