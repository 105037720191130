import { OrderedSet } from 'immutable'
import map from 'lodash/map'
import { combineReducers } from 'redux-immutable'
import { createSelector } from 'reselect'

import {
	FETCH_TEAM_FAILURE,
	FETCH_TEAM_REQUEST,
	FETCH_TEAM_SUCCESS,
} from '../actions'
import { getById } from './users-reducer'

const ids = (state = OrderedSet(), action) => {
	const userIds = OrderedSet(map(action.response, 'id'))
	switch (action.type) {
		case FETCH_TEAM_SUCCESS:
			return state.intersect(userIds).union(userIds)
		default:
			return state
	}
}

const isFetching = (state = false, action) => {
	switch (action.type) {
		case FETCH_TEAM_REQUEST:
			return true
		case FETCH_TEAM_SUCCESS:
		case FETCH_TEAM_FAILURE:
			return false
		default:
			return state
	}
}

export const team = combineReducers({
	ids,
	isFetching,
})

export const getIds = (state) => state.get('team').get('ids')
export const getIsFetching = (state) => state.get('team').get('isFetching')

export const getTeam = createSelector([getIds, getById], (ids, getById) =>
	ids.map((id) => getById.get(id))
)
