export const NEXT_STEP_INDEX = 'NEXT_STEP_INDEX'
export const PREV_STEP_INDEX = 'PREV_STEP_INDEX'
export const RESET_TOUR = 'RESET_TOUR'
export const SET_TOUR_STEP_INDEX = 'SET_TOUR_STEP_INDEX'
export const START_TOUR = 'START_TOUR'
export const STOP_TOUR = 'STOP_TOUR'

export const nextStepIndex = () => ({ type: NEXT_STEP_INDEX })
export const prevStepIndex = () => ({ type: PREV_STEP_INDEX })
export const resetTour = () => ({ type: RESET_TOUR })
export const setTourStepIndex = payload => ({
	type: SET_TOUR_STEP_INDEX,
	payload,
})
export const startTour = () => ({ type: START_TOUR })
export const stopTour = () => ({ type: STOP_TOUR })
