import {
	differenceInBusinessDays,
	differenceInCalendarDays,
	differenceInSeconds,
} from 'date-fns'

interface TimeInfoOptions {
	refDate?: Date | number
	timeTaken?: number
	includeWeekends?: boolean
	status?: 'stopped' | 'started' | 'paused'
}

export interface TimeInfo {
	daysToSubtract: number
	diff: number
	duration: number
	elapsed: number
	exceeded: number
	isOvertime: boolean
	isNearOvertime: boolean
	remaining: number
	taken: number
}

const ONE_DAY = 24 * 60 * 60

/**
 * Get time info from a starting date and duration
 *
 * @param startDate - The date the timer started
 * @param duration - The duration of the timer
 * @param options
 * @returns
 */
export const getTimeInfo = (
	startDate: Date | number | null,
	duration: number,
	{
		refDate = new Date(),
		timeTaken = 0,
		includeWeekends = false,
		status = 'stopped',
	}: TimeInfoOptions = {}
): TimeInfo => {
	const businessDaysDiff = startDate
		? differenceInBusinessDays(refDate, startDate)
		: 0
	const calendarDaysDiff = startDate
		? differenceInCalendarDays(refDate, startDate)
		: 0
	const daysToSubtract = includeWeekends
		? 0
		: Math.max(0, calendarDaysDiff - businessDaysDiff)

	const diff = startDate
		? differenceInSeconds(refDate, startDate) - daysToSubtract * ONE_DAY
		: 0
	const taken = timeTaken

	// If no date is provided, the task may be paused.
	const elapsed = status !== 'started' || isNaN(diff) ? taken : taken + diff

	return {
		daysToSubtract,
		diff,
		duration,
		elapsed,
		exceeded: elapsed - duration,
		isOvertime: elapsed - duration > 0,
		isNearOvertime: elapsed > duration * (2 / 3),
		remaining: duration - elapsed,
		taken,
	}
}

export default getTimeInfo
