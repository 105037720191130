import { MutationObserver, QueryClient } from '@tanstack/react-query'
import produce from 'immer'

import {
	appendToPagePaginatedResult,
	createEmptyPagePaginatedResult,
} from '../../../api-adapter/api-adapter-utils'
import { createMutationErrorRollback } from '../../mutations/mutations-utils'
import { StoreContext } from '../../store-types'
import { chatKeys } from '../chatKeys'
import { createAddChatRoomMutationFn } from '../chatQueries'
import {
	ChatRoom,
	ChatRoomResultDetail,
	ChatRoomResultList,
} from '../chatTypes'

export const createAddChatRoomOptimisticHandler =
	(queryClient: QueryClient) => async (chatRoom: ChatRoom) => {
		const roomDetailKey = chatKeys.roomDetail(chatRoom.id)
		const roomListKey = chatKeys.roomList()
		const previousResult =
			queryClient.getQueryData<ChatRoomResultList>(roomListKey)
		await queryClient.cancelQueries(roomListKey)

		queryClient.setQueryData<ChatRoomResultDetail>(roomDetailKey, chatRoom)
		queryClient.setQueryData<ChatRoomResultList>(
			roomListKey,
			produce((draft) => {
				if (!draft || !draft.items) {
					draft = createEmptyPagePaginatedResult<ChatRoom>()
				}
				const existingRoom = draft.items.find(
					(room) => room.id === chatRoom.id
				)
				if (!existingRoom) {
					appendToPagePaginatedResult(draft, chatRoom)
				}
			})
		)
		return { previousResult: previousResult, queryKey: roomListKey }
	}

export const createAddChatRoomMutationObserver = ({
	apiAdapter,
	queryClient,
}: Pick<StoreContext, 'apiAdapter' | 'queryClient'>) =>
	new MutationObserver(queryClient, {
		mutationFn: createAddChatRoomMutationFn(apiAdapter),
		// Need to wait for the api to respond with an ID before we can update the cache
		// onMutate: createAddChatRoomOptimisticHandler(queryClient),
		onError: createMutationErrorRollback(queryClient),
	})
