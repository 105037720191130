export const SET_MAKE_A_COPY_DESCENDANTS = 'SET_MAKE_A_COPY_DESCENDANTS'
export const SET_MAKE_A_COPY_TASK = 'SET_MAKE_A_COPY_TASK'
export const TOGGLE_MAKE_A_COPY = 'TOGGLE_MAKE_A_COPY'

export const setMakeACopyDescendants = tasks => ({
	type: SET_MAKE_A_COPY_DESCENDANTS,
	payload: tasks,
})

export const setMakeACopyTask = id => ({ type: SET_MAKE_A_COPY_TASK, id })

export const toggleMakeACopy = isVisible => ({
	type: TOGGLE_MAKE_A_COPY,
	isVisible,
})
