import { QueryFunction } from '@tanstack/react-query'
import { parseISO } from 'date-fns'

import {
	ApiTaskActivity,
	TaskActivity,
	TaskActivityUnknown,
	WrappedTaskActivityV2,
} from '../../task-activity'
import { ApiAdapter, ApiListResult } from '../api'
import { TaskActivityKeysList } from './taskActivityKeys'

export const convertTaskActivityFromApiToV2 = (
	activity: ApiTaskActivity
): WrappedTaskActivityV2 => {
	const data: TaskActivity | TaskActivityUnknown = {
		type: activity.type,
		...activity.descrJson,
	}
	if (activity.version !== 2) {
		data.type = 'unknown'
		data['original'] = activity
	}
	return {
		dateCreated: parseISO(activity.dateCreated),
		descr: activity.descr,
		data,
		hasPermission: activity.hasPermission,
		id: activity.id,
		reactions: activity.reactions,
		taskId: activity.taskId,
		userId: activity.userId,
		version: 2,
	}
}

export const createFetchTaskActivityQueryFn =
	(
		apiAdapter: ApiAdapter
	): QueryFunction<
		ApiListResult<WrappedTaskActivityV2>,
		TaskActivityKeysList
	> =>
	async ({ queryKey, pageParam = {}, signal }) => {
		const [, , taskId, , , params] = queryKey
		const results = await apiAdapter.tasks.getActivityV2(
			taskId,
			params ? { ...params, ...pageParam } : pageParam,
			{ signal }
		)

		return {
			...results,
			items: results.items.map(convertTaskActivityFromApiToV2),
		}
	}
