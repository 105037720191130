import { Map, fromJS } from 'immutable'

import { SET_INVITE_TYPE, TOGGLE_INVITE } from '../actions'

const initialState = Map({
	isVisible: false,
	inviteType: 'guest',
	email: '',
	task: null,
	taskId: null,
})

export const invite = (state = initialState, action) => {
	switch (action.type) {
		case SET_INVITE_TYPE:
			return state.merge(
				fromJS({
					inviteType: action.data.inviteType,
					email: action.data.email,
					task: action.data.task,
					taskId: action.data.task?.id,
				})
			)

		case TOGGLE_INVITE:
			return state.set(
				'isVisible',
				typeof action.isVisible === 'boolean'
					? action.isVisible
					: !state.get('isVisible')
			)

		default:
			return state
	}
}

export const getIsVisible = state => state.get('invite').get('isVisible')
