import { z } from 'zod'

const RawDraftEntitySchema = z.object({
	type: z.string(),
	mutability: z.enum(['MUTABLE', 'IMMUTABLE', 'SEGMENTED']),
	data: z.record(z.string(), z.any()),
})

const DraftBlockTypeSchema = z.enum([
	'header-one',
	'header-two',
	'header-three',
	'header-four',
	'header-five',
	'header-six',
	'section',
	'article',
	'unordered-list-item',
	'ordered-list-item',
	'blockquote',
	'atomic',
	'code-block',
	'unstyled',
])

const RawDraftInlineStyleRangeSchema = z.array(
	z.object({
		style: z.enum(['BOLD', 'CODE', 'ITALIC', 'STRIKETHROUGH', 'UNDERLINE']),
		offset: z.number(),
		length: z.number(),
	})
)
const RawDraftEntityRangeSchema = z.array(
	z.object({ key: z.number(), offset: z.number(), length: z.number() })
)

const RawDraftContentBlockSchema = z.object({
	key: z.string(),
	type: DraftBlockTypeSchema.or(z.string()),
	text: z.string(),
	depth: z.number(),
	inlineStyleRanges: RawDraftInlineStyleRangeSchema,
	entityRanges: RawDraftEntityRangeSchema,
	data: z.record(z.string(), z.any()).optional(),
})

export const RawDraftContentStateSchema = z.object({
	blocks: z.array(RawDraftContentBlockSchema),
	entityMap: z.record(z.string(), RawDraftEntitySchema),
})
