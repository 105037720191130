export type RecentsKeysAll = ['recents']
export type RecentsKeysUserProjects = ['recents', 'projects', string]
export type RecentsKeysViewedProjects = ['recents', 'projects', 'viewed']

type RecentsKeys = {
	all: RecentsKeysAll
	userProjects: (userId: string) => RecentsKeysUserProjects
	viewedProjects: () => RecentsKeysViewedProjects
}

export const recentsKeys: RecentsKeys = {
	all: ['recents'],
	userProjects: (userId: string) => [...recentsKeys.all, 'projects', userId],
	viewedProjects: () => [...recentsKeys.all, 'projects', 'viewed'],
}
