import { mergeDeepRight, mergeWith } from 'ramda'

import { Task } from '../types'

// Declaring toString to avoid TS error from the mailbot. Seems like it doesn't
// think this exists in the node scope. The definition seems to point to the dom
// scope. The dna would ideally not include the dom scope in the future to be
// more environment agnostic.
declare function toString(): string

export const mergeTask = (source: Task, changes: Partial<Task>): Task =>
	mergeWith(
		(srcField, changeField) => {
			if (Array.isArray(srcField)) {
				return changeField
			}

			if (
				toString.call(srcField) === '[object Object]' &&
				toString.call(changeField) === '[object Object]'
			) {
				return mergeDeepRight(srcField, changeField)
			}
			return changeField
		},
		source,
		changes
	)

export default mergeTask
