import axios, { AxiosInstance, RawAxiosRequestConfig } from 'axios'
import { RawDraftContentState } from 'draft-js'
import Stripe from 'stripe'

import { createEmptyPagePaginatedResult } from '../../api-adapter/api-adapter-utils'
import { PaginatedList } from '../../helpers/data-containers'
import { Mention } from '../../helpers/mentions'
import { createEmptySectionIndex } from '../../helpers/sections'
import {
	ApiTaskActivity,
	TaskActivity,
	TaskActivityRequestGetQuery,
	WrappedTaskActivityV2,
} from '../../task-activity'
import { TaskTimeEntry } from '../../taskTime/timeModels'
import { isObject } from '../../type-guards'
import {
	Attachment,
	BillingDetails,
	BillingInvoice,
	BillingPlan,
	CommentLog,
	FileOrMobileFile,
	ObjectIndex,
	Organisation,
	PlayerOptions,
	Section,
	SectionId,
	Task,
	TaskFollower,
	TaskParent,
	TaskPosition,
	TaskReminder,
	TaskTimer,
	User,
	UserFocus,
	VideoCallStatusUser,
	Workflow,
} from '../../types'
import {
	ChatAllMessagesParams,
	ChatArchiveRoomParams,
	ChatMessage,
	ChatRoom,
	ChatRoomMessagesParams,
	SendChatMessageResult,
} from '../chat/chatTypes'

const chatRoomStub: ChatRoom = {
	id: 'chat_room_1',
	dateCreated: new Date().toISOString(),
	isAdmin: false,
	isArchived: false,
	lastReadDate: new Date().toISOString(),
	name: 'Room 1',
	roomType: 'user',
	lastMessage: {
		id: 'chat_message_1',
		chatRoomId: 'chat_room_1',
		dateCreated: new Date().toISOString(),
		files: [],
		message: 'test',
		replyToId: null,
		type: 'message' as const,
		userId: 'user_1',
		replyMessage: null,
	},
	unreadMessages: [],
	users: [],
}

const resultListStub = createEmptyPagePaginatedResult()

const paginatedStub = {
	count: 0,
	cursor: new Date().toISOString(),
	hasMore: false,
	items: [],
	limit: 30,
}

const playerEmailStub: PlayerEmail = {
	control: 0,
	dateCreated: '',
	email: '',
	isDefault: false,
	verified: false,
}

const sendChatMessageStub: SendChatMessageResult = {
	message: {
		id: 'chat_message_1',
		chatRoomId: 'chat_room_1',
		dateCreated: new Date().toISOString(),
		files: [],
		message: 'test',
		replyToId: null,
		type: 'message' as const,
		userId: 'user_1',
		replyMessage: null,
	},
	reference: null,
	room: chatRoomStub,
}

export interface ApiChatFile {
	contentType: string
	filename: string
	id: string
	lastUpdated: string
	totalBytes: number
	chatMessageId: string
	type: 'chat'
}

export interface ApiTaskFile {
	contentType: string
	filename: string
	id: string
	lastUpdated: string
	totalBytes: number
	taskId: string
	type: 'task'
}

export type ApiFile = ApiChatFile | ApiTaskFile

export interface BillingDetailsChanges {
	address?: {
		country?: string
		city?: string
		line1?: string
		line2?: string
		postalCode?: string
		state?: string
	}
	name?: string
	email?: string
}

export interface TaskApiFile {
	contentType: string
	filename: string
	id: string
	lastUpdated: string
	taskId: string
	totalBytes: number
	type: 'task'
}

export interface LoginResponse {
	token: string
	user?: User
}
export interface LoginError {
	error: Error
	message?: string
}

export interface MoveParams {
	parentId: string | null
	position: number | string[]
}
export interface PlayerEmail {
	control: number
	dateCreated: string
	email: string
	isDefault: boolean
	verified: boolean
}

export interface TaskTimeResult {
	currentTimer: TaskTimer
	hoursTaken: number
	stoppedTaskId: string
	stoppedTimerId: string
	stoppedTimerSecondsTaken: number
	taskId: string
	taskOwnerId: string
}
export interface MoveResult {
	destParent: {
		id: string
		childSortOrder: string[]
	}
	srcParent: {
		id: string
		childSortOrder: string[]
	}
	task: {
		id: string
		parentId: string
		parents: TaskParent[]
	}
}

export interface RemoveResponse {
	success: boolean
}

export type VideoCallStatusResult = VideoCallStatusUser[]

export interface ApiChatMessage extends Omit<ChatMessage, 'files'> {
	files: ApiFile[]
}

export interface ApiListResult<T> {
	items: T[]
	page: number
	pageSize: number
	hasMore: boolean
	count: number
}

export interface ApiAdapter {
	apiInstance: AxiosInstance
	debug: {
		simulateErrorResponse: () => Promise<unknown>
	}
	auth: {
		login: (
			email: string,
			password: string,
			config?: RawAxiosRequestConfig
		) => Promise<LoginResponse>
	}
	billing: {
		cancelSubscription: (config?: RawAxiosRequestConfig) => Promise<unknown>
		getDetails: (config?: RawAxiosRequestConfig) => Promise<BillingDetails>
		getInvoices: (
			params?: InvoicesListParams,
			config?: RawAxiosRequestConfig
		) => Promise<ApiListResult<BillingInvoice>>
		getPlan: (config?: RawAxiosRequestConfig) => Promise<BillingPlan>
		getSavedPaymentMethods: (
			config?: RawAxiosRequestConfig
		) => Promise<Stripe.PaymentMethod[]>
		removePaymentMethod: (
			paymentMethodId: string,
			config?: RawAxiosRequestConfig
		) => Promise<unknown>
		setDefaultPaymentMethod: (
			paymentMethodId: string,
			config?: RawAxiosRequestConfig
		) => Promise<unknown>
		updateDetails: (
			changes: BillingDetailsChanges,
			config?: RawAxiosRequestConfig
		) => Promise<unknown>
		updatePlan: (
			payload: {
				currency: 'usd' | 'eur' | 'gbp' | 'zar'
				planId: string
				period: 'monthly' | 'yearly'
			},
			config?: RawAxiosRequestConfig
		) => Promise<unknown>
	}
	chat: {
		addMessageReaction: (
			messageId: string,
			reaction: string,
			config?: RawAxiosRequestConfig
		) => Promise<unknown>
		removeMessageReaction: (
			messageId: string,
			reaction: string,
			config?: RawAxiosRequestConfig
		) => Promise<unknown>
		archiveRoom: (
			roomId: string,
			params?: ChatArchiveRoomParams,
			config?: RawAxiosRequestConfig
		) => Promise<unknown>
		addRoom: (
			chatRoom: { name?: string | void; users: string[] },
			config?: RawAxiosRequestConfig
		) => Promise<Pick<ChatRoom, 'id' | 'name' | 'users' | 'roomType'>>
		updateRoom: (
			roomId: string,
			changes: Pick<ChatRoom, 'name' | 'users'>,
			config?: RawAxiosRequestConfig
		) => Promise<Pick<ChatRoom, 'id' | 'name' | 'users' | 'roomType'>>
		getAllMessages: (
			params?: ChatAllMessagesParams,
			config?: RawAxiosRequestConfig
		) => Promise<ApiListResult<ChatMessage>>
		getRoomMessages: (
			roomId: string,
			params?: ChatRoomMessagesParams,
			config?: RawAxiosRequestConfig
		) => Promise<ApiListResult<ChatMessage>>
		getRooms: (
			config?: RawAxiosRequestConfig
		) => Promise<ApiListResult<ChatRoom>>
		sendMessage: (
			roomId: string,
			message: Omit<ChatMessage, 'replyMessage'>,
			config?: RawAxiosRequestConfig
		) => Promise<SendChatMessageResult>
		sendMessageFiles: (
			messageId: string,
			files: FileOrMobileFile[],
			config?: RawAxiosRequestConfig
		) => Promise<Attachment[]>
		typing: (
			roomId: string,
			config?: RawAxiosRequestConfig
		) => Promise<unknown>
		updateLastReadDate: (
			roomId: string,
			lastReadDate: string,
			config?: RawAxiosRequestConfig
		) => Promise<unknown>
	}
	player: {
		addUserEmail: (
			email: string,
			config?: RawAxiosRequestConfig
		) => Promise<PlayerEmail>
		calendarSyncGoogle: (
			config?: RawAxiosRequestConfig
		) => Promise<{ url?: string }>
		defaultUserEmail: (
			control: number,
			config?: RawAxiosRequestConfig
		) => Promise<unknown>
		deleteUserEmail: (
			control: number,
			config?: RawAxiosRequestConfig
		) => Promise<unknown>
		getEmails: (config?: RawAxiosRequestConfig) => Promise<PlayerEmail[]>
		getMentions: (config?: RawAxiosRequestConfig) => Promise<Mention[]>
		getOrganisation: (
			config?: RawAxiosRequestConfig
		) => Promise<Organisation>
		getRecentlyViewedProjects: (
			params?: { limit: number },
			config?: RawAxiosRequestConfig
		) => Promise<string[]>
		resendEmailVerification: (
			control: number,
			config?: RawAxiosRequestConfig
		) => Promise<unknown>
		resolveMention: (
			mentionId: number,
			config?: RawAxiosRequestConfig
		) => Promise<unknown>
		resolveAllMentions: (config?: RawAxiosRequestConfig) => Promise<boolean>
		update: (
			changes: Partial<User>,
			config?: RawAxiosRequestConfig
		) => Promise<unknown>
	}
	reports: {
		getProjectTimesheet: (
			taskId: string,
			params?: { endDate?: string; startDate?: string },
			config?: RawAxiosRequestConfig
		) => Promise<{
			data: Record<string, string>[]
			endDate: string
			startDate: string
		}>
	}
	settings: {
		mergeUser: (
			mergeEmail: string,
			config?: RawAxiosRequestConfig
		) => Promise<unknown>
	}
	tasks: {
		add: (
			task: { title: string } & Partial<Task>,
			position?: TaskPosition,
			config?: RawAxiosRequestConfig
		) => Promise<ApiResult<Task>>
		addActivity: (
			taskActivity: WrappedTaskActivityV2
		) => Promise<ApiResult<ApiTaskActivity>>
		addActivityReaction: (
			taskId: string,
			activityId: string,
			reactionType: string
		) => Promise<ApiResult<ApiTaskActivity>>
		addFollower: (
			taskId: string,
			follower: TaskFollower,
			config?: RawAxiosRequestConfig
		) => Promise<ApiResult<TaskFollower[]>>
		addMultiple: (
			tasks: Partial<Task>[],
			config?: RawAxiosRequestConfig
		) => Promise<ApiResult<Task[]>>
		getActivity: (
			taskId: string,
			params?: { type: string },
			config?: RawAxiosRequestConfig
		) => Promise<ApiListResult<ApiTaskActivity>>
		getActivityV2: (
			taskId: string,
			params?: TaskActivityRequestGetQuery,
			config?: RawAxiosRequestConfig
		) => Promise<ApiListResult<ApiTaskActivity>>
		removeActivityReaction: (
			taskId: string,
			activityId: string,
			reactionType: string
		) => Promise<ApiResult<TaskActivity>>
		getDetail: (
			taskId: string,
			config?: RawAxiosRequestConfig
		) => Promise<ApiResult<Task>>
		getFiles: (
			taskId: string,
			config?: RawAxiosRequestConfig
		) => Promise<Attachment[]>
		getList: (
			params: TaskListParams,
			config?: RawAxiosRequestConfig
		) => Promise<ApiListResult<Task> & { privateTaskCount: number }>
		getListOfAllUsersTasks: (
			userId: string,
			config?: RawAxiosRequestConfig
		) => Promise<{ tasks: Task[] }>
		getReminders: (
			taskId: string,
			config?: RawAxiosRequestConfig
		) => Promise<TaskReminder[]>
		getTimesList: (
			taskId: string,
			params?: { cursor: string; limit: number },
			config?: RawAxiosRequestConfig
		) => Promise<PaginatedList<TaskTimeEntry>>
		getTimesGroupedList: (
			taskId: string,
			params?: { cursor: string; limit: number },
			config?: RawAxiosRequestConfig
		) => Promise<PaginatedList<[string, TaskTimeEntry[]]>>
		markAsDone: (
			taskId: string,
			commentData?: {
				draftModel: RawDraftContentState
				html: string
			},
			config?: RawAxiosRequestConfig
		) => Promise<ApiResult<Task>>
		move: (taskId: string, params: MoveParams) => Promise<MoveResult | null>
		moveBulk: (taskIds: string[], params: MoveParams) => Promise<unknown>
		remove: (
			taskId: string,
			config?: RawAxiosRequestConfig
		) => Promise<ApiResult<Task>>
		removeFollower: (
			taskId: string,
			followerId: string,
			config?: RawAxiosRequestConfig
		) => Promise<unknown>
		removeReminder: (
			taskId: string,
			config?: RawAxiosRequestConfig
		) => Promise<TaskReminder>
		setReminder: (
			taskId: string,
			date: Date,
			config?: RawAxiosRequestConfig
		) => Promise<TaskReminder>
		update: (
			taskId: string,
			changes: Partial<Task>,
			config?: RawAxiosRequestConfig
		) => Promise<ApiResult<Task>>
		updateBulk: (
			taskIds: string[],
			changes: Partial<Task>,
			config?: RawAxiosRequestConfig
		) => Promise<ApiResult<Task>>
		updateTimer: (
			taskId: string,
			currentTimer: TaskTimer
		) => Promise<ApiResult<TaskTimeResult>>
		uploadMultipleFiles: (
			taskId: string,
			attachments: Attachment[],
			config?: RawAxiosRequestConfig
		) => Promise<ApiFile[]>
		removeFile: (
			taskId: string,
			fileId: string,
			config?: RawAxiosRequestConfig
		) => Promise<{ success: boolean }>
		updateFile: (
			taskId: string,
			fileId: string,
			changes: { name: string },
			config?: RawAxiosRequestConfig
		) => Promise<ApiResult<Attachment>>
		fetchTaskLog: (
			logId: string,
			config?: RawAxiosRequestConfig
		) => Promise<ApiResult<CommentLog>>
	}
	users: {
		deleteAccount: (
			config?: RawAxiosRequestConfig
		) => Promise<{ success: boolean }>
		disableUser: (
			userId: string,
			config?: RawAxiosRequestConfig
		) => Promise<{ success: boolean }>
		getActivity: (
			userId: string,
			params: UserActivityListParams,
			config?: RawAxiosRequestConfig
		) => Promise<ApiListResult<ApiTaskActivity>>
		getDetail: (
			userId: string,
			config?: RawAxiosRequestConfig
		) => Promise<ApiResult<User>>
		getList: (
			params?: UserListParams,
			config?: RawAxiosRequestConfig
		) => Promise<ApiListResult<User>>
		getOptions: (
			userId: string,
			optionIds: string[],
			config?: RawAxiosRequestConfig
		) => Promise<Partial<PlayerOptions>>
		getSections: (
			userId: string,
			config?: RawAxiosRequestConfig
		) => Promise<SectionsResult>
		getUsersFocus: (
			config?: RawAxiosRequestConfig
		) => Promise<ObjectIndex<UserFocus>>
		getVideoCallStatus: (
			config?: RawAxiosRequestConfig
		) => Promise<VideoCallStatusResult>
		setUsersFocus: (
			userFocus: UserFocus,
			config?: RawAxiosRequestConfig
		) => Promise<UserFocus | ObjectIndex<UserFocus>>
		updateOptions: (
			userId: string,
			data: Partial<PlayerOptions>,
			config?: RawAxiosRequestConfig
		) => Promise<ApiResult<PlayerOptions>>
		updateSection: (
			userId: string,
			sectionId: string,
			changes: Partial<Section>,
			config?: RawAxiosRequestConfig
		) => Promise<ApiResult<Section>>
	}
	workflows: {
		add: (
			workflow: Workflow,
			config?: RawAxiosRequestConfig
		) => Promise<ApiResult<Workflow>>
		getList: (
			params?: WorkflowListParams,
			config?: RawAxiosRequestConfig
		) => Promise<ApiListResult<Workflow>>
		remove: (
			workflowId: string,
			config?: RawAxiosRequestConfig
		) => Promise<ApiResult<Workflow>>
		update: (
			workflowId: string,
			changes: Partial<Workflow>,
			config?: RawAxiosRequestConfig
		) => Promise<ApiResult<Workflow>>
	}
}

export type ApiTaskListResult<T = string> = ApiListResult<T> & {
	privateTaskCount: number
}

// TODO: api result would be ideal to have data and error fields.
export type ApiResult<T> = T | null

export interface InvoicesListParams {
	page?: number
	pageSize?: number
}

export type SectionsResultItem = Pick<
	Section,
	'id' | 'manualSortOrder' | 'sortType' | 'title'
>
export interface SectionsResult {
	byId: {
		[S in SectionId]: SectionsResultItem
	}
	displayOrder: SectionId[]
}

export interface TaskListParams {
	ancestorId?: string | null
	assigneeId?: string | null
	filterIds?: string
	hasChildren?: boolean
	homeOrphans?: boolean
	inactiveFrom?: string | null
	includeRoot?: boolean
	isMission?: boolean
	isProject?: boolean
	ownerId?: string
	page?: number
	pageSize?: number
	parentId?: string | null
	search?: string
	showDoneFrom?: string
	startDateFrom?: string
	startDateUntil?: string
	statusCodes?: 'all' | 'active' | 'inactive' | string
	updatedAfter?: string
}

export interface UserListParams {
	isActive?: boolean
	isOnline?: boolean
	page?: number
	pageSize?: number
}

export interface UserActivityListParams {
	page?: number
	pageSize?: number
}

export interface WorkflowListParams {
	page?: number
	pageSize?: number
}

export const isApiListResult = <T>(data: unknown): data is ApiListResult<T> =>
	isObject(data) &&
	'items' in data &&
	Array.isArray(data.items) &&
	'count' in data &&
	typeof data.count === 'number' &&
	'page' in data &&
	typeof data.page === 'number' &&
	'pageSize' in data &&
	typeof data.pageSize === 'number' &&
	'hasMore' in data &&
	typeof data.hasMore === 'boolean'

export const baseApiAdapter: ApiAdapter = {
	apiInstance: axios.create(),
	debug: {
		simulateErrorResponse: async () => null,
	},
	auth: {
		login: async () => ({ token: '' }),
	},
	billing: {
		cancelSubscription: async () => null,
		getDetails: async () => ({}) as unknown as Promise<BillingDetails>,
		getInvoices: async () => ({ ...resultListStub }),
		getPlan: async () => ({}) as unknown as Promise<BillingPlan>,
		getSavedPaymentMethods: async () =>
			[] as unknown as Promise<Stripe.PaymentMethod[]>,
		removePaymentMethod: async () => null,
		setDefaultPaymentMethod: async () => null,
		updateDetails: async () => null,
		updatePlan: async () => null,
	},
	chat: {
		addMessageReaction: async () => null,
		addRoom: async () => ({ ...chatRoomStub }),
		updateRoom: async () => ({ ...chatRoomStub }),
		removeMessageReaction: async () => null,
		archiveRoom: async () => null,
		getAllMessages: async () => ({ ...resultListStub }),
		getRoomMessages: async () => ({ ...resultListStub }),
		getRooms: async () => ({ ...resultListStub }),
		sendMessage: async () => ({ ...sendChatMessageStub }),
		sendMessageFiles: async () => [],
		typing: async () => null,
		updateLastReadDate: async () => null,
	},
	player: {
		addUserEmail: async () => ({ ...playerEmailStub }),
		calendarSyncGoogle: async () => ({}),
		defaultUserEmail: async () => null,
		deleteUserEmail: async () => null,
		getMentions: async () => null,
		getEmails: async () => [],
		getOrganisation: async () => ({}) as unknown as Organisation,
		getRecentlyViewedProjects: async () => [],
		resendEmailVerification: async () => null,
		resolveMention: async () => null,
		resolveAllMentions: async () => null,
		update: async () => null,
	},
	reports: {
		getProjectTimesheet: async () => ({
			data: [],
			endDate: '',
			startDate: '',
		}),
	},
	settings: {
		mergeUser: async () => null,
	},
	tasks: {
		add: async () => null,
		addActivity: async () => null,
		addActivityReaction: async () => null,
		addFollower: async () => null,
		addMultiple: async () => null,
		getActivity: async () => ({ ...resultListStub }),
		getActivityV2: async () => ({ ...resultListStub }),
		removeActivityReaction: async () => null,
		getDetail: async () => null,
		getFiles: async () => [],
		getList: async () => ({
			...resultListStub,
			privateTaskCount: 0,
		}),
		getListOfAllUsersTasks: async () => ({ tasks: [] }),
		getReminders: async () => null,
		getTimesList: async () => ({ ...paginatedStub }),
		getTimesGroupedList: async () => ({ ...paginatedStub }),
		markAsDone: async () => null,
		move: async () => null,
		moveBulk: async () => null,
		remove: async () => null,
		removeFollower: async () => null,
		removeReminder: async () => null,
		setReminder: async () => null,
		update: async () => null,
		updateBulk: async () => null,
		updateTimer: async () => null,
		uploadMultipleFiles: async () => null,
		updateFile: async () => null,
		removeFile: async () => ({ success: true }),
		fetchTaskLog: async () => null,
	},
	users: {
		deleteAccount: async () => ({ success: true }),
		disableUser: async () => ({
			success: true,
			data: { userId: '', updatedTasksCount: 0 },
		}),
		getActivity: async () => ({ ...resultListStub }),
		getDetail: async () => null,
		getList: async () => ({ ...resultListStub }),
		getOptions: async () => ({}),
		getSections: async () => ({
			byId: createEmptySectionIndex(),
			displayOrder: [],
		}),
		getUsersFocus: async () => ({}),
		getVideoCallStatus: async () => [],
		setUsersFocus: async () => ({}),
		updateOptions: async () => null,
		updateSection: async () => null,
	},
	workflows: {
		add: async () => null,
		getList: async () => ({ ...resultListStub }),
		remove: async () => null,
		update: async () => null,
	},
}
