export const env = (variableName: string, defaultValue = '') => {
	// For vite projects, process.env needs to be defined in the config.
	// Trying to use import.meta here will create issues with other projects (like mobile).
	return (
		(variableName === 'NODE_ENV' && process.env['NODE_ENV']) ||
		process.env[`NX_${variableName}`] ||
		process.env[`VITE_${variableName}`] ||
		process.env[`EXPO_PUBLIC_${variableName}`] ||
		process.env[variableName] ||
		defaultValue
	)
}
