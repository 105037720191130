import { isAfter, parseISO, sub } from 'date-fns'
import { merge } from 'remeda'

import { User } from '../types'
import { createUserId } from './createId'

export const getDefaultUser = (): User => ({
	id: createUserId(),
	active: 1,
	email: '',
	gravatar: '',
	avatar: {
		type: 'letters',
		data: {
			userId: '',
			name: '',
			nickname: '',
		},
	},
	name: '',
	nickname: '',
	currentScore: 0,
	currentTaskId: null,
	currentTaskStartDate: null,
	countryCode: '',
	isStarred: false,
	lastActivityDate: null,
	organisationId: '',
	timeZone: '',
	userGroups: [],
})

export const createBaseUser = (initialUser: Partial<User> = {}) =>
	merge(getDefaultUser(), initialUser)

export const getUserName = (user?: Pick<User, 'name' | 'nickname'>) => {
	if (!user) {
		return ''
	}
	return user.nickname || user.name || ''
}

export const getUserNameList = (users: Pick<User, 'name' | 'nickname'>[]) =>
	users.map(getUserName).join(', ')

export const isRecentlyActive = (user: Pick<User, 'lastActivityDate'>) => {
	const lastActivityDate = user.lastActivityDate
	const threshold = sub(new Date(), { months: 2 })

	return lastActivityDate
		? isAfter(parseISO(lastActivityDate), threshold)
		: false
}
