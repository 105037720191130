import { fromJS, List, Map, Set } from 'immutable'
import map from 'lodash/fp/map'
import size from 'lodash/size'
import { combineReducers } from 'redux-immutable'
import { createSelector } from 'reselect'

import {
	ADD_FOLLOWER,
	CLEAR_USER_VIEW_TASKS,
	FETCH_TASKS_FAILURE,
	FETCH_TASKS_REQUEST,
	FETCH_TASKS_SUCCESS,
	FETCH_USER_VIEW_TASKS_FAILURE,
	FETCH_USER_VIEW_TASKS_REQUEST,
	FETCH_USER_VIEW_TASKS_SUCCESS,
	MERGE_TASK,
	MERGE_TASKS,
	PUSH_TASK_TO_USER_VIEW,
	REMOVE_FOLLOWER,
	REMOVE_TASK,
	SET_TASK,
	//SET_TASK_FILES,
	SHALLOW_MERGE_TASK,
	TOGGLE_TASK_TIME_MODAL,
} from '../actions'
import { validateTask } from '../utils'

const updateTask = (byIdState, task, overwriteArrays) => {
	let newTask = fromJS(validateTask(task))
	const rewriteProps = [
		'followers',
		'childSortOrder',
		'parents',
		'tags',
		'workflowData',
		'descrJson',
	]

	const emptyArrayProps = ['followers']

	return byIdState.update(task.id, Map(), (oldTask) => {
		let prunedTask = newTask
		for (let i = 0; i < size(rewriteProps); i++) {
			const prop = rewriteProps[i]
			prunedTask = prunedTask.delete(prop)
		}
		//updateQueryCache(oldTask.toJS(), prunedTask.toJS())
		let updateTask = oldTask.mergeDeep(prunedTask)
		rewriteProps.forEach((prop) => {
			if (newTask.has(prop) && newTask.get(prop)) {
				// some calls will have empty arrays for followers
				if (
					!overwriteArrays &&
					emptyArrayProps.includes(prop) &&
					!newTask.get(prop).size &&
					updateTask.has(prop) &&
					updateTask.get(prop).size
				) {
					return
				}
				updateTask = updateTask.set(prop, newTask.get(prop))
			}
		})

		return updateTask
	})
}

export const mergeTaskFromState = updateTask

const byId = (state = Map(), action) => {
	switch (action.type) {
		case FETCH_TASKS_SUCCESS:
		case FETCH_USER_VIEW_TASKS_SUCCESS:
		case MERGE_TASKS:
			return state.withMutations((state) => {
				for (const task of action.payload) {
					updateTask(state, task, false)
				}
			})

		case SET_TASK:
			return state.set(action.task.id, fromJS(validateTask(action.task)))

		case MERGE_TASK: {
			const newState = updateTask(state, action.task, true)
			return state.equals(newState) ? state : newState
		}
		case ADD_FOLLOWER:
			return state.updateIn(
				[action.payload.taskId, 'followers'],
				List(),
				(followers) =>
					followers.push(
						Map({
							id: action.payload.followerId,
							roles: 'follower',
							isVirtual: false,
						})
					)
			)

		case REMOVE_FOLLOWER:
			return state.updateIn(
				[action.payload.taskId, 'followers'],
				(followers) =>
					followers.filter(
						(follower) =>
							follower.get('id') !== action.payload.followerId
					)
			)

		case SHALLOW_MERGE_TASK:
			return state.update(action.payload.id, Map(), (t) =>
				t.merge(fromJS(validateTask(action.payload)))
			)

		case REMOVE_TASK:
			return state.delete(action.payload)

		default:
			return state
	}
}

const userViewIds = (state = Set(), action) => {
	switch (action.type) {
		case CLEAR_USER_VIEW_TASKS:
			return state.clear()

		case FETCH_USER_VIEW_TASKS_SUCCESS: {
			const taskIds = fromJS(map((task) => task.id)(action.payload))
			return Set(taskIds)
		}

		case PUSH_TASK_TO_USER_VIEW:
			return state.add(action.taskId)

		default:
			return state
	}
}

const isFetching = (state = false, action) => {
	switch (action.type) {
		case FETCH_TASKS_REQUEST:
			return true
		case FETCH_TASKS_SUCCESS:
		case FETCH_TASKS_FAILURE:
			return false
		default:
			return state
	}
}

const fetchUserViewTasksFor = (state = null, action) => {
	switch (action.type) {
		case FETCH_USER_VIEW_TASKS_REQUEST:
			return action.payload
		default:
			return state
	}
}

const isFetchingUserViewTasks = (state = true, action) => {
	switch (action.type) {
		case FETCH_USER_VIEW_TASKS_REQUEST:
			return true
		case FETCH_USER_VIEW_TASKS_SUCCESS:
		case FETCH_USER_VIEW_TASKS_FAILURE:
			return false
		default:
			return state
	}
}

export const timeEditModal = (
	state = Map({ taskId: null, timerId: null }),
	action
) => {
	switch (action.type) {
		case TOGGLE_TASK_TIME_MODAL:
			return state.merge({
				timerId: action.timerId,
				taskId: action.taskId,
			})
		default:
			return state
	}
}

export const tasks = combineReducers({
	byId,
	userViewIds,
	isFetching,
	isFetchingUserViewTasks,
	fetchUserViewTasksFor,
	timeEditModal,
})

export const getIsFetchingTasks = (state) =>
	state.get('tasks').get('isFetching')
export const getIsFetchingUserViewTasks = (state) =>
	state.get('tasks').get('isFetchingUserViewTasks')
export const getById = (state) => state.getIn(['tasks', 'byId'])
export const getAssigneeTasks = (state, { userId }) =>
	getById(state).filter((task) => task.get('assigneeId') === userId)
export const getUserViewIds = (state) => state.get('tasks').get('userViewIds')
export const getFetchingUserViewTasksFor = (state) =>
	state.getIn(['tasks', 'fetchUserViewTasksFor'])

const nullTask = Map({
	id: null,
	title: '',
	parents: List(),
})

const getTaskId = (state, props) => props.taskId

export const getAllTasks = createSelector([getById], (byId) =>
	byId.map((t) => t)
)

// Deprecated
export const getProjectColumns = (state, { taskId }) => {
	const task = getById(state).get(taskId)
	return task ? task.get('projectColumns') : null
}

export const getTask = (state, id, defaultTask = nullTask) =>
	getById(state).get(id, defaultTask)
export const getParentTask = (state, props) => {
	const task = getById(state).get(props.taskId)
	return task ? getById(state).get(task.get('parentId')) : null
}

export const getTask2 = createSelector(
	[(state, props = {}) => state.getIn(['tasks', 'byId']).get(props.taskId)],
	(task) => task
)
export const makeGetTask = () => getTask2

export const getTaskChildSortOrder = createSelector(
	[getTaskId, getById],
	(taskId, byId) => {
		const task = byId.get(taskId)
		return task && task.get('childSortOrder')
			? task.get('childSortOrder')
			: List()
	}
)

export const getUserViewTasks = createSelector(
	[getUserViewIds, getById],
	(userViewIds, byId) => userViewIds.map((id) => byId.get(id))
)
export const getUserViewTotalHours = createSelector(
	[getUserViewIds, getById],
	(userViewIds, byId) =>
		Math.round(
			userViewIds
				.map((id) => byId.get(id))
				.reduce((a, t) => a + t && t.get('hoursAllocated'), 0)
		)
)
export const getUserViewTotalTasks = createSelector(
	[getUserViewIds],
	(userViewIds) => userViewIds.size
)

export const taskToAssigneeProps = createSelector([getTask2], (task) => ({
	userId: task ? task.get('assigneeId') : null,
}))

export const getTaskWorkflowData = (state, taskId) =>
	getTask(state, taskId).get('workflowData')

export const isTimeEditModalVisible = (state) =>
	!!state.getIn(['tasks', 'timeEditModal', 'timerId'], false)
export const getTimeEditModalTimerId = (state) =>
	state.getIn(['tasks', 'timeEditModal', 'timerId'], null)
export const getTimeEditModalTaskId = (state) =>
	state.getIn(['tasks', 'timeEditModal', 'taskId'], null)
