import {
	TaskActivity,
	TaskActivityComment,
	TaskActivityCommentSchema,
} from '../task-activity-schemas'

export const parseTaskCommentToString = (
	activity: TaskActivityComment
): string => {
	return activity.comment
}

export const validateTaskComment = (taskActivity: TaskActivity) =>
	TaskActivityCommentSchema.safeParse(taskActivity)
