import { QueryClient } from '@tanstack/react-query'
import { parseISO } from 'date-fns'
import { isObject } from 'lodash'

import { Task } from '../../types'
import { ApiListResult, TaskListParams } from '../api'
import { taskKeys } from '../queries/tasks/taskKeys'

const isApiListResult = <T>(data: unknown): data is ApiListResult<T> => {
	return (
		isObject(data) &&
		'items' in data &&
		'count' in data &&
		'page' in data &&
		'pageSize' in data &&
		'hasMore' in data &&
		Array.isArray(data.items) &&
		typeof data.count === 'number' &&
		typeof data.page === 'number' &&
		typeof data.pageSize === 'number' &&
		typeof data.hasMore === 'boolean'
	)
}

export const updateTaskListsInQueryCache = (
	queryClient: QueryClient,
	taskId: string
) => {
	const task = queryClient.getQueryData<Task>(taskKeys.detail(taskId))

	// Can't do much if the task isn't in the cache
	if (!task) {
		return
	}

	const queries = queryClient.getQueriesData(taskKeys.lists())
	for (const [queryKey, data] of queries) {
		// TODO: use type guard here to infer type rather than `as`, future list param types might be very different
		const params = queryKey[2] as TaskListParams

		if (!isApiListResult<string>(data)) {
			return
		}

		const startDate = task.startDate ? parseISO(task.startDate) : null
		const dateFrom = params.startDateFrom
			? parseISO(params.startDateFrom)
			: null
		const dateUntil = params.startDateUntil
			? parseISO(params.startDateUntil)
			: null

		if (dateFrom || dateUntil) {
			// Check if the task is in the list
			// If it is, check if it must be removed
			// If it isn't, check if it must be added
			// Also check if the date range is set and if the task is within the date range, else remove or add as needed

			if (data.items.includes(taskId)) {
				// Remove the task if it doesn't match the date range
				if (
					!startDate ||
					(dateFrom && startDate < dateFrom) ||
					(dateUntil && startDate > dateUntil)
				) {
					queryClient.setQueryData<ApiListResult<string> | undefined>(
						taskKeys.list(params),
						(prevData) => {
							if (!prevData) {
								return prevData
							}
							const newItems = prevData.items.filter(
								(id) => id !== taskId
							)
							return {
								...prevData,
								items: newItems,
								count: newItems.length,
							} satisfies ApiListResult<string>
						}
					)
				}
			} else {
				// Add the task if it matches the date range
				if (
					startDate &&
					dateFrom &&
					startDate >= dateFrom &&
					dateUntil &&
					startDate <= dateUntil
				) {
					queryClient.setQueryData<ApiListResult<string> | undefined>(
						taskKeys.list(params),
						(prevData) => {
							if (!prevData) {
								return prevData
							}
							const newItems = [...prevData.items, taskId]
							return {
								...prevData,
								items: newItems,
								count: newItems.length,
							} satisfies ApiListResult<string>
						}
					)
				}
			}
		}
	}
}
