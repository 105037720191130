import { combineReducers } from 'redux-immutable'
import { List, fromJS } from 'immutable'

import {
	PUSH_QUICK_ADD_DRAFT,
	REMOVE_QUICK_ADD_DRAFT,
	SET_QUICK_ADD_DRAFTS,
	TOGGLE_QUICK_ADD,
} from '../actions'

const isVisible = (state = false, action) => {
	switch (action.type) {
		case TOGGLE_QUICK_ADD:
			return typeof action.isVisible === 'boolean'
				? action.isVisible
				: !state.isVisible

		default:
			return state
	}
}

const drafts = (state = List(), action) => {
	switch (action.type) {
		case PUSH_QUICK_ADD_DRAFT:
			return state.push(fromJS(action.payload))
		case SET_QUICK_ADD_DRAFTS:
			return fromJS(action.payload)
		case REMOVE_QUICK_ADD_DRAFT:
			return state.remove(action.payload)
		default:
			return state
	}
}

export const quickAdd = combineReducers({
	isVisible,
	drafts,
})

export const getIsVisible = state => state.getIn(['quickAdd', 'isVisible'])
export const getDrafts = state => state.getIn(['quickAdd', 'drafts'])
