export const TOGGLE_QUICK_ADD = 'TOGGLE_QUICK_ADD'
export const PUSH_QUICK_ADD_DRAFT = 'PUSH_QUICK_ADD_DRAFT'
export const SET_QUICK_ADD_DRAFTS = 'SET_QUICK_ADD_DRAFTS'
export const INIT_QUICK_ADD_DRAFTS = 'INIT_QUICK_ADD_DRAFTS'
export const REMOVE_QUICK_ADD_DRAFT = 'REMOVE_QUICK_ADD_DRAFT'

export const toggleQuickAdd = isVisible => ({
	type: TOGGLE_QUICK_ADD,
	isVisible,
})

export const pushQuickAddDraft = payload => ({
	type: PUSH_QUICK_ADD_DRAFT,
	payload,
})
export const setQuickAddDrafts = payload => ({
	type: SET_QUICK_ADD_DRAFTS,
	payload,
})
export const initQuickAddDrafts = () => ({ type: INIT_QUICK_ADD_DRAFTS })
export const removeQuickAddDraft = index => ({
	type: REMOVE_QUICK_ADD_DRAFT,
	payload: index,
})
