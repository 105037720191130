import { combineReducers } from 'redux-immutable'

import { SOCKET_OFF, SOCKET_ON } from '../actions'

const status = (state = 'off', action) => {
	switch (action.type) {
		case SOCKET_ON:
			return 'on'

		case SOCKET_OFF:
			return 'off'

		default:
			return state
	}
}

export const socket = combineReducers({
	status,
})

export const getSocketStatus = state => state.getIn(['socket', 'status'])
