import { array, object, string } from 'yup'

import { Workflow } from '../../types'

export const workflowStepSchema = object({
	id: string().required(),
	title: string().required(),
	description: string(),
})

export const workflowSchema = object({
	id: string().defined(),
	title: string().defined(),
	// TODO: re-enable this when we have organisitionId available for optimistic
	// updates
	//organisationId: string().defined(),
	description: string(),
	steps: array(workflowStepSchema.defined()).ensure().defined(),
}).defined()

export const workflowListSchema = array().of(workflowSchema).defined()

export const validateWorkflow =
	(fn: (workflow: Workflow) => Promise<Workflow>) => (workflow: Workflow) => {
		// const validatedWorkflow = workflowSchema.validateSync(workflow)
		return fn(workflow)
	}
