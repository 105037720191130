import { useQuery } from '@tanstack/react-query'
import { merge, pick, pipe } from 'remeda'

import { PlayerOptions } from '../../types'
import { getDefaultPlayerOptions } from '../player/getDefaultPlayerOptions'
import { fetchPlayerOptions, playerKeys } from '../queries'
import { AppState } from '../store-types'
import useStore from '../useStore'
import { userOptionIds } from '../utils'

const playerIdSelector = (state: AppState) => state.player.id
export const usePlayerId = () => useStore(playerIdSelector)

export const usePlayerOptions = (watchFields?: Array<keyof PlayerOptions>) => {
	return useQuery({
		queryKey: playerKeys.options(),
		queryFn: fetchPlayerOptions(useStore.getState().apiAdapter, {
			optionIds: userOptionIds,
		}),
		initialData: getDefaultPlayerOptions(),
		initialDataUpdatedAt: 0,
		placeholderData: {},
		select: watchFields
			? (data) =>
					pipe(
						getDefaultPlayerOptions(),
						merge(data),
						pick(watchFields)
					) as Partial<PlayerOptions>
			: undefined,
	})
}

export const usePlayerOption = <T extends keyof PlayerOptions>(
	optionKey: T
): PlayerOptions[T] => {
	const defaultData = getDefaultPlayerOptions()
	const { data } = usePlayerOptions([optionKey])
	return data?.[optionKey] || defaultData[optionKey]
}
