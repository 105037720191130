import { QueryClient } from '@tanstack/react-query'

import { Task, User } from '../../types'
import { taskKeys, userKeys } from '../queries'

export const mapExistingTasks = (
	queryClient: QueryClient,
	taskIds: string[]
) => {
	const taskIndex = taskIds.reduce(
		(acc, taskId) => {
			acc[taskId] = true
			return acc
		},
		{} as Record<string, boolean>
	)
	// This has better performance than querying each task individually
	const tasks = queryClient.getQueriesData<Task>(taskKeys.details())
	return tasks.reduce((acc, [, task]) => {
		if (task && taskIndex[task.id]) {
			acc.push(task)
		}
		return acc
	}, [] as Task[])
}

export const mapExistingUsers = (
	queryClient: QueryClient,
	userIds: string[]
) => {
	const userIndex = userIds.reduce(
		(acc, userId) => {
			acc[userId] = true
			return acc
		},
		{} as Record<string, boolean>
	)

	const users = queryClient.getQueriesData<User>(userKeys.details())
	return users.reduce((acc, [, user]) => {
		if (user && userIndex[user.id]) {
			acc.push(user)
		}
		return acc
	}, [] as User[])
}
