import defaults from 'lodash/defaults'
import includes from 'lodash/includes'

const DEFAULT = {}

export const validateUser = user => {
	if (!user) return defaults({}, DEFAULT)

	const newUser = defaults({ ...user }, DEFAULT)
	newUser.isAdmin = includes(user.userGroups, 'admin')
	if (newUser.timeZone) {
		newUser.timezone = newUser.timeZone
		delete newUser.timeZone
	}

	return newUser
}
