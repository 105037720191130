import { combineReducers } from 'redux-immutable'
import { Map } from 'immutable'

import { CLOSE_WHATS_NEW_DIALOG, OPEN_WHATS_NEW_DIALOG } from '../actions'

const defaultState = Map({
	open: false,
})

const whatsNew = (state = defaultState, action) => {
	switch (action.type) {
		case CLOSE_WHATS_NEW_DIALOG:
			return state.set('open', false)

		case OPEN_WHATS_NEW_DIALOG:
			return state.set('open', true)

		default:
			return state
	}
}

export const dialogs = combineReducers({
	whatsNew,
})

export const isWhatsNewDialogOpen = state =>
	state.getIn(['dialogs', 'whatsNew', 'open'], false)
