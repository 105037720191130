export const MERGE_PLAYERS_REQUEST = 'MERGE_PLAYERS_REQUEST'
export const MERGE_PLAYERS_SUCCESS = 'MERGE_PLAYERS_SUCCESS'
export const MERGE_PLAYERS_FAILURE = 'MERGE_PLAYERS_FAILURE'
export const SET_PLAYER = 'SET_PLAYER'
export const SET_PLAYER_FOCUS = 'SET_PLAYER_FOCUS'
export const SET_PLAYER_SETTINGS = 'SET_PLAYER_SETTINGS'
export const UPDATE_PLAYER = 'UPDATE_PLAYER'
export const UPDATE_PLAYER_SCORE = 'UPDATE_SCORE'

export const setPlayer = (user) => ({ type: SET_PLAYER, payload: user })
export const setPlayerFocus = (focus) => ({
	type: SET_PLAYER_FOCUS,
	payload: focus,
})
export const setPlayerSettings = (settings) => ({
	type: SET_PLAYER_SETTINGS,
	payload: settings,
})
export const updatePlayer = (payload) => ({ type: UPDATE_PLAYER, payload })

export const updatePlayerScore = (payload) => ({
	type: UPDATE_PLAYER_SCORE,
	payload,
})

export const mergePlayersRequest = (payload) => ({
	type: MERGE_PLAYERS_REQUEST,
	payload,
})
export const mergePlayersSuccess = (payload) => ({
	type: MERGE_PLAYERS_SUCCESS,
	payload,
})
export const mergePlayersFailure = (payload) => ({
	type: MERGE_PLAYERS_FAILURE,
	payload,
})
