interface Error {
	name: string
	message: string
	stack?: string
	cause?: unknown
}

interface ErrorConstructor {
	new (message?: string, options?: { cause?: unknown }): Error
	(message?: string, options?: { cause?: unknown }): Error
	readonly prototype: Error
}

declare const Error: ErrorConstructor

const isObject = (value: unknown): value is object =>
	value !== null && typeof value === 'object'

// An error type that stitches stack traces together
export class TraceableError extends Error {
	innerError: unknown

	constructor(message: string, innerError?: unknown) {
		super(message, { cause: innerError })
		if (isObject(innerError) && !('cause' in this)) {
			const cause = innerError
			this.cause = cause
			if (cause instanceof Error) {
				this.stack = this.stack + '\nCAUSE: ' + cause.stack
			}
		}

		Object.setPrototypeOf(this, TraceableError.prototype)
	}
}

// export class TraceableError extends Error {
// 	cause: unknown

// 	constructor(message: string, error?: unknown) {
// 		super(message)

// 		this.cause = error

// 		if (error instanceof Error) {
// 			const messageLines = (this.message.match(/\n/g) || []).length + 1

// 			this.stack = `${(this.stack || '')
// 				.split('\n')
// 				.slice(0, messageLines + 1)
// 				.join('\n')}'\n'${error.stack}`
// 		}
// 	}
// }
