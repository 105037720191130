import { Map, fromJS } from 'immutable'

import { SET_APP_MESSAGE } from '../actions'

export const appMessage = (state = Map(), action) => {
	switch (action.type) {
		case SET_APP_MESSAGE:
			// Return a new reference so we can show the same message multiple
			// times.
			return fromJS({
				delay: action.delay || 0,
				text: action.text,
				type: action.messageType || null,
			})

		default:
			return state
	}
}
