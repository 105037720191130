export const ADD_PROJECT_MENU_TASK = 'ADD_PROJECT_MENU_TASK'
export const FETCH_PROJECT_MENU_DATA_FAILURE = 'FETCH_PROJECT_MENU_DATA_FAILURE'
export const FETCH_PROJECT_MENU_DATA_REQUEST = 'FETCH_PROJECT_MENU_DATA_REQUEST'
export const FETCH_PROJECT_MENU_DATA_SUCCESS = 'FETCH_PROJECT_MENU_DATA_SUCCESS'
export const PROJECT_MENU_SEARCH = 'PROJECT_MENU_SEARCH'
export const SET_PROJECT_MENU_FOCUSED = 'SET_PROJECT_MENU_FOCUSED'
export const SET_PROJECT_MENU_NAVIGATING = 'SET_PROJECT_MENU_NAVIGATING'
export const SET_PROJECT_MENU_RECENTS = 'SET_PROJECT_MENU_RECENTS'
export const SET_PROJECT_MENU_TASKS = 'SET_PROJECT_MENU_TASKS'

export const addProjectMenuTask = taskId => ({
	type: ADD_PROJECT_MENU_TASK,
	taskId,
})

export const fetchProjectMenuDataFailure = reason => ({
	type: FETCH_PROJECT_MENU_DATA_FAILURE,
	reason,
})

export const fetchProjectMenuDataRequest = (taskId, userId, resetPage) => ({
	type: FETCH_PROJECT_MENU_DATA_REQUEST,
	taskId,
	userId,
	resetPage,
})

export const fetchProjectMenuDataSuccess = () => ({
	type: FETCH_PROJECT_MENU_DATA_SUCCESS,
})

export const projectMenuSearch = (query, resetPage) => ({
	type: PROJECT_MENU_SEARCH,
	query,
	resetPage,
})

export const setProjectMenuNavigating = isNavigating => ({
	type: SET_PROJECT_MENU_NAVIGATING,
	isNavigating,
})

export const setProjectMenuFocused = (taskId, page) => ({
	type: SET_PROJECT_MENU_FOCUSED,
	taskId,
	page,
})

export const setProjectMenuRecents = recentIds => ({
	type: SET_PROJECT_MENU_RECENTS,
	recentIds,
})

export const setProjectMenuTasks = (
	taskIds,
	merge = false,
	hasMore = true,
	page = 1
) => ({
	type: SET_PROJECT_MENU_TASKS,
	taskIds,
	merge,
	hasMore,
	page,
})
