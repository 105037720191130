import { QueryFunctionContext } from '@tanstack/react-query'

import { ApiAdapter } from '../api'

export const playerKeys = {
	all: ['player'] as const,
	options: () => [...playerKeys.all, 'options'] as const,
}

interface PlayerOptionsParams {
	optionIds: string[]
}

export const fetchPlayerOptions =
	(apiAdapter: ApiAdapter, { optionIds }: PlayerOptionsParams) =>
	async ({ signal }: QueryFunctionContext) =>
		apiAdapter.users.getOptions('me', optionIds, { signal })
