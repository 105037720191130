import { QueryClient } from '@tanstack/react-query'

import { chatKeys } from '../store/chat/chatKeys'
import { taskKeys } from '../store/queries/tasks/taskKeys'

// Fetch all active queries to keep the data up to date.
export const syncStore = (queryClient: QueryClient) => () => {
	// NOTE: Only using on tasks for now because we have caching/optimisations
	// for tasks.
	queryClient.invalidateQueries(taskKeys.lists())
	queryClient.invalidateQueries(chatKeys.all)
}

export default syncStore
