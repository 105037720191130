import { combineReducers } from 'redux-immutable'
import { List, fromJS } from 'immutable'

import { ENQUEUE_SNACKBAR, REMOVE_SNACKBAR } from '../actions'

const notifications = (state = List(), action) => {
	switch (action.type) {
		case ENQUEUE_SNACKBAR:
			return state.push(fromJS(action.payload))

		case REMOVE_SNACKBAR: {
			const index = state.findIndex(
				notification => notification.get('key') === action.payload
			)
			return index > -1 ? state.delete(index) : state
		}

		default:
			return state
	}
}

export const snackbar = combineReducers({
	notifications,
})

export const createSnackbarKey = () =>
	String(new Date().getTime() + Math.random() * 1000)
export const getSnackbarNotifications = state =>
	state.getIn(['snackbar', 'notifications'])
