import merge from 'lodash/merge'

import { env } from '@tyto/utils'

import { getToken as getAuthToken } from '../auth-provider'
import Http from './http'

let isDisabled = false

const defaultParams = {
	headers: {
		'Content-Type': 'application/json',
		Authorization: '',
	},
}

let currentRequests = {}

const getToken = () => `Bearer ${getAuthToken()}`

const apiUrl = `${env('API_URL')}/v1`

class ApiAdapter {
	static disable() {
		isDisabled = true
	}

	static enable() {
		isDisabled = false
	}

	static get(path, params = {}, token = {}) {
		if (isDisabled) {
			return Promise.reject('disabled')
		}

		defaultParams.headers['Authorization'] = getToken()

		let key = path + JSON.stringify(params)
		let promiseHandler = (resolve, reject) => {
			params = merge({}, defaultParams, params)

			Http.get(apiUrl + path, params, token)
				.then((response) => {
					delete currentRequests[key]

					if (response.data) {
						resolve(response.data)
					} else {
						reject('ApiAdapter::get - no data returned')
					}
				})
				.catch((err) => {
					console.log(
						'ApiAdapter.get - failed:',
						err.data.message,
						err
					)

					delete currentRequests[key]
					reject(err)
				})
		}

		if (!currentRequests[key]) {
			currentRequests[key] = new Promise(promiseHandler)
		}

		return currentRequests[key]
	}

	static patch(path, data, params = {}, token = {}) {
		if (isDisabled) {
			return Promise.reject('disabled')
		}

		defaultParams.headers['Authorization'] = getToken()
		params = Object.assign({}, defaultParams, params)

		if (params.query) {
			params.body = params.query
			params.query = null
		}

		return Http.patch(
			apiUrl + path,
			JSON.stringify(data),
			params,
			token
		).then((response) => response.data)
	}

	static post(path, data, params = {}, token = {}) {
		if (isDisabled) {
			return Promise.reject('disabled')
		}

		defaultParams.headers['Authorization'] = getToken()
		params = Object.assign({}, defaultParams, params)

		if (params.query) {
			params.body = params.query
			params.query = null
		}

		return Http.post(
			apiUrl + path,
			JSON.stringify(data),
			params,
			token
		).then((response) => response.data)
	}

	static put(path, data, params = {}, token = {}) {
		if (isDisabled) {
			return Promise.reject('disabled')
		}

		defaultParams.headers['Authorization'] = getToken()
		params = Object.assign({}, defaultParams, params)

		if (params.query) {
			params.body = params.query
			params.query = null
		}

		return Http.put(
			apiUrl + path,
			JSON.stringify(data),
			params,
			token
		).then((response) => response.data)
	}

	static remove(path, params = {}, token = {}) {
		if (isDisabled) {
			return Promise.reject('disabled')
		}

		defaultParams.headers['Authorization'] = getToken()
		params = Object.assign({}, defaultParams, params)

		return Http.remove(apiUrl + path, params, token).then(
			(response) => response.data
		)
	}
}

export default ApiAdapter
