import { QueryClient } from '@tanstack/react-query'
import { map, merge, reject } from 'lodash'

import { Attachment, TeardownFn } from '../../types'
import { ApiAdapter, ApiResult, RemoveResponse } from '../api'

export type FileRequestArgs = {
	taskId: string
	fileId: string
}

export interface UpdateFileArgs extends FileRequestArgs {
	partialFile: Attachment
}

type UpdateFileFn<T> = (args: UpdateFileArgs) => T
type RemoveFileFn<T> = (args: FileRequestArgs) => T

export const updateTaskFileFromApi =
	(apiAdapter: ApiAdapter): UpdateFileFn<Promise<ApiResult<Attachment>>> =>
	async (arg) =>
		apiAdapter.tasks.updateFile(arg.taskId, arg.fileId, {
			filename: arg.partialFile.name,
		})

export const updateTaskFileMutation =
	(apiAdapter: ApiAdapter, queryClient: QueryClient) =>
	async (args: UpdateFileArgs): Promise<ApiResult<Attachment>> => {
		const updateTaskFile = updateTaskFileFromApi(apiAdapter)
		const rollback = await updateTaskFileFromQueryCache(queryClient)(args)

		let result = null
		try {
			result = await updateTaskFile(args)
		} catch (error) {
			rollback()
		}

		return result
	}

export const updateTaskFileFromQueryCache =
	(queryClient: QueryClient) =>
	async (args: UpdateFileArgs): Promise<TeardownFn> => {
		const { partialFile, taskId, fileId } = args
		const fileQueryKey = ['files', fileId]
		const fileListQueryKey = ['tasks', taskId, 'files']
		// Cancel any outgoing refetches (so they don't overwrite our optimistic update).
		queryClient.cancelQueries(fileQueryKey)

		// Snapshot the previous value.
		const previousFile = queryClient.getQueryData(fileQueryKey)
		const previousFileList = queryClient.getQueryData(fileListQueryKey)

		// Optimistically update to the new value.
		queryClient.setQueryData<Attachment>(fileQueryKey, (oldFile: any) => {
			if (!oldFile) {
				return undefined
			}
			return merge({}, oldFile, partialFile)
		})
		queryClient.setQueryData<Attachment[]>(
			fileListQueryKey,
			(oldFileList) =>
				oldFileList
					? map(oldFileList, (file) =>
							file.id === fileId
								? merge({}, file, partialFile)
								: file
						)
					: []
		)

		// Return a rollback function.
		return () => {
			queryClient.setQueryData(fileQueryKey, previousFile)
			queryClient.setQueryData(fileListQueryKey, previousFileList)
		}
	}

export const removeTaskFileFromApi =
	(
		apiAdapter: ApiAdapter
	): RemoveFileFn<Promise<ApiResult<RemoveResponse>>> =>
	async (arg) =>
		apiAdapter.tasks.removeFile(arg.taskId, arg.fileId)

export const removeTaskFileMutation =
	(apiAdapter: ApiAdapter, queryClient: QueryClient) =>
	async (args: FileRequestArgs): Promise<ApiResult<RemoveResponse>> => {
		const removeTaskFile = removeTaskFileFromApi(apiAdapter)
		const rollback = await removeTaskFileFromQueryCache(queryClient)(args)

		let result = null
		try {
			result = await removeTaskFile(args)
		} catch (error) {
			rollback()
		}

		return result
	}

export const removeTaskFileFromQueryCache =
	(queryClient: QueryClient) =>
	async (args: FileRequestArgs): Promise<TeardownFn> => {
		const { taskId, fileId } = args
		const fileQueryKey = ['files', fileId]
		const fileListQueryKey = ['tasks', taskId, 'files']
		// Cancel any outgoing refetches (so they don't overwrite our optimistic update).
		queryClient.cancelQueries(fileQueryKey)

		// Snapshot the previous value.
		const previousFile = queryClient.getQueryData(fileQueryKey)
		const previousFileList = queryClient.getQueryData(fileListQueryKey)

		// Optimistically update to the new value.
		queryClient.removeQueries(fileQueryKey)
		queryClient.setQueryData<Attachment[]>(
			fileListQueryKey,
			(oldFileList) => reject(oldFileList, { id: fileId })
		)

		// Return a rollback function.
		return () => {
			queryClient.setQueryData(fileQueryKey, previousFile)
			queryClient.setQueryData(fileListQueryKey, previousFileList)
		}
	}
