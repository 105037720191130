import {
	TaskActivity,
	TaskActivityUpdate,
	TaskActivityUpdateSchema,
} from '../task-activity-schemas'

export const parseTaskUpdateToString = (
	activity: TaskActivityUpdate
): string => {
	const meta = activity.meta
	return `${meta.user.nickname} updated this task.`
}

export const validateTaskUpdate = (taskActivity: TaskActivity) =>
	TaskActivityUpdateSchema.safeParse(taskActivity)
