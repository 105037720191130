import defaults from 'lodash/defaults'
import forEach from 'lodash/forEach'

const DEFAULT = {}

export const validateDailyTaskMail = user => {
	if (!user) return defaults({}, DEFAULT)

	const newChanges = defaults({ ...user }, DEFAULT)
	const booleanFields = [
		'wantsDailyTaskMail',
		'wantsDailyTaskMailOnWeekends',
		'wantsDailyTaskMailWhenTasksAssignedForDay',
	]

	forEach(booleanFields, field => {
		// eslint-disable-next-line no-prototype-builtins
		if (newChanges.hasOwnProperty(field)) {
			newChanges[field] = Boolean(newChanges[field])
		}
	})

	return newChanges
}
