import { combineReducers } from 'redux-immutable'
import { OrderedSet } from 'immutable'
import { createSelector } from 'reselect'

import {
	ADD_PROJECT_MENU_TASK,
	FETCH_PROJECT_MENU_DATA_FAILURE,
	FETCH_PROJECT_MENU_DATA_REQUEST,
	FETCH_PROJECT_MENU_DATA_SUCCESS,
	PROJECT_MENU_SEARCH,
	SET_PROJECT_MENU_FOCUSED,
	SET_PROJECT_MENU_NAVIGATING,
	SET_PROJECT_MENU_TASKS,
} from '../actions'
import { getById } from './tasks-reducer'

const focusedId = (state = null, action) => {
	switch (action.type) {
		case SET_PROJECT_MENU_FOCUSED:
			return action.taskId || null
		default:
			return state
	}
}

const isNavigating = (state = false, action) => {
	switch (action.type) {
		case SET_PROJECT_MENU_NAVIGATING:
			return action.isNavigating
		default:
			return state
	}
}

const isFetching = (state = true, action) => {
	switch (action.type) {
		case FETCH_PROJECT_MENU_DATA_REQUEST:
		case SET_PROJECT_MENU_FOCUSED:
		case PROJECT_MENU_SEARCH:
			return true
		case FETCH_PROJECT_MENU_DATA_SUCCESS:
		case FETCH_PROJECT_MENU_DATA_FAILURE:
			return false
		default:
			return state
	}
}

const hasMore = (state = true, action) => {
	switch (action.type) {
		case SET_PROJECT_MENU_TASKS:
			return action.hasMore
		default:
			return state
	}
}

const taskIds = (state = OrderedSet(), action) => {
	switch (action.type) {
		case ADD_PROJECT_MENU_TASK:
			return state.add(action.taskId)

		case SET_PROJECT_MENU_TASKS:
			return action.merge
				? state.concat(action.taskIds)
				: OrderedSet(action.taskIds)

		default:
			return state
	}
}

const page = (state = 1, action) => {
	switch (action.type) {
		case SET_PROJECT_MENU_FOCUSED:
			return action.page || 1
		case SET_PROJECT_MENU_TASKS:
			return action.merge ? action.page : 2
		case PROJECT_MENU_SEARCH:
		case FETCH_PROJECT_MENU_DATA_REQUEST:
			return action.resetPage ? 1 : state
		default:
			return state
	}
}

export const projectMenu = combineReducers({
	focusedId,
	isFetching,
	isNavigating,
	taskIds,
	hasMore,
	page,
})

export const getFocusedId = state => state.getIn(['projectMenu', 'focusedId'])
export const getIsFetching = state => state.get('projectMenu').get('isFetching')
export const getIsNavigating = state =>
	state.get('projectMenu').get('isNavigating')

export const getTaskIds = state => state.get('projectMenu').get('taskIds')

export const getProjects = createSelector(
	[getTaskIds, getById],
	(taskIds, byId) => taskIds.map(id => byId.get(id)).filter(t => t)
)

export const getProjectsWithParentId = (state, parentId) =>
	getProjects(state).filter(task => task.get('parentId') === parentId)

export const getHasMore = state => state.get('projectMenu').get('hasMore')

export const getPage = state => state.get('projectMenu').get('page')
