import { TreeNode, TreeNodeData } from '../types'

/**
 * Build an N-ary tree data structure from a list of items.
 * It can be serialized into a JSON object.
 */
export function buildTree<T extends TreeNodeData>(
	rootId: string,
	items: T[] = []
): TreeNode<T> {
	if (!rootId) {
		throw new Error('rootId is required')
	}

	// TODO:
	// - test if any ids match parent ids
	// - test for cyclic dependencies

	const nodeIndex: Record<string, TreeNode<T>> = {}
	const fringe: TreeNode<T>[] = []
	let tree!: TreeNode<T>
	for (const item of items) {
		if (!item) {
			continue
		}

		// Check for duplicates (ignore them for now)
		if (nodeIndex[item.id]) {
			continue
		}
		nodeIndex[item.id] = createTreeNode(item)

		// The tree is just the root item after all decendants have been added
		if (item.id === rootId) {
			tree = nodeIndex[item.id]
			continue
		} else {
			fringe.unshift(nodeIndex[item.id])
		}
	}

	// Throw an error if there is no rootId in the items list
	if (!tree) {
		throw new Error('rootId not found in items')
	}

	// Define an upper limit to prevent infinite loops
	const MAX_ITER = items.length * 2

	let i = 0
	while (fringe.length > 0 && i < MAX_ITER) {
		const item = fringe.pop()

		if (!item || !item.data) {
			break
		}
		i++

		// If we're dealing with a nested project, then there may be ancestors
		// that shouldn't be added to the tree.
		if (!item.data.parentId) {
			continue
		}

		// Add to children
		if (nodeIndex[item.data.parentId]) {
			nodeIndex[item.data.parentId].children.push(item)
		} else {
			// Ignore orphans for now
		}
	}

	return tree
}

/**
 * Build a tree node from a TreeNodeData item.
 */
function createTreeNode<T extends TreeNodeData>(data: T): TreeNode<T> {
	return { children: [], data }
}

/**
 * Transforms tree structure into flat list of items.
 * Iterates with depth-first search.
 */
export function flattenAndUnwrapTree<T extends TreeNodeData>(
	tree: TreeNode<T>
): T[] {
	const list: T[] = []
	depthFirstIteration(tree, (item) => {
		list.push(item.data)
	})
	return list
}

/**
 * Iterates over a tree structure with depth-first search
 * Takes a callback that is run on each item in the iteration.
 * Callback can optionally return true to stop the iteration.
 */
export function depthFirstIteration<T extends TreeNodeData>(
	tree: TreeNode<T>,
	callbackFn: (item: TreeNode<T>) => boolean | void
): void {
	const fringe: TreeNode<T>[] = [tree]
	const visited = new Set<TreeNode<T>>() // Track visited nodes to avoid cycles

	// Define an upper limit to prevent infinite loops
	const MAX_ITER = 1e6
	let i = 0

	while (fringe.length > 0 && i++ < MAX_ITER) {
		const item = fringe.pop()

		if (!item) {
			break
		}

		if (visited.has(item)) {
			continue
		}
		visited.add(item)

		const shouldBreak = callbackFn(item)
		if (shouldBreak) {
			break
		}

		for (const child of item.children.slice().reverse()) {
			fringe.push(child)
		}
	}
}

/**
 * Iterates over a tree structure with breadth-first search
 * Tasks a callback that is run on each item in the iteration.
 * Callback can optionally return true to stop the iteration.
 */
export function breadthFirstIteration<T extends TreeNodeData>(
	tree: TreeNode<T>,
	callbackFn: (item: TreeNode<T>) => boolean | void
): void {
	const fringe: TreeNode<T>[] = [tree]
	const visited = new Set<TreeNode<T>>() // Track visited nodes to avoid cycles

	// Define an upper limit to prevent infinite loops
	const MAX_ITER = 1e6
	let i = 0

	while (fringe.length > 0 && i++ < MAX_ITER) {
		const item = fringe.shift()

		if (!item) {
			break
		}

		if (visited.has(item)) {
			continue
		}
		visited.add(item)

		const shouldBreak = callbackFn(item)
		if (shouldBreak) {
			break
		}

		for (const child of item.children) {
			fringe.push(child)
		}
	}
}
