import { Map } from 'immutable'

import { TOGGLE_NEW_PROJECT } from '../actions'

const initialState = Map({
	isVisible: false,
})

export const newProject = (state = initialState, action) => {
	switch (action.type) {
		case TOGGLE_NEW_PROJECT:
			return state.update('isVisible', isVisible =>
				typeof action.isVisible === 'boolean'
					? action.isVisible
					: !isVisible
			)

		default:
			return state
	}
}

export const getIsVisible = state => state.get('newProject').get('isVisible')
