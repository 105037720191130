import { createActivityId } from '../helpers/createId'
import { Task } from '../types'
import { getTaskLogData } from './getTaskLogData'
import {
	MetaUser,
	TaskActivity,
	TaskActivityStart,
	TaskActivityStop,
	WrappedTaskActivityV2,
	WrappedTaskActivityV2WithType,
} from './task-activity-schemas'

const createEmptyMetaUser = (userId: string): MetaUser => ({
	id: userId,
	name: '',
	nickname: '',
	gravatar: '',
})

export const createNewTaskActivity = (
	source: 'mail' | 'mobile' | 'web' | 'chrome',
	userId: string,
	taskId: string
): WrappedTaskActivityV2 => ({
	id: createActivityId(),
	dateCreated: new Date(),
	data: {
		type: 'task.create',
		source,
		userId,
		meta: { user: createEmptyMetaUser(userId) },
	},
	descr: '',
	hasPermission: true,
	reactions: {},
	taskId,
	userId,
	version: 2,
})

/**
 * Create a TaskActivity type for starting tasks
 *
 * @param {string} performingUserId
 * @param {string} assigneeId
 * @param {string} taskId
 *
 * @return {TaskActivity}
 */
export const createStartTaskActivity = (
	performingUserId: string,
	assigneeId: string,
	taskId: string
): WrappedTaskActivityV2WithType<TaskActivityStart> => ({
	id: createActivityId(),
	data: {
		type: 'task.start',
		userId: performingUserId,
		assigneeId,
		meta: {
			assignee: createEmptyMetaUser(assigneeId),
			user: createEmptyMetaUser(performingUserId),
		},
	},
	dateCreated: new Date(),
	descr: '',
	hasPermission: true,
	reactions: {},
	taskId,
	userId: performingUserId,
	version: 2,
})

/**
 * Create a TaskActivity type for starting tasks
 *
 * @param {string} performingUserId
 * @param {string} assigneeId
 * @param {number} duration The duration in seconds
 * @param {string} taskId
 *
 * @return {TaskActivity}
 */
export const createStopTaskActivity = (
	performingUserId: string,
	assigneeId: string,
	duration: number,
	taskId: string
): WrappedTaskActivityV2WithType<TaskActivityStop> => ({
	id: createActivityId(),
	data: {
		type: 'task.stop',
		userId: performingUserId,
		assigneeId,
		duration,
		meta: {
			assignee: createEmptyMetaUser(assigneeId),
			user: createEmptyMetaUser(performingUserId),
		},
	},
	dateCreated: new Date(),
	descr: '',
	hasPermission: true,
	reactions: {},
	taskId,
	userId: performingUserId,
	version: 2,
})

export const createTaskActivity = <T extends TaskActivity>(
	performingUserId: string,
	oldTask: Task,
	newTask: Task,
	extra = {}
): WrappedTaskActivityV2WithType<T> | null => {
	const data: T = getTaskLogData(performingUserId, oldTask, newTask, extra)

	return data
		? {
				id: createActivityId(),
				dateCreated: new Date(),
				data,
				descr: '',
				hasPermission: true,
				reactions: {},
				taskId: newTask.id,
				userId: performingUserId,
				version: 2,
			}
		: null
}

export default createTaskActivity
