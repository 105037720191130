import { combineReducers } from 'redux-immutable'
import { Set } from 'immutable'

import {
	ADD_TO_BULK_EDIT,
	ADD_MULTIPLE_TO_BULK_EDIT,
	CLEAR_BULK_EDIT,
	REMOVE_FROM_BULK_EDIT,
	REMOVE_MULTIPLE_FROM_BULK_EDIT,
} from '../actions'

const ids = (state = Set(), action) => {
	switch (action.type) {
		case ADD_TO_BULK_EDIT:
			return state.add(action.payload)
		case ADD_MULTIPLE_TO_BULK_EDIT:
			return state.union(action.payload)
		case CLEAR_BULK_EDIT:
			return state.clear()
		case REMOVE_FROM_BULK_EDIT:
			return state.delete(action.payload)
		case REMOVE_MULTIPLE_FROM_BULK_EDIT:
			return state.subtract(action.payload)
		default:
			return state
	}
}

export const bulkEdit = combineReducers({
	ids,
})

export const getMarkedIds = state => state.getIn(['bulkEdit', 'ids'])
export const isMarked = (state, props) =>
	state.getIn(['bulkEdit', 'ids']).has(props.taskId)
