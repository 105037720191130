export const PRIORITY_COLORS = {
	urgency1: '#ffc107',
	urgency2: '#ff9800',
	urgency3: '#f78860',
	importance1: '#80deea',
	importance2: '#00bcd4',
	importance3: '#5dbdd2',
	complexity1: '#dce775',
	complexity2: '#c0ca33',
	complexity3: '#b8cf6a',
} as const
