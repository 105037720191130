export type FileKeysAll = ['files']
export type FileKeysAllDetails = ['files', 'detail']
export type FileKeysDetail = ['files', 'detail', string]

type FileKeys = {
	all: FileKeysAll
	details: () => FileKeysAllDetails
	detail: (fileId: string) => FileKeysDetail
}

export const fileKeys: FileKeys = {
	all: ['files'],
	details: () => [...fileKeys.all, 'detail'],
	detail: (fileId) => [...fileKeys.details(), fileId],
}
