import forEach from 'lodash/forEach'

class Http {
	/**
	 * @param {string} method
	 * @param {string} url
	 * @param {object} params
	 * @param {object} token To be used for cancellation
	 * @returns {Promise}
	 */
	static request(method, url, params, token) {
		if (!method) {
			throw TypeError('Http.request: You must provide a method')
		}

		let query = ''

		if (params.query) {
			let queryItems = []

			Object.keys(params.query).forEach(key => {
				queryItems.push(
					key + '=' + encodeURIComponent(params.query[key])
				)
			})

			query = '?' + queryItems.join('&')
		}

		const httpRequest = new XMLHttpRequest()
		httpRequest.open(method.toUpperCase(), url + query, true)

		if (params.headers) {
			forEach(params.headers, (value, header) => {
				httpRequest.setRequestHeader(header, value)
			})
		}

		return new Promise((resolve, reject) => {
			if (token) {
				token.cancel = () => {
					httpRequest.abort()
					return reject(new Error('Cancelled'))
				}
			}

			httpRequest.onreadystatechange = () => {
				if (httpRequest.readyState === XMLHttpRequest.DONE) {
					let response = {
						request: httpRequest,
					}

					try {
						response.data = JSON.parse(httpRequest.responseText)
					} catch (e) {
						response.data = httpRequest.responseText
					}

					if (httpRequest.status >= 200 && httpRequest.status < 300) {
						resolve(response)
					} else {
						reject(response)
					}
				}
			}

			httpRequest.send(params.body)
		})
	}

	static get(url, params, token) {
		return this.request('GET', url, params, token)
	}

	static patch(url, data, params, token) {
		params.body = data

		return this.request('PATCH', url, params, token)
	}

	static post(url, data, params, token) {
		params.body = data

		return this.request('POST', url, params, token)
	}

	static put(url, data, params, token) {
		params.body = data

		return this.request('PUT', url, params, token)
	}

	static remove(url, params, token) {
		return this.request('DELETE', url, params, token)
	}
}

export default Http
