import { QueryClient, QueryFunctionContext } from '@tanstack/react-query'

import { Task } from '../../../types'
import { ApiAdapter } from '../../api'
import { taskKeys } from './taskKeys'

type TaskParams = {
	h?: string // Data hash value
}

export const fetchTask =
	(
		apiAdapter: ApiAdapter,
		queryClient: QueryClient,
		taskId: string,
		params?: TaskParams
	) =>
	async ({
		queryKey,
		signal,
	}: QueryFunctionContext): Promise<Task | undefined> => {
		const task = await apiAdapter.tasks.getDetail(taskId, {
			params,
			signal,
		})

		if (task) {
			return task
		} else {
			// If the hash matches, the query will return nothing. So use the
			// previous task data
			return queryClient.getQueryData<Task>(queryKey)
		}

		// TODO: this could be better... and needs to handle all task fields
		// try {
		// 	return taskSchema.validateSync(task)
		// } catch (err) {
		// 	return null
		// }
		//return task
	}

export const createTaskDetailQuery =
	(apiAdapter: ApiAdapter, queryClient: QueryClient) =>
	(taskId: string | null, params?: TaskParams) => ({
		queryKey: taskKeys.detail(taskId || ''),
		queryFn: fetchTask(apiAdapter, queryClient, taskId || '', params),
		enabled: Boolean(taskId) && taskId !== 'newTask',
	})
