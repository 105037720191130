import { parseISO } from 'date-fns'

import { ApiFile } from '../store/api/baseApiAdapter'
import { getMessageFileUrl, getTaskFileUrl } from '../store/utils/files'
import { Attachment, AttachmentWithUrls } from '../types'

// This is just a helper function to add image and thumbnail urls to a file.
// Ideally, we would have a way to do this in the backend, but for now, we'll
// do it here.
export const addUrlsToFile = (file: Attachment): AttachmentWithUrls => {
	const imageUrl = file.url?.replace('&thumbnail=true', '') || ''
	return {
		...file,
		downloadUrl: imageUrl?.replace(/(\?.*)/, '/download$1'),
		imageUrl,
		thumbnailUrl: `${imageUrl}&thumbnail=true`,
	}
}
export const addUrls = (files: Attachment[]): AttachmentWithUrls[] =>
	files.map(addUrlsToFile)

export const convertApiFileToAttachment = (
	baseURL: string,
	authToken: string,
	file: ApiFile
) => {
	const newFile: Attachment = {
		id: file.id,
		lastUpdated: parseISO(file.lastUpdated),
		name: file.filename,
		size: file.totalBytes,
		contentType: file.contentType,
		type: file.type,
		url:
			file.type === 'chat'
				? getMessageFileUrl(
						baseURL || '',
						file.chatMessageId,
						file.id,
						authToken || ''
					)
				: getTaskFileUrl(
						baseURL || '',
						file.taskId,
						file.id,
						authToken || ''
					),
	}

	return addUrlsToFile(newFile)
}

export const getFileExtension = (filename: string): string => {
	// Split the filename by dot and get the last part
	const parts = filename.split('.')

	// If there's no extension (no dots or filename starts with a dot), return an empty string
	if (parts.length <= 1 || filename.startsWith('.')) {
		return ''
	}

	// Return the last part (extension) in lowercase
	return parts[parts.length - 1].toLowerCase()
}
