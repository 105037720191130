import {
	TaskActivity,
	TaskActivityPriority,
	TaskActivityPrioritySchema,
} from '../task-activity-schemas'
import { parseErrorMessage } from './task-activity-parsers-utils'

export const parseTaskPriorityToString = (
	activity: TaskActivityPriority
): string => {
	const meta = activity.meta

	if (activity.newUrgency && activity.newImportance) {
		return `${meta.user.nickname} changed the urgency from ${activity.oldUrgency} to ${activity.newUrgency} and importance from ${activity.oldImportance} to ${activity.newImportance}.`
	} else if (activity.newUrgency) {
		return `${meta.user.nickname} changed the urgency from ${activity.oldUrgency} to ${activity.newUrgency}.`
	} else if (activity.newImportance) {
		return `${meta.user.nickname} changed the importance from ${activity.oldImportance} to ${activity.newImportance}.`
	} else {
		return parseErrorMessage
	}
}

export const validateTaskPriority = (taskActivity: TaskActivity) =>
	TaskActivityPrioritySchema.safeParse(taskActivity)
