import { Iterable } from 'immutable'
import first from 'lodash/first'

export * from './browser'
export * from './events'
export * from './coverHelper'
export * from './mergeRefs'
export { default as taskIsProject } from './taskIsProject'
export { validateDailyTaskMail } from './validateDailyTaskMail'
export { validateTask } from './validateTask'
export { validateUser } from './validateUser'

// Gets the point on a circle and offsets from the center.
export const pointOnCircle = (degrees = -45) => ({
	x: `calc(50% * ${Math.cos((degrees * Math.PI) / 180)} + 50%)`,
	y: `calc(50% * ${Math.sin((degrees * Math.PI) / 180)} + 50%)`,
})

/**
 * Performs equality by iterating through keys on an object and returning false
 * when any key has values which are not strictly equal between the arguments.
 * Returns true when the values of all keys are strictly equal.
 */
export function shallowEqual(objA, objB) {
	if (objA === objB) {
		return true
	}

	if (
		typeof objA !== 'object' ||
		objA === null ||
		typeof objB !== 'object' ||
		objB === null
	) {
		return false
	}

	const keysA = Object.keys(objA)
	const keysB = Object.keys(objB)

	if (keysA.length !== keysB.length) {
		return false
	}

	// Test for A's keys different from B.
	const bHasOwnProperty = hasOwnProperty.bind(objB)
	for (let i = 0; i < keysA.length; i++) {
		if (!bHasOwnProperty(keysA[i]) || objA[keysA[i]] !== objB[keysA[i]]) {
			return false
		}
	}

	return true
}

export function shallowCompare(instance, nextProps, nextState) {
	return (
		!shallowEqual(instance.props, nextProps) ||
		!shallowEqual(instance.state, nextState)
	)
}

export const toJS = (value, defaultValue) =>
	(Iterable.isIterable(value) ? value.toJS() : value) ?? defaultValue

export function ucFirst(string) {
	return string.charAt(0).toUpperCase() + string.slice(1)
}

export const getUserFirstName = (name) => first(String(name).split(' '))
