export const doOnceAcrossTabs = (action, key) =>
	new Promise((resolve) => {
		const tabKey = `${Date.now()}.${String(
			Math.floor(Math.random() * 10000000)
		)}`
		const lsKey = `TAB_SYNC_${key}`
		const existingKey = localStorage.getItem(lsKey)
		const setKey = () => {
			localStorage.setItem(lsKey, tabKey)
			setTimeout(() => {
				if (localStorage.getItem(lsKey) !== tabKey) {
					resolve()
				} else {
					action()
					setTimeout(() => {
						localStorage.removeItem(lsKey)
					}, 5000)
					resolve()
				}
			}, 500)
		}
		if (existingKey) {
			const [time, random] = existingKey.split('.')
			if (!random || Date.now() - time > 20000) {
				localStorage.removeItem(lsKey)
				setKey()
			}
			resolve()
		} else {
			setKey()
		}
	})
