import { StoreError } from './store/store-types'
import { Attachment, RecurFreqType, SectionId } from './types'

export type Fn = (this: any, ...args: any[]) => any

export const isAttachment = (file: any): file is Attachment =>
	typeof file === 'object' &&
	'contentType' in file &&
	'type' in file &&
	(file.type === 'chat' || file.type === 'task') &&
	'url' in file

export const isBoolean = (val: any): val is boolean => typeof val === 'boolean'

export const isDefined = <T = any>(val?: T): val is T =>
	typeof val !== 'undefined'

export const isFunction = <T extends Fn>(val: any): val is T =>
	typeof val === 'function'

export const isNumber = (val: any): val is number => typeof val === 'number'

const toString = Object.prototype.toString
export const isObject = (val: any): val is object =>
	toString.call(val) === '[object Object]'

const repeatFreqTypes = ['day', 'week', 'month', 'year']
export const isRecurFreqType = (type: string): type is RecurFreqType =>
	type in repeatFreqTypes

export const isSectionId = (sectionId: string): sectionId is SectionId =>
	[
		'nextFewDays',
		'future',
		'inactive',
		'pinned',
		'lastWeek',
		'needsAttention',
		'notForMe',
		'notScheduled',
		'overdue',
		'priority',
		'thisWeek',
		'today',
		'unread',
		'upNext',
	].includes(sectionId)

// @deprecated use isStoreError from store/store-type-guards.ts
export const isStoreError = (storeError: unknown): storeError is StoreError => {
	return (
		isObject(storeError) && 'error' in storeError && 'message' in storeError
	)
}

export const isString = (val: unknown): val is string => typeof val === 'string'

export const isUndefined = (val: unknown): val is undefined =>
	typeof val === 'undefined'
