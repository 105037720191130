import remove from 'lodash/remove'

class Event {
	queue = {}
	_uid = 0

	emit(event, data) {
		const queue = this.queue[event]

		if (typeof queue === 'undefined') {
			return
		}

		queue.forEach(callback => callback(data))
	}

	on(event, callback) {
		if (typeof this.queue[event] === 'undefined') {
			this.queue[event] = []
		}

		callback.uid = this._uid++
		this.queue[event].push(callback)
	}

	off(event, callback) {
		remove(this.queue[event], c => c.uid === callback.uid)
	}
}

export default Event
