import { TaskActivityRequestGetQuery } from '@tyto/task-activity/task-activity-schemas'

import { taskKeys } from '../queries'

export type TaskActivityKeysAll = ['tasks', 'detail', string, 'activity']
export type TaskActivityKeysAllLists = [
	'tasks',
	'detail',
	string,
	'activity',
	'list',
]
export type TaskActivityKeysList =
	| [
			'tasks',
			'detail',
			string,
			'activity',
			'list',
			TaskActivityRequestGetQuery,
	  ]
	| ['tasks', 'detail', string, 'activity', 'list']

type TaskActivityKeys = {
	all: (taskId: string) => TaskActivityKeysAll
	lists: (taskId: string) => TaskActivityKeysAllLists
	list: (
		taskId: string,
		params?: TaskActivityRequestGetQuery
	) => TaskActivityKeysList
}

export const taskActivityKeys: TaskActivityKeys = {
	all: (taskId) => [...taskKeys.detail(taskId), 'activity'],
	lists: (taskId) => [...taskActivityKeys.all(taskId), 'list'],
	list: (taskId, params) =>
		params
			? [...taskActivityKeys.lists(taskId), params]
			: [...taskActivityKeys.lists(taskId)],
}
