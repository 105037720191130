import { filter } from 'remeda'

import { BoardStrategy, OwnerTreeTask } from '../projectsTypes'
import { buildTaskTreeItemsById } from './utils'

type Strategy = BoardStrategy<OwnerTreeTask>

const buildOwnerTree: Strategy['buildTree'] = (_, tasks = []) => {
	const ownedTasks = filter(tasks, (task) => Boolean(task.ownerId))

	return buildTaskTreeItemsById(
		'owner',
		'ownerRoot',
		ownedTasks,
		(task) => task.ownerId as string
	)
}

export const ownerStrategy: Strategy = {
	buildTree: buildOwnerTree,
	name: 'ownerStrategy',
}
