import forEach from 'lodash/forEach'
import filter from 'lodash/fp/filter'
import flow from 'lodash/fp/flow'
import map from 'lodash/fp/map'
import identity from 'lodash/identity'
import moment from 'moment'

import { mergeTask } from './task'

const DEFAULT = {
	hoursAllocated: 0,
	hoursTaken: 0,

	importance: 0,
	urgency: 0,
}

export const validateTask = (task) => {
	if (!task) return mergeTask({}, DEFAULT)

	const newTask = { ...task }

	forEach(newTask, (value, key) => {
		if (key.startsWith('_')) {
			delete newTask[key]
		}
	})

	if (newTask.currentTimer) {
		if (newTask.currentTimer.statusCode) {
			newTask.currentTimer = {
				date: newTask.currentTimer.startDate,
				status: newTask.currentTimer.statusCode,
			}
		}
	}

	if (newTask.childSortOrder) {
		newTask.childSortOrder = flow(
			map((id) => id.replace(' ', '')),
			filter(identity)
		)(newTask.childSortOrder)
	}

	newTask.repeat = null
	if (newTask.start) {
		newTask.startDate = newTask.start.date
		newTask.repeat = newTask.start.taskScheduleRecurView

		if (newTask.repeat) {
			newTask.repeat.time = moment
				.utc(newTask.repeat.time, 'HH:mm:ssZ')
				.local()
				.format('HH:mm:ssZ')
		}
	}

	return newTask
}
