import createTask from '../../tasks/createTask'
import mergeTask from '../../tasks/mergeTask'
import { AddTaskPayload, StoreError, Task, WorkflowIndex } from '../../types'
import { applyWorkflowActionsToTask } from '../../workflows'
import { workflowKeys } from '../queries'
import { AppState } from '../store-types'

type NormalisedTaskResult = { failed: string[]; task: Task }

export const isNormalisedTaskResult = (
	result: NormalisedTaskResult | StoreError
): result is NormalisedTaskResult =>
	Boolean((result as NormalisedTaskResult).task)

export const normaliseTask = (
	state: AppState,
	partialTask: AddTaskPayload
): NormalisedTaskResult | StoreError => {
	const failed: string[] = []
	const queryClient = state.queryClient

	const workflowById = queryClient.getQueryData<WorkflowIndex>(
		workflowKeys.list()
	)

	const playerId = state.player.id
	const inboxId = state.player.inboxTaskId

	if (!playerId) {
		return {
			error: new Error('Player is not logged in or has no id'),
			message: `Couldn't create task`,
		}
	}

	const normalisedTask = createTask(playerId, partialTask)

	// If there are workflow actions, apply them optimistically.
	// NOTE: This mutates the update data and the API will be doing the same
	// action on it's side. But it's mutation will result in no extra change
	// because it should resolve to the same value.
	if (normalisedTask.workflowData != null && normalisedTask.workflowData.id) {
		if (workflowById) {
			const workflow = workflowById[normalisedTask.workflowData.id]
			partialTask = applyWorkflowActionsToTask(
				normalisedTask,
				workflow,
				{} as Partial<Task>
			) as AddTaskPayload
		} else {
			failed.push('workflow')
		}
	}

	const newTask = mergeTask(normalisedTask, partialTask)

	if (normalisedTask.assigneeId === 'me') {
		newTask.assigneeId = playerId
	}

	if (normalisedTask.parentId === 'inbox') {
		if (inboxId) {
			newTask.parentId = inboxId
		} else {
			failed.push('project folder')
		}
	}

	return { failed, task: newTask } as NormalisedTaskResult
}

export default normaliseTask
