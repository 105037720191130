import { fromEvent, of } from 'rxjs'
import { filter, map, switchMap, tap } from 'rxjs/operators'
import { Socket } from 'socket.io-client'

import { getSocketClient } from '@tyto/dna'
import { env } from '@tyto/utils'

import { getToken } from './auth-provider'

// solves a jest testing issue
let token: string | null = ''
export let socket: Socket | null = null
;(function init() {
	if (getToken) {
		token = getToken()
		socket = token ? getSocketClient(token) : null
	}
})()

// Some debugging functions to manually open and close the socket connection.
// @ts-expect-error - we're adding these to the window object for debugging purposes
window.socket = {
	instance: socket,
	connect: () => socket && socket.open(),
	disconnect: () => socket && socket.close(),
}

if (env('NODE_ENV') === 'development') {
	socket &&
		socket.onAny((eventName, ...args) => {
			console.debug(`%c${eventName}`, 'color: #2ac059', ...args)
		})
}

// Type guard to ensure `socket` is not null
function isSocket(socket: Socket | null): socket is Socket {
	return socket !== null
}

export const socket$ = of(socket).pipe(filter(isSocket))

// Stream of connections
export const connect$ = socket$.pipe(
	switchMap((socket) => fromEvent(socket, 'connect').pipe(map(() => socket)))
)

// Listen for event after connection is valid
export const listenFor = (eventName: string) =>
	connect$.pipe(
		switchMap((socket) => fromEvent(socket, eventName)),
		tap(() => console.log(`socket listening for ${eventName}`))
	)
