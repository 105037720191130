import { IdentityCriteria } from './taskCriteria'
import { Criteria } from './types'

interface FilterDefaults {
	count?: number
	criteria?: Criteria
	id?: string | null
	label?: string
}

// A filter object to help with displaying filtering UI.
class Filter implements Criteria {
	criteria: Criteria = new IdentityCriteria()
	id: string | null = null
	label = ''
	check = this.criteria.check

	_type = ''
	_count = 0

	constructor(
		type: string,
		{ count, criteria, id, label = '' }: FilterDefaults = {}
	) {
		if (!type) throw new Error('type is required')

		this.id = id ?? type // default id to the filter type
		this._type = type

		if (criteria != null) this.criteria = criteria
		if (label != null) this.label = label

		if (count != null) this._count = count

		this.check = this.criteria.check
	}

	get count() {
		return this._count
	}
	set count(value) {
		this._count = value
	}

	get type() {
		return this._type
	}
}

export default Filter
