import { TaskListParams } from '../../api'

export type TaskKeysAll = ['tasks']
export type TaskKeysAllLists = ['tasks', 'list']
export type TaskKeysList = ['tasks', 'list', TaskListParams]
export type TaskKeysAllDetails = ['tasks', 'detail']
export type TaskKeysDetail = ['tasks', 'detail', string]
export type TaskKeysDetailTimeList = ['tasks', 'detail', string, 'time', 'list']
export type TaskKeysFilesList = ['tasks', 'detail', string, 'files', 'list']
export type TaskKeysRemindersList = [
	'tasks',
	'detail',
	string,
	'reminders',
	'list',
]

type TaskKeys = {
	all: TaskKeysAll
	lists: () => TaskKeysAllLists
	list: (params: TaskListParams) => TaskKeysList
	details: () => TaskKeysAllDetails
	detail: (taskId: string) => TaskKeysDetail
	detailTimeList: (taskId: string) => TaskKeysDetailTimeList
	filesList: (taskId: string) => TaskKeysFilesList
	remindersList: (taskId: string) => TaskKeysRemindersList
}

export const taskKeys: TaskKeys = {
	all: ['tasks'],
	lists: () => [...taskKeys.all, 'list'],
	list: (params) => [...taskKeys.lists(), params],
	details: () => [...taskKeys.all, 'detail'],
	detail: (taskId) => [...taskKeys.details(), taskId],
	detailTimeList: (taskId) => [...taskKeys.detail(taskId), 'time', 'list'],
	filesList: (taskId) => [...taskKeys.detail(taskId), 'files', 'list'],
	remindersList: (taskId) => [
		...taskKeys.detail(taskId),
		'reminders',
		'list',
	],
}
