export const FETCH_SETTINGS_FAILURE = 'FETCH_SETTINGS_FAILURE'
export const FETCH_SETTINGS_REQUEST = 'FETCH_SETTINGS_REQUEST'
export const FETCH_SETTINGS_SUCCESS = 'FETCH_SETTINGS_SUCCESS'

export const SET_GOOGLE_AUTH_CODE = 'SET_GOOGLE_AUTH_CODE'
export const SET_GOOGLE_SETTINGS = 'SET_GOOGLE_SETTINGS'

export const UPDATE_SETTINGS_FAILURE = 'UPDATE_SETTINGS_FAILURE'
export const UPDATE_SETTINGS_REQUEST = 'UPDATE_SETTINGS_REQUEST'
export const UPDATE_SETTINGS_SUCCESS = 'UPDATE_SETTINGS_SUCCESS'

export const FETCH_USER_EMAILS_REQUEST = 'FETCH_USER_EMAILS_REQUEST'
export const FETCH_USER_EMAILS_SUCCESS = 'FETCH_USER_EMAILS_SUCCESS'
export const FETCH_USER_EMAILS_FAILURE = 'FETCH_USER_EMAILS_FAILURE'

export const ADD_USER_EMAIL_REQUEST = 'ADD_USER_EMAIL_REQUEST'
export const ADD_USER_EMAIL_SUCCESS = 'ADD_USER_EMAIL_SUCCESS'
export const ADD_USER_EMAIL_FAILURE = 'ADD_USER_EMAIL_FAILURE'

export const DELETE_USER_EMAIL_REQUEST = 'DELETE_USER_EMAIL_REQUEST'
export const DELETE_USER_EMAIL_SUCCESS = 'DELETE_USER_EMAIL_SUCCESS'
export const DELETE_USER_EMAIL_FAILURE = 'DELETE_USER_EMAIL_FAILURE'

export const DEFAULT_USER_EMAIL_REQUEST = 'DEFAULT_USER_EMAIL_REQUEST'
export const DEFAULT_USER_EMAIL_SUCCESS = 'DEFAULT_USER_EMAIL_SUCCESS'
export const DEFAULT_USER_EMAIL_FAILURE = 'DEFAULT_USER_EMAIL_FAILURE'

export const SET_EMAIL_CREATE_FIELD = 'SET_EMAIL_CREATE_FIELD'
export const SET_SHOW_WALKTHROUGH = 'SET_SHOW_WALKTHROUGH'

export const RESEND_EMAIL_VERIFICATION_REQUEST =
	'RESEND_EMAIL_VERIFICATION_REQUEST'
export const RESEND_EMAIL_VERIFICATION_SUCCESS =
	'RESEND_EMAIL_VERIFICATION_SUCCESS'
export const RESEND_EMAIL_VERIFICATION_FAILURE =
	'RESEND_EMAIL_VERIFICATION_FAILURE'

export const fetchSettingsFailure = (reason) => ({
	type: FETCH_SETTINGS_FAILURE,
	reason,
})
export const fetchSettingsRequest = () => ({ type: FETCH_SETTINGS_REQUEST })
export const fetchSettingsSuccess = (response) => ({
	type: FETCH_SETTINGS_SUCCESS,
	response,
})

export const setGoogleAuthCode = (code) => ({
	type: SET_GOOGLE_AUTH_CODE,
	code,
})
export const setGoogleSettings = (settings) => ({
	type: SET_GOOGLE_SETTINGS,
	settings,
})

export const updateSettingsFailure = (reason) => ({
	type: UPDATE_SETTINGS_FAILURE,
	reason,
})
export const updateSettingsRequest = (payload) => ({
	type: UPDATE_SETTINGS_REQUEST,
	payload,
})
export const updateSettingsSuccess = (response) => ({
	type: UPDATE_SETTINGS_SUCCESS,
	response,
})

export const addUserEmail = (email) => ({ type: ADD_USER_EMAIL_REQUEST, email })
export const addUserEmailSuccess = (email) => ({
	type: ADD_USER_EMAIL_SUCCESS,
	email,
})
export const addUserEmailFailure = (reason) => ({
	type: ADD_USER_EMAIL_FAILURE,
	reason,
})

export const deleteUserEmail = (control) => ({
	type: DELETE_USER_EMAIL_REQUEST,
	control,
})
export const deleteUserEmailSuccess = (control) => ({
	type: DELETE_USER_EMAIL_SUCCESS,
	control,
})
export const deleteUserEmailFailure = (reason) => ({
	type: DELETE_USER_EMAIL_FAILURE,
	reason,
})

export const defaultUserEmail = (control) => ({
	type: DEFAULT_USER_EMAIL_REQUEST,
	control,
})
export const defaultUserEmailSuccess = (control) => ({
	type: DEFAULT_USER_EMAIL_SUCCESS,
	control,
})
export const defaultUserEmailFailure = (reason) => ({
	type: DEFAULT_USER_EMAIL_FAILURE,
	reason,
})

export const setEmailCreateField = (value) => ({
	type: SET_EMAIL_CREATE_FIELD,
	value,
})
export const setShowWalkthrough = (payload) => ({
	type: SET_SHOW_WALKTHROUGH,
	payload,
})

export const resendEmailVerification = (control) => ({
	type: RESEND_EMAIL_VERIFICATION_REQUEST,
	control,
})
export const resendEmailVerificationSuccess = (control) => ({
	type: RESEND_EMAIL_VERIFICATION_SUCCESS,
	control,
})
export const resendEmailVerificationFailure = (reason) => ({
	type: RESEND_EMAIL_VERIFICATION_FAILURE,
	reason,
})
