/**
 * Convert a value in bytes to something a little more human readable.
 */
export const bytesToString = (bytes: number, decimals?: number) => {
	if (bytes === 0) return '0 Bytes'
	const k = 1024,
		dm = decimals || 2,
		sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
		i = Math.floor(Math.log(bytes) / Math.log(k))
	return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export default bytesToString
