import type { QueryClient } from '@tanstack/react-query'
import { produce } from 'immer'
import { isDeepEqual, omit } from 'remeda'

import { createEmptyDatePaginatedList } from '../../api-adapter/api-adapter-utils'
import { createEmptyInfiniteList } from '../../helpers/data-containers'
import {
	isTaskActivityType,
	overwriteActivity,
	shouldOverwriteLastActivity,
	TaskActivityRequestGetQuery,
	WrappedTaskActivityV2,
} from '../../task-activity'
import { ApiListResult } from '../api'
import { isInfiniteData } from '../store-type-guards'
import { TaskActivityQueryData } from './task-activity-types'
import { taskActivityKeys } from './taskActivityKeys'

export const createEmptyTaskActivityQueryData = (): TaskActivityQueryData =>
	createEmptyInfiniteList<WrappedTaskActivityV2>()

const isSameAsLastActivity = (
	activity: WrappedTaskActivityV2,
	lastActivity: WrappedTaskActivityV2
) => {
	const omitFields = (activity: WrappedTaskActivityV2) => {
		return {
			...omit(activity, ['id', 'dateCreated']),
			data: omit(activity.data, ['meta']),
		}
	}

	return (
		lastActivity &&
		isDeepEqual(omitFields(activity), omitFields(lastActivity))
	)
}

export const addToTaskActivityLists = (
	queryClient: QueryClient,
	activity: WrappedTaskActivityV2
) => {
	const updater = produce((draft) => {
		if (!draft) {
			draft = {}
		}
		if (!draft.pages) {
			draft.pages = [
				createEmptyDatePaginatedList<WrappedTaskActivityV2>(),
			]
			draft.pageParams = []
		}
		if (
			!draft.pages[0].items.find(
				(item: WrappedTaskActivityV2) => item.id === activity.id
			)
		) {
			const lastTaskActivity = draft.pages[0].items[0]
			if (shouldOverwriteLastActivity(lastTaskActivity, activity)) {
				draft.pages[0].items[0] = overwriteActivity(
					lastTaskActivity,
					activity
				)
			} else {
				draft.pages[0].items.unshift(activity)
			}
			draft.pages[0].count = draft.pages[0].items.length
		}

		// If the draft starts off as undefined, we need to return it to create the new object.
		return draft
	})

	const queriesData = queryClient.getQueriesData(
		taskActivityKeys.lists(activity.taskId)
	)
	if (queriesData.length > 0) {
		queriesData.forEach(([queryKey, data]) => {
			const params = queryKey[5] as
				| TaskActivityRequestGetQuery
				| undefined
			if (params && params.type && !isTaskActivityType(params.type)) {
				return
			}

			if (isInfiniteData<ApiListResult<WrappedTaskActivityV2>>(data)) {
				const lastActivity = data.pages[0]
					.items[0] as WrappedTaskActivityV2
				// If the activity from the socket matches the last activity in
				// memory, then we want to abort and not trigger an update.
				if (isSameAsLastActivity(activity, lastActivity)) {
					return
				}
			}

			queryClient.setQueryData(queryKey, updater)
		})
	} else {
		// TODO: Ideally we need a way to enforce using only one of these...
		queryClient.setQueryData(
			taskActivityKeys.list(activity.taskId, { limit: 10 }),
			updater
		)
		queryClient.setQueryData(
			taskActivityKeys.list(activity.taskId),
			updater
		)
	}
}

export const getActivityMeta = (activity: WrappedTaskActivityV2) => {
	if ('meta' in activity.data) {
		return activity.data.meta
	} else {
		return {
			user: { id: '', name: '', nickname: '', gravatar: '' },
		}
	}
}
