import { put, takeLatest } from 'redux-saga/effects'
import {
	FETCH_WHATS_NEW_REQUEST,
	fetchWhatsNewFailure,
	fetchWhatsNewSuccess,
} from '../actions'
import { logError } from '../log-error'

export function* whatsNewSaga() {
	yield takeLatest(FETCH_WHATS_NEW_REQUEST, handleFetchWhatsNew)
}

function* handleFetchWhatsNew() {
	try {
		const data = []
		yield put(fetchWhatsNewSuccess(data))
	} catch (err) {
		logError(err)
		yield put(fetchWhatsNewFailure(err))
	}
}
