import filter from 'lodash/fp/filter'
import flow from 'lodash/fp/flow'
import map from 'lodash/fp/map'
import reject from 'lodash/fp/reject'
import uniqBy from 'lodash/fp/uniqBy'
import identity from 'lodash/identity'
import isArray from 'lodash/isArray'
import isEqual from 'lodash/isEqual'
import mergeWith from 'lodash/mergeWith'
import omitBy from 'lodash/omitBy'
import reduce from 'lodash/reduce'
import moment from 'moment'

import { createTaskId } from '@tyto/helpers'
import { Task, TaskParent } from '@tyto/types'

export const diffTasks = (oldTask: Task, newTask: Task) =>
	reduce(
		newTask,
		(acc, value, key) => {
			if (!isEqual(oldTask[key], newTask[key])) {
				acc[key] = {
					old: oldTask[key],
					new: newTask[key],
				}
			}
			return acc
		},
		{}
	)

export const addTaskToList =
	(newTask: Task) =>
	(oldList: Task[] = []) =>
		uniqBy('id')([...oldList, newTask])

export const mergeTaskInList = (partialTask: Task) =>
	map((oldTask: Task) =>
		oldTask.id === partialTask.id
			? mergeTask(oldTask, partialTask)
			: oldTask
	)

export const removeTaskFromList = (taskId: string) => reject({ id: taskId })

export const replaceTaskInList = (newTask: Task) =>
	map((oldTask: Task) => (oldTask.id === newTask.id ? newTask : oldTask))

export const getBreadcrumbString = (parents: TaskParent[] = []) =>
	parents.map(({ title }) => title).join(' / ')

// Returning undefined defaults to normal merge behaviour.
const taskMergePredicate = (objValue: any, srcValue: any) => {
	if (isArray(objValue)) {
		return srcValue
	}
}

export const mergeTask = (task: Task, ...sources: Partial<Task>[]) =>
	mergeWith({}, task, ...sources, taskMergePredicate)

const TASK_DEFAULTS = {
	hasPermission: true,
	hoursAllocated: 0,
	hoursTaken: 0,
	importance: 0,
	statusCode: 'new',
	urgency: 0,
}

const pruneTask = (value, key) => key.startsWith('_')

export const makeNormaliseTask =
	(playerId: string, inboxId?: string) => (task: Task) => {
		if (!playerId)
			throw new Error(`Can't normalise task without a valid playerId`)

		const defaultTask = mergeTask(
			{ id: createTaskId(playerId) },
			TASK_DEFAULTS
		)
		defaultTask.dateCreated = moment().format()

		if (!task) return defaultTask

		const prunedTask = omitBy(task, pruneTask)
		const newTask = mergeTask(defaultTask, prunedTask)

		if (task.assigneeId === 'me') {
			task.assigneeId = playerId
		}

		if (!task.id) {
			newTask.id = createTaskId(playerId)
		}

		if (!task.ownerId) {
			newTask.ownerId = playerId
		}

		if (task.parentId === 'inbox' && inboxId) {
			newTask.parentId = inboxId
		}

		if (newTask.currentTimer) {
			if (newTask.currentTimer.statusCode) {
				newTask.currentTimer = {
					date: newTask.currentTimer.startDate,
					status: newTask.currentTimer.statusCode,
				}
			}
		}

		if (newTask.childSortOrder) {
			newTask.childSortOrder = flow(
				map((id) => id.replace(' ', '')),
				filter(identity)
			)(newTask.childSortOrder)
		}

		newTask.repeat = null
		if (newTask.start) {
			newTask.startDate = newTask.start.date
			newTask.repeat = newTask.start.taskScheduleRecurView

			if (newTask.repeat) {
				newTask.repeat.time = moment
					.utc(newTask.repeat.time, 'HH:mm:ssZ')
					.local()
					.format('HH:mm:ssZ')
			}
		}

		return newTask
	}
