import { put, takeLatest } from 'redux-saga/effects'

import {
	SOCKET_INVITE_ACCEPTED,
	SOCKET_INVITE_DECLINED,
	addUser,
} from '../actions'
import { logWarning } from '../log-error'

//import { createSnackbarKey } from '../reducers/snackbar-reducer'

export function* invite() {
	yield takeLatest(SOCKET_INVITE_ACCEPTED, handleInviteAccepted)
	yield takeLatest(SOCKET_INVITE_DECLINED, handleInviteDeclined)
}

function* handleInviteAccepted({ payload }) {
	const { user } = payload
	try {
		//const key = createSnackbarKey()
		console.log('user accepted invite', user)
		yield put(addUser(user))
		/* yield put(
			enqueueSnackbar({
				message: `${user.name} has joined your team!`,
				options: {
					key,
					action: (
						<SnackbarCloseButton
							snackbarKey={key}
							title={'Great!'}
						/>
					),
					persist: true,
				},
			})
		) */
	} catch (err) {
		return logWarning('invite.handleInviteAccepted', err)
	}
}

function* handleInviteDeclined({ payload }) {
	//const { user } = payload
	try {
		/*const key = createSnackbarKey()
		 yield put(
			enqueueSnackbar({
				message: `${user.name} declined your invitation.`,
				options: {
					key,
					action: (
						<SnackbarCloseButton snackbarKey={key} title={'Okay'} />
					),
					persist: true,
				},
			})
		) */
	} catch (err) {
		return logWarning('invite.handleInviteDeclined', err)
	}
	yield
}
