import { endOfDay, isAfter, isBefore, parseISO } from 'date-fns'

import { TraceableError } from '../../helpers/error-handling'
import { createParentsItem } from '../../helpers/task'
import { StoreContext, Task } from '../../types'
import { fetchTask, taskKeys } from '../queries'

// Return task.parents fields based off a given parent task
export const getParentsByParent = (
	parentTask?: Pick<Task, 'id' | 'parents' | 'title'>
) => {
	const parents = parentTask?.parents || []
	if (parentTask) {
		return [...parents, createParentsItem(parentTask)]
	} else {
		return []
	}
}

export const getTasksFromIdsAsync = async (
	{ apiAdapter, queryClient }: StoreContext,
	taskIds: string[]
) => {
	const taskPromises = taskIds.map(async (taskId) =>
		queryClient.fetchQuery(
			taskKeys.detail(taskId),
			fetchTask(apiAdapter, queryClient, taskId)
		)
	)

	const tasks = await Promise.all(taskPromises)

	const emptyValues = tasks.filter((task) => task === undefined)

	if (emptyValues.length > 0) {
		throw new TraceableError('Failed to prefetch missing tasks')
	}

	return tasks as Task[]
}

export const filterFutureTasks = (tasks: Pick<Task, 'startDate'>[]) =>
	tasks.filter((task) =>
		task.startDate
			? isAfter(parseISO(task.startDate), endOfDay(Date.now()))
			: true
	)

export const rejectFutureTasks = (tasks: Pick<Task, 'startDate'>[]) =>
	tasks.filter((task) =>
		task.startDate
			? isBefore(parseISO(task.startDate), endOfDay(Date.now()))
			: true
	)
