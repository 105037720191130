import * as chrono from 'chrono-node'
import { Parser, ParsingReference, Refiner } from 'chrono-node'
import {
	addDays,
	addMonths,
	format,
	getDate,
	getDay,
	getMonth,
	getYear,
	isBefore,
	isSameDay,
	setDate,
} from 'date-fns'
import { omit } from 'remeda'

export type NlpParseDateOptions = chrono.ParsingOption & {
	refDate?: Date | ParsingReference
}

const customChrono = chrono.casual.clone()

const mapShortDays = ['su', 'mo', 'tu', 'we', 'th', 'fr', 'sa']
const mapShortMonths = [
	'jan',
	'feb',
	'mar',
	'apr',
	'may',
	'jun',
	'jul',
	'aug',
	'sep',
	'nov',
	'dec',
]

const getDateFromWeekday = (
	refDate: Date,
	weekdayText: string,
	forwardDate = false
) => {
	const parsedWeekDay = mapShortDays.indexOf(weekdayText)
	const refWeekday = refDate.getDay()
	const diff = parsedWeekDay - refWeekday

	const add =
		parsedWeekDay < refWeekday && forwardDate
			? diff + 7 // add a week
			: diff
	return addDays(refDate, add)
}

const shortDaysParser: Parser = {
	pattern: () => /\b(mo|tu|we|th|fr|sa|su)\b/i,
	extract: (context, match) => {
		const weekday = mapShortDays.indexOf(match[1])
		const parsedWeekday = getDay(context.refDate)
		const parsingResult = context.createParsingComponents({ weekday })
		const diff = weekday - parsedWeekday
		let newDate = context.refDate
		if (context.option.forwardDate && weekday <= parsedWeekday) {
			newDate = addDays(newDate, diff + 7)
		} else {
			newDate = addDays(context.refDate, diff)
		}
		parsingResult.imply('day', getDate(newDate))
		parsingResult.imply('month', getMonth(newDate) + 1)
		parsingResult.imply('year', getYear(newDate))
		return parsingResult
	},
}

const shortMonthParser: Parser = {
	pattern: () => /\b(jan|feb|mar|apr|may|jun|jul|aug|sep|nov|dec)\b/i,
	extract: (context, match) => {
		return { month: mapShortMonths.indexOf(match[1]) + 1 }
	},
}

const shortTomorrowParser: Parser = {
	pattern: () => /^tom?/i,
	extract: (context) => {
		const newDate = addDays(context.refDate, 1)
		return { day: newDate.getDate() }
	},
}

const partialDaysParser: Parser = {
	pattern: () => /(mo|tu|we|th|fr|sa|su)[a-z]*/i,
	extract: (context, match) => {
		const newDate = getDateFromWeekday(
			context.refDate,
			match[1],
			context.option.forwardDate
		)
		return {
			day: newDate.getDate(),
			month: newDate.getMonth() + 1,
			year: newDate.getFullYear(),
		}
	},
}

const numberIntoDayParser: Parser = {
	pattern: () => /^(\d{1,2})$/i,
	extract: (context, match) => {
		// Make sure dayNumber is a valid day of the month
		const dayNumber = parseInt(match[1])
		if (dayNumber < 1 || dayNumber > 31) {
			return null
		}

		let newDate = setDate(context.refDate, dayNumber)

		// When setting the date to the 31st (for example) of a month that
		// doesn't have 31 days, date-fns just advances the month and sets the
		// date to the 1st. So here we can reset and iterate through the months
		// until we find one that has the 31st.
		const resetAndAddMonths = (num: number) =>
			setDate(addMonths(context.refDate, num), dayNumber)

		if (context.option.forwardDate && isBefore(newDate, context.refDate)) {
			newDate = addMonths(newDate, 1)
		}

		// No need to iterate past 2 months
		let max = 0
		while (newDate.getDate() !== dayNumber && max++ < 3) {
			newDate = resetAndAddMonths(max)
		}

		// We replace the text because chrono-node has a default refiner that
		// removes text that has numbers only.
		const textDate = format(newDate, 'd MMM')
		return context.createParsingResult(0, textDate, {
			day: newDate.getDate(),
			month: newDate.getMonth() + 1,
			year: newDate.getFullYear(),
		})
	},
}

const timeParser: Parser = {
	pattern: () =>
		/(\d{1,2})(?::(\d{2}))?(?::(\d{2}))?(?:\s?(a|p)(?:\.?m\.?)?)?/i,
	extract: (context, match) => {
		const period = match[4] ? match[4].toLowerCase() : null
		const hour =
			period && period === 'p'
				? parseInt(match[1]) + 12
				: parseInt(match[1])
		const minute = match[2] ? parseInt(match[2]) : 0
		const second = match[3] ? parseInt(match[3]) : 0
		return { hour, minute, second }
	},
}

const sameDayRefiner: Refiner = {
	refine: (context, results) => {
		results.forEach((result) => {
			if (
				context.text.toLowerCase() === 'today' ||
				!context.option.forwardDate ||
				!isSameDay(context.refDate, result.date())
			) {
				return
			}

			const parsedWeekday = getDay(context.refDate)
			const day = result.start.isCertain('day')
				? result.start.get('day')
				: null
			const weekday = result.start.isCertain('weekday')
				? result.start.get('weekday')
				: null
			if (day !== null) {
				result.start.assign('day', day + 7)
			} else if (weekday !== null && weekday <= parsedWeekday) {
				const newDate = addDays(result.start.date(), 7)
				result.start.imply('day', getDate(newDate))
				result.start.imply('month', getMonth(newDate) + 1)
				result.start.imply('year', getYear(newDate))
			}
		})
		return results
	},
}

const defaultTimeRefiner: Refiner = {
	refine: (context, results) => {
		// If no time is specified then default to the start of the day
		results.forEach((result) => {
			if (result.start.isOnlyDate()) {
				result.start.assign('hour', 0)
				result.start.assign('minute', 0)
				result.start.assign('second', 0)
			}
		})

		return results
	},
}

const nextWeekRefiner: Refiner = {
	refine: (context, results) => {
		// If no time is specified then default to the start of the day
		results.forEach((result) => {
			const match = result.text.match(
				/next week (mo|tu|we|th|fr|sa|su)[a-z]*/i
			)
			if (match) {
				const newDate = addDays(
					getDateFromWeekday(context.refDate, match[1]),
					7
				)
				const parsedWeekDay = mapShortDays.indexOf(match[1])
				result.start.assign('day', newDate.getDate())
				result.start.assign('weekday', parsedWeekDay)
			}
		})

		return results
	},
}

customChrono.parsers.push(shortDaysParser)
customChrono.parsers.push(shortMonthParser)
customChrono.parsers.push(shortTomorrowParser)
customChrono.parsers.push(partialDaysParser)
customChrono.parsers.push(numberIntoDayParser)
customChrono.parsers.push(timeParser)
customChrono.refiners.push(sameDayRefiner)
customChrono.refiners.push(defaultTimeRefiner)
customChrono.refiners.push(nextWeekRefiner)

// Convenience function to apply some common defaults
export const nlpParseDate = (text: string, options: NlpParseDateOptions = {}) =>
	customChrono.parseDate(text, options.refDate, omit(options, ['refDate']))
