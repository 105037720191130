import {
	TaskActivity,
	TaskActivityDelete,
	TaskActivityDeleteSchema,
} from '../task-activity-schemas'

export const parseTaskDeleteToString = (
	activity: TaskActivityDelete
): string => {
	const meta = activity.meta

	return `${meta.user.nickname} deleted this task.`
}

export const validateTaskDelete = (taskActivity: TaskActivity) =>
	TaskActivityDeleteSchema.safeParse(taskActivity)
