import { List } from 'immutable'
import indexOf from 'lodash/fp/indexOf'
import pick from 'lodash/fp/pick'
import uniq from 'lodash/uniq'
import { put, select, takeEvery } from 'redux-saga/effects'

import { applyWorkflowActionsToTask } from '@tyto/dna'

import {
	mergeTask,
	mergeTasks,
	MOVE_TASK,
	moveTask,
	SOCKET_MOVE_TASK,
} from '../actions'
import { getTask } from '../reducers/tasks-reducer'
import { toJS } from '../utils'

export function* moveTasksSaga() {
	yield takeEvery(MOVE_TASK, handleMoveTask)
	yield takeEvery(SOCKET_MOVE_TASK, handleSocketMoveTask)
}

function* handleMoveTask({ payload }) {
	const { destination, source, taskId } = payload
	const state = yield select()
	const sourceParentTask = getTask(state, source.parentId, null)
	const destParentTask = getTask(state, destination.parentId, null)

	// Remove id from source parent's sort order
	if (sourceParentTask && sourceParentTask !== destParentTask) {
		let sortOrder = sourceParentTask.get('childSortOrder', List())
		// Remove from list first if it exists
		if (sortOrder.includes(taskId)) {
			sortOrder = sortOrder.delete(sortOrder.indexOf(taskId))
		}
		// Update the source parent
		yield put(
			mergeTask({ id: source.parentId, childSortOrder: sortOrder.toJS() })
		)
	}

	// Add id to destination parent
	if (destParentTask) {
		let sortOrder = destParentTask.get('childSortOrder', List())

		// If moving within the same parent, then delete the current taskId from
		// the sort.
		if (sourceParentTask === destParentTask) {
			sortOrder = sortOrder.delete(sortOrder.indexOf(taskId))
		}

		if (destination.index === -1) {
			// Add to the end if no index.
			sortOrder = sortOrder.push(taskId)
		} else {
			sortOrder = sortOrder.insert(destination.index, taskId)
		}

		// Update the destination parent
		const childSortOrder = uniq(sortOrder.toJS())
		yield put(mergeTask({ id: destination.parentId, childSortOrder }))
	}

	// Update the task if the parent changed
	if (source.parentId !== destination.parentId) {
		const jsParentTask = destParentTask ? destParentTask.toJS() : []
		const parents = [
			...(jsParentTask.parents || []),
			pick(['id', 'title'])(jsParentTask),
		]

		let changes = {}
		// If the task is moved into a parent with child workflow, then update
		// the task with the workflow actions.
		if (destParentTask && destParentTask.get('workflowData')) {
			const workflowData = toJS(destParentTask.get('workflowData'))
			const workflow = toJS(
				state.getIn(['workflow', 'byId', workflowData?.childId])
			)
			const oldTask = toJS(getTask(state, taskId, {}))
			changes = applyWorkflowActionsToTask({}, workflow, oldTask)
		}

		yield put(
			mergeTask({
				id: taskId,
				parentId: destination.parentId,
				parents,
				...changes,
			})
		)
	}
}

function* handleSocketMoveTask({ payload }) {
	const { task, destParent, srcParent } = payload
	const tasks = [task]

	if (srcParent && destParent && srcParent.id !== destParent.id) {
		tasks.push(srcParent)
	}
	if (destParent) tasks.push(destParent)
	yield put(mergeTasks(tasks))

	const state = yield select()
	const sourceParentTask = srcParent ? getTask(state, srcParent.id) : null
	let sortOrder = sourceParentTask
		? sourceParentTask.get('childSortOrder', List())
		: []

	yield put(
		moveTask(
			task.id,
			{
				parentId: srcParent ? srcParent.id : null,
				index: indexOf(task.id)(sortOrder),
			},
			{
				parentId: destParent ? destParent.id : null,
				index: destParent
					? indexOf(task.id)(destParent.childSortOrder)
					: -1,
			}
		)
	)
}
