import { call, put, take, takeEvery, takeLatest } from 'redux-saga/effects'

import {
	ADD_USER_EMAIL_REQUEST,
	addUserEmailFailure,
	addUserEmailSuccess,
	DEFAULT_USER_EMAIL_REQUEST,
	defaultUserEmailFailure,
	defaultUserEmailSuccess,
	DELETE_USER_EMAIL_REQUEST,
	deleteUserEmailFailure,
	deleteUserEmailSuccess,
	FETCH_SETTINGS_REQUEST,
	FETCH_USER_EMAILS_REQUEST,
	fetchSettingsFailure,
	fetchSettingsSuccess,
	RESEND_EMAIL_VERIFICATION_REQUEST,
	resendEmailVerificationFailure,
	resendEmailVerificationSuccess,
	SET_GOOGLE_AUTH_CODE,
	SET_SHOW_WALKTHROUGH,
	setGoogleSettings,
	UPDATE_SETTINGS_REQUEST,
	updateSettingsFailure,
	updateSettingsSuccess,
} from '../actions'
import Api from '../api'
import { logWarning } from '../log-error'

export function* fetchSettingsSaga() {
	yield takeLatest(FETCH_SETTINGS_REQUEST, fetchSettings)
}

function* fetchSettings() {
	try {
		const response = yield call(Api.fetchSettings)
		yield put(fetchSettingsSuccess(response))
	} catch (e) {
		yield put(fetchSettingsFailure(e.message))
	}
}

export function* setGoogleAuthCodeSaga() {
	const { code } = yield take(SET_GOOGLE_AUTH_CODE)
	yield put(
		setGoogleSettings({
			syncToGoogleCalendar: true,
		})
	)

	try {
		const response = yield call(Api.syncGoogleCalander, code)
		console.warn('TODO: setGoogleAuthCodeSaga - handle response', response)
	} catch (e) {
		return logWarning(
			'SettingsAuthRedirect - failed to send googleOneTimeCode',
			e
		)
	}
}

function* updateSettings({ payload }) {
	yield put(setGoogleSettings(payload))

	try {
		const response = yield call(Api.updateSettings, payload)
		yield put(updateSettingsSuccess(response))
	} catch (e) {
		yield put(updateSettingsFailure(e.message))
	}
}

export function* updateSettingsSaga() {
	yield takeEvery(UPDATE_SETTINGS_REQUEST, updateSettings)
	yield takeLatest(SET_SHOW_WALKTHROUGH, handleSetShowWalkthrough)
}

function* handleSetShowWalkthrough({ payload }) {
	yield call(Api.updateSettings, { showWalkthrough: Boolean(payload) })
}

export function* addEmail() {
	while (true) {
		const { email } = yield take(ADD_USER_EMAIL_REQUEST)
		try {
			const receivedEmail = yield call(Api.addUserEmail, email)
			yield put(addUserEmailSuccess(receivedEmail))
		} catch (e) {
			console.log(e)
			yield put(addUserEmailFailure(e.response.data.message))
		}
	}
}

export function* defaultEmail() {
	while (true) {
		const { control } = yield take(DEFAULT_USER_EMAIL_REQUEST)
		try {
			yield call(Api.defaultUserEmail, control)
			yield put(defaultUserEmailSuccess(control))
		} catch (e) {
			yield put(defaultUserEmailFailure(e.data.message))
		}
	}
}

export function* deleteEmail() {
	while (true) {
		const { control } = yield take(DELETE_USER_EMAIL_REQUEST)
		try {
			yield call(Api.deleteUserEmail, control)
			yield put(deleteUserEmailSuccess(control))
		} catch (e) {
			yield put(deleteUserEmailFailure(e.message))
		}
	}
}

export function* resendEmailVerification() {
	while (true) {
		const { control } = yield take(RESEND_EMAIL_VERIFICATION_REQUEST)
		try {
			yield call(Api.resendEmailVerification, control)
			yield put(resendEmailVerificationSuccess(control))
		} catch (e) {
			yield put(resendEmailVerificationFailure(e.message))
		}
	}
}
