// Create guaranteed error from javascript catch result
export const createError = (
	err: unknown,
	{ fallbackMessage } = { fallbackMessage: 'Unknown error' }
) => {
	if (err instanceof Error) {
		return err
	} else if (typeof err === 'string') {
		return new Error(err)
	} else {
		return new Error(fallbackMessage, { cause: err })
	}
}
