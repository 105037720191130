import moment from 'moment'
import { call, put, select } from 'redux-saga/effects'
import { v4 as uuidv4 } from 'uuid'

import {
	chatOpened,
	createChatRoomSuccess,
	enqueueSnackbar,
	sendMessageFailure,
	sendMessageSuccess,
	setLastReadDate,
	startChat,
	updateLastReadDate as updateLastReadDateAction,
	createChatRoomFailure,
	updateChatRoomSuccess,
	updateChatRoomFailure,
	stopChat,
} from '../actions'
import Api from '../api'
import { logWarning } from '../log-error'
import { getCurrentRoomId } from '../reducers/chat-reducer'
import { getPlayerId } from '../reducers/player-reducer'
import { handleError } from '../sentry'
import fileHelper from '../utils/fileHelper'

export function* chatSaga() {
	// Disabled saga
	// yield takeLatest(START_CHAT, initChat)
	// yield takeEvery(SEND_MESSAGE, sendMessage)
	// yield takeLatest(UPDATE_LAST_READ_DATE, updateLastReadDate)
	// yield takeLatest(NOTIFY_CHAT_NOW, notifyChatNow)
	// yield takeLatest(CREATE_ROOM_REQUEST, createChatRoom)
	// yield takeLatest(UPDATE_ROOM_REQUEST, updateChatRoom)
	// yield takeEvery(CHAT_ROOM_REMOVED, chatRoomRemoved)
	// yield takeEvery(ARCHIVE_ROOM, archiveRoom)
	// yield takeEvery(CHAT_LAST_READ_DATE_UPDATED, lastReadUpdated)
}

function* initChat({ payload }) {
	if (!payload) {
		payload = yield select(getCurrentRoomId)
		if (!payload) {
			return
		}
	}

	//let room = yield select((state) => getRoom(state, payload))
	// if (!room) {
	// 	const otherUserId = payload.replace(playerId, '')
	// 	// if this is a user to user chat create a room
	// 	if (otherUserId !== payload) {
	// 		yield put(createChatRoomAction({ userIds: [otherUserId] }))
	// 		yield take(CREATE_ROOM_SUCCESS)
	// 		room = yield select((state) => getRoom(state, payload))
	// 	} else {
	// 		// otherwise get a fresh list of rooms
	// 		//yield put(fetchChatRoomsRequest())
	// 		take(FETCH_CHAT_MESSAGES_SUCCESS)
	// 	}
	// }

	//yield put(fetchChatMessagesRequest(payload))
	// if (room && room.get('roomType') === 'user') {
	// 	const otherUserId = room.get('users').find((id) => id !== playerId)
	// 	yield put(
	// 		fetchUserOptions(otherUserId, [optionIds.NOTIFICATIONS_STATE])
	// 	)
	// }
	yield put(chatOpened(payload))
	yield put(updateLastReadDateAction(payload, moment().format()))
}

function* createChatRoom({ payload }) {
	try {
		const room = yield call(Api.createRoom, payload)
		if (!room) {
			return
		}

		const playerId = yield select(getPlayerId)
		yield put(
			createChatRoomSuccess({
				...room,
				users: [playerId, ...payload.userIds],
			})
		)
		yield put(startChat(room.id))
	} catch (err) {
		yield put(
			enqueueSnackbar({
				message: `Failed to create room`,
			})
		)
		handleError(err)
		yield put(createChatRoomFailure(err))
	}
}

function* updateChatRoom({ payload }) {
	try {
		const room = yield call(Api.updateRoom, payload)

		const playerId = yield select(getPlayerId)
		yield put(
			updateChatRoomSuccess({
				...room,
				users: [playerId, ...payload.userIds],
			})
		)
	} catch (err) {
		yield put(updateChatRoomFailure(err))
		yield put(
			enqueueSnackbar({
				message: `Failed to save room`,
			})
		)
		handleError(err)
	}
}

function* notifyChatNow({ payload: roomId }) {
	try {
		yield call(Api.notifyChatNow, roomId)
	} catch (err) {
		handleError(err)
	}
}

function* chatRoomRemoved({ payload }) {
	const roomId = yield select(getCurrentRoomId)
	if (roomId === payload) {
		yield put(startChat(null))
		yield put(stopChat())
	}
}

function* archiveRoom({ payload }) {
	try {
		yield call(Api.archiveRoom, payload)
	} catch (e) {
		logWarning(`Couldn't archive room ${payload}`, e)
	}
}
