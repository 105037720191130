import { fromJS, List, Map, OrderedSet } from 'immutable'
import { combineReducers } from 'redux-immutable'
import { createSelector } from 'reselect'

import { isRecentlyActive } from '@tyto/helpers'

import {
	ADD_USER,
	FETCH_RECENT_ASSIGNEES_SUCCESS,
	FETCH_TEAM_SUCCESS,
	MERGE_USER,
	RECEIVE_USER_OPTIONS,
	SET_ADMIN_FAILURE,
	SET_ADMIN_REQUEST,
	SET_ADMIN_SUCCESS,
	SET_IS_ONLINE,
	SET_USER,
	//SET_USER_FOCUS,
	//SET_USER_FOCUS_MANY,
	SET_USER_OPTIONS,
	SET_USER_PRODUCTIVITY,
	SET_USER_STATS,
	SET_USER_WORKLOAD,
	SOCKET_USER_FOCUS,
	UPDATE_USER,
	USER_IS_OFFLINE,
	USER_IS_ONLINE,
} from '../actions'
import { validateUser } from '../utils'
import { SelectorStore } from '../utils/SelectorStore'
import { getPlayer, getPlayerId } from './player-reducer'
import { getTask } from './tasks-reducer'

const byId = (state = Map(), action) => {
	switch (action.type) {
		case ADD_USER:
			return state.set(
				action.payload.id,
				fromJS(validateUser(action.payload))
			)

		case FETCH_TEAM_SUCCESS:
			return state.mergeDeep(
				action.response.reduce(
					(acc, user) => acc.set(user.id, fromJS(validateUser(user))),
					Map()
				)
			)

		case SET_USER:
			return state.set(action.user.id, fromJS(validateUser(action.user)))

		case MERGE_USER:
		case UPDATE_USER:
			if (state.get(action.payload.id)) {
				const newUser = fromJS(validateUser(action.payload))
				const newState = state.update(action.payload.id, (user) =>
					user.equals(newUser) ? user : user.merge(newUser)
				)
				return state.equals(newState) ? state : newState
			} else {
				return state
			}

		default:
			return state
	}
}

const focus = (state = Map(), action) => {
	switch (action.type) {
		//case SET_USER_FOCUS:
		//	return state.set(action.payload.userId, fromJS(action.payload))

		// case SET_USER_FOCUS_MANY:
		case SOCKET_USER_FOCUS:
			return state.merge(fromJS(action.payload))

		default:
			return state
	}
}

const ids = (state = OrderedSet(), action) => {
	switch (action.type) {
		case ADD_USER:
			return state.add(action.payload.id)

		default:
			return state
	}
}

const isSettingAdmin = (state = false, action) => {
	switch (action.type) {
		case SET_ADMIN_REQUEST:
			return true
		case SET_ADMIN_FAILURE:
		case SET_ADMIN_SUCCESS:
			return false
		default:
			return state
	}
}

const online = (state = OrderedSet(), action) => {
	switch (action.type) {
		case ADD_USER:
			return state.add(action.payload.id)

		case SET_IS_ONLINE: {
			const newState = OrderedSet(action.payload)
			return state.equals(newState) ? state : newState
		}

		case USER_IS_OFFLINE:
			return state.delete(action.id)

		case USER_IS_ONLINE:
			return state.add(action.id)

		default:
			return state
	}
}

const options = (state = Map(), action) => {
	switch (action.type) {
		case RECEIVE_USER_OPTIONS:
		case SET_USER_OPTIONS: {
			const { userId, options } = action.payload
			const newOptions = fromJS(options)
			return state.update(userId, (options) =>
				options && options.equals(newOptions) ? options : newOptions
			)
		}

		default:
			return state
	}
}

const productivity = (state = Map(), action) => {
	switch (action.type) {
		case SET_USER_PRODUCTIVITY: {
			const { productivity, userId } = action.payload
			const newProductivity = fromJS(productivity)
			return state.update(userId, (productivity) =>
				productivity && productivity.equals(newProductivity)
					? productivity
					: newProductivity
			)
		}

		default:
			return state
	}
}

const recentAssignees = (state = OrderedSet(), action) => {
	let userIds
	switch (action.type) {
		case FETCH_RECENT_ASSIGNEES_SUCCESS:
			userIds = OrderedSet(action.payload)
			return state.intersect(userIds).union(userIds)
		default:
			return state
	}
}

const stats = (state = Map(), action) => {
	switch (action.type) {
		case SET_USER_STATS: {
			const { userId, stats } = action.payload
			const newStats = fromJS(stats)
			return state.update(userId, (stats) =>
				stats && stats.equals(newStats) ? stats : newStats
			)
		}

		default:
			return state
	}
}

const workload = (state = Map(), action) => {
	switch (action.type) {
		case SET_USER_WORKLOAD: {
			const { userId, workload } = action.payload
			const newWorkload = fromJS(workload)
			return state.update(userId, (workload) =>
				workload && workload.equals(newWorkload)
					? workload
					: newWorkload
			)
		}

		default:
			return state
	}
}

export const users = combineReducers({
	byId,
	focus,
	ids,
	isSettingAdmin,
	online,
	options,
	recentAssignees,
	productivity,
	stats,
	workload,
})

export const getAllWorkloads = (state) => state.getIn(['users', 'workload'])
export const getIsFetching = (state) => state.getIn(['users', 'isFetching'])
export const getById = (state) => state.getIn(['users', 'byId'])
export const getIds = (state) => state.getIn(['users', 'ids'])
export const getIsSettingAdmin = (state) =>
	state.getIn(['users', 'isSettingAdmin'])
export const getOnlineUserIds = (state) => state.getIn(['users', 'online'])
export const getRecentAssigneesUserIds = (state) =>
	state.getIn(['users', 'recentAssignees'])
export const getNickname = (state, props) =>
	state.getIn(['users', 'byId', props.userId, 'nickname'])
export const getUsersFocus = (state) => state.getIn(['users', 'focus'])
export const getUserOptions = (state, props) =>
	state.getIn(['users', 'options', props.userId])
export const getProductivity = (state, props) =>
	state.getIn(['users', 'productivity', props.userId])
export const getStats = (state, props) =>
	state.getIn(['users', 'stats', props.userId])
export const getWorkload = (state, props) =>
	state.getIn(['users', 'workload', props.userId])

// without the selector store the selector was reset every time cause it got a new selector as a parameter
const filterGuestsSelectorStore = new SelectorStore((selector) =>
	createSelector([selector, getPlayer], (users, me) =>
		users.filter(
			(user) =>
				!user.get('userGroups', []).includes('guest') &&
				+user.get('organisationId') === +me.get('organisationId')
		)
	)
)

export const filterGuests = (state, selector) =>
	filterGuestsSelectorStore.get(selector)(state)

export const getAllUsers = createSelector([getIds, getById], (ids, byId) =>
	ids.map((id) => byId.get(id)).filter((user) => user.get('active'))
)

export const getFollowers = createSelector(
	[getById, (state, followers) => followers],
	(users, followers) =>
		followers
			? fromJS(followers)
					.map((f) => {
						const id = f.get ? f.get('id') : f.id
						const isVirtual = f.get
							? f.get('isVirtual')
							: f.isVirtual
						const user = users.get(id)
						return user ? user.merge({ isVirtual }) : null
					})
					.filter((f) => f)
			: List()
)

export const getRecentlyActiveUsers = createSelector(
	[getIds, getById],
	(ids, byId) => ids.map((id) => byId.get(id)).filter(isRecentlyActive)
)

export const makeFollowersSelector = () =>
	createSelector(
		[(state, props) => getTask(state, props.taskId), getById],
		(task, byId) => {
			const followers = task.get('followers', List())
			return followers
				.map((f) =>
					byId.get(f.get('id'), Map({ id: f.get('id') })).merge({
						isVirtual: f.get('isVirtual'),
						roles: f.get('roles'),
					})
				)
				.filter((f) => f && f.get('id'))
		}
	)

export const getBusyUsers = createSelector([getById], (byId) =>
	byId.filter((u) => u.get('currentTaskId')).toList()
)

export const getOnlineUsers = createSelector(
	[getOnlineUserIds, getById],
	(ids, byId) => ids.map((id) => byId.get(id))
)

export const getRecentAssignees = createSelector(
	[getRecentAssigneesUserIds, getById],
	(ids, byId) =>
		ids
			.map((id) => byId.get(id))
			.filter((user) => user && user.get('active'))
)

const nullUser = Map({
	id: null,
})

const getUserId = (state, props) => props.userId

export const getUser = (state, id, defaultUser) =>
	getById(state).get(id, defaultUser !== undefined ? defaultUser : nullUser)

export const makeGetUser = () => getUser2
export const getUser2 = createSelector([getUserId, getById], (userId, byId) =>
	byId.get(userId)
)

const getUserIds = (state, props) => List(props.userIds)
export const getUsersById = createSelector(
	[getById, getUserIds],
	(byId, userIds) => userIds.map((id) => byId.get(id))
)
export const getUsersExcludingPlayer = createSelector(
	[getPlayerId, getById, getUserIds],
	(playerId, byId, userIds) =>
		userIds.filter((id) => id && id !== playerId).map((id) => byId.get(id))
)

export const isUserOnline = createSelector(
	[getOnlineUserIds, getUserId],
	(ids, userId) => ids.has(userId)
)
