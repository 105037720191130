import { PlayerOptions } from '../../types'

const defaultPlayerOptions: PlayerOptions = {
	browserNotificationsState: true,
	notificationsState: 'enabled',
	minimiseProjects: 'false',
	minimiseTasks: 'false',
	minimiseUsers: 'false',
	'recents.calls': [],
	'recents.managedUsers': [],
	'recents.search.queries': [],
	'recents.tags': [],
	'recents.taskEdit.viewedTasks': [],
	tasksTableSort: {
		direction: 'asc',
		orderBy: 'startDate',
	},
	onboarding: {
		sidebarProjectsVisible: false,
		sidebarInviteVisible: false,
	},
	starredProjects: [],
	theme: 'flatWhite',
	themeNight: 'darkMatter',
	usersIsStarred: [],
	'googleCalendar.syncEnabled': false,
	'googleCalendar.tytoCalendarId': null,
	'googleCalendar.channelId': null,
	'outlookCalendar.syncEnabled': false,
	'projects.defaultView': 'board',
	'projects.boardView': 'parent',
	'projects.inactiveTasksRange': 'today',
	isTaskEditorDetached: true,
	'taskEdit.activityTab': 'all',
	'userView.selectedView': 'default',
	'userView.taskLayout': 'full',
}

export const getDefaultPlayerOptions = (): PlayerOptions => {
	return defaultPlayerOptions
}
