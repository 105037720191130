import moment from 'moment'

import { DATETIME_FORMAT, scoreRoles } from '../constants'
import { Task, User } from '../types'

const DEFAULT_TASK_DURATION = 10 // minutes

const buildTaskScoreSummary = (
	{ id, title }: Pick<Task, 'id' | 'title'>,
	user: Partial<User>,
	role: string,
	meta: any
) => ({
	taskId: id,
	taskTitle: title,
	userId: user.id,
	userRole: role,
	scoreDate: moment.utc().format(DATETIME_FORMAT),
	score: 0,
	meta,
	taskScoreRemarks: [],
})

const addTaskScoreRemark = (taskScoreSummary: any, taskScoreRemark: any) => {
	taskScoreSummary.taskScoreRemarks.push(taskScoreRemark)
}

const buildTaskScoreRemark = (
	factorCode: any,
	allocation: any,
	severity: any,
	points: any
) => ({
	factorCode: factorCode,
	allocation: allocation,
	severity: severity,
	points: points,
	factors: [],
})

const withFactor = (taskScoreRemark: any, factorCode: any) => {
	taskScoreRemark.factors.push(factorCode)
}

const Severity = {
	HIGH: 'high',
	MEDIUM: 'med',
	LOW: 'low',
}

const Allocation = {
	BASE: 'base',
	PENALTY: 'penalty',
	BONUS: 'bonus',
	ADJUSTMENT: 'adjustment',
}

const FactorCode = {
	BASE_SCORE: 'baseScore',
	HOURS_ALLOCATED: 'hoursAllocated',
	START_DATE: 'startDate',
	DUE_DATE: 'dueDate',
	COMPLEXITY: 'complexity',
	URGENCY: 'urgency',
	IMPORTANCE: 'importance',
	HOURS_TAKEN: 'hoursTaken',
	COLLABORATED: 'collaborated',
	TIME_TRACKED: 'timeTracked',
	COMPLETED_PROMPTLY: 'completedPromptly',
}

export const calculateScore = (
	{
		hoursAllocated = DEFAULT_TASK_DURATION / 60,
		hoursTaken = 0,
		importance = 0,
		urgency = 0,
		startDate,
		dueDate,
		id,
		title,
	}: Task,
	user: Partial<User>,
	role: string,
	meta: any = { type: 'taskCompleted' }
) => {
	const taskScoreSummary = buildTaskScoreSummary(
		{ id, title },
		user,
		role,
		meta
	)

	let score = 0

	if (role === scoreRoles.DOER) {
		// Score should be based on hours allocated for the doer
		score = hoursAllocated * 20

		const taskScoreRemark = buildTaskScoreRemark(
			FactorCode.BASE_SCORE,
			Allocation.BASE,
			Severity.MEDIUM,
			score
		)
		withFactor(taskScoreRemark, FactorCode.BASE_SCORE)
		addTaskScoreRemark(taskScoreSummary, taskScoreRemark)

		if (urgency) {
			// Calculate bonus urgency
			const urgencyScore = Math.max(1, urgency * 0.25)
			score += urgencyScore

			const taskScoreRemark = buildTaskScoreRemark(
				FactorCode.URGENCY,
				Allocation.BONUS,
				urgency > 7
					? Severity.HIGH
					: urgency > 4
					  ? Severity.MEDIUM
					  : Severity.LOW,
				urgencyScore
			)
			withFactor(taskScoreRemark, FactorCode.URGENCY)
			addTaskScoreRemark(taskScoreSummary, taskScoreRemark)
		}

		if (importance) {
			// Calculate bonus importance
			const importanceScore = Math.max(1, importance * 0.25)
			score += importanceScore

			const taskScoreRemark = buildTaskScoreRemark(
				FactorCode.IMPORTANCE,
				Allocation.BONUS,
				importance > 7
					? Severity.HIGH
					: importance > 4
					  ? Severity.MEDIUM
					  : Severity.LOW,
				importanceScore
			)
			withFactor(taskScoreRemark, FactorCode.IMPORTANCE)
			addTaskScoreRemark(taskScoreSummary, taskScoreRemark)
		}

		// Bonus points if time was tracked at all
		if (hoursTaken) {
			score += 1

			const taskScoreRemark = buildTaskScoreRemark(
				FactorCode.TIME_TRACKED,
				Allocation.BONUS,
				Severity.LOW,
				1
			)
			addTaskScoreRemark(taskScoreSummary, taskScoreRemark)

			// Bonus points if time taken was smaller than time allocated
			if (hoursTaken < hoursAllocated) {
				score += 1

				const taskScoreRemark = buildTaskScoreRemark(
					FactorCode.COMPLETED_PROMPTLY,
					Allocation.BONUS,
					Severity.LOW,
					1
				)
				addTaskScoreRemark(taskScoreSummary, taskScoreRemark)
			}
		}

		// Bonus points if finishing before the due date
		if (dueDate && moment.utc(dueDate).isAfter(moment.utc())) {
			score += 1

			const taskScoreRemark = buildTaskScoreRemark(
				FactorCode.DUE_DATE,
				Allocation.BONUS,
				Severity.MEDIUM,
				1
			)
			withFactor(taskScoreRemark, FactorCode.DUE_DATE)
			addTaskScoreRemark(taskScoreSummary, taskScoreRemark)
		}
	} else if (role === scoreRoles.ORGANISER) {
		score = score = hoursAllocated * 10

		const taskScoreRemark = buildTaskScoreRemark(
			FactorCode.BASE_SCORE,
			Allocation.BASE,
			Severity.MEDIUM,
			score
		)
		withFactor(taskScoreRemark, FactorCode.BASE_SCORE)
		addTaskScoreRemark(taskScoreSummary, taskScoreRemark)

		// Bonus for adding urgency to a task
		if (urgency) {
			score += 2

			const taskScoreRemark = buildTaskScoreRemark(
				FactorCode.URGENCY,
				Allocation.BONUS,
				Severity.LOW,
				2
			)
			addTaskScoreRemark(taskScoreSummary, taskScoreRemark)
		}

		// Bonus for adding importance to a task
		if (importance) {
			score += 2

			const taskScoreRemark = buildTaskScoreRemark(
				FactorCode.IMPORTANCE,
				Allocation.BONUS,
				Severity.LOW,
				2
			)
			addTaskScoreRemark(taskScoreSummary, taskScoreRemark)
		}

		// Bonus for scheduling
		if (startDate) {
			score += 3

			const taskScoreRemark = buildTaskScoreRemark(
				FactorCode.START_DATE,
				Allocation.BONUS,
				Severity.MEDIUM,
				3
			)
			addTaskScoreRemark(taskScoreSummary, taskScoreRemark)
		}

		if (dueDate) {
			score += 3

			const taskScoreRemark = buildTaskScoreRemark(
				FactorCode.DUE_DATE,
				Allocation.BONUS,
				Severity.MEDIUM,
				3
			)
			addTaskScoreRemark(taskScoreSummary, taskScoreRemark)
		}
	}

	if (score < 0) {
		score = 0
	}

	// Round off the score to an integer
	taskScoreSummary.score = Math.round(score)

	return taskScoreSummary
}
