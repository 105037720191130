export const FETCH_TEAM_FAILURE = 'FETCH_TEAM_FAILURE'
export const FETCH_TEAM_REQUEST = 'FETCH_TEAM_REQUEST'
export const FETCH_TEAM_SUCCESS = 'FETCH_TEAM_SUCCESS'

export const fetchTeamFailure = reason => ({ type: FETCH_TEAM_FAILURE, reason })
export const fetchTeamRequest = () => ({ type: FETCH_TEAM_REQUEST })
export const fetchTeamSuccess = response => ({
	type: FETCH_TEAM_SUCCESS,
	response,
})
