import { Map, List } from 'immutable'

import {
	SET_MAKE_A_COPY_DESCENDANTS,
	SET_MAKE_A_COPY_TASK,
	TOGGLE_MAKE_A_COPY,
} from '../actions'
import { getTask } from './tasks-reducer'

const initialState = Map({
	isVisible: false,
	taskId: null,
	descendants: List(),
})

export const makeACopy = (state = initialState, action) => {
	switch (action.type) {
		case SET_MAKE_A_COPY_DESCENDANTS:
			return state.set('descendants', List(action.payload))

		case SET_MAKE_A_COPY_TASK:
			return state.set('taskId', action.id)

		case TOGGLE_MAKE_A_COPY:
			return state.set(
				'isVisible',
				typeof action.isVisible === 'boolean'
					? action.isVisible
					: !state.get('isVisible')
			)

		default:
			return state
	}
}

export const getMakeACopyDescendants = state =>
	state.getIn(['makeACopy', 'descendants'])
export const getIsVisible = state => state.getIn(['makeACopy', 'isVisible'])
export const getTaskId = state => state.getIn(['makeACopy', 'taskId'])
export const getMakeACopyParent = state =>
	getTask(state, getMakeACopyTask(state).get('parentId'))
export const getMakeACopyTask = state => getTask(state, getTaskId(state))
