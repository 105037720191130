/*
 * Task factory functions for mutating state
 */

import { QueryClient } from '@tanstack/react-query'
import { formatISO } from 'date-fns'

import { ApiAdapter } from '../../api/baseApiAdapter'
import { AppState } from '../../store-types'
import { createUpdateTaskOnQueryCache } from './update'

type RemoveTaskFn = (taskId: string) => void

export const removeTaskMutation = async (state: AppState, taskId: string) => {
	const { apiAdapter, queryClient } = state
	createRemoveTaskFromApi(apiAdapter)(taskId)
	createRemoveTaskFromQueryCache(queryClient)(taskId)
}

export const createRemoveTaskFromApi =
	(apiAdapter: ApiAdapter): RemoveTaskFn =>
	async (taskId) => {
		apiAdapter.tasks.remove(taskId)
	}

export const createRemoveTaskFromQueryCache =
	(queryClient: QueryClient): RemoveTaskFn =>
	async (taskId) => {
		const deletedPatch = {
			isActive: false,
			statusCode: 'deleted' as const,
			deletedDate: formatISO(Date.now()),
		}
		const updateTask = createUpdateTaskOnQueryCache(queryClient)
		return updateTask(taskId, deletedPatch)
	}
