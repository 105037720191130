import { filter } from 'remeda'

import { BoardStrategy, StatusTreeTask } from '../projectsTypes'
import { buildTaskTreeItemsById } from './utils'

type Strategy = BoardStrategy<StatusTreeTask>

const buildStatusTree: Strategy['buildTree'] = (_, tasks = []) => {
	const statusTasks = filter(tasks, (task) => Boolean(task.statusCode))

	return buildTaskTreeItemsById(
		'status',
		'statusRoot',
		statusTasks,
		(task) => task.statusCode as string
	)
}

export const statusStrategy: Strategy = {
	buildTree: buildStatusTree,
	name: 'statusStrategy',
}
