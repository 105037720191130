import { QueryClient, QueryFunctionContext } from '@tanstack/react-query'

import { ApiAdapter } from '../api'

export const sectionKeys = {
	all: ['sections'] as const,
	lists: () => [...sectionKeys.all, 'list'] as const,
	list: (userId: string) => [...sectionKeys.lists(), userId] as const,
}

export const createSectionsQuery =
	(apiAdapter: ApiAdapter, queryClient: QueryClient) => (userId: string) => ({
		queryKey: sectionKeys.list(userId),
		queryFn: fetchSections(apiAdapter, queryClient, userId),
	})

export const fetchSections =
	(apiAdapter: ApiAdapter, queryClient: QueryClient, userId: string) =>
	async ({ signal }: QueryFunctionContext) =>
		apiAdapter.users.getSections(userId, { signal })
