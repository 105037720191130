import { BehaviorSubject, Subject } from 'rxjs'

import {
	PlatformNotification,
	Task,
	TaskPosition,
	UserStatus,
	Workflow,
} from '../../types'
import { ApiFile } from '../api'
import { ChatMessage, ChatReactionsSocket } from '../chat/chatTypes'
import {
	MoveTaskAction,
	UpdateTaskAction,
	UpdateUserAction,
	UpdateWorkflowAction,
} from '../createActions'

export const platformNotificationsSubject = new Subject<PlatformNotification>()

export const addTaskSubject = new Subject<{
	position?: TaskPosition
	task: Task
}>()
export const chatMessageSubject = new Subject<ChatMessage>()
export const chatMessageFileSubject = new Subject<ApiFile>()
export const chatReactionsSubject = new Subject<ChatReactionsSocket>()
export const moveTaskSubject = new Subject<MoveTaskAction>()
export const removeTaskSubject = new Subject<string>()
export const updateTaskSubject = new Subject<UpdateTaskAction>()

export const onlineUsersSubject = new BehaviorSubject<string[]>([])
export const userStatusesSubject = new Subject<Record<string, UserStatus>>()
export const updateUserSubject = new Subject<UpdateUserAction>()

export const addWorkflowSubject = new Subject<Workflow>()
export const removeWorkflowSubject = new Subject<string>()
export const updateWorkflowSubject = new Subject<UpdateWorkflowAction>()

export const typingInChatSubject = new Subject<{
	chatRoomId: string
	typingUserId: string
}>()
