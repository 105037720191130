import { formatISO } from 'date-fns'

import { createChatRoomId } from '../../helpers/createId'
import { User } from '../../types'

export const createChatRoom = (
	player: Pick<User, 'id' | 'organisationId'>,
	userIds: string[],
	name?: string | void
) => {
	if (!player || !player.id || !player.organisationId) {
		throw new Error(
			'createChatRoom: player must be provided with id and organisationId'
		)
	}

	if (!userIds && Array.isArray(userIds)) {
		throw new Error('createChatRoom: userIds must be an array')
	}

	const filteredUserIds = userIds.filter((id) => id !== player.id)
	if (filteredUserIds.length === 0) {
		throw new Error(
			'createChatRoom: userIds must contain at least one id that is not the player id'
		)
	}

	if (!name && filteredUserIds.length > 1) {
		throw new Error('createChatRoom: a room name must be provided')
	}

	const isGroup = filteredUserIds.length > 1

	const roomId = isGroup
		? createChatRoomId()
		: createChatRoomId(player.id, userIds[0])
	const roomType: 'group' | 'user' = isGroup ? 'group' : 'user'

	return {
		id: roomId,
		dateCreated: formatISO(new Date()),
		name,
		organisationId: player.organisationId,
		roomType,
		users: [player.id, ...filteredUserIds],
	}
}
