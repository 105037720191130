import {
	parseTaskAssignToString,
	parseTaskCommentToString,
	parseTaskCreateToString,
	parseTaskDeleteToString,
	parseTaskDescrToString,
	parseTaskDoneToString,
	parseTaskDueDateToString,
	parseTaskDurationToString,
	parseTaskFileRenameToString,
	parseTaskFilesToString,
	parseTaskFollowerToString,
	parseTaskMoveToString,
	parseTaskMultipleToString,
	parseTaskOwnerToString,
	parseTaskPauseToString,
	parseTaskPriorityToString,
	parseTaskScheduleToString,
	parseTaskStartToString,
	parseTaskStatusToString,
	parseTaskStopToString,
	parseTaskTitleToString,
	parseTaskUpdateToString,
	parseTaskWorkflowToString,
} from './parsers'
import { Source, TaskActivity } from './task-activity-schemas'

export const parseTaskLogs = (
	activity: TaskActivity
	//{ excludeRenderedData = false } = {}
) => {
	// excludeRenderedData - some data like the workflow step change will be rendered by the component,
	// so no need to include it in the html
	switch (activity.type) {
		case 'task.assign':
			return parseTaskAssignToString(activity)

		case 'task.comment':
			return parseTaskCommentToString(activity)

		case 'task.create':
			return parseTaskCreateToString(activity)

		case 'task.delete':
			return parseTaskDeleteToString(activity)

		case 'task.descr':
			return parseTaskDescrToString(activity)

		case 'task.dueDate':
			return parseTaskDueDateToString(activity)

		case 'task.duration':
			return parseTaskDurationToString(activity)

		case 'task.files':
			return parseTaskFilesToString(activity)

		case 'task.files.rename':
			return parseTaskFileRenameToString(activity)

		case 'task.finish':
			return parseTaskDoneToString(activity)

		case 'task.follower':
			return parseTaskFollowerToString(activity)

		case 'task.move':
			return parseTaskMoveToString(activity)

		case 'task.multiple':
			return parseTaskMultipleToString(activity)

		case 'task.owner':
			return parseTaskOwnerToString(activity)

		case 'task.pause':
			return parseTaskPauseToString(activity)

		case 'task.priority':
			return parseTaskPriorityToString(activity)

		//case 'task.private':
		// TODO: do necessary sanitisation
		//break

		case 'task.schedule':
			return parseTaskScheduleToString(activity)

		case 'task.start':
			return parseTaskStartToString(activity)

		case 'task.status':
			return parseTaskStatusToString(activity)

		case 'task.stop':
			return parseTaskStopToString(activity)

		case 'task.title':
			return parseTaskTitleToString(activity)

		case 'task.update':
			return parseTaskUpdateToString(activity)

		case 'task.workflow':
			return parseTaskWorkflowToString(activity)

		// return

		// Workflow should come from the dataSource
		// const workflow = dataSource('workflow', value.workflowId)

		// // Keeping this around for later:
		// // Simplified the workflow to get the core design right. Can add
		// // complexity in new iterations when needed.
		// const actions = [`${user.nickname} updated the workflow:`]
		// const currentWorkflow =
		// 	value.referenceMap.workflows[value.workflowId]
		// if (value.hasOwnProperty('newChildId') && !value.newChildId) {
		// 	actions.push(li(`Removed the child task's workflow`))
		// }
		// if (value.newChildId) {
		// 	const workflow = value.referenceMap.workflows[value.newChildId]
		// 	actions.push(
		// 		li(`Changed the child task's workflow to ${workflow.title}`)
		// 	)
		// }
		// if (value.hasOwnProperty('newId') && !value.newId) {
		// 	actions.push(li(`Removed the current workflow`))
		// }
		// if (value.newId) {
		// 	actions.push(
		// 		`Changed the current workflow to ${currentWorkflow.title}`
		// 	)
		// }

		// const joinSteps = steps =>
		// 	steps.map(step => li(getStep(step.id))).join('')
		// const getStep = stepId => value.referenceMap.steps[stepId].title

		// if (value.newCompleted) {
		// 	if (value.newCompleted.length > 1) {
		// 		actions.push(
		// 			`Completed workflow steps: ${joinSteps(
		// 				value.newCompleted
		// 			)}`
		// 		)
		// 	} else if (value.newCompleted.length === 1) {
		// 		actions.push(
		// 			`Completed a workflow step: ${getStep(
		// 				value.newCompleted[0].id
		// 			)}`
		// 		)
		// 	}
		// }

		// if (value.newUnCompleted) {
		// 	if (value.newUnCompleted.length > 1) {
		// 		actions.push(
		// 			`Uncompleted workflow steps: ${joinSteps(
		// 				value.newUnCompleted
		// 			)}`
		// 		)
		// 	} else if (value.newUnCompleted.length === 1) {
		// 		actions.push(
		// 			`Uncompleted a workflow step: ${getStep(
		// 				value.newUnCompleted[0].id
		// 			)}`
		// 		)
		// 	}
		// }

		// if (value.newActiveStepId && !excludeRenderedData) {
		// 	if (value.oldActiveStepId) {
		// 		actions.push(
		// 			`Changed the active step ${getStep(
		// 				value.oldActiveStepId
		// 			)} -> ${getStep(value.newActiveStepId)}`
		// 		)
		// 	} else {
		// 		actions.push(
		// 			`Changed the active step to ${getStep(
		// 				value.newActiveStepId
		// 			)}`
		// 		)
		// 	}
		// }
		// return actions.join('<br>')

		default:
			return `Unknown activity type ${activity.type}`
	}
}

export const messageTypeCreate = ({
	source,
	userNickname,
}: {
	source: Source
	userNickname: string
}) => {
	switch (source) {
		case 'mail':
			return `${userNickname} mailed in this task.`

		case 'web':
		default:
			return `${userNickname} added this task.`
	}
}
