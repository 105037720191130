import { formatISO } from 'date-fns'
import { findIndex, prop, propEq, uniqBy } from 'ramda'
import { Socket } from 'socket.io-client'

import { noop } from '@tyto/utils'

import { events } from '../constants'
import { createSocketObservable } from '../socketUtils'
import { Slice, VideoCallEventAction, VideoCallStatusUser } from '../types'
import {
	createQueryObservable,
	fetchVideoCallStatus,
	videoCallKeys,
} from './queries'
import { MutatedAppState } from './store-types'

const createVideoCallStatusSocket = (socket: Socket) =>
	createSocketObservable<VideoCallEventAction>(socket, events.VIDEO_CALL)

export interface VideoCallSlice extends Slice {
	usersInCalls: VideoCallStatusUser[]
}

const createVideoCallSlice: MutatedAppState<VideoCallSlice> = (set, get) => ({
	usersInCalls: [],
	init: () => {
		const queryClient = get().queryClient
		const socket = get().socket

		if (!socket) {
			return noop
		}

		const videoCallStatusSocket = createVideoCallStatusSocket(socket)

		// Get user's video call state from API
		const querySubscription = createQueryObservable(queryClient, {
			queryKey: videoCallKeys.usersStatus(),
			queryFn: fetchVideoCallStatus(get().apiAdapter),
			staleTime: 1000 * 60 * 5,
		}).subscribe((result) => {
			const { data: usersInCalls } = result
			if (!usersInCalls) {
				return
			}

			set((draft) => {
				draft.videoCall.usersInCalls = usersInCalls
			})
		})

		// Setup socket listeners
		const statusSocketSubscription = videoCallStatusSocket.subscribe(
			(action) => {
				switch (action.type) {
					case 'userJoined':
						set((draft) => {
							draft.videoCall.usersInCalls.push({
								userId: action.userId,
								dateJoined: formatISO(Date.now()),
							})
							draft.videoCall.usersInCalls = uniqBy(
								prop('userId'),
								draft.videoCall.usersInCalls
							)
						})
						break
					case 'userLeft':
						set((draft) => {
							const index = findIndex(
								propEq('userId', action.userId),
								draft.videoCall.usersInCalls
							)
							if (index > -1) {
								draft.videoCall.usersInCalls.splice(index, 1)
							}
						})
						break
				}
			}
		)

		return () => {
			querySubscription.unsubscribe()
			statusSocketSubscription.unsubscribe()
		}
	},
})

export default createVideoCallSlice
