import { QueryClient } from '@tanstack/react-query'
import { formatISO } from 'date-fns'
import produce from 'immer'

import { Task, TaskTimer, User } from '../../../types'
import { ApiAdapter } from '../../api/baseApiAdapter'
import { StoreActions } from '../../createActions'
import { taskKeys, userKeys } from '../../queries'
import { AppState } from '../../store-types'
import { getTimeInfoFromTask } from '../../utils'

export const pauseTaskMutation = (
	state: AppState,
	taskId: string,
	actions: Pick<StoreActions, 'updateTask' | 'updateUser'>
) => {
	const { apiAdapter, queryClient } = state
	const { updateTask, updateUser } = actions
	createPauseTaskOnApi(apiAdapter)(taskId)

	const task = queryClient.getQueryData<Task>(taskKeys.detail(taskId))
	const timeInfo = getTimeInfoFromTask(task)
	const userId = task?.assigneeId || task?.ownerId

	if (userId) {
		updateUser(userId, {
			currentTaskStartDate: null,
		})
	}

	updateTask(taskId, {
		currentTimer: {
			status: 'paused',
			date: formatISO(Date.now()),
		},
		hoursTaken: timeInfo.elapsed / 3600,
	})
}

export const createPauseTaskOnApi =
	(apiAdapter: ApiAdapter) => (taskId: string) =>
		apiAdapter.tasks.updateTimer(taskId, {
			date: formatISO(Date.now()),
			status: 'paused',
		})

export const createPauseTaskOnQueryCache =
	(queryClient: QueryClient) => (taskId: string, currentTimer: TaskTimer) => {
		const task = queryClient.getQueryData<Task>(taskKeys.detail(taskId))

		if (!task) {
			return
		}

		const userId = task?.assigneeId || task?.ownerId

		// Update user timer details

		queryClient.setQueryData<User | undefined>(
			userKeys.detail(userId),
			(prevUser) =>
				prevUser &&
				produce(prevUser, (draft) => {
					draft.currentTaskId = null
					draft.currentTaskStartDate = null
				})
		)

		// Update task timer details
		queryClient.setQueryData<Task | undefined>(
			taskKeys.detail(taskId),
			(prevTask) =>
				prevTask &&
				produce(prevTask, (draft) => {
					draft.currentTimer = currentTimer
				})
		)
	}
