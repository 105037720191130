import { QueryClient } from '@tanstack/react-query'
import { formatISO } from 'date-fns'
import produce from 'immer'

import { TraceableError } from '../../../helpers/error-handling'
//import { createStartTaskActivity } from '@tyto/task-activity'

import { Task, TaskTimer, User } from '../../../types'
import { ApiAdapter } from '../../api/baseApiAdapter'
import { StoreActions } from '../../createActions'
import { taskKeys, userKeys } from '../../queries'
import { AppState } from '../../store-types'
//import { createAddTaskActivityToQueryCache } from '../../task-activity/taskActivityMutations'
import { createStopTask, sendTaskStoppedSnackbar } from './stop'

export const startTaskMutation = (
	state: AppState,
	taskId: string,
	actions: Pick<StoreActions, 'updateTask' | 'updateUser'>
) => {
	const { apiAdapter, queryClient } = state
	const { updateTask, updateUser } = actions
	createStartTaskOnApi(apiAdapter)(taskId)
		.then((data) => {
			if (data && data.stoppedTaskId) {
				createStopTask(queryClient, updateTask)(data.stoppedTaskId)

				sendTaskStoppedSnackbar(state, data)
			}
			return data
		})
		.catch((err) => {
			throw new TraceableError('Failed to start task', err)
		})

	const task = queryClient.getQueryData<Task>(taskKeys.detail(taskId))
	const userId = task?.assigneeId || task?.ownerId

	if (userId) {
		updateUser(userId, {
			currentTaskId: taskId,
			currentTaskStartDate: formatISO(Date.now()),
		})
	}

	updateTask(taskId, {
		currentTimer: {
			status: 'started',
			date: formatISO(Date.now()),
		},
	})

	/* Disabled optimistic updates for activity since there are some issues with ids not matching up
	// Add task activity
	const playerId = state.player.id
	if (!playerId) {
		throw new Error('playerId is falsey in AppState')
	}
	const newTask = queryClient.getQueryData<Task>(taskKeys.detail(taskId))
	if (!newTask) {
		throw new Error(
			'Cannot create optimistic task activity for stopTask action without a task in cache'
		)
	}
	const activity = createStartTaskActivity(
		playerId,
		newTask.assigneeId || playerId,
		taskId
	)
	if (activity) {
		createAddTaskActivityToQueryCache(apiAdapter, queryClient)(activity)
	}
	*/
}

export const createStartTaskOnApi =
	(apiAdapter: ApiAdapter) => (taskId: string) =>
		apiAdapter.tasks.updateTimer(taskId, {
			date: formatISO(Date.now()),
			status: 'started',
		})

export const createStartTaskOnQueryCache =
	(queryClient: QueryClient) => (taskId: string, currentTimer: TaskTimer) => {
		const task = queryClient.getQueryData<Task>(taskKeys.detail(taskId))

		if (!task) {
			return
		}

		const userId = task?.assigneeId || task?.ownerId
		const user = queryClient.getQueryData<User>(userKeys.detail(userId))

		// Update user timer details
		if (user) {
			queryClient.setQueryData<User | undefined>(
				userKeys.detail(userId),
				(prevUser) =>
					prevUser &&
					produce(prevUser, (draft) => {
						draft.currentTaskId = taskId
						draft.currentTaskStartDate = currentTimer.date
					})
			)
		}

		// Update task timer details
		queryClient.setQueryData<Task | undefined>(
			taskKeys.detail(taskId),
			(prevTask) =>
				prevTask &&
				produce(prevTask, (draft) => {
					draft.currentTimer = currentTimer
				})
		)
	}
