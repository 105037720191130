import { format, parse, parseISO } from 'date-fns'

import { DATEFNS_TIME_FORMAT } from '../../constants'
import { dateFormats } from '../../helpers/formatters/date-formats'
import {
	TaskActivity,
	TaskActivityDueDate,
	TaskActivityDueDateSchema,
} from '../task-activity-schemas'

export const parseTaskDueDateToString = (
	activity: TaskActivityDueDate
): string => {
	const meta = activity.meta

	if (activity.isRecurring) {
		return `${meta.user.nickname} set the due date as recurring.`
	}

	const date = activity.date ? parseISO(activity.date) : null
	const time = activity.time
		? parse(activity.time, 'HH:mm:ss', new Date())
		: null

	if (!date && !time) {
		return `${meta.user.nickname} removed the due date.`
	}

	const dateFormat = date ? dateFormats.smartDate(date) : ''
	const timeFormat = time ? format(time, DATEFNS_TIME_FORMAT) : ''

	if (date && time) {
		return `${meta.user.nickname} set the due date to <em>${dateFormat}</em> at <em>${timeFormat}</em>.`
	} else if (time) {
		return `${meta.user.nickname} set the due time to <em>${timeFormat}</em>.`
	} else {
		return `${meta.user.nickname} set the due date to <em>${dateFormat}</em>.`
	}
}

export const validateTaskDueDate = (taskActivity: TaskActivity) =>
	TaskActivityDueDateSchema.safeParse(taskActivity)
