import { equals, forEach, map, reduce } from 'ramda'
import { indexBy, prop } from 'remeda'

import { sortWithOrder } from '../../helpers/sort'
import { createTaskTreeItem } from '../../store/utils'
import { BoardStrategy, ParentTreeTask, Tree, TreeItem } from '../projectsTypes'

type Strategy = BoardStrategy<ParentTreeTask>

const fillPaths = (tree: Tree<ParentTreeTask>): Tree<ParentTreeTask> => {
	const fringe = tree.rootId ? [tree.rootId] : []

	while (fringe.length > 0) {
		const itemId = fringe.pop()
		if (!itemId) {
			continue
		}

		const item = tree.items[itemId]
		if (!item) {
			continue
		}

		// Add children to fringe
		fringe.push(...item.children)

		if (!item.data.parentId) {
			continue
		}

		// Add path
		const parent = tree.items[item.data.parentId]
		if (!parent) {
			continue
		}
		item.path = [...parent.path, parent.children.indexOf(item.id)]
	}

	return tree
}

const buildParentTree: Strategy['buildTree'] = (rootTask, tasks = []) => {
	if (!rootTask) {
		return { items: {}, rootId: null }
	}

	if (!Array.isArray(tasks)) {
		tasks = []
	}

	const treeItemsById = reduce<
		ParentTreeTask,
		Record<string, TreeItem<ParentTreeTask>>
	>(
		(acc, task) => {
			if (task.id) {
				acc[task.id] = createTaskTreeItem(task)
			}
			return acc
		},
		{},
		[rootTask, ...tasks]
	)

	// Create tree structure
	for (const task of tasks) {
		if (task.parentId && treeItemsById[task.parentId]) {
			const parent = treeItemsById[task.parentId]
			parent.children.push(task.id)
			parent.hasChildren = true
		} else {
			// Handle orphans
			// NOTE: this case may also happen from task filtering
			if (treeItemsById[rootTask.id]) {
				treeItemsById[rootTask.id].children.push(task.id)
			}
		}
		if (treeItemsById[task.id]) {
			treeItemsById[task.id].isExpanded = !task.isMinimised
		}
	}

	// Handle sorting and update task.childSortOrder
	for (const task of tasks) {
		const parent = task.parentId ? treeItemsById[task.parentId] : null
		if (parent && parent.hasChildren) {
			const childrenForSorting = map((id) => ({ id }), parent.children)

			parent.children = map(
				prop('id'),
				sortWithOrder(childrenForSorting, parent.data.childSortOrder)
			)

			if (!equals(parent.children, parent.data.childSortOrder)) {
				parent.data.childSortOrder = parent.children
			}
		}
	}

	return fillPaths({ items: treeItemsById, rootId: rootTask.id })
}

const parentsOrphanResolver: Strategy['filterResolver'] = (
	taskMap,
	filteredTasks
) => {
	const filteredMap = indexBy(filteredTasks, prop('id'))
	const addedTasks: { [id: string]: boolean } = {}
	return filteredTasks.reduce<ParentTreeTask[]>((acc, task) => {
		acc.push(task)

		// If this task is orphaned, then add all it's parents
		if (
			task.parentId &&
			taskMap[task.parentId] &&
			!filteredMap[task.parentId] &&
			Array.isArray(task.parents)
		) {
			forEach((parent) => {
				if (
					taskMap[parent.id] &&
					!addedTasks[parent.id] &&
					!filteredMap[parent.id]
				) {
					addedTasks[parent.id] = true
					acc.push(taskMap[parent.id])
				}
			}, task.parents)
		}
		return acc
	}, [])
}

export const parentStrategy: Strategy = {
	buildTree: buildParentTree,
	filterResolver: parentsOrphanResolver,
	name: 'parentStrategy',
}
