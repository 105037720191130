import { formatISO } from 'date-fns'
import { produce } from 'immer'

// TODO: move to shared types
interface Reaction {
	userId: string
	dateCreated: string
}
export const addToReactionIndex = (
	reactionIndex: Record<string, Reaction[]>,
	emoji: string,
	userId: string
) =>
	produce(reactionIndex || {}, (draft) => {
		draft[emoji] = draft[emoji] || []
		const existingReaction = draft[emoji].find((r) => r.userId === userId)
		if (!existingReaction) {
			draft[emoji].push({ userId, dateCreated: formatISO(new Date()) })
		}
	})

export const removeFromReactionIndex = (
	reactionIndex: Record<string, Reaction[]>,
	emoji: string,
	userId: string
) => {
	const result = produce(reactionIndex || {}, (draft) => {
		if (!Array.isArray(draft[emoji])) {
			return
		}
		const index = draft[emoji].findIndex((r) => r.userId === userId)
		draft[emoji].splice(index, 1)
		if (draft[emoji].length === 0) {
			delete draft[emoji]
		}
	})

	if (Object.keys(result).length === 0) {
		return undefined
	} else {
		return result
	}
}
