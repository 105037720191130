import * as Sentry from '@sentry/react'
import sample from 'lodash/fp/sample'
import { call, delay, put, select, takeEvery } from 'redux-saga/effects'

import { createTaskId } from '@tyto/helpers'

import {
	ADD_TASK,
	enqueueSnackbar,
	mergeTask,
	moveTask,
	pushTaskToUserView,
	SOCKET_ADD_TASK,
} from '../actions'
import Api from '../api'
import { doOnceAcrossTabs } from '../doOnceAcrossTabs'
import { getPlayerId } from '../reducers/player-reducer'
import { getFetchingUserViewTasksFor, getTask } from '../reducers/tasks-reducer'
import { playPopSound } from '../soundsData'

const RETRY_AMOUNT = 10
const REQUEST_FAILED = 'API request failed'

export function* addTasksSaga() {
	yield takeEvery(ADD_TASK, handleAddTask)
	yield takeEvery(SOCKET_ADD_TASK, handleAddTaskSocket)
}

function* addTaskToApi(task) {
	for (let i = 0; i < RETRY_AMOUNT; i++) {
		try {
			return yield call(Api.addTask, task)
		} catch (err) {
			Sentry.captureException(err)
			if (i === 0) {
				yield put(
					enqueueSnackbar({
						message:
							"Can't save the task. Check your network connection. Retrying...",
						options: { variant: 'error' },
					})
				)
			}
			if (i < RETRY_AMOUNT - 1) {
				try {
					// check if task was actually created
					return yield call(Api.fetchTask, task.id)
				} catch (e) {
					yield delay(2000 * i + 1)
				}
			}
		}
	}

	// Failed after RETRY_AMOUNT attempts
	throw new Error(REQUEST_FAILED)
}

function* handleAddTask({ payload }) {
	const state = yield select()
	let userViewId = state.getIn(['tasks', 'fetchUserViewTasksFor'])
	const playerId = getPlayerId(state)
	const { index, task } = payload

	if (!task.id) {
		task.id = createTaskId(playerId)
	}

	if (!userViewId) userViewId = playerId

	// Add task to memory
	yield put(mergeTask(task))

	if (task?.id && task?.assigneeId === userViewId) {
		yield put(pushTaskToUserView(task.id))
		doOnceAcrossTabs(playPopSound, 'ADD_TASK')
	}

	// Commenting this out for now because the projects-saga also handles adding
	// to the project.
	/*const taskIdsInProject = [getCurrentId(state), ...getTaskIds(state)]
	const taskInProject = includes(taskIdsInProject, task.parentId)
	if (taskInProject) {
		yield put(addTaskToProject(null, task.id, task.parentId))
	}*/

	const taskIndex = index || task.parentIndex
	yield put(
		moveTask(task.id, {}, { parentId: task.parentId, index: taskIndex })
	)

	try {
		// Only send data to the API if there is a valid task title.
		if (task.title) {
			yield call(addTaskToApi, task)
			// yield put(
			// 	postToMoveTask(
			// 		task.id,
			// 		{},
			// 		{ parentId: task.parentId, index: taskIndex },
			// 		{ optimisticUpdate: false }
			// 	)
			// )
		}
	} catch (err) {
		const failedMessages = [
			`😱 Task save failed for task: ${task.title}. Please resort to pen and paper.`,
			//'🤦🏻‍ Computer says no. Please try again later.',
		]
		yield put(
			enqueueSnackbar({
				message: sample(failedMessages),
				options: { variant: 'error' },
			})
		)
		Sentry.withScope((scope) => {
			scope.setExtra('tasks', task)
			scope.setExtra('request', 'POST /tasks')
			Sentry.captureException(err)
		})
	}
}

function* handleAddTaskSocket({ payload }) {
	const state = yield select()
	let newTask = payload

	// If there is no task in memory, then fetch it.
	let oldTask = getTask(state, payload.id, null)
	if (!oldTask) {
		oldTask = yield call(Api.fetchTask, payload.id)
		newTask = {
			...oldTask,
			...payload,
		}
	}

	yield put(mergeTask(newTask))

	if (newTask.assigneeId === getFetchingUserViewTasksFor(state)) {
		yield put(pushTaskToUserView(payload.id))
		doOnceAcrossTabs(playPopSound, 'ADD_TASK')
	}
}
