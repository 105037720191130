import differenceBy from 'lodash/fp/differenceBy'
import filter from 'lodash/fp/filter'
import includes from 'lodash/fp/includes'
import map from 'lodash/fp/map'
import size from 'lodash/size'

export const getFollowerChanges = (oldValue: any, newValue: any) => {
	const result: any = {
		added: null,
		removed: null,
		updated: null,
	}
	const added = differenceBy('id')(newValue)(oldValue)

	if (size(added) > 0) {
		result.added = added
	}
	const removed = differenceBy('id')(oldValue)(newValue)
	if (size(removed) > 0) {
		result.removed = removed
	}

	const updated = filter((value: any) =>
		includes(value.id)(map('id')(oldValue))
	)(differenceBy('isVirtual')(newValue)(oldValue))
	if (size(updated) > 0) {
		result.updated = updated
	}

	return result
}
