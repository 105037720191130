export const SOCKET_OFF = 'SOCKET_OFF'
export const SOCKET_ON = 'SOCKET_ON'
export const START_CHANNEL = 'START_CHANNEL'
export const STOP_CHANNEL = 'STOP_CHANNEL'
export const START_SOCKET = 'START_SOCKET'

export const socketOff = () => ({ type: SOCKET_OFF })
export const socketOn = () => ({ type: SOCKET_ON })

export const startChannel = eventName => ({
	type: START_CHANNEL,
	payload: eventName,
})
export const stopChannel = eventName => ({
	type: STOP_CHANNEL,
	payload: eventName,
})

export const startSocket = () => ({ type: START_SOCKET })
