import { Map, Set } from 'immutable'
import { COLLAPSE_ID } from '../actions'

const initialState = Map({ collapsedIds: Set() })

export const board = (state = initialState, action) => {
	switch (action.type) {
		case COLLAPSE_ID:
			return state.update('collapsedIds', collapsedIds =>
				collapsedIds.has(action.id)
					? collapsedIds.remove(action.id)
					: collapsedIds.add(action.id)
			)
		default:
			return state
	}
}

export const isCollapsed = (state, id) =>
	state.getIn(['board', 'collapsedIds']).has(id)
export const getCollapsedIds = state => state.getIn(['board', 'collapsedIds'])
