import { omit } from 'remeda'

import { Task } from '../types'

export const prepareTaskForHash = (
	task: Task & { hash?: string; googleCalendarId?: string }
) =>
	omit(task, [
		'googleCalendarId',
		'hash',
		'hasPermission',
		'isMinimised',
		'isStarred',
		'notificationLevel',
	])
