import {
	differenceInDays,
	formatRelative,
	isBefore,
	isSameYear,
	isValid,
	startOfDay,
} from 'date-fns'
import { enUS } from 'date-fns/locale'
import moment from 'moment'

import { TIME_FORMAT } from '../../constants'

const addTimeToDate = (
	date: moment.Moment | Date | null,
	time?: string | null
): Date | null => {
	if (time && moment(date).isValid()) {
		const timeObj = moment(time, TIME_FORMAT)
		if (timeObj.isValid()) {
			return moment(date)
				.hour(timeObj.hour())
				.minute(timeObj.minute())
				.second(timeObj.second())
				.toDate()
		}
	}
	if (moment.isMoment(date)) {
		return date.toDate()
	} else {
		return date
	}
}

const calendarDate = (momentTime: moment.Moment) => {
	if (!momentTime) {
		return ''
	}
	let format
	const now = moment()

	if (momentTime.isSame(now, 'day')) {
		format = 'HH:mm'
	} else if (momentTime.isSame(now, 'week')) {
		format = 'ddd'
	} else if (momentTime.isSame(now, 'year')) {
		format = 'MMM Do'
	} else {
		format = 'D/M/YY'
	}
	return momentTime.format(format)
}

export const dateFormats = {
	addTimeToDate,
	calendarDate,
	smartDate: (
		date: moment.Moment | Date,
		time?: string | null,
		{ suffix = true } = {}
	) => {
		if (moment.isMoment(date)) {
			if (date.isValid()) {
				date = date.toDate()
			} else {
				return ''
			}
		}

		if (!date || !isValid(date)) {
			console.warn('dateFormats.smartDate - expected a valid date', date)
			return ''
		}

		const now = new Date()

		date = addTimeToDate(date, time) as Date
		const addTime = (format: string) => `${format}${time ? ' p' : ''}`
		const getOtherFormat = (date: Date) => {
			let format = 'iii, d MMM yyyy'

			if (isBefore(startOfDay(date), startOfDay(now))) {
				const days = differenceInDays(now, date)
				return `${days}'d${suffix ? ' ago' : ''}'`
			}

			if (isSameYear(now, date)) {
				format = format.replace(' yyyy', '')
			}

			return format
		}
		const formatRelativeLocale = {
			today: addTime("'Today'"),
			tomorrow: addTime("'Tomorrow'"),
			yesterday: addTime("'Yesterday'"),
			nextWeek: addTime('EEEE'),
			lastWeek: addTime("'Last' EEEE"),
			other: addTime(getOtherFormat(date as Date)),
		} as Record<string, string>

		const locale = {
			...enUS,
			formatRelative: (token: string) =>
				formatRelativeLocale[token]
					? formatRelativeLocale[token]
					: formatRelativeLocale['other'],
		}

		return formatRelative(date as Date, now, { locale, weekStartsOn: 1 })
	},
}

export default dateFormats
