import { InfiniteData } from '@tanstack/react-query'
import { z } from 'zod'

import { ApiDatePaginationResult } from '../api-adapter/api-adapter-types'
import { createEmptyDatePaginatedList } from '../api-adapter/api-adapter-utils'

// @deprecated use ApiDatePaginationResult
export type PaginatedList<T = unknown> = {
	count: number
	cursor: string
	hasMore: boolean
	items: T[]
	limit: number
}

// Example usage with a specific type, e.g., strings in items
// const stringPaginatedListSchema = PaginatedListSchema(z.string())
// @deprecated see ApiDatePaginationResult
export const PaginatedListSchema = <T>(itemSchema: z.ZodType<T>) =>
	z.object({
		count: z.number().nonnegative().default(0),
		cursor: z.string().datetime().default(new Date().toISOString()),
		hasMore: z.boolean().default(false),
		items: z.array(itemSchema),
		limit: z.number().int().nonnegative().default(30),
	})

// Example usage with a specific type, e.g., strings in pages
// const stringInifiniteListSchema = InifiniteListSchema(z.string())
export const InifiniteListSchema = <T>(pageSchema: z.ZodType<T>) =>
	z.object({
		pages: z.array(pageSchema),
		pageParams: z.array(z.unknown()),
	})

export const createEmptyInfiniteList = <T = unknown>(): InfiniteData<
	ApiDatePaginationResult<T>
> => ({
	pages: [createEmptyDatePaginatedList()],
	pageParams: [],
})
