import { QueryClient } from '@tanstack/react-query'
import { forEach, merge } from 'remeda'

import { Task } from '../../types'
import { taskKeys } from '../queries/tasks/taskKeys'

const updateTaskQueryCacheWithList = (
	queryClient: QueryClient,
	taskList: Task[]
) => {
	if (!taskList) {
		return
	}

	forEach(taskList, (task) => {
		if (!task) {
			return
		}

		// Add tasks to queryCache
		queryClient.setQueryData<Task>(taskKeys.detail(task.id), (oldTask) =>
			oldTask ? merge(oldTask, task) : task
		)
	})
}

export default updateTaskQueryCacheWithList
