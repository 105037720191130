import { createAxiosApiAdapter, useStore } from '@tyto/dna/store'

import { apiInstance, catchApiResponseError } from './api'
import { clearToken } from './auth-provider'

// Initialise store
// TODO: rather create the store with apiAdapter, socket and queryClient as
// dependencies and dna hooks can get the useStore hook from a top-level
// provider.
export const configureApiAdapter = () => {
	const apiAdapter = createAxiosApiAdapter(apiInstance)
	apiInstance.interceptors.response.use(
		undefined,
		catchApiResponseError(() => {
			console.debug('401 response received: logging out')
			clearToken()
			useStore.getState().player.logout()
		})
	)

	useStore.setState({ apiAdapter })
}
