import { Mutate, StoreApi } from 'zustand'

import { AppState, MutatorMiddleware } from '../../store-types'
import { createZustandObservable } from '../../utils/createZustandObservable'

export const createUserViewUserIdObservable = (
	api: Mutate<StoreApi<AppState>, MutatorMiddleware>
) =>
	createZustandObservable(api, (state) => state.userView.user.id, {
		fireImmediately: true,
	})
