export const deepMerge = <T extends Record<string, any>>(
	target: T,
	source: Partial<T>
): T => {
	// TODO: rather do a deep clone here
	const merged = { ...target }

	for (const key in source) {
		if (Object.prototype.hasOwnProperty.call(source, key)) {
			const targetValue = target[key]
			const sourceValue = source[key]

			if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
				merged[key] = [...targetValue, ...sourceValue] as T[Extract<
					keyof T,
					string
				>]
			} else if (
				typeof targetValue === 'object' &&
				typeof sourceValue === 'object'
			) {
				if (targetValue && sourceValue) {
					merged[key] = deepMerge(targetValue, sourceValue)
				} else if (sourceValue) {
					merged[key] = sourceValue as T[Extract<keyof T, string>]
				}
			} else if (sourceValue !== undefined) {
				merged[key] = sourceValue as T[Extract<keyof T, string>]
			}
		}
	}

	return merged
}
