export const escapeRegExp = (string: string) => {
	// Escape characters with special meaning either inside or outside character
	// sets. Use a simple backslash escape when it’s always valid, and a `\xnn`
	// escape when the simpler form would be disallowed by Unicode patterns'
	// stricter grammar.
	return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&').replace(/-/g, '\\x2d')
}

export const noop = () => void 0

export const isNotNull = <T>(val: T): val is Exclude<T, null> => val !== null

export const range = (start: number, stop: number, step = 1) =>
	Array.from(
		{ length: (stop - start) / step + 1 },
		(_, i) => start + i * step
	)

export const lowercaseFirst = (str: string) =>
	str.charAt(0).toLowerCase() + str.slice(1)

export const uppercaseFirst = (str: string) =>
	str.charAt(0).toUpperCase() + str.slice(1)
