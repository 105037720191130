import merge from 'lodash/fp/merge'
import { call, put, select, takeLatest } from 'redux-saga/effects'

import {
	MERGE_PLAYERS_REQUEST,
	mergePlayersFailure,
	mergePlayersSuccess,
	//SET_PLAYER_FOCUS,
	SET_PLAYER_SETTINGS,
	setPlayer,
	//setUserFocus,
	UPDATE_PLAYER,
} from '../actions'
import Api from '../api'
import { history } from '../history'
import { logError, logWarning } from '../log-error'
import { getPlayer, getPlayerId } from '../reducers/player-reducer'

export function* setPlayerSaga() {
	yield takeLatest(MERGE_PLAYERS_REQUEST, mergePlayers)
	//yield takeLatest(SET_PLAYER_FOCUS, handleSetPlayerFocus)
	yield takeLatest(SET_PLAYER_SETTINGS, handleSetPlayerSettings)
	yield takeLatest(UPDATE_PLAYER, handleUpdatePlayer)
}

//const handleSetPlayerFocus = function* ({ payload }) {
//	const state = yield select()
//	yield call(Api.setFocus, payload)
//	yield put(
//		setUserFocus({
//			...payload,
//			userId: getPlayerId(state),
//		})
//	)
//}

const handleSetPlayerSettings = function* ({ payload }) {
	yield call(Api.updateSettings, payload)
}

function* handleUpdatePlayer({ payload }) {
	const state = yield select()
	try {
		const player = getPlayer(state).toJS()
		const newPlayer = merge(player, payload)
		yield put(setPlayer(newPlayer))
		yield call(Api.updatePlayer, payload)
	} catch (err) {
		logWarning('Failed to update player', err)
	}
}

function* mergePlayers({ payload: { token, taskId } }) {
	try {
		yield call(Api.mergePlayers, token)
		yield put(mergePlayersSuccess())
		history.push(`/my-tasks#${taskId}`)
	} catch (e) {
		logError(e)
		yield put(mergePlayersFailure())
	}
}
