export const regexMatchAll = (regex: RegExp, str: string) => {
	let match
	const result = []
	while ((match = regex.exec(str))) {
		result.push(match)
	}
	return result
}

/**
 * Remove the trailing ":" from a string.
 *
 * @returns string String with a single ":" removed from the end of the original value.
 */
export const removeTrailingColon = (string: string) => {
	const value = string.trim()

	if (value.length > 1 && value.endsWith(':')) {
		return value.substring(0, value.length - 1)
	}

	return value
}
