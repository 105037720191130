import { filter, pipe } from 'remeda'

import { createTaskTreeItem } from '../../store/utils'
import { getActiveStepId, getWorkflowData } from '../../workflows'
import {
	BoardStrategy,
	TreeItem,
	WorkflowStepTreeTask,
	WorkflowTreeTask,
} from '../projectsTypes'

type Strategy = BoardStrategy<WorkflowTreeTask | WorkflowStepTreeTask>

const buildWorkflowTree: Strategy['buildTree'] = (
	rootData,
	dataItems = [],
	rootWorkflow
) => {
	if (!rootData || !rootWorkflow || rootData.type !== 'workflow') {
		return { items: {}, rootId: null }
	}

	if (!Array.isArray(dataItems)) {
		dataItems = []
	}

	const activeTreeItems = pipe(
		dataItems,
		filter((item): item is WorkflowTreeTask => {
			const { activeWorkflow } = getWorkflowData(item, rootData)
			return Boolean(activeWorkflow)
		}),
		// Filter out treeItems lower than level 2.
		filter((item) => item.parentId === rootData.id)
	)
	const rootTreeItem = createTaskTreeItem(rootData, [])
	const treeItems: Record<
		string,
		TreeItem<WorkflowTreeTask | WorkflowStepTreeTask>
	> = {
		[rootTreeItem.id]: rootTreeItem,
	}

	activeTreeItems.forEach((task) => {
		treeItems[task.id] = createTaskTreeItem(task)
	})

	const tasksByStepId = activeTreeItems.reduce<
		Record<string, WorkflowTreeTask[]>
	>((acc, treeItem) => {
		const { taskWorkflow } = getWorkflowData(treeItem, rootData)
		const activeStepId = getActiveStepId(
			rootWorkflow,
			taskWorkflow || undefined
		) as string

		if (!activeStepId || !treeItem) {
			return acc
		}

		acc[activeStepId] = acc[activeStepId] || []
		acc[activeStepId].push(treeItem)
		return acc
	}, {})

	rootWorkflow?.steps.forEach((step) => {
		const taskIds = tasksByStepId[step.id]?.map((item) => item.id)
		const treeItem = {
			type: 'workflowStep',
			id: step.id,
			title: step.title,
		} satisfies WorkflowStepTreeTask
		treeItems[step.id] = createTaskTreeItem(treeItem, taskIds)
	})

	rootTreeItem.children = rootWorkflow?.steps.map((item) => item.id)

	return { items: treeItems, rootId: rootTreeItem.id }
}

export const workflowStrategy: Strategy = {
	buildTree: buildWorkflowTree,
	name: 'workflowStrategy',
}
