import { StoreError } from '../../types'

export const parseQueryClientError = (
	error: unknown,
	fallbackMessage: string
): StoreError => {
	let storeError: StoreError
	if (error instanceof Error) {
		storeError = { error, message: error.message }
	} else {
		storeError = {
			error: new Error(fallbackMessage),
			message: 'Unknown error',
			raw: error,
		}
	}
	return storeError
}

export default parseQueryClientError
